type Props = {
  displayLeft?: boolean;
  customY?: string;
  customX?: string;
  customHeight?: string;
  customWidth?: string;
  customPosition?: string;
  customColor?: string;
};

export const SimpleSpinner = ({
  displayLeft,
  customX,
  customY,
  customHeight,
  customWidth,
  customPosition,
  customColor,
}: Props) => {
  return (
    <div
      className={`animate-spin rounded-full border-l-2 border-t-2 transition-all
      ${customColor ? customColor : 'border-primary group-hover:border-primary/70'}
      ${customPosition ? customPosition : 'absolute'}
      ${customWidth ? customWidth : 'w-2'} ${
        customHeight ? customHeight : 'h-2'
      } ${customY ? customY : '-top-0.5'} ${
        customX ? customX : displayLeft ? '-left-0.5' : '-right-0.5'
      }`}
    ></div>
  );
};
