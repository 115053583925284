import { useAtom } from 'jotai';
import { authUserAtom } from '../store';
import { useDeleteAccount, useScrollToTop, useShowUserPhoto } from '../hooks';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { ConfirmActionModal } from '../components/reusableBlocks/modals';
import { Trans, useTranslation } from 'react-i18next';

/**
 * User will NOT be null here, as this component is only accessible to authenticated users
 */
export const AccountDetails = () => {
  const [user] = useAtom(authUserAtom);
  const [showConfirmActionModal, setShowConfirmActionModal] =
    useState<boolean>(false);
  const [showSpinner, setShowSpinner] = useState<boolean>(false);

  const { t } = useTranslation();

  const [showUserPhoto, setShowUserPhoto] = useShowUserPhoto();

  useScrollToTop();

  const mutation = useDeleteAccount();

  const handleDeleteAccount = async () => {
    setShowSpinner(true);
    await mutation.mutateAsync();
    setShowConfirmActionModal(false);
  };

  return (
    <div className='relative flex w-full flex-col items-center justify-center'>
      <div className='flex w-full items-center justify-center bg-primary-dark py-12 sm:py-16 md:py-20 lg:py-24'>
        <h1 className='heading-700 whitespace-nowrap text-offWhite'>
          {t('account_details.my_account')}
        </h1>
      </div>
      <div className='relative flex w-full items-center justify-center'>
        <div className='absolute top-0 z-0 h-[60px] w-full bg-primary-dark'></div>
        <div className='z-[1] flex h-[120px] w-[120px] items-center justify-center rounded-full border-[8px] border-offWhite bg-grassGreen'>
          {showUserPhoto && (
            <img
              className='h-full w-full rounded-full '
              src={user!.photo}
              alt='User'
              loading='lazy'
              onError={() => setShowUserPhoto(false)}
            />
          )}
          <p
            className={`heading-700 rounded-full text-center ${showUserPhoto ? 'hidden' : 'block'}`}
          >
            {user!.name ? user!.name.charAt(0) : '+'}
          </p>
        </div>
      </div>

      <section className='flex max-w-[400px] flex-col items-center justify-center gap-4 px-4 pt-4 sm:gap-5 sm:pt-5 md:gap-6 md:pt-6 lg:gap-8 lg:pt-8'>
        <div className='flex w-full flex-col items-center justify-center gap-6'>
          <h3 className='heading-300 w-full text-left'>
            {t('account_details.personal_info')}
          </h3>

          <div className='flex w-full flex-col items-start justify-center gap-1 md:gap-2'>
            <h5 className='heading-100'>{t('account_details.email')}</h5>
            <p className='py-1 font-secondary text-base font-semibold leading-4 md:py-2'>
              {user!.email || t('account_details.no_email_found')}
            </p>
          </div>

          <div className='flex w-full flex-col items-start justify-center gap-1 md:gap-2'>
            <h5 className='heading-100'>{t('account_details.username')}</h5>
            <input
              disabled={true}
              type='text'
              placeholder={t('common.coming_soon')}
              className={`flex h-12 w-full items-center rounded border-none bg-cloudGray p-2 pr-8 font-secondary text-base font-semibold uppercase text-primary-dark outline-none ring-2 ring-transparent transition-all selection:bg-primary selection:text-cloudGray placeholder:font-normal placeholder:normal-case placeholder:text-[#636E7A] hover:bg-gray-200 focus:bg-cloudGray focus:ring-2 focus:ring-primary
                hover:focus:bg-cloudGray`}
            />
          </div>

          <div className='flex w-full flex-col items-start justify-center gap-1 md:gap-2'>
            <h5 className='heading-100'>{t('account_details.personal_bio')}</h5>
            <textarea
              name='cart-note'
              cols={60}
              rows={3}
              maxLength={150}
              placeholder={t('common.coming_soon')}
              disabled={true}
              className='w-full resize-none rounded border-none bg-cloudGray px-3 py-4 font-secondary font-semibold leading-[24px] text-primary-dark outline-none transition-all selection:bg-primary selection:text-offWhite placeholder:font-normal placeholder:text-[#636E7A] hover:bg-gray-200 focus:ring-2 focus:ring-primary hover:focus:bg-offWhite'
            />
            <span className='text-100 w-full text-right'>0 / 150</span>
          </div>
        </div>

        <div className='items-center-justify-center flex w-full flex-col gap-6'>
          <h3 className='heading-300 w-full text-left'>
            {t('account_details.subscription')}
          </h3>
          <h5 className='heading-200'>
            <Trans
              i18nKey={t('account_details.plus_free')}
              components={[<span className='text-primary'></span>]}
            />
          </h5>
          <div className='m flex w-full flex-col items-center justify-center gap-2 sm:flex-row'>
            <Link to='/plus/manage-subscription' className='w-full sm:w-auto'>
              <button className='button-primary h-12 w-full sm:w-auto'>
                {t('common.manage_subscription')}
              </button>
            </Link>
            <button
              onClick={() => {
                setShowConfirmActionModal(true);
              }}
              className='button-primary h-12 w-full bg-rose-500 hover:bg-rose-500/70 sm:w-auto'
            >
              {t('account_details.delete_account')}
            </button>
          </div>
        </div>
      </section>

      <ConfirmActionModal
        show={showConfirmActionModal}
        setShow={setShowConfirmActionModal}
        onClickPrimaryAsync={async () => handleDeleteAccount()}
        titleText={t('account_details.are_you_sure')}
        bodyText={
          <Trans
            i18nKey={t(
              'account_details.deleting_your_account_will_remove_all_of_your_cart_history_and_cannot_be_undone',
            )}
            components={[<br />, <b></b>]}
          />
        }
        showSpinner={showSpinner}
        disableButtons={mutation.isPending}
      />
    </div>
  );
};
