import { CartGetItem } from '../../../types';

type Args = {
  cartItems: CartGetItem[];
};

export const sendCartToBHPhotoVideo = ({ cartItems }: Args) => {
  let cartUrl = '';
  let itemParams = '';

  for (let i = 0; i < cartItems.length; i++) {
    // TODO - functionality of selecting items on a per vendor basis is not supported at the moment
    // if ((handleSplitNewVendor || handleSplitOriginalVendor) && (!cartItems[i].selected == true)) {
    //     continue;
    // }

    itemParams += `${cartItems[i].asin}:REG:${cartItems[i].quantity}`;
    if (i < cartItems.length - 1) {
      itemParams += '|';
    }
  }

  cartUrl = `https://www.bhphotovideo.com/bnh/controller/home?O=getpage.jsp&A=cart&Q=add&skus=${encodeURIComponent(itemParams)}`;

  // Wrapping into a partner link
  cartUrl = `${encodeURIComponent(cartUrl)}`;

  return cartUrl;
};
