import { Link } from 'react-router-dom';

type Props = {
  totalQty: number;
  cartId: string;
};

export const CartTotalQty = ({ totalQty, cartId }: Props) => {
  return (
    <Link
      to={`/get/${cartId}`}
      state={{ from: 'history' }}
      className='h-full w-full font-semibold text-primary hover:cursor-pointer hover:underline'
    >
      {totalQty}&nbsp;{totalQty === 1 ? 'Item' : 'Items'}
    </Link>
  );
};
