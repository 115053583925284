import { type SetStateAction } from 'react';

import { ReactComponent as SacPlusIcon } from '../../../../images/icons/sac-plus-fire-cart.svg';
import { useExtension } from '../../../../hooks';
import { useTranslation } from 'react-i18next';

type SetAtom<Args extends unknown[], Result> = (...args: Args) => Result;

type Props = {
  setShow: SetAtom<[SetStateAction<boolean>], void>;
};

export const ImportCartsError = ({ setShow }: Props) => {
  const { importCartsFromExtension } = useExtension();

  const { t } = useTranslation();

  return (
    <>
      <div className='flex flex-col items-center justify-center gap-2'>
        <SacPlusIcon className='-mb-2 -ml-2 h-auto w-[130px]' />
        <h2 className='heading-400 text-center'>
          {t('popup.toast_titles_error')}
        </h2>
      </div>

      <p className='text-300 text-center'>
        {t('popup.looks_like_something_went_wrong_while_importing_your_carts')}
      </p>

      <div className='flex w-full flex-col items-center justify-center gap-2 sm:flex-row'>
        <button
          className='button-primary h-12 w-full max-w-[280px] sm:w-auto'
          onClick={importCartsFromExtension}
        >
          {t('popup.try_again')}
        </button>
      </div>
    </>
  );
};
