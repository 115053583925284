import { useTranslation } from 'react-i18next';
import { useExtension } from '../../hooks';

export const SimpleImportCartsText = () => {
  const { importCartsFromExtension } = useExtension();

  const { t } = useTranslation();

  return (
    <span
      onClick={importCartsFromExtension}
      className='text-200 cursor-pointer text-primary underline transition-all hover:text-primary/70'
    >
      {t('history.import_carts')}
    </span>
  );
};
