import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import ls from '@livesession/sdk';

import { Footer, NavbarFull } from '.';
import { useExtension } from '../hooks';
import { useAtom } from 'jotai';
import { authUserAtom } from '../store';
import dayjs from 'dayjs';

export const Layout = () => {
  const [user] = useAtom(authUserAtom);
  const { pingExtension } = useExtension();

  useEffect(() => {
    pingExtension();
    // We only want to check for extension existence once on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    ls.init('142cc529.3e52a220', { keystrokes: true });

    if (user) {
      try {
        ls.identify({
          email: user.email || '',
          name: user.name || '',
          params: {
            userCartsLength: user.carts.length || '',
            loginCount: user.loginCount || '',
            userCreated: user.createTimestamp
              ? dayjs(user.createTimestamp).format('MMM DD, YYYY - HH:mm')
              : '',
            userLoggedIn: user.loginTimestamp
              ? dayjs(user.loginTimestamp).format('MMM DD, YYYY - HH:mm')
              : '',
            previouslyLoggedIn: user.previousLoginTimestamp
              ? dayjs(user.previousLoginTimestamp).format(
                  'MMM DD, YYYY - HH:mm',
                )
              : '',
            provider: user.provider || '',
            plan: user.subscription.level || '',
          },
        });
      } catch (e) {
        console.error('LS error:', e);
      }
    }

    ls.newPageView();
  }, [user]);

  return (
    <>
      <div className='flex-[1_0_auto] bg-white'>
        <NavbarFull />
        <Outlet />
      </div>
      <Footer />
    </>
  );
};
