export const predefinedTags = [
  'Cancelled',
  'Completed',
  'Not sent',
  'Ordered',
  'Pending',
  'Placed',
  'Purchased',
  'Received',
  'Shipped',
];
