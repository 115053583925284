import Switch from '@mui/material/Switch';
import { alpha, styled } from '@mui/material';

export const SwitchStyled = styled(Switch)(() => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: '#26CA99',
    '&:hover': {
      backgroundColor: alpha('#26CA99', 0.15),
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: '#26CA99',
  },
}));
