import { CartGetItem } from '../../../types';

type Args = {
  cartStore: string;
  cartItems: CartGetItem[];
  cartReferrer?: string;
  tagParam: string | null;
};

export const sendCartToAmazon = ({
  cartStore,
  cartItems,
  cartReferrer,
  tagParam,
}: Args) => {
  let cartUrl = '';
  let itemParams = '';

  for (let i = 0, j = 1; i < cartItems.length; i++) {
    // TODO - functionality of selecting items on a per vendor basis is not supported at the moment
    // if ((handleSplitNewVendor || handleSplitOriginalVendor) && (!cartItems[i].selected === true)) {
    //   continue;
    // }
    const counter = Math.floor(cartItems[i].quantity / 10);
    const remainder = cartItems[i].quantity % 10;

    if (counter === 0) {
      itemParams += `ASIN.${j}=${cartItems[i].asin}&Quantity.${j}=${cartItems[i].quantity}&`;
      j += 1;
    } else {
      for (let q = 1; q <= counter; q++) {
        itemParams += `ASIN.${j}=${cartItems[i].asin}&Quantity.${j}=10&`;
        j = j + 1;
      }

      if (remainder !== 0) {
        itemParams += `ASIN.${j}=${cartItems[i].asin}&Quantity.${j}=${remainder}&`;
        j = j + 1;
      }
    }
  }

  cartUrl = `${cartStore}/gp/aws/cart/add.html?${itemParams}`;

  if (cartReferrer) {
    cartUrl += `&AssociateTag=${cartReferrer}&tag=${cartReferrer}`;
  }

  if (tagParam) {
    cartUrl += `&AssociateTag=${tagParam}&tag=${tagParam}`;
  }

  return cartUrl = `${encodeURIComponent(cartUrl)}`;
};
