import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpBackend from 'i18next-http-backend';

i18n
  .use(HttpBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: `${process.env.REACT_APP_LIVE_URL}/locales/{{lng}}/{{ns}}.json`,
    },

    debug: process.env.NODE_ENV === 'development',

    defaultNS: 'translations',

    detection: {
      caches: ['cookie'],

      order: ['querystring', 'cookie', 'navigator'],

      lookupQuerystring: 'lng',

      lookupCookie: 'lng',
    },

    fallbackLng: 'en',

    interpolation: {
      escapeValue: false, // not needed, React does this.
    },

    keySeparator: '.',

    ns: 'translations',

    supportedLngs: ['en', 'es', 'de', 'fr', 'pt'],
  });

export default i18n;
