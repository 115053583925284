import { CartGetItem } from './cart';

export type CallExtensionArgs = {
  cartId: string;
  vendor: string;
  cartItems?: CartGetItem[];
};

export enum SACFlavorEnum {
  amazon = 'amazon',
  walmart = 'walmart',
  aliexpress = 'aliexpress',
  instacart = 'instacart',
  sac = 'sac',
}

export type SACFlavor = keyof typeof SACFlavorEnum;
