import { type SetStateAction, useRef } from 'react';
import Lottie, { LottieRefCurrentProps } from 'lottie-react';
import { useTranslation } from 'react-i18next';

const animation = require('../../../../images/lotties/cart-sending-bar.json');

type SetAtom<Args extends unknown[], Result> = (...args: Args) => Result;

type Props = {
  setShow: SetAtom<[SetStateAction<boolean>], void>;
};

export const ImportCartsPending = ({ setShow }: Props) => {
  const lottieRef = useRef<LottieRefCurrentProps | null>(null);

  const { t } = useTranslation();

  return (
    <>
      <div className='flex flex-col items-center justify-center gap-2'>
        <Lottie
          lottieRef={lottieRef}
          animationData={animation}
          loop={true}
          autoplay={true}
          style={{ width: 352, height: 'auto', marginTop: -24 }}
        />
        <h2 className='heading-400 text-center'>
          {t('popup.importing_carts')}
        </h2>
      </div>

      <p className='text-300 text-center'>
        {t('popup.we_are_importing_the_history_from_sac_ext')}
      </p>

      <button className='button-secondary h-12' onClick={() => setShow(false)}>
        {t('popup.got_it')}
      </button>
    </>
  );
};
