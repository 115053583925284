import Menu, { MenuProps, menuClasses } from '@mui/material/Menu';
import { styled } from '@mui/material/styles';

export const MenuStyled = styled(({ className, ...props }: MenuProps) => (
  <Menu {...props} classes={{ root: className }} />
))(() => ({
  [`& .${menuClasses.paper}`]: {
    borderRadius: 16,
  },
}));
