export enum HistorySortByEnum {
  'timestamp|desc' = 'Date - Newest to Oldest',
  'timestamp|asc' = 'Date - Oldest to Newest',
  'items|desc' = 'No. of Items',
  // items|asc = 'No. of Items - Lowest to Highest',
  'total|desc' = 'Cart Total - Highest to Lowest',
  'total|asc' = 'Cart Total - Lowest to Highest',
  'vendor|desc' = 'Retailer - Alphabetical',
  // RetailerZA = 'Retailer - Alphabetical - Z to A',
  'tags|desc' = 'Tags',
}

export type HistorySortByValue = keyof typeof HistorySortByEnum;

export enum HistoryFilterByEnum {
  sent_and_received = 'Sent & Received',
  sent = 'Sent Carts',
  received = 'Received Carts',
}

export type HistoryFilterByValue = keyof typeof HistoryFilterByEnum;

export enum ChangelogFilterByEnum {
  all = 'All',
  improvement = 'Improvement',
  // update = 'Update',
  bug_fix = 'Bug Fix',
  retailer_added = 'Retailer Added',
  firefox = 'Firefox',
  safari = 'Safari',
}

export type ChangelogFilterByValue = keyof typeof ChangelogFilterByEnum;
