import { useEffect, useRef, useState } from 'react';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { CustomDropdown } from '../reusableBlocks';
import { useAtom } from 'jotai';
import { historySortByAtom } from 'src/store';
import { SortItems } from './filterAndSortBlocks';
import { useTranslation } from 'react-i18next';

export const SortByDropdown = () => {
  const [sortBy] = useAtom(historySortByAtom);
  const [buttonText, setButtonText] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);

  const { t, i18n } = useTranslation();

  const anchorEl = useRef<HTMLDivElement>(null);

  const handleClick = () => {
    setOpen((previousOpen) => !previousOpen);
  };

  const canBeOpen = open && Boolean(anchorEl);
  const id = canBeOpen ? 'transition-popper' : undefined;

  const i18nLang = i18n.resolvedLanguage
    ? i18n.resolvedLanguage.toLowerCase()
    : i18n.language.toLowerCase();

  useEffect(() => {
    const selectedValue = t(`history.${sortBy}`);
    setButtonText(selectedValue.split(' - ')[0]);
  }, [sortBy, t]);

  return (
    <>
      <div
        className={`flex h-12 cursor-pointer items-center justify-between rounded bg-primary-off p-3 font-semibold transition-all hover:bg-primary-offHover ${
          open ? 'ring-2 ring-primary' : ''
        } ${
          i18nLang === 'en'
            ? 'w-[203px]'
            : i18nLang === 'es'
              ? 'w-[267px]'
              : i18nLang === 'ru'
                ? 'w-[242px] 2xl:w-[362px]'
                : i18nLang === 'zh'
                  ? 'w-[200px]'
                  : 'w-[203px]'
        }`}
        aria-describedby={id}
        onClick={handleClick}
        ref={anchorEl}
      >
        <span
          className={`${i18nLang === 'ru' ? 'hidden 2xl:block' : ''} whitespace-nowrap pr-1.5 text-sm font-normal text-cloudGray`}
        >
          {t('history.sort_by')}:
        </span>
        <span className='mr-auto truncate whitespace-nowrap pr-2 text-cloudGray'>
          {buttonText}
        </span>
        <KeyboardArrowUpIcon
          style={{
            fontSize: '28px',
            transform: open ? 'rotate(0deg)' : 'rotate(180deg)',
            transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1)',
          }}
          className='-mr-[7px] transform cursor-pointer text-blueGray'
        />
      </div>
      <CustomDropdown
        id={id}
        open={open}
        anchorEl={anchorEl.current}
        handleClick={handleClick}
        customStyle={{
          marginTop: '6px !important',
          marginRight: '-2px !important',
        }}
      >
        <div className='z-20 flex min-w-fit flex-col items-start justify-center rounded-lg bg-cloudGray font-secondary'>
          <div className='flex flex-col items-start gap-2 py-4 pl-6 pr-8 font-semibold'>
            <SortItems handleClick={handleClick} />
          </div>
        </div>
      </CustomDropdown>
    </>
  );
};
