import { t } from 'i18next';
import { useAtom } from 'jotai';
import { Fragment, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useContinueWithItems, useGetCartQuery } from '../../hooks';
import { getCartIdAtom } from '../../store';
import type { CartGetData } from '../../types';
import { LoadingCart } from '../reusableBlocks';
import { CartBaseNotes } from '../reusableBlocks/cartBlocks';
import {
  ExtensionInstalledModal,
  ExtensionMissingModal,
  ChunkCartModal,
} from '../reusableBlocks/modals';
import { GetCartItem } from './getCartItems';
import { GetCompareCard, GetCompareHeader } from './getCompareBlocks';

export const GetCompare = () => {
  const [cartId] = useAtom(getCartIdAtom);
  const sendItems = useContinueWithItems();
  const nav = useNavigate();

  const cartQuery = useGetCartQuery();

  // This useEffect and the below if statements look REALLY F'in stupid and bad, but it works. Better way to do this??
  useEffect(() => {
    if (
      cartQuery.isError ||
      cartQuery.data === null ||
      (cartQuery.data === undefined && !cartQuery.isLoading)
    ) {
      nav('/get', { state: { error: true, cartId } });
    }
  }, [cartQuery.isError, cartId, nav, cartQuery.data, cartQuery.isLoading]);

  if (
    cartQuery.data === null ||
    (cartQuery.data === undefined && !cartQuery.isLoading)
  ) {
    return null;
  }

  if (cartQuery.isLoading) {
    return <LoadingCart />;
  }

  const handleContinue = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    let handleSplitNewVendor = false;
    let handleSplitOriginalVendor = false;
    let ignoreSavings = false;

    switch (event.currentTarget.name) {
      case 'all':
        ignoreSavings = true;
        break;
      case 'splitNew':
        handleSplitNewVendor = true;
        break;
      case 'splitOriginal':
        handleSplitOriginalVendor = true;
        break;
      default:
        console.log("shouldn't be here");
        break;
    }

    sendItems({
      id: cartQuery.data?.id as string,
      cart: cartQuery.data as CartGetData,
      handleSplitNewVendor,
      handleSplitOriginalVendor,
      ignoreSavings,
      page: 'get',
    });
  };

  return (
    <div className=''>
      <GetCompareHeader cartQuery={cartQuery} handleContinue={handleContinue} />
      {cartQuery.data?.matchedVendorCart.items.map((item, i) => (
        <Fragment key={`${item.asin},${i}` || i}>
          <GetCartItem
            item={item}
            ccy={cartQuery.data?.cartCCY}
            ccys={cartQuery.data?.cartCCYS}
            vendor={cartQuery.data?.matchedVendorCart.matchTo}
            compare={true}
            locale={cartQuery.data?.locale || 'us'}
          />
        </Fragment>
      ))}

      <CartBaseNotes notes={cartQuery.data?.note} border={false} />

      <div className='flex flex-col flex-wrap items-center justify-between gap-y-4 bg-primary-dark p-4 py-10 xs:p-6 smMed:gap-x-4 md:px-10 md:py-12 lg:px-12 lg:py-14'>
        <h2 className='heading-400 whitespace-normal text-center text-offWhite transition-all'>
          {t('get.no_extra_savings_found')}
        </h2>
        <p className='text-200 text-center text-blueGray'>
          {t('get.no_savings_on_the_rest_of_the_items')}:
        </p>

        {cartQuery.data?.originalVendorCart.items.length! > 0 && (
          <GetCompareCard
            vendor={cartQuery.data?.originalVendorCart.matchTo!}
            vendorDisplayName={
              cartQuery.data?.originalVendorCart.matchToDisplayName!
            }
            ccy={cartQuery.data?.cartCCY!}
            ccys={cartQuery.data?.cartCCYS!}
            locale={cartQuery.data?.locale || 'us'}
            price={
              typeof cartQuery.data?.originalVendorCart
                .splitOriginalTotalPrice! === 'number'
                ? cartQuery.data?.originalVendorCart.splitOriginalTotalPrice!
                : parseFloat(
                    cartQuery.data?.originalVendorCart.splitOriginalTotalPrice!,
                  ).toFixed(2)
            }
            itemsLength={cartQuery.data?.originalVendorCart.originalItemsQty!}
            allText={false}
            btnName={'splitOriginal'}
            handleContinue={handleContinue}
          />
        )}
      </div>

      {cartQuery.data?.originalVendorCart.items.map((item, i) => (
        <Fragment key={`${item.asin},${i}` || i}>
          <GetCartItem
            item={item}
            ccy={cartQuery.data?.cartCCY!}
            ccys={cartQuery.data?.cartCCYS!}
            vendor={cartQuery.data?.originalVendorCart.matchTo}
            compare={true}
            locale={cartQuery.data?.locale || 'us'}
          />
        </Fragment>
      ))}

      <div className='flex flex-col flex-wrap items-center justify-between gap-y-4 bg-primary-dark p-4 py-10 xs:p-6 smMed:gap-x-4 md:px-10 md:py-12 lg:px-12 lg:py-14'>
        <h2 className='heading-400 whitespace-normal text-center text-offWhite transition-all'>
          {t('get.higher_price_cart')}
        </h2>
        <p className='text-200 text-center text-blueGray'>
          {t(
            'get.no_thanks_savings_are_not_important_to_me_take_me_to_vendor',
            { vendorName: cartQuery.data?.vendorDisplayName },
          )}
          :
        </p>

        <GetCompareCard
          vendor={cartQuery.data?.vendor!}
          vendorDisplayName={cartQuery.data?.vendorDisplayName!}
          ccy={cartQuery.data?.cartCCY!}
          ccys={cartQuery.data?.cartCCYS!}
          locale={cartQuery.data?.locale || 'us'}
          price={
            typeof cartQuery.data?.cartTotalPrice! === 'number'
              ? cartQuery.data?.cartTotalPrice!
              : parseFloat(cartQuery.data?.cartTotalPrice!).toFixed(2)
          }
          itemsLength={cartQuery.data?.cartTotalQty!}
          allText={true}
          btnName={'all'}
          handleContinue={handleContinue}
        />
      </div>

      <ChunkCartModal
        cartId={cartId}
        cartQueryData={cartQuery.data as CartGetData}
        page='get'
      />

      <ExtensionInstalledModal
        cartQueryData={cartQuery.data as CartGetData}
        page='get'
      />
      <ExtensionMissingModal
        numOfItems={cartQuery.data?.cartTotalQty || 0}
        items={cartQuery.data?.items || []}
        cartId={cartId}
        cartVendor={
          cartQuery.data?.vendorDisplayName || cartQuery.data?.vendor || ''
        }
        cartValue={Number(cartQuery.data?.cartTotalPrice) || 0}
        cartCCY={cartQuery.data?.cartCCY || 'USD'}
        page='get'
      />
    </div>
  );
};
