import type { CallExtensionArgs } from '../../../types/ext';

type Args = {
  cartId: string;
  sendCartToExtension: (args: CallExtensionArgs) => void;
  cartVendor: string;
};

export const sendCartToOtherVendors = ({
  cartId,
  sendCartToExtension,
  cartVendor,
}: Args) => {
  return sendCartToExtension({ cartId, vendor: cartVendor });

  // TODO - functionality of selecting items on a per vendor basis is not supported at the moment
  // else {
  //     const selectedItems = [];

  //     for (let i = 0; i < cartItems.length; i++) {
  //         if (cartItems[i].selected == true) {
  //             selectedItems.push(cartItems[i]);
  //         }
  //     }

  //     sendCartToExtension(cart.id, selectedItems);
  // }
};
