import { Trans, useTranslation } from 'react-i18next';
import { WelcomeModalTitle } from '../WelcomeModalBlocks';

type Props = {
  step: 1 | 2 | 3 | 4 | 5 | 6;
};

export const WelcomeModalStep1 = ({ step }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <WelcomeModalTitle step={step}>
        <Trans
          i18nKey={t('popup.welcome_to_sac_plus')}
          components={[<span className='text-primary'></span>]}
        />
      </WelcomeModalTitle>

      <p className='text-center font-secondary text-lg font-normal text-primary-dark'>
        {t('popup.the_beta_period_for_sac_plus')}
      </p>

      {/* <p className='text-center font-secondary text-lg font-normal text-primary-dark'>
          View your latest 5 carts here for free. If you need to access your
          full cart history you can{' '}
          <Link to='/plus/upgrade' className='text-primary hover:underline'>
            upgrade
          </Link>{' '}
          at any time
        </p> */}
    </>
  );
};
