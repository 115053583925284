import { useAtom } from 'jotai';
import { LottieRefCurrentProps } from 'lottie-react';
import { useRef } from 'react';
import {
  useActiveBrowser,
  useContinueWithItems,
  useGetCartQuery,
} from '../../hooks';
import { getCartIdAtom } from '../../store';
import { CartGetData } from '../../types';
import { LoadingCart } from '../reusableBlocks';
import { CartBaseNotes } from '../reusableBlocks/cartBlocks';
import {
  ExtensionInstalledModal,
  ExtensionMissingModal,
  ChunkCartModal,
} from '../reusableBlocks/modals';
import { GetCartItemsList } from './getCartItems';
import {
  GetReviewFooter,
  GetReviewHeader,
  GetReviewMissingItems,
  GetReviewOwnership,
} from './getReviewBlocks';

type Props = {
  page?: 'get' | 'embed';
};

export const GetReview = ({ page = 'get' }: Props) => {
  const [cartId] = useAtom(getCartIdAtom);
  const lottieRefHeader = useRef<LottieRefCurrentProps | null>(null);
  const lottieRefFooter = useRef<LottieRefCurrentProps | null>(null);

  const sendItems = useContinueWithItems();
  const activeBrowser = useActiveBrowser();

  const cartQuery = useGetCartQuery();

  const handleContinue = async () => {
    lottieRefHeader.current?.playSegments([0, 30], true);
    lottieRefFooter.current?.playSegments([0, 30], true);

    // Safari does not execute redirects in intervals or timeouts
    if (activeBrowser === 'Safari') {
      sendItems({
        id: cartId,
        cart: cartQuery.data as CartGetData,
        handleSplitNewVendor: false,
        handleSplitOriginalVendor: false,
        page,
      });

      return;
    }

    // wait for the lottie animation to run
    await new Promise((resolve) => setTimeout(resolve, 1000));

    sendItems({
      id: cartId,
      cart: cartQuery.data as CartGetData,
      handleSplitNewVendor: false,
      handleSplitOriginalVendor: false,
      page,
    });

    // lottieRefHeader.current?.setDirection(-1);
    // lottieRefFooter.current?.setDirection(-1);

    lottieRefHeader.current?.playSegments([30, 0], true);
    lottieRefFooter.current?.playSegments([30, 0], true);

    // await new Promise((resolve) => setTimeout(resolve, 1000));

    // lottieRefHeader.current?.setDirection(1);
    // lottieRefFooter.current?.setDirection(1);
  };

  return (
    <div id='page' className='overflow-x-clip bg-offWhite'>
      <GetReviewHeader
        ref={lottieRefHeader}
        handleContinue={handleContinue}
        cartTotalQty={cartQuery.data?.cartTotalQty || 0}
        cartTimestamp={cartQuery.data?.timestamp || 0}
        cartTitle={cartQuery.data?.title || undefined}
        cartPrice={cartQuery.data?.formattedCartTotalPrice || '$0.00 USD'}
        cartVendor={cartQuery.data?.vendor!}
        cartVendorDisplayName={cartQuery.data?.vendorDisplayName!}
        cartCreated={cartQuery.data?.ownershipData?.created || false}
        cartStore={cartQuery.data?.store || undefined}
        displaySourceSite={
          cartQuery.data && cartQuery.data.displaySourceSite
            ? (cartQuery.data[
                cartQuery.data.displaySourceSite as keyof CartGetData
              ] as string)
            : undefined
        }
        isLoading={cartQuery.isLoading}
      />
      {page === 'get' &&
        cartQuery.data?.ownershipData &&
        !cartQuery.isLoading && (
          <GetReviewOwnership
            cartTimestamp={cartQuery.data.timestamp || 0}
            cartListed={cartQuery.data.ownershipData?.listed || false}
            cartNotes={cartQuery.data.ownershipData?.note || ''}
            cartTags={cartQuery.data.ownershipData?.tags || []}
            cartTitle={cartQuery.data.title || undefined}
            cartCreated={cartQuery.data.ownershipData?.created || false}
            cartStore={cartQuery.data?.store || undefined}
            cartCCY={cartQuery.data?.cartCCY || 'USD'}
            cartCCYS={cartQuery.data?.cartCCYS || '$'}
            cartItems={cartQuery.data?.items || []}
          />
        )}
      {cartQuery.isLoading ? (
        <LoadingCart customTextColor='text-primary' customBgColor='bg-white' />
      ) : (
        <>
          <GetReviewMissingItems
            missingCount={cartQuery.data?.missingCount || 0}
            cartItemsLength={cartQuery.data?.items.length || 1}
          />
          <GetCartItemsList
            cartItems={cartQuery.data?.items || []}
            cartCCY={cartQuery.data?.cartCCY || 'USD'}
            cartCCYS={cartQuery.data?.cartCCYS || '$'}
            cartVendor={cartQuery.data?.vendor!}
            cartLocale={cartQuery.data?.locale || 'us'}
          />
          <CartBaseNotes notes={cartQuery.data?.note} border />
          <GetReviewFooter
            ref={lottieRefFooter}
            handleContinue={handleContinue}
            ccy={cartQuery.data?.cartCCY || 'USD'}
            ccyS={cartQuery.data?.cartCCYS || '$'}
            cartPrice={cartQuery.data?.formattedCartTotalPrice || '$0.00 USD'}
            totalQty={cartQuery.data?.cartTotalQty || 0}
            cartTitle={cartQuery.data?.title || undefined}
            cartItems={cartQuery.data?.items || []}
            cartTimestamp={cartQuery.data?.timestamp || 0}
            cartStore={cartQuery.data?.store || undefined}
            isLoading={cartQuery.isLoading}
            page={page}
          />
        </>
      )}

      <ChunkCartModal
        cartId={cartId}
        cartQueryData={cartQuery.data as CartGetData}
        page={page}
      />

      <ExtensionInstalledModal
        cartQueryData={cartQuery.data as CartGetData}
        page={page}
      />
      <ExtensionMissingModal
        numOfItems={cartQuery.data?.cartTotalQty || 0}
        items={cartQuery.data?.items || []}
        cartId={cartId}
        cartVendor={
          cartQuery.data?.vendorDisplayName || cartQuery.data?.vendor || ''
        }
        cartValue={Number(cartQuery.data?.cartTotalPrice) || 0}
        cartCCY={cartQuery.data?.cartCCY || 'USD'}
        page={page}
      />
    </div>
  );
};
