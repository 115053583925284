import styled from '@emotion/styled';
import { Dialog } from '@mui/material';

export const WelcomeModalStyled = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    // padding: 32,
    borderRadius: 16,
    backgroundColor: '#FEFEFE',
    position: 'relative',
    width: '90%',
    maxWidth: 646,
    minWidth: 294,
  },
}));
