import { useAtom } from 'jotai';
import { authUserAtom } from '../store';
import { useEffect, useState } from 'react';

export const useShowUserPhoto = (): [
  boolean,
  React.Dispatch<React.SetStateAction<boolean>>,
] => {
  const [user] = useAtom(authUserAtom);
  const [showUserPhoto, setShowUserPhoto] = useState<boolean>(false);

  useEffect(() => {
    if (user?.photo) {
      setShowUserPhoto(true);
    }
  }, [user]);

  return [showUserPhoto, setShowUserPhoto];
};
