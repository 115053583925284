import { Link } from 'react-router-dom';
import { toast } from 'sonner';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import { TooltipStyled, ToastStyled } from '../../reusableBlocks';
import { CartTitle } from '../../cartHistory/cartDisplayBlocks/reusables';
import {
  ContinueTotalBlock,
  PriceDisclaimerBlock,
  VendorLogoBlock,
} from '../reuseables';
import { forwardRef } from 'react';
import { LottieRefCurrentProps } from 'lottie-react';
import { useAtom } from 'jotai';
import { getCartIdAtom } from '../../../store';
import { GetReviewHeaderAllCartsBtn } from './GetReviewHeaderAllCartsBtn';
import { Trans, useTranslation } from 'react-i18next';
// import { CartTotalQty } from '../../cartHistory/cartDisplayBlocks/reusables';

type Props = {
  handleContinue: () => void;
  cartTotalQty: number;
  cartTimestamp: number;
  cartTitle: string | undefined;
  cartPrice: string;
  cartVendor: string;
  cartVendorDisplayName: string;
  cartCreated: boolean;
  displaySourceSite: string | undefined;
  isLoading: boolean;
  cartStore?: string;
};

export const GetReviewHeader = forwardRef<LottieRefCurrentProps, Props>(
  (
    {
      handleContinue,
      cartTotalQty,
      cartTimestamp,
      cartTitle,
      cartPrice,
      cartVendor,
      cartVendorDisplayName,
      cartCreated,
      cartStore,
      displaySourceSite,
      isLoading,
    },
    ref,
  ) => {
    const [cartId] = useAtom(getCartIdAtom);

    const { t } = useTranslation();

    return (
      <div className='flex flex-row flex-wrap items-end justify-between gap-y-8 bg-primary-dark p-6 pb-8 pt-0 smMed:gap-x-4 md:px-10 lg:px-12'>
        <div className='flex w-full flex-col items-start justify-center gap-4 sm:w-auto'>
          <GetReviewHeaderAllCartsBtn />
          {isLoading ? (
            <div className='skeleton-dark h-[31px] w-[260px] md:h-[39px]'></div>
          ) : cartTitle ? (
            <CartTitle
              cartId={cartId}
              cartTitle={cartTitle}
              cartCreated={cartCreated}
              forGetPage={true}
              cartVendorDisplayName={cartVendorDisplayName}
            />
          ) : null}

          <div className='flex w-full flex-row flex-wrap items-center justify-start gap-2 smMed:w-auto'>
            <div className='inline-flex w-fit items-center justify-center'>
              {isLoading ? (
                <div className='skeleton-dark h-8 w-8'></div>
              ) : (
                <VendorLogoBlock vendor={cartVendor} />
              )}
              {isLoading ? (
                <div className='skeleton-dark mx-2 h-8 w-[60px]'></div>
              ) : (
                <span className='heading-200 px-2 py-[2.5px] text-offWhite'>
                  {cartId}
                </span>
              )}
              {isLoading ? (
                <div className='skeleton-dark mr-[1px] h-8 w-[19px]'></div>
              ) : (
                <TooltipStyled title={t('popup.copy_list_id')}>
                  <div
                    onClick={() => {
                      navigator.clipboard.writeText(cartId);
                      toast.custom(
                        (toastBlock) => (
                          <ToastStyled
                            variant='info'
                            toastTitle={t('popup.copied_to_clipboard')}
                            handleClose={() => toast.dismiss(toastBlock)}
                          >
                            <Trans
                              i18nKey={t('popup.cart_copied_to_clipboard')}
                              values={{ cartId }}
                              components={[<strong />]}
                            />
                          </ToastStyled>
                        ),
                        {
                          duration: 2000,
                        },
                      );
                    }}
                    className='flex cursor-pointer items-center justify-center fill-offGray hover:fill-gray-300'
                  >
                    <ContentCopyIcon
                      className='transition-all'
                      style={{ fontSize: 20, fill: 'inherit' }}
                    />
                  </div>
                </TooltipStyled>
              )}
              {isLoading ? (
                <div className='skeleton-dark ml-[1px] h-8 w-[19px]'></div>
              ) : (
                <TooltipStyled title={t('common.share_list')}>
                  <Link
                    to={`${process.env.REACT_APP_LIVE_URL}/share/${cartId}`}
                    reloadDocument
                    className='flex cursor-pointer items-center justify-center fill-offGray hover:fill-gray-300'
                  >
                    <ShareOutlinedIcon
                      className='transition-all'
                      style={{ fontSize: 20, fill: 'inherit' }}
                    />
                  </Link>
                </TooltipStyled>
              )}
            </div>
            {isLoading ? (
              <div className='skeleton-dark h-[22px] w-[240px]'></div>
            ) : (
              <PriceDisclaimerBlock
                timestamp={cartTimestamp}
                itemCount={cartTotalQty}
                vendorDisplayName={cartVendorDisplayName || undefined}
                displaySourceSite={displaySourceSite}
                forCompare={false}
                cartStore={cartStore}
                cartVendor={cartVendor}
              />
            )}
          </div>
        </div>
        <ContinueTotalBlock
          ref={ref}
          handleContinue={handleContinue}
          totalPrice={cartPrice}
          totalQty={cartTotalQty}
          forReviewFooter={false}
          isLoading={isLoading}
        />
      </div>
    );
  },
);
