import { useRef, useState } from 'react';
import TuneIcon from '@mui/icons-material/Tune';
import CloseIcon from '@mui/icons-material/Close';
import { CustomDropdown } from '..';
import { FilterItems, SortItems } from './filterAndSortBlocks';
import { useTranslation } from 'react-i18next';

type Props = {
  isListView: boolean;
  setIsListView: React.Dispatch<React.SetStateAction<boolean>>;
};

export const MobileDropdown = ({ isListView, setIsListView }: Props) => {
  const [open, setOpen] = useState<boolean>(false);

  const { t } = useTranslation();

  const anchorEl = useRef<HTMLDivElement>(null);

  const handleClick = () => {
    setOpen((previousOpen) => !previousOpen);
  };

  const canBeOpen = open && Boolean(anchorEl);
  const id = canBeOpen ? 'transition-popper' : undefined;

  return (
    <div className='font-secondary'>
      <div
        className={`flex cursor-pointer items-center justify-center fill-skyBlue hover:fill-white ${
          open ? 'fill-white' : 'fill-skyBlue'
        }`}
        aria-describedby={id}
        onClick={handleClick}
        ref={anchorEl}
      >
        <TuneIcon
          className='cursor-pointer p-0.5 transition-all'
          style={{ fontSize: '30px', fill: 'inherit' }}
        />
      </div>
      <CustomDropdown
        id={id}
        open={open}
        anchorEl={anchorEl.current}
        handleClick={handleClick}
      >
        <div className='z-20 flex min-w-[200px] flex-col items-start justify-center rounded-lg bg-cloudGray font-secondary xs:min-w-[240px]'>
          {/* not setting items-center here on purpose - allow the 'X' button to push up a little */}
          <div className='inline-flex w-full items-center justify-between p-2 xs:p-3'>
            <span className='whitespace-nowrap font-secondary text-primary-dark'>
              {t('history.sort_by')}:
            </span>
            <div
              onClick={handleClick}
              className='cursor-pointer fill-darkSteel hover:fill-gray-400'
            >
              <CloseIcon
                className='px-[1px] pb-[2px] transition-all'
                style={{ fontSize: '22px', fill: 'inherit' }}
              />
            </div>
          </div>
          <div className='flex flex-col items-start gap-2 px-3 pb-4 pl-3 pr-4 font-semibold xs:px-6'>
            <SortItems handleClick={handleClick} />
          </div>

          {/*
              CART TYPES
            */}
          <div className='inline-flex w-full justify-between p-2 xs:p-3'>
            <span className='whitespace-nowrap font-secondary text-primary-dark'>
              {t('history.cart_type')}:
            </span>
          </div>
          <div className='flex flex-col items-start gap-2 px-3 pb-6 pl-3 pr-4 font-semibold xs:px-6'>
            <FilterItems handleClick={handleClick} />
          </div>

          {/* 
              View Type - List or Grid
            */}
          {/* <div className='inline-flex w-full justify-between p-2 xs:p-3'>
            <span className='whitespace-nowrap font-secondary text-primary-dark'>
              View:
            </span>
          </div>
          <div className='flex flex-col items-start gap-2 px-3 pb-6 pl-3 pr-4 font-semibold xs:px-6'>
            <button
              className={`w-full whitespace-nowrap py-0.5 text-left text-primary transition-all hover:text-primary-dark ${
                isListView
                  ? 'text-primary-dark hover:text-primary'
                  : 'text-primary hover:text-primary-dark'
              }`}
              name='ListView'
              onClick={(e) => {
                e.preventDefault();
                setIsListView(true);
              }}
            >
              List
            </button>
            <button
              className={`w-full whitespace-nowrap py-0.5 text-left text-primary transition-all hover:text-primary-dark ${
                !isListView
                  ? 'text-primary-dark hover:text-primary'
                  : 'text-primary hover:text-primary-dark'
              }`}
              name='GridView'
              onClick={(e) => {
                e.preventDefault();
                setIsListView(false);
              }}
            >
              Grid
            </button>
          </div> */}
        </div>
      </CustomDropdown>
    </div>
  );
};
