import Popover, { PopoverProps, popoverClasses } from '@mui/material/Popover';
import { styled } from '@mui/material/styles';

export const PopoverStyled = styled(({ className, ...props }: PopoverProps) => (
  <Popover {...props} classes={{ root: className }} />
))(() => ({
  [`& .${popoverClasses.paper}`]: {
    borderRadius: 16,
  },
}));
