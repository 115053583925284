type Props = {
  vendor: string;
  compare?: boolean;
};

export const VendorLogoBlock = ({ vendor, compare = false }: Props) => {
  return (
    <div
      className={`flex items-center justify-center rounded bg-white ${compare ? 'h-7 w-7' : 'h-8 w-8'}`}
    >
      <img
        src={`${process.env.REACT_APP_LIVE_URL}/images/favis/32/${vendor}.png`}
        alt='vendor-logo'
        loading='lazy'
        className={`rounded-sm ${compare ? 'h-6 w-6' : 'h-7 w-7'}`}
      />
    </div>
  );
};
