import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

export const TooltipStyled = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#F1F1F1',
    color: '#04033C',
    fontSize: 14,
    borderRadius: 8,
    paddingInline: 8,
    paddingBlock: 4,
    fontFamily: 'Open Sans',
    marginTop: '8px !important',
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25)',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: '#F1F1F1',
  },
  [`& .${tooltipClasses.popper}`]: {
    color: '#F1F1F1',
  },
  [`& .${tooltipClasses.popperArrow}`]: {
    color: '#F1F1F1',
  },
}));
