import { CartGetItem } from '../../../types';
import TagManager from 'react-gtm-module';

type Args = {
  cartId: string;
  cartStore: string;
  cartItems: CartGetItem[];
  loggerUrl: string;
  cartValue: number;
  cartCCY: string;
  cartAp?: string;
  page: 'get' | 'embed';
};

export const sendCartToWalmart = ({
  cartId,
  cartStore,
  cartItems,
  loggerUrl,
  cartValue,
  cartCCY,
  cartAp,
  page,
}: Args): {
  cartUrl?: string | undefined;
  cartChunks?: string[];
} => {
  let cartUrl = '';
  let a2c = 'buynow';
  let ap = '';

  let divider = '|';
  const offers = [],
    items = [];

  // TODO: Disabled until we add support for this on the UI
  // append ap if it exists
  // if (cartAp) {
  //   ap = `ap=${cartAp}`;
  // }

  for (let i = 0; i < cartItems.length; i++) {
    // TODO - functionality of selecting items on a per vendor basis is not supported at the moment
    // if ((handleSplitNewVendor || handleSplitOriginalVendor) && (!cartItems[i].selected == true)) {
    //     continue;
    // }

    // https://affil.walmart.com/cart/buynow?offers=29D74C2CDB374A44AC819F536607C591|1&items=554875938|1&ap=d8218e84-0323-48b3-94e6-d789ea3c6254

    if (cartItems[i]['offerId' as keyof CartGetItem]) {
      offers.push(
        `${cartItems[i]['offerId' as keyof CartGetItem]}${divider}${cartItems[i].quantity}`,
      );
    } else {
      items.push(
        `${cartItems[i]['asin' as keyof CartGetItem]}${divider}${cartItems[i].quantity}`,
      );
    }
  }

  const impact =
    'https://goto.walmart.com/c/1425298/566719/9383?sourceid=imp_000011112222333344&veh=aff&u=';

  const affilLink = `https://affil.walmart.com/cart/${a2c}?${ap}`;

  let offerParams = '';
  let itemParams = '';

  if (offers.length) {
    offerParams += offers.join(',');
  }

  if (items.length) {
    itemParams += items.join(',');
  }

  // if the itemParams is longer than 3800 characters, we need to chunk the cart (more characters get added later by the server, this is a safe number)
  const maxCharLimit = 3800;
  // we will split the offers and items into separate chunks
  if (offerParams.length + itemParams.length > maxCharLimit) {
    const offersSplit = offerParams ? offerParams.split(',') : '';
    const itemsSplit = itemParams ? itemParams.split(',') : '';

    const chunks = [];
    let currentChunk = '';

    // Build the chunks. When the currentChunk is over the maxCharLimit, we will push it to the chunks array
    for (let i = 0; i < offersSplit.length; i++) {
      if (currentChunk === '' || !currentChunk.includes('&offers=')) {
        currentChunk += '&offers=';
      }

      currentChunk += offersSplit[i];

      // length of ~40 characters per offer id
      if (currentChunk.length > maxCharLimit - 40) {
        chunks.push(currentChunk);
        currentChunk = '';
      } else {
        currentChunk += ',';
      }
    }

    // Build the chunks. When the currentChunk is over 4k characters, we will push it to the chunks array
    for (let i = 0; i < itemsSplit.length; i++) {
      if (currentChunk === '' || !currentChunk.includes('&items=')) {
        currentChunk += '&items=';
      }

      currentChunk += itemsSplit[i];

      // length of ~20 characters per offer id
      if (currentChunk.length > maxCharLimit - 20) {
        chunks.push(currentChunk);
        currentChunk = '';
      } else {
        currentChunk += ',';
      }
    }

    // make sure we push the last chunk
    if (currentChunk !== '') {
      if (currentChunk.endsWith(',')) {
        chunks.push(currentChunk.substring(0, currentChunk.length - 1));
      } else {
        chunks.push(currentChunk);
      }
    }

    // turn the chunks into full URLs with the logger url, impact link, and affil link
    for (let i = 0; i < chunks.length; i++) {
      const tempChunk: string =
        impact + encodeURIComponent(affilLink + chunks[i]);
      const chunkUrl = `${loggerUrl}${encodeURIComponent(tempChunk)}`;
      chunks[i] = chunkUrl;
    }

    // send the event to GTM
    TagManager.dataLayer({
      dataLayer: {
        event: 'Chunk Cart',
        eventCategory: 'engagement',
        eventLabel: `${page && page === 'embed' ? 'Embed' : 'Get'} Page`,
        eventValue: {
          chunks: chunks.length,
          cartId,
          cartValue,
          cartCCY,
          cartVendor: 'Walmart',
        },
      },
    });

    return { cartChunks: chunks };
  }

  TagManager.dataLayer({
    dataLayer: {
      event: 'Send to Vendor',
      eventCategory: 'engagement',
      eventLabel: `${page && page === 'embed' ? 'Embed' : 'Get'} Page`,
      eventValue: {
        cartValue,
        cartCCY,
        cartId,
        cartVendor: 'Walmart',
      },
    },
  });

  // append offers and items to the cart URL if we don't chunk the cart above
  cartUrl =
    impact +
    encodeURIComponent(
      affilLink +
        `${offerParams ? `&offers=${offerParams}` : ''}` +
        `${itemParams ? `&items=${itemParams}` : ''}`,
    );

  return { cartUrl: `${loggerUrl}${encodeURIComponent(cartUrl)}` };
};
