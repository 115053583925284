import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import type { TReviewFormState } from '../../../types';
import shockGif from '../../../images/other/cena-shock.gif';
import { useFeedbackReviewMutation } from '../../../hooks';
import { useTranslation } from 'react-i18next';

const schema = z.object({
  feedback: z
    .string()
    .min(4, { message: 'Please let us know how we can do better' })
    .max(2000, { message: 'Feedback is too long' }),
  email: z.string().email({ message: 'Invalid email address' }),
});

export type SadFeedbackForm = z.infer<typeof schema>;

type Props = {
  setReviewFormState: React.Dispatch<React.SetStateAction<TReviewFormState>>;
};

export const GetFeedbackSad = ({ setReviewFormState }: Props) => {
  const mutation = useFeedbackReviewMutation(setReviewFormState);

  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SadFeedbackForm>({ resolver: zodResolver(schema) });

  const onSubmit = (data: SadFeedbackForm) => {
    mutation.mutate(data);
  };

  return (
    <div className='flex w-full max-w-[400px] flex-col items-center justify-center gap-4'>
      <img
        src={shockGif}
        width='250px'
        height='139.73px'
        alt='John Cena Shocked GIF'
      />
      <h3 className='heading-400 text-center text-offWhite'>
        {t('get.no_how_can_we_improve')}
      </h3>
      <p className='text-200 text-balance text-center text-blueGray'>
        {t('get.well_send_you_a')}&nbsp;
        <span className='text-customYellow'>{t('get.5_dollar_gift_card')}</span>
        &nbsp;{t('get.in_return_for_your_honest_feedback')}:
      </p>
      <div className='flex w-full flex-col items-center justify-center gap-6'>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className='flex w-full max-w-[360px] flex-col items-center justify-center gap-4'
        >
          <div className='flex w-full flex-col items-start justify-center gap-0.5'>
            <TextareaAutosize
              {...register('feedback')}
              autoComplete='off'
              placeholder='Say as much or as little as you like'
              minRows={6}
              maxLength={2000}
              className='w-full resize-none rounded border-none bg-primary-off px-3 py-2 font-secondary text-base leading-6 text-offWhite placeholder:text-cloudGray/70 focus:ring-2 focus:ring-primary'
              name='feedback'
            />
            <span className='text-red-500'>
              {errors.feedback?.message?.toString()}
            </span>
          </div>

          <div className='flex w-full flex-col items-center justify-start gap-2'>
            <label className='text-100 w-full text-offWhite'>
              {t('get.where_should_we_send_your_gift_card')}
            </label>
            <div className='flex w-full flex-col items-center justify-start gap-0.5'>
              <input
                {...register('email')}
                placeholder='Email Address'
                className='h-12 w-full rounded border-none bg-primary-off px-3 py-4 font-secondary text-base leading-4 text-offWhite placeholder:text-cloudGray/70 focus:ring-2 focus:ring-primary'
              />
              <span className='w-full text-left text-red-500'>
                {errors.email?.message?.toString()}
              </span>
            </div>
          </div>

          <button
            type='submit'
            disabled={mutation.isPending}
            className='button-primary h-12 w-full text-wrap text-center'
          >
            {t('get.send_me_my_gift_card')}
          </button>
        </form>
      </div>
    </div>
  );
};
