import { Fragment, useEffect, useRef, useState } from 'react';
import { Transition } from '@headlessui/react';
import TagIcon from '@mui/icons-material/LocalOfferOutlined';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import {
  AlertPing,
  ButtonPrimary,
  ButtonSecondary,
  SimpleSpinner,
  ToastContentBlock,
} from '..';
import { useMutateCart, useTagHooks } from '../../../hooks';
import { BaseCartTag } from './cartTagBlocks/BaseCartTag';
import { TempCartTag } from './cartTagBlocks/TempCartTag';
import {
  CartTagsPopoverStyled,
  GlobalCartTagsListDisplay,
} from './cartTagBlocks';
import { Trans, useTranslation } from 'react-i18next';

type Props = {
  cartId: string;
  cartTags: string[];
  forGetPage: boolean;
  cartVendorDisplayName?: string;
};

export const CartTags = ({
  cartId,
  cartTags,
  forGetPage,
  cartVendorDisplayName,
}: Props) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [showHoveredText, setShowHoveredText] = useState<boolean>(
    forGetPage ? true : false,
  );
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [tempTags, setTempTags] = useState<string[]>(cartTags);
  const [tagInput, setTagInput] = useState<string>('');
  const [showTip, setShowTip] = useState<boolean>(false);

  const { t } = useTranslation();
  // const i18nLang = i18n.resolvedLanguage
  //   ? i18n.resolvedLanguage.toLowerCase()
  //   : i18n.language.toLowerCase();

  // const { windowWidth } = useWindowSize();

  const handleOpen = () => {
    setAnchorEl(anchorEl ? null : containerRef.current);
  };

  const mutation = useMutateCart(handleOpen);

  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const open = Boolean(anchorEl);
  const id = open ? 'cart-tag-popover' : undefined;

  useEffect(() => {
    setTempTags(cartTags);
  }, [cartTags]);

  const { addTag, addGlobalTag, checkTagEquality, removeTag } = useTagHooks({
    cartTags,
    tagInput,
    setTagInput,
    tempTags,
    setTempTags,
  });

  const handleMouseEnter = () => {
    if (!forGetPage) {
      setShowHoveredText(true);
    }

    setIsHovered(true);
  };

  // Used for translations /// The text ALWAYS shows on the get page if tags are empty
  // When the width of the tags is dynamically sized, leaving the element causes the text to disappear immediately
  // which causes the element to flash to being a smaller size which is jarring.
  // This func fixes that by setting a timeout to check if the element is hovered after 150ms.
  // We use 15ms b/c the transition of the parent runs for 150ms.
  const handleMouseLeave = () => {
    if (!open && !forGetPage) {
      setIsHovered(false);
      setTimeout(() => {
        if (!isHovered) {
          setShowHoveredText(false);
        }
      }, 150);
    }
  };

  const handleClose = async (clear = false, saving = false) => {
    setAnchorEl(null);

    if (!forGetPage) {
      setIsHovered(false);
    }

    // will clear the input and reset the tags to the original value
    if (mutation.isError && !saving) {
      setTimeout(() => {
        mutation.reset();
      }, 200);
    }

    if (clear) {
      setTimeout(() => {
        setTagInput('');
        setTempTags(cartTags ?? []);
      }, 200);
    }

    setTimeout(() => {
      setShowTip(false);
    }, 200);
  };

  const handleUpdate = () => {
    setShowTip(false);

    const tagEquality = checkTagEquality();

    // check if value actually changed
    if (tagEquality) {
      // focus the input
      if (inputRef.current) {
        inputRef.current.focus();
      }

      setShowTip(true);
      return;
    }

    mutation.mutate({
      cartId,
      propertyToUpdate: 'tags',
      updatedValue: tempTags,
      cartVendor: cartVendorDisplayName || 'not passed',
    });

    setAnchorEl(null);

    if (!forGetPage) {
      setIsHovered(false);
    }
  };

  return (
    <>
      <div
        ref={containerRef}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleOpen}
        // I use outline with a negative offset to mimic the border used when there are no cart tags present. This makes the elements the same height
        className={`group relative flex min-h-[36px] items-center justify-center rounded border-x-[3px] border-transparent transition-all ${
          cartTags && cartTags.length !== 0 // ONLY display these if there are tags
            ? mutation.isError && !open
              ? 'outline-dashed outline-2 -outline-offset-2 outline-rose-500 hover:outline-rose-300'
              : mutation.isError && open
                ? 'outline-dashed outline-2 -outline-offset-2 outline-rose-300'
                : ''
            : ''
        } ${anchorEl && cartTags.length !== 0 ? 'bg-[#eaeafb] outline-dashed outline-2 outline-primary/70' : ''}
        `}
      >
        {cartTags && cartTags.length !== 0 ? (
          <ul className='inline-flex h-full min-h-[36px] w-full flex-wrap items-center justify-start gap-3'>
            {cartTags.map((tag, i) => (
              <Fragment key={i}>
                <BaseCartTag tag={tag} handleOpen={handleOpen} />
              </Fragment>
            ))}
          </ul>
        ) : (
          <div
            onClick={handleOpen}
            className={`my-auto inline-flex h-full min-h-[36px] w-fit min-w-[180px] items-center justify-center rounded border-2 border-dashed px-4 py-1 transition-all group-hover:cursor-pointer
            ${
              mutation.isError && !open
                ? 'border-rose-500 bg-rose-200 group-hover:border-rose-300 group-hover:bg-rose-100'
                : mutation.isError && open
                  ? 'border-rose-300 bg-rose-100'
                  : mutation.isPending
                    ? 'border-primary/70 bg-[#eaeafb]'
                    : open
                      ? 'border-[#aaacfd] bg-[#eaeafb]'
                      : 'border-transparent group-hover:border-2 group-hover:border-primary/70 group-hover:bg-[#eaeafb]'
            } ${forGetPage ? 'border-[#aaacfd] bg-[#eaeafb] hover:border-primary/70' : ''}
            `}
          >
            <Transition
              as={Fragment}
              show={
                (!forGetPage && isHovered) ||
                forGetPage ||
                mutation.isPending ||
                mutation.isError
              }
              enter='transition ease-out duration-50'
              enterFrom='transform opacity-0'
              enterTo='transform scale-100'
              leave='transition ease-in duration-75'
              leaveFrom='transform scale-100'
              leaveTo='transform opacity-0'
            >
              {showHoveredText || mutation.isPending || mutation.isError ? (
                <div>
                  {mutation.isError ? (
                    <span
                      className={`text-center text-rose-500 transition-all group-hover:text-rose-300 ${
                        open ? 'text-rose-300' : ''
                      }`}
                    >
                      {t('history.error_updating_tags')}
                    </span>
                  ) : mutation.isPending ? (
                    <span
                      className={`text-center text-primary/70 transition-all group-hover:text-[#aaacfd] ${
                        open ? 'text-[#aaacfd]' : ''
                      }`}
                    >
                      {t('history.updating_tags')}
                    </span>
                  ) : (
                    <span
                      className={`text-balance text-center text-primary/70 transition-all ${
                        open ? 'text-[#aaacfd]' : ''
                      }`}
                    >
                      {t('history.click_to_add_tags')}
                    </span>
                  )}
                </div>
              ) : (
                <span>&nbsp;</span>
              )}
            </Transition>
          </div>
        )}
        {mutation.isError && !open ? (
          <AlertPing variant='error' displayLeft={true} />
        ) : mutation.isPending ? (
          <SimpleSpinner displayLeft={true} />
        ) : (
          ''
        )}
      </div>
      <CartTagsPopoverStyled
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => handleClose(false)}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
      >
        <div className='relative flex w-full flex-col gap-3 rounded-2xl bg-cloudGray p-6'>
          <div
            onClick={() => handleClose(true)}
            className='absolute right-3 top-3 cursor-pointer fill-darkSteel hover:fill-gray-400'
          >
            <CloseIcon className='transition-all' style={{ fill: 'inherit' }} />
          </div>
          <div className='inline-flex w-full items-center justify-start gap-2'>
            <TagIcon className='text-darkSteel' style={{ fontSize: 20 }} />
            <h3 className='heading-100'>{t('history.tags')}</h3>
          </div>
          <ul className='inline-flex w-full flex-wrap items-center justify-start gap-3 border-b border-[#25796D] pb-4'>
            {!tempTags || tempTags.length === 0 ? (
              <span className='text-100 pb-[5px] pt-[10.61px]'>
                {t('history.no_tags_added_yet')}
              </span>
            ) : (
              <>
                {tempTags.map((tempTag, i) => (
                  <Fragment key={i}>
                    <TempCartTag tag={tempTag} removeTag={removeTag} />
                  </Fragment>
                ))}
              </>
            )}
          </ul>
          <div className='relative'>
            <input
              type='text'
              placeholder={t('history.find_or_create_a_tag')}
              autoFocus
              value={tagInput}
              ref={inputRef}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  addTag();
                }
              }}
              onChange={(e) => setTagInput(e.target.value)}
              className='h-12 w-full rounded border-none bg-offWhite px-3 py-2 font-secondary font-semibold leading-[24px] text-primary-dark outline-none transition-all selection:bg-primary selection:text-offWhite placeholder:font-normal placeholder:text-[#636E7A] hover:bg-gray-200 focus:ring-2 focus:ring-primary hover:focus:bg-offWhite'
            />
            <SearchIcon className='absolute right-2 top-2 cursor-text text-darkSteel xs:top-3' />
          </div>

          {mutation.isError ? (
            <ToastContentBlock variant='error'>
              {t(
                'history.there_was_an_issue_updating_the_tag_please_try_again',
              )}
            </ToastContentBlock>
          ) : (
            ''
          )}

          {showTip ? (
            <ToastContentBlock variant='info'>
              <Trans
                i18nKey={t('history.tag_tip')}
                components={[<strong></strong>]}
              />
            </ToastContentBlock>
          ) : (
            ''
          )}

          <GlobalCartTagsListDisplay
            currentTags={tempTags}
            tagInput={tagInput}
            addGlobalTag={addGlobalTag}
          />

          <div className='inline-flex h-12 w-full flex-nowrap justify-end gap-2'>
            <div onClick={() => handleClose(true)}>
              <ButtonSecondary customWidth='w-[102px]'>
                {t('common.cancel')}
              </ButtonSecondary>
            </div>
            <div onClick={handleUpdate}>
              <ButtonPrimary customWidth='w-[86px]'>
                {t('common.save')}
              </ButtonPrimary>
            </div>
          </div>
        </div>
      </CartTagsPopoverStyled>
    </>
  );
};
