import { utils, writeFile } from 'xlsx';
import dayjs from 'dayjs';
import { CartHistoryData } from '../types';

export type CartHistoryToExcelRows = {
  'Created or Received?': string;
  Vendor: string | undefined;
  ID: string;
  Title: string;
  'Creation Date': string;
  'Public or Private': string;
  'Item Qty': number;
  CCYS: string;
  'Total Price': number | string;
  CCY: string;
  Note: string;
  'Link to Cart': string;
};

export const exportCartHistoryToExcel = (
  data: {
    carts: CartHistoryData[];
    pageParam: number;
  }[],
) => {
  const rows: CartHistoryToExcelRows[] = [];

  data.forEach((group) => {
    group.carts.forEach((cart) => {
      rows.push({
        'Created or Received?': cart.created ? 'Created' : 'Received',
        Vendor: cart.vendorDisplayName || cart.cart.vendor,
        ID: cart.cart.id.toUpperCase(),
        Title: cart.cart.title || '',
        'Creation Date': dayjs(cart.cart.timestamp).format("D MMM 'YY"),
        'Public or Private': cart.listed ? 'Public' : 'Private',
        'Item Qty': cart.cart.cartTotalQty || 0,
        CCYS: cart.cart.cartCCYS || '$',
        'Total Price': cart.cart.cartTotalPrice || 0,
        CCY: cart.cart.cartCCY || 'USD',
        Note: cart.note || '',
        'Link to Cart': `https://share-a-cart.com/get/${cart.cart.id}`,
      });
    });
  });

  // @ts-expect-error - TS error but still works.
  const ws = utils.json_to_sheet(rows, { origin: 'A3' });
  const wb = utils.book_new();

  // add hyperlinks to Link to Cart column
  for (let i = 4, j = 0; j < rows.length; i++, j++) {
    ws[`L${i}`].l = {
      Target: ws[`L${i}`].v,
    };
  }

  utils.book_append_sheet(wb, ws, 'Carts');

  utils.sheet_add_aoa(ws, [['Share-A-Cart.com']], { origin: 'A1' });
  ws['A1'].l = {
    Target: 'https://share-a-cart.com',
  };

  utils.sheet_add_aoa(
    ws,
    [[`Sheet Created: ${dayjs(Date.now()).format('D MMM YYYY, h:mm A')}`]],
    {
      origin: 'C1',
    },
  );

  // Column Widths
  const wscols = [
    {
      // Created or Received?
      wch: 20,
    },
    {
      // Vendor
      wch: 16,
    },
    {
      // ID
      wch: 10,
    },
    {
      // Title
      wch: 25,
    },
    {
      // Creation Date
      wch: 12,
    },
    {
      // Public or Private
      wch: 14,
    },
    {
      // Item Qty
      wch: 10,
    },
    {
      // CCYS
      wch: 4,
    },
    {
      // Total Price
      wch: 10,
    },
    {
      // CCY
      wch: 6,
    },
    {
      // Note
      wch: 40,
    },
    {
      // Link to Cart
      wch: 40,
    },
  ];
  ws['!cols'] = wscols;

  writeFile(wb, 'Carts.xlsx');
};
