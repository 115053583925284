import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomDropdown } from './CustomDropdown';

import { ReactComponent as LanguageSelectorIcon } from '../../images/icons/language-selector-icon.svg';
import { useI18nQueryParam } from 'src/hooks';

type Langs = {
  [key: string]: {
    nameBase: string;
    nameNative: string;
    nameShort: string;
  };
};

const langs: Langs = {
  en: { nameBase: 'English', nameNative: 'English', nameShort: 'EN' },
  es: { nameBase: 'Spanish', nameNative: 'Español', nameShort: 'ES' },
  de: { nameBase: 'German', nameNative: 'Deutsch', nameShort: 'DE' },
  fr: { nameBase: 'French', nameNative: 'Français', nameShort: 'FR' },
  pt: { nameBase: 'Portuguese', nameNative: 'Português', nameShort: 'PT' },
  // ru: { nameBase: 'Russian', nameNative: 'Русский', nameShort: 'RU' },
  // zh: {
  //   nameBase: 'Chinese (Simplified)',
  //   nameNative: '简体中文',
  //   nameShort: 'ZH',
  // },
};

export const LanguageSwitcher = () => {
  const [open, setOpen] = useState<boolean>(false);

  const anchorEl = useRef<HTMLDivElement>(null);

  const { i18n } = useTranslation();

  const setLngQueryParam = useI18nQueryParam();

  const handleClick = () => {
    setOpen((previousOpen) => !previousOpen);
  };

  const canBeOpen = open && Boolean(anchorEl);
  const id = canBeOpen ? 'language-popper' : undefined;

  const i18nLang = i18n.resolvedLanguage
    ? i18n.resolvedLanguage.toLowerCase()
    : i18n.language.toLowerCase();

  return (
    <>
      <div
        className={`group -mx-1 flex w-full max-w-fit cursor-pointer items-center justify-between gap-1 p-1 font-semibold transition-all`}
        onClick={handleClick}
        ref={anchorEl}
      >
        <LanguageSelectorIcon
          width='24'
          height='24'
          className={`transition-all ${open ? 'fill-grassGreen' : 'fill-skyBlue group-hover:fill-grassGreen'}`}
        />

        <span className='w-6 text-center font-secondary text-base font-bold leading-4 text-offWhite'>
          {langs[i18nLang].nameShort}
        </span>
      </div>
      <CustomDropdown
        id={id}
        open={open}
        anchorEl={anchorEl.current}
        handleClick={handleClick}
        customStyle={{
          width: 'fit-content',
        }}
        customTransformOrigin='top right'
      >
        <div className='z-20 flex w-full max-w-fit flex-col items-start justify-center rounded-lg bg-cloudGray medLg:mt-1'>
          <ul className='flex w-full flex-col items-start gap-2 px-2 py-4'>
            {Object.keys(langs).map((lang) => {
              const isCurrentLang =
                i18nLang.toLowerCase() ===
                  langs[lang].nameShort.toLowerCase() ||
                (i18nLang.toLowerCase() === 'zh' &&
                  langs[lang].nameShort === 'ZH');

              return (
                <li
                  key={lang}
                  className={`group flex w-full cursor-pointer flex-row flex-nowrap items-center justify-start gap-4 whitespace-nowrap rounded px-4 py-0.5 text-left transition-all`}
                  onClick={() => {
                    i18n.changeLanguage(lang);
                    setLngQueryParam(lang);
                    setOpen(false);
                  }}
                >
                  <span
                    className={`font-secondary text-base font-semibold leading-[25.6px] transition-all ${isCurrentLang ? 'text-primary-dark' : 'text-primary group-hover:text-primary-hover'}`}
                  >
                    {langs[lang].nameNative}
                  </span>
                  <span
                    className={`font-secondary text-sm font-normal transition-all ${isCurrentLang ? 'text-primary-dark' : 'text-darkGray/80 group-hover:text-darkGray/60'}`}
                  >
                    {langs[lang].nameBase}
                  </span>
                </li>
              );
            })}
          </ul>
        </div>
      </CustomDropdown>
    </>
  );
};
