import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAtom } from 'jotai';
import { authUserAtom } from 'src/store';
import type { CookieCart, UserProfile } from 'src/types';
import { getCookie, setCookie } from 'src/utils';

/**
 * If user is logged in, associates the cart with the user.
 * If user is not logged in, adds the cart to local storage.
 */
export const useSaveCartToUserOrCookie = () => {
  const [user, setUser] = useAtom(authUserAtom);
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: async (cartToAssociate: CookieCart) => {
      const res = await fetch(
        `${process.env.REACT_APP_LIVE_URL}/user/cart/associate-single`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
          body: JSON.stringify(cartToAssociate),
        },
      );

      if (!res.ok) {
        throw new Error('Error associating single cart...');
      }

      return true;
    },
    onSuccess: (mutationReturn, variables) => {
      // Update user atom to contain new cart
      setUser((oldUser) => {
        if (!oldUser) {
          return oldUser;
        }

        return {
          ...oldUser,
          carts: [...oldUser.carts, variables.cartid],
        };
      });
      // Update user in cache to contain new cart
      queryClient.setQueryData(['currentUser'], (oldData: UserProfile) => ({
        ...oldData,
        carts: [...oldData.carts, variables.cartid],
      }));
      // the reason we invalidate here is because when the user goes back to the history page,
      // it will trigger a refetch of the cart list query. We don't want to trigger a refetch
      // from the Get pages, so we instead invalidate here.
      queryClient.invalidateQueries({
        queryKey: ['cart', 'list'],
        exact: false,
        type: 'all',
      });
    },
    onError: (err) => {
      console.error(err);
    },
  });

  const saveCartToUserOrCookie = (cartId: string) => {
    const newCart: CookieCart = {
      cartid: cartId,
      created: false,
      timestamp: Date.now(),
    };

    // no user, add cart to cookies
    if (user === null) {
      const cookieCarts = getCookie('cookieCarts');

      // if localCarts exists in localStorage, add the new cart to the array
      if (cookieCarts) {
        const carts: CookieCart[] = JSON.parse(decodeURIComponent(cookieCarts));

        // cart already exists in the cookie, return
        if (carts.some((cart: { cartid: string }) => cart.cartid === cartId)) {
          return;
        }

        carts.push(newCart);

        // if there are more than 5 carts in the cookie now, remove the oldest one
        if (carts.length > 5) {
          carts.shift();
        }

        setCookie('cookieCarts', encodeURIComponent(JSON.stringify(carts)));
      } else {
        setCookie('cookieCarts', encodeURIComponent(JSON.stringify([newCart])));
      }
    } else {
      // cart already associated to user, return
      if (user.carts.some((cart) => cart === cartId)) {
        return;
      }

      mutation.mutate(newCart);
    }
  };

  return saveCartToUserOrCookie;
};
