import { NavLink } from 'react-router-dom';
import { ReactComponent as Logo } from '../../images/logos/logo+icon_svg.svg';

export const NavbarSimple = () => {
  return (
    <nav className='flex w-full flex-row items-center justify-start bg-primary-dark p-6 md:px-10 lg:px-12'>
      <div className='flex h-12 flex-row flex-nowrap items-center justify-center gap-2'>
        <NavLink
          to={`${process.env.REACT_APP_LIVE_URL}/`}
          reloadDocument
          className='flex flex-row px-1 py-1'
        >
          <Logo />
        </NavLink>
      </div>
    </nav>
  );
};
