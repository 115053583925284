import { useEffect, useRef, useState } from 'react';
import {
  ButtonPrimary,
  ButtonSecondary,
  AlertPing,
  PopoverStyled,
  SimpleSpinner,
  ToastContentBlock,
  TooltipStyled,
} from '..';
import NotesIcon from '@mui/icons-material/Notes';
import CloseIcon from '@mui/icons-material/Close';
import { ReactComponent as EditIcon } from '../../../images/icons/edit-icon.svg';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import { useMutateCart } from '../../../hooks';
import { useTranslation } from 'react-i18next';

type Props = {
  cartId: string;
  cartNotes: string;
  forGetPage: boolean;
  cartVendorDisplayName?: string;
};

export const CartNotes = ({
  cartId,
  cartNotes,
  forGetPage,
  cartVendorDisplayName,
}: Props) => {
  const [inputNote, setInputNote] = useState<string>(cartNotes);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isEditActive, setIsEditActive] = useState<boolean>(false);

  const { t } = useTranslation();

  const handleOpen = () => {
    setAnchorEl(anchorEl ? null : containerRef.current);
    if (!inputNote || inputNote !== cartNotes) {
      setIsEditActive(true);
    }
  };

  const mutation = useMutateCart(handleOpen);

  const containerRef = useRef<HTMLDivElement>(null);

  const open = Boolean(anchorEl);
  const id = open ? 'cart-notes-popover' : undefined;

  useEffect(() => {
    setInputNote(cartNotes);
  }, [cartNotes]);

  const handleClose = (clear = false, saving = false) => {
    setAnchorEl(null);

    setTimeout(() => {
      setIsEditActive(false);

      if (clear) {
        setInputNote(cartNotes || '');
      }

      if (mutation.isError && !saving) {
        mutation.reset();
      }
    }, 200);
  };

  const checkNoteEquality = (trimmedNotes: string): boolean =>
    (cartNotes && trimmedNotes === cartNotes.trim()) ||
    (!cartNotes && trimmedNotes === '')
      ? true
      : false;

  const handleUpdate = () => {
    const trimmedNotes = inputNote.trim();
    const noteEquality = checkNoteEquality(trimmedNotes);

    if (noteEquality) {
      handleClose();
      return;
    }

    mutation.mutate({
      cartId,
      propertyToUpdate: 'note',
      updatedValue: trimmedNotes,
      cartVendor: cartVendorDisplayName || 'not passed',
    });

    handleClose(false, true);
  };

  return (
    <>
      <TooltipStyled
        title={
          cartNotes
            ? t('history.click_to_see_note')
            : t('history.click_to_create_note')
        }
      >
        <div
          ref={containerRef}
          aria-describedby={id}
          onClick={handleOpen}
          className={`group relative ${
            open && mutation.isError
              ? 'fill-rose-300'
              : open && cartNotes
                ? 'fill-primary/70'
                : open && !cartNotes
                  ? forGetPage
                    ? 'fill-darkSteel'
                    : 'fill-[#CCCCCC]'
                  : ''
          } ${
            mutation.isPending
              ? 'fill-primary/70'
              : !open && mutation.isError
                ? 'fill-rose-500 hover:fill-rose-300 group-hover:fill-rose-300'
                : !open && cartNotes
                  ? 'fill-primary hover:fill-primary/70 group-hover:fill-primary/70'
                  : !open && !cartNotes
                    ? forGetPage
                      ? 'fill-gray-400 hover:fill-darkGray group-hover:fill-darkGray'
                      : 'fill-cloudGray hover:fill-[#CCCCCC] group-hover:fill-[#CCCCCC]'
                    : ''
          }`}
        >
          <NotesIcon
            className='relative h-6 min-w-[24px] cursor-pointer transition-all'
            style={{ fill: 'inherit' }}
          />

          {mutation.isError && !open ? (
            <AlertPing variant='error' />
          ) : mutation.isPending ? (
            <SimpleSpinner />
          ) : (
            ''
          )}
        </div>
      </TooltipStyled>
      <PopoverStyled
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => handleClose(false)}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
      >
        <div className='relative flex w-full min-w-[280px] max-w-[504px] flex-col gap-3 rounded-2xl bg-cloudGray p-6 xs:min-w-[320px] sm:min-w-[380px] md:min-w-[400px] lg:min-w-[504px]'>
          <div
            onClick={() => handleClose(true)}
            className='absolute right-3 top-3 cursor-pointer fill-darkSteel hover:fill-gray-400'
          >
            <CloseIcon
              className='transition-all'
              style={{ fontSize: '24px', fill: 'inherit' }}
            />
          </div>

          {/* POPOVER HEADER CONTENT & LOGIC */}
          <div className='inline-flex w-full items-center justify-start gap-2'>
            {inputNote && !isEditActive ? (
              <>
                <span className='font-primary text-xl font-semibold text-primary-dark'>
                  {t('history.notes')}:
                </span>
              </>
            ) : (
              <>
                <NotesIcon className='h-6 min-w-[24px] text-darkSteel' />
                <h3 className='font-primary text-xl font-semibold text-primary-dark'>
                  {t('history.notes')}
                </h3>
              </>
            )}
          </div>

          {/* POPOVER BODY CONTENT & LOGIC */}
          <div className='flex'>
            {/* if notes has text but cartNotes doesn't and it closes, focus textarea */}
            {inputNote &&
            !isEditActive &&
            !mutation.isError &&
            !mutation.isPending &&
            cartNotes === inputNote ? (
              <p className='text-200'>{inputNote}</p>
            ) : (
              <TextareaAutosize
                name='cart-note'
                cols={60}
                minRows={3}
                maxLength={1024}
                defaultValue={inputNote ? inputNote : ''}
                placeholder={t('history.add_any_notes_youd_like')}
                onChange={(e) => setInputNote(e.target.value)}
                disabled={mutation.isPending}
                autoFocus
                onFocus={(e) => {
                  const val = e.target.value;
                  e.target.value = '';
                  e.target.value = val;
                }}
                className='w-full rounded border-none bg-offWhite px-3 py-4 font-secondary font-semibold leading-[24px] text-primary-dark outline-none transition-all selection:bg-primary selection:text-offWhite placeholder:font-normal placeholder:text-[#636E7A] hover:bg-gray-200 focus:ring-2 focus:ring-primary hover:focus:bg-offWhite'
              />
            )}
          </div>

          {mutation.isError ? (
            <ToastContentBlock variant='error'>
              {t(
                'history.there_was_an_issue_updating_the_note_please_try_again',
              )}
            </ToastContentBlock>
          ) : (
            ''
          )}

          {/* POPOVER BOTTOM BUTTONS & LOGIC */}
          <div className='inline-flex h-auto w-full flex-nowrap justify-end gap-2'>
            {inputNote &&
            !isEditActive &&
            !mutation.isError &&
            !mutation.isPending &&
            cartNotes === inputNote ? (
              <button
                onClick={() => setIsEditActive(true)}
                className='inline-flex items-center justify-center gap-1 font-primary font-semibold text-primary transition-all hover:text-primary/70 active:scale-[95%]'
              >
                <EditIcon className='relative h-6 min-w-[24px] cursor-pointer' />
                {t('common.edit')}
              </button>
            ) : (
              <div className='inline-flex h-12 w-full flex-nowrap justify-end gap-2'>
                <div onClick={() => handleClose(true)}>
                  <ButtonSecondary customWidth='w-[102px]'>
                    {t('common.cancel')}
                  </ButtonSecondary>
                </div>
                <div onClick={handleUpdate}>
                  <ButtonPrimary customWidth='w-[86px]'>
                    {t('common.save')}
                  </ButtonPrimary>
                </div>
              </div>
            )}
          </div>
        </div>
      </PopoverStyled>
    </>
  );
};
