import { useAtom } from 'jotai';
import { authUserAtom } from 'src/store';
import { CartGetData } from 'src/types';
import { useSaveCartToUserOrCookie } from './useSaveCartToUserOrCookie';
import TagManager from 'react-gtm-module';
import { formatPrice } from 'src/utils/formatPrice';

export const useGetCartAPI = () => {
  const [user] = useAtom(authUserAtom);

  const saveCartToUserOrLocal = useSaveCartToUserOrCookie();

  const getCartAPI = async (cartId: string): Promise<CartGetData> => {
    const res = await fetch(
      `${process.env.REACT_APP_LIVE_URL}/api/get/r/cart/${cartId}`,
      {
        credentials: 'include',
      },
    );
    if (!res.ok) {
      throw new Error(`Error fetching Cart ${cartId}'s data...`);
    }

    const data = await res.json();

    if (data.error) {
      throw new Error(`Error fetching Cart ${cartId}'s data...`);
    }

    // add default values here
    if (!data.cartCCY) data.cartCCY = 'USD';
    if (!data.cartCCYS) data.cartCCYS = '$';
    if (!data.cartTotalQty) data.cartTotalQty = 0;
    if (!data.timestamp) data.timestamp = 0;
    if (!data.title) data.title = undefined;
    if (!data.cartTotalPrice) data.cartTotalPrice = 0;
    if (!data.missingCount) data.missingCount = 0;
    if (!data.store) data.store = undefined;

    if (!data.formattedCartTotalPrice) {
      data.formattedCartTotalPrice = formatPrice(
        data.cartTotalPrice,
        data.locale,
        data.cartCCY,
        data.cartCCYS,
      );
    }

    TagManager.dataLayer({
      dataLayer: {
        event: 'Load Cart',
        eventCategory: 'engagement',
        eventLabel: 'Get Page',
        eventValue: {
          cartId,
          cartValue: data.cartTotalPrice,
          cartCCY: data.cartCCY,
          cartVendor: data.vendorDisplayName,
        },
      },
    });

    // if there's no ownership data attached to the cart, save it to the user or local storage
    if (!data.ownershipData) {
      saveCartToUserOrLocal(cartId);

      // if user is logged in, add ownership data to the cart
      if (user) {
        return {
          ...data,
          ownershipData: { created: false, timestamp: Date.now() },
        };
      }
    }

    return data;
  };

  return getCartAPI;
};
