import { useMutation, useQueryClient } from '@tanstack/react-query';
import React from 'react';
import { toast } from 'sonner';
import { ToastStyled } from 'src/components';
import { ChangelogForm } from 'src/components/changelog';
import { Changelog } from 'src/types';

type ChangelogMutation = ChangelogForm & {
  id: number;
};

export const useChangelogMutation = ({
  logIndex,
  setOpen,
  resetForm,
}: {
  logIndex?: number;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  resetForm?: () => void;
}) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: ChangelogMutation): Promise<boolean> => {
      const url = logIndex
        ? `${process.env.REACT_APP_LIVE_URL}/api/changelog/ext/issues/update/${logIndex}`
        : `${process.env.REACT_APP_LIVE_URL}/api/changelog/ext/issues/create`;

      const res = await fetch(url, {
        credentials: 'include',
        method: logIndex ? 'PUT' : 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (!res.ok) {
        throw new Error(
          `Error ${logIndex ? 'updating' : 'creating'} changelog... res not ok...`,
        );
      }

      const { status } = await res;

      if (status !== 200) {
        throw new Error(
          `Error ${logIndex ? 'updating' : 'creating'} cart... status...`,
        );
      }

      // return true to trigger the onSuccess below
      return true;
    },
    onSuccess: (mutationReturn, variables) => {
      queryClient.setQueryData(['changelog'], (oldData: Changelog[]) => {
        // if logIndex is undefined, it means we are creating a new log
        if (logIndex) {
          const oldLog = oldData[logIndex - 1];
          const newLog = { ...oldLog, ...variables };
          const newData = [...oldData];
          newData[logIndex - 1] = newLog;

          return newData;
        } else {
          const newLogs = [{ ...variables }, ...oldData];

          if (newLogs.length > 20) {
            newLogs.pop();
          }

          return newLogs;
        }
      });

      setOpen(false);

      toast.custom((t) => (
        <ToastStyled variant='success' handleClose={() => toast.dismiss(t)}>
          Changelog {logIndex ? 'updated' : 'created'} successfully!
        </ToastStyled>
      ));

      resetForm && resetForm();
    },
    onError: () => {
      toast.custom((t) => (
        <ToastStyled variant='error' handleClose={() => toast.dismiss(t)}>
          Something went wrong while {logIndex ? 'updating' : 'creating'} the
          changelog...
        </ToastStyled>
      ));
    },
  });
};
