/**
 * @description Formats a price to a string with the correct currency symbol and locale
 * @param {*} price is the price to format (can be a number or a string)
 * @param {*} locale is the locale to format the price in, e.g. 'us' - defaults to 'us'
 * @param {*} ccy is the currency to append to the price, e.g. 'USD' - defaults to 'USD'
 * @param {*} ccyS is the symbol to prepend to the price if there's an error, e.g. '$' - defaults to '$'
 * @param {*} displayCCY is a boolean to determine if the currency should be displayed, e.g. true - defaults to true - shouldn't be shown on item prices
 */
export const formatPrice = (
  price: number,
  locale: string = 'us',
  ccy: string = 'USD',
  ccyS: string = '$',
  displayCCY: boolean = true,
) => {
  let priceCCY = ccy.toUpperCase();
  let isSymbolLast = false; // if true, the price will is reversed, e.g. $100 -> 100$
  let formattedPrice = '';

  try {
    // weird edge cases where the currency comes through as a 2 letter code, convert to 3 letter code
    if (priceCCY === 'EU') {
      priceCCY = 'EUR';
    } else if (priceCCY === 'JP') {
      priceCCY = 'JPY';
    }

    const formatter = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: priceCCY,
      currencyDisplay: 'narrowSymbol',
    });

    const parts = formatter.formatToParts(price);
    const symbolIndex = parts.findIndex((part) => part.type === 'currency');
    const firstNumIndex = parts.findIndex((part) => part.type === 'integer');
    isSymbolLast = symbolIndex > firstNumIndex;

    formattedPrice = formatter.format(price);
  } catch (err) {
    console.error(
      `formatPrice(${price}, ${locale}, ${priceCCY}, ${ccyS}, ${displayCCY}) - Error formatting price: ${err}`,
    );
  }

  if (isSymbolLast) {
    return `${displayCCY ? `${priceCCY} ` : ''}${formattedPrice}`;
  }

  return `${formattedPrice}${displayCCY ? ` ${priceCCY}` : ''}`;
};
