import { useAtom } from 'jotai';
import { useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import {
  EmbedModal,
  ExtensionLoadingModal,
} from 'src/components/reusableBlocks/modals';
import {
  GetCompare,
  GetEmpty,
  GetFeedback,
  GetReview,
  GetTopControls,
} from '../components/get';
import { useRedirectToUpperCase, useScrollToTop } from '../hooks';
import { getCartIdAtom, getStepAtom, showEmbedModalAtom } from '../store';

export const Get = () => {
  const [step, setStep] = useAtom(getStepAtom);
  const [cartId, setCartId] = useAtom(getCartIdAtom);
  const [showEmbedModal, setShowEmbedModal] =
    useAtom<boolean>(showEmbedModalAtom);

  const { cartId: cartIdParam } = useParams();
  let [searchParams] = useSearchParams();

  useScrollToTop();
  useRedirectToUpperCase({
    cartIdParam,
    setCartId,
    searchParams,
    pathname: 'get',
  });

  useEffect(() => {
    if (!cartId) {
      setStep(1);
      return;
    } else if (
      cartId &&
      !searchParams.has('split') &&
      !searchParams.has('feedback')
    ) {
      setStep(2);
      return;
    } else if (cartId && searchParams.has('split')) {
      setStep(3);
      return;
    } else if (cartId && searchParams.has('feedback')) {
      setStep(4);
      return;
    }
  }, [cartId, searchParams, setStep]);

  return (
    <>
      <GetTopControls />
      {!cartIdParam ? (
        <GetEmpty />
      ) : (
        <div style={{ minHeight: window.innerHeight - 300 }}>
          {cartId && step === 2 && <GetReview />}
          {cartId && step === 3 && <GetCompare />}
          {cartId && step === 4 && <GetFeedback />}
        </div>
      )}

      <ExtensionLoadingModal />
      <EmbedModal
        cartId={cartId}
        show={showEmbedModal}
        setShow={setShowEmbedModal}
      />
    </>
  );
};
