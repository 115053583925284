type Props = {
  cartVendor: string;
  cartVendorDisplayName: string;
};

export const CartVendor = ({ cartVendor, cartVendorDisplayName }: Props) => {
  return (
    <div className='inline-flex min-w-[120px] max-w-[120px] items-center justify-start gap-4'>
      <img
        src={`${process.env.REACT_APP_LIVE_URL}/images/favis/32/${cartVendor}.png`}
        alt={cartVendor}
        loading='lazy'
        className='h-6 w-6'
      />
      <span className='line-clamp-2 max-h-[34px] max-w-[80px] truncate whitespace-normal leading-[16px]'>
        {cartVendorDisplayName}
      </span>
    </div>
  );
};
