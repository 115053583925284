import { useEffect } from 'react';
import { setCookie } from 'src/utils';

export const useConvertLocalCartsToCookieCarts = () => {
  return useEffect(() => {
    const localCarts = localStorage.getItem('localCarts');

    if (localCarts) {
      const carts = JSON.parse(localCarts);

      localStorage.removeItem('localCarts');

      setCookie('cookieCarts', encodeURIComponent(JSON.stringify(carts)));
    }
  }, []);
};
