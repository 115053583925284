import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

// Note: Using react-router-dom's (rrd) useSearchParams() triggers a rerender of the component when updating the search params.
// This creates multiple issues, one being when passing state from page to page with rrd.
// This is a workaround to avoid the rerender as well as state issues.
export const useI18nQueryParam = () => {
  const location = useLocation();
  const { i18n } = useTranslation();

  const updateWindowHistory = useCallback(
    (searchParams: URLSearchParams) =>
      window.history.replaceState(
        null,
        '',
        `${location.pathname}${searchParams.size > 0 ? `?${searchParams.toString()}` : ''}`,
      ),
    [location.pathname],
  );

  useEffect(() => {
    // retrieve the current lang param from the url
    const searchParams = new URLSearchParams(location.search);
    const lngParam = searchParams.get('lng') || 'en';
    const currentLng = i18n.resolvedLanguage || 'en';

    // if the current lang isn't english and the lang param is set and it's the same as the current lang, return.
    // if the current language isn't english and the language param isn't set, set it.
    // if the current language is english and the language param is set, remove it.
    if (currentLng !== 'en' && lngParam && lngParam === currentLng) {
      return;
    } else if (currentLng !== 'en' && lngParam !== currentLng) {
      searchParams.set('lng', currentLng);
    } else {
      searchParams.delete('lng');
    }

    updateWindowHistory(searchParams);
  }, [i18n.resolvedLanguage, location.search, updateWindowHistory]);

  const setLngQueryParam = useCallback(
    (lang: string) => {
      const searchParams = new URLSearchParams(location.search);

      if (lang === 'en') {
        searchParams.delete('lng');
      } else {
        searchParams.set('lng', lang);
      }

      updateWindowHistory(searchParams);
    },
    [location.search, updateWindowHistory],
  );

  return setLngQueryParam;
};
