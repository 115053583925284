import { useRef } from 'react';
import Lottie, { LottieRefCurrentProps } from 'lottie-react';
import { TReviewFormState } from '../../../types';
import { useTranslation } from 'react-i18next';

const animation = require('../../../images/lotties/cart-sending-bar.json');

type Props = {
  setReviewFormState: React.Dispatch<React.SetStateAction<TReviewFormState>>;
};

export const GetFeedbackReviewCTA = ({ setReviewFormState }: Props) => {
  const lottieRef = useRef<LottieRefCurrentProps | null>(null);

  const { t } = useTranslation();

  return (
    <>
      <div className='flex w-full flex-col items-center justify-center gap-4'>
        <div className='flex w-full flex-col items-center justify-center gap-8'>
          <Lottie
            lottieRef={lottieRef}
            animationData={animation}
            loop={true}
            autoplay={true}
            style={{ width: 352, height: 'auto', marginTop: -24 }}
          />
          <h2 className='heading-300 text-balance text-center leading-10 text-offWhite'>
            {t('get.hey_want_to_make_a_quick')}&nbsp;
            <span className='relative inline-block'>
              <span className='relative font-extrabold text-customYellow'>
                {t('get.5_dollar')}
              </span>
            </span>
            ?
          </h2>
        </div>

        <div className='flex flex-col items-center justify-center gap-2'>
          <h5 className='text-center font-secondary text-lg leading-8 text-offWhite md:text-xl'>
            {t('get.just_tell_us_what_you_think_of_sac_below')}:
          </h5>

          <div className='flex w-full flex-col items-center justify-center gap-2 pt-2 sm:w-auto sm:flex-row sm:gap-4'>
            <button
              onClick={() => setReviewFormState('happy')}
              className='button-primary h-20 w-full bg-grassGreen text-center font-secondary text-2xl font-bold leading-6 text-[#073326] hover:bg-grassGreen/85 xs:min-w-[220px]'
            >
              <span className='pb-2 text-center text-[48px] leading-[48px]'>
                👍
              </span>
              {t('get.i_love_it')}
            </button>
            <button
              onClick={() => setReviewFormState('sad')}
              className='button-primary h-20 w-full bg-accentOrange text-center font-secondary text-2xl font-bold leading-6 text-[#220A02] hover:bg-accentOrange/85 xs:min-w-[220px]'
            >
              <span className='pb-1.5 text-center text-[48px] leading-[48px]'>
                👎
              </span>
              {t('get.meh')}
            </button>
          </div>
        </div>
      </div>
      <div className='flex flex-row items-center justify-center gap-16'>
        <div className='flex flex-col items-center justify-center gap-6'>
          <h3 className='heading-500 text-offWhite'>100K+</h3>
          <span className='text-300 text-center text-blueGray'>
            {t('get.plugin_downloads')}
          </span>
        </div>

        <div className='flex flex-col items-center justify-center gap-6'>
          <h3 className='heading-500 flex flex-row flex-nowrap items-center justify-center gap-2 text-offWhite'>
            4.9
            <svg
              width='39'
              height='37'
              viewBox='0 0 39 37'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M19.5 0L23.9903 13.8197H38.5211L26.7654 22.3607L31.2557 36.1803L19.5 27.6393L7.7443 36.1803L12.2346 22.3607L0.47887 13.8197H15.0097L19.5 0Z'
                fill='#F1B820'
              />
            </svg>
          </h3>
          <span className='text-300 text-center text-blueGray'>
            {t('get.average_from_5k_reviews')}
          </span>
        </div>
      </div>
    </>
  );
};
