import { useTranslation } from 'react-i18next';

type Props = {
  notes: string | undefined;
  border: boolean;
};

export const CartBaseNotes = ({ notes, border }: Props) => {
  const { t } = useTranslation();

  if (!notes) return null;

  return (
    <div className={`w-full border-blueGray p-4 ${border && 'border-b'}`}>
      <h4 className='heading-100'>{t('common.cart_note')}:</h4>
      <p className='text-200 p-2'>{notes}</p>
    </div>
  );
};
