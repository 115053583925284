import { forwardRef } from 'react';
import { TransitionProps } from '@mui/material/transitions';
import { Grow } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import { DialogStyled } from '../DialogStyled';
import { useAtom } from 'jotai';
import { showExtensionLoadingModalAtom } from '../../../store';
import { useTranslation } from 'react-i18next';

export const ExtensionLoadingModal = () => {
  const [show, setShow] = useAtom(showExtensionLoadingModalAtom);

  const { t } = useTranslation();

  return (
    <DialogStyled
      open={show}
      onClose={() => setShow(false)}
      TransitionComponent={Transition}
      aria-describedby='Share-A-Cart Extension is loading the cart...'
    >
      <div
        onClick={() => setShow(false)}
        className='absolute right-3 top-2.5 cursor-pointer fill-darkSteel hover:fill-gray-400'
      >
        <CloseIcon
          className='transition-all'
          style={{ fontSize: '24px', fill: 'inherit' }}
        />
      </div>
      <div className='flex flex-col items-center justify-center gap-4 p-4 sm:py-6 md:gap-6 md:px-6 md:py-8'>
        <div className='flex flex-col items-center justify-center gap-2 md:gap-3'>
          <div className='flex h-12 w-12 items-center justify-center rounded-full bg-green-200'>
            <CheckOutlinedIcon
              style={{ fontSize: 30 }}
              className='text-green-600'
            />
          </div>

          <h3 className='heading-300 text-center'>
            {t('popup.cart_received')}
          </h3>
          <p className='text-200 text-center xs:px-2 sm:px-4'>
            <span className='font-norwester'>Share-A-Cart</span>&nbsp;
            {t('popup.addon_received_the_list')}
          </p>
        </div>

        <button
          onClick={() => setShow(false)}
          className='button-primary h-12 w-full'
        >
          {t('common.close')}
        </button>
      </div>
    </DialogStyled>
  );
};

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Grow ref={ref} {...props} />;
});
