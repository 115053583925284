import { useAtom } from 'jotai';
import { chunkLinksAtom, getStepAtom, showChunkCartModalAtom } from '../store';
import { useSearchParams } from 'react-router-dom';
import type { CartGetData } from '../types';
import {
  sendCartToAmazon,
  sendCartToBHPhotoVideo,
  sendCartToBestBuy,
  sendCartToIkea,
  sendCartToInstacart,
  sendCartToKroger,
  sendCartToNewegg,
  sendCartToOtherVendors,
  sendCartToShopify,
  sendCartToWalmart,
} from '../utils/get/sendCart';
import TagManager from 'react-gtm-module';
import { useActiveBrowser, useExtension, useGetFeedback } from '.';

type ContinueWithItemsArgs = {
  id: string;
  cart: CartGetData;
  handleSplitNewVendor?: boolean;
  handleSplitOriginalVendor?: boolean;
  ignoreSavings?: boolean;
  isChunk?: boolean;
  doneWithChunks?: boolean;
  chunkUrl?: string;
  page: 'get' | 'embed';
};

export const useContinueWithItems = () => {
  const [step, setStep] = useAtom(getStepAtom);
  const [, setChunkLinks] = useAtom(chunkLinksAtom);
  const [, setShowChunkCartModal] = useAtom(showChunkCartModalAtom);
  const [searchParams, setSearchParams] = useSearchParams();
  const { extExists, sendCartToExtension } = useExtension();
  const activeBrowser = useActiveBrowser();
  const { shouldReviewBeShown } = useGetFeedback();

  const handleContinue = async ({
    id,
    cart,
    handleSplitNewVendor,
    handleSplitOriginalVendor,
    ignoreSavings = false,
    isChunk = false,
    doneWithChunks = false,
    chunkUrl,
    page,
  }: ContinueWithItemsArgs) => {
    // if we are on the embed page, we should not show the compare cart view
    if (page === 'get' && step === 2 && cart.showSplit) {
      setSearchParams({ split: 'true' });
      setStep(3);
    } else {
      pushCartFinal({
        id,
        cart,
        handleSplitNewVendor,
        handleSplitOriginalVendor,
        ignoreSavings,
        isChunk,
        doneWithChunks,
        chunkUrl,
        page,
      });
    }
  };

  const openItemsAndFeedback = (
    cartUrl: string | undefined,
    possiblyShowFeedback: boolean,
    cartVendor: string,
    isChunk: boolean,
    doneWithChunks?: boolean, // this will be false if we are loading the cart in chunks and it's not the last chunk
  ) => {
    // turn off showing feedback for now
    possiblyShowFeedback = false;
    // if we are chunking the cart and it's the 'all done' button, we won't pass a URL but we still want to check to show feedback
    if (cartUrl) {
      window.open(`${process.env.REACT_APP_LIVE_URL}${cartUrl}`);
    }

    // we don't want to show the feedback if we are chunking the cart and it's not done yet
    if (isChunk && !doneWithChunks) {
      return;
    }

    // possiblyShowFeedback will be true for Amazon and Walmart ONLY right now
    // shouldReviewBeShown will return true based on certain conditions pertaining to the user seeing, interacting with, and completing the review form
    if (possiblyShowFeedback && shouldReviewBeShown()) {
      setSearchParams({
        feedback: 'true',
        browser: activeBrowser,
        vendor: cartVendor,
        extPresent: extExists ? 'true' : 'false',
      });
    }
  };

  const pushCartFinal = ({
    id,
    cart,
    handleSplitNewVendor,
    handleSplitOriginalVendor,
    ignoreSavings,
    isChunk,
    doneWithChunks,
    chunkUrl,
    page,
  }: ContinueWithItemsArgs) => {
    let cartUrl: string | undefined = undefined;
    let cartVendor = cart.vendor;
    let cartStore = cart.store;
    let cartItems = cart.items;
    let loggerUrl = `/uv/${id}/`;
    let possiblyShowFeedback = false;

    // if we are splitting (new vendor or original with less items)
    // only get the items for split original vendor or split new vendor
    if (handleSplitNewVendor) {
      cartVendor = cart.matchTo as string;
      cartStore = `https://www.${cartVendor}.com`; // TODO - this probably needs to change. Is it even used???
      cartItems = cart.matchedVendorCart.items;

      loggerUrl = `/uvv/${id}/${cartVendor}/`;

      TagManager.dataLayer({
        dataLayer: {
          event: 'Savings',
          eventCategory: 'engagement',
          eventLabel: 'Savings Taken',
          eventValue: {
            savingsValue:
              Number(cart.matchedVendorCart?.splitItemsTotalPrice) || 0,
            cartCCY: cart.cartCCY,
            cartId: id,
            originalVendor: cart.vendorDisplayName,
            savingsVendor: cart.matchedVendorCart.matchToDisplayName,
          },
        },
      });
    } else if (handleSplitOriginalVendor) {
      cartItems = cart.originalVendorCart.items;
      TagManager.dataLayer({
        dataLayer: {
          event: 'Savings',
          eventCategory: 'engagement',
          eventLabel: 'Non Savings Partial',
          eventValue: {
            savingsValue:
              Number(cart.matchedVendorCart?.splitItemsTotalPrice) || 0,
            cartCCY: cart.cartCCY,
            cartId: id,
            originalVendor: cart.vendorDisplayName,
            savingsVendor: cart.matchedVendorCart.matchToDisplayName,
          },
        },
      });
    } else if (ignoreSavings) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'Savings',
          eventCategory: 'engagement',
          eventLabel: 'Savings Ignored',
          eventValue: {
            savingsValue:
              Number(cart.matchedVendorCart?.splitItemsTotalPrice) || 0,
            cartCCY: cart.cartCCY,
            cartId: id,
            originalVendor: cart.vendorDisplayName,
            savingsVendor: cart.matchedVendorCart.matchToDisplayName,
          },
        },
      });
    } else if (cartVendor !== 'walmart') {
      TagManager.dataLayer({
        dataLayer: {
          event: 'Send to Vendor',
          eventCategory: 'engagement',
          eventLabel: `${page && page === 'embed' ? 'Embed' : 'Get'} Page`,
          eventValue: {
            cartValue: Number(cart.cartTotalPrice),
            cartCCY: cart.cartCCY,
            cartId: id,
            cartVendor: cart.vendorDisplayName,
          },
        },
      });
    }

    if (cartVendor === 'amazon') {
      if (
        cartStore.endsWith('/cart/localmarket') ||
        cartStore.endsWith('/cart/fresh')
      ) {
        sendCartToExtension({ cartId: id, vendor: cartVendor });
        return;
      }

      const tag = searchParams.get('tag');

      possiblyShowFeedback = true;
      cartUrl = `${loggerUrl}${sendCartToAmazon({
        cartStore,
        cartItems,
        cartReferrer: cart.referrer || undefined,
        tagParam: tag,
      })}`;
    } else if (
      cartVendor === 'walmart' &&
      cartStore === 'https://www.walmart.com'
    ) {
      possiblyShowFeedback = true;

      if (isChunk) {
        openItemsAndFeedback(
          chunkUrl,
          possiblyShowFeedback,
          cartVendor,
          true,
          doneWithChunks,
        );

        return;
      }

      const walmartStatus = sendCartToWalmart({
        cartId: id,
        cartStore,
        cartItems,
        cartAp: cart.ap,
        loggerUrl,
        cartValue: Number(cart.cartTotalPrice) || 0,
        cartCCY: cart.cartCCY,
        page,
      });

      if (walmartStatus.cartChunks && walmartStatus.cartChunks.length > 0) {
        setChunkLinks(walmartStatus.cartChunks);
        setShowChunkCartModal(true);

        return; // return here b/c we don't want to send the cart yet, we will show the chunk modal
      }

      cartUrl = walmartStatus.cartUrl;
    } else if (
      cartVendor === 'bestbuy' &&
      cartStore === 'https://www.bestbuy.com'
    ) {
      cartUrl = `${loggerUrl}${sendCartToBestBuy({ cartItems })}`; // TODO - test
    } else if (cartVendor === 'bhphotovideo') {
      cartUrl = `${loggerUrl}${sendCartToBHPhotoVideo({ cartItems })}`; // TODO - test
    } else if (cartVendor === 'ikea') {
      cartUrl = `${loggerUrl}${sendCartToIkea({ cartItems, cartAp: cart.ap })}`;
    } else if (cartVendor === 'instacart' && !extExists) {
      // TODO - why is extExists needed here but not others?
      cartUrl = `${loggerUrl}${sendCartToInstacart({ cartId: id, cartItems, cartAp: cart.ap })}`;
    } else if (cartVendor === 'kroger') {
      // KROGER does not allow this to work from an iframe, i.e. the embed view
      return sendCartToKroger({ cartId: id, page });
    } else if (cartVendor === 'shopify') {
      // ap will NOT be undefined for Shopify
      return sendCartToShopify({
        cartId: id,
        cartItems,
        cartAp: cart.ap!,
        sendCartToExtension,
        loggerUrl,
      });
    } else if (cartVendor === 'newegg') {
      cartUrl = `${loggerUrl}${sendCartToNewegg({ cartItems, cartStore })}`;
    } else if (cartVendor) {
      return sendCartToOtherVendors({
        cartId: id,
        sendCartToExtension,
        cartVendor,
      });
    }

    openItemsAndFeedback(cartUrl, possiblyShowFeedback, cartVendor, false);

    return;
  };

  return handleContinue;
};
