import { useState } from 'react';
import { Squash as Hamburger } from '.';
import { NavLink, Navigate } from 'react-router-dom';
import { CustomDropdown, TooltipStyled } from '../';
import { CreateACartBlock, ReceiveACartBlock, SacPlusBlock } from '.';
import { useAtom } from 'jotai';
import { authUserAtom } from '../../store';
import { useQueryClient } from '@tanstack/react-query';
import { useShowUserPhoto } from '../../hooks';
import { useTranslation } from 'react-i18next';

export const NavUserOrLoginBlock = () => {
  const { t } = useTranslation();
  const [user, setUser] = useAtom(authUserAtom);
  const [open, setOpen] = useState<boolean>(false);
  const [isTooltipOpen, setIsTooltipOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  const [showUserPhoto, setShowUserPhoto] = useShowUserPhoto();

  const queryClient = useQueryClient();

  const handleOpen = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClick = () => {
    setOpen((previousOpen) => !previousOpen);
    setIsTooltipOpen(false);
  };

  const canBeOpen = open && Boolean(anchorEl);
  const id = canBeOpen ? 'navbar-menu-popper' : undefined;

  const handleLogout = async () => {
    await fetch(`${process.env.REACT_APP_LIVE_URL}/signout`);

    setUser(null);
    queryClient.setQueryData(['currentUser'], null);

    return <Navigate to='/' replace />;
  };

  return (
    <>
      {!user ? (
        <div className='hidden lgXl:block'>
          <NavLink
            to='/login'
            className='flex-row flex-nowrap gap-2 whitespace-nowrap rounded-full bg-primary-off px-4 py-2 transition-all hover:bg-primary-offHover active:scale-95 xs:flex'
          >
            {t('navbar.login')}
          </NavLink>
        </div>
      ) : (
        <div className='hidden lgXl:block'>
          <TooltipStyled
            title={user.name}
            open={isTooltipOpen}
            onClose={() => setIsTooltipOpen(false)}
            onOpen={() => {
              if (!open) {
                setIsTooltipOpen(true);
              }
            }}
          >
            <div
              aria-describedby={id}
              onClick={(e) => {
                handleOpen(e);
                handleClick();
              }}
              className='flex h-10 w-10 cursor-pointer items-center justify-center p-1 transition-all hover:opacity-90'
            >
              {showUserPhoto ? (
                <img
                  className='rounded-full'
                  src={user.photo}
                  alt='User'
                  loading='lazy'
                  onError={() => setShowUserPhoto(false)}
                />
              ) : (
                ''
              )}
              <p
                className={`h-8 w-8 rounded-full bg-grassGreen text-center text-lg leading-8 text-primary-dark ${showUserPhoto ? 'hidden' : 'block'}`}
              >
                {user.name ? user.name.charAt(0) : '+'}
              </p>
            </div>
          </TooltipStyled>
        </div>
      )}
      <div
        className='block transition-all hover:opacity-[85%] lgXl:hidden'
        onClick={(e) => {
          handleOpen(e);
          handleClick();
        }}
      >
        <Hamburger
          size={24}
          color='#FEFEFE'
          toggled={open}
          duration={0.277} // matches the MUI popover transition duration}}
        />
      </div>
      <CustomDropdown
        id={id}
        open={open}
        anchorEl={anchorEl}
        handleClick={handleClick}
      >
        <div className='relative flex w-full flex-col items-start justify-center gap-1 rounded-lg bg-cloudGray px-5 py-4 font-secondary text-base font-semibold'>
          <div className='flex h-7 max-h-7 min-h-7 w-full flex-row items-center justify-center md:hidden'>
            <ReceiveACartBlock forDropdown={true} clickHandler={handleClick} />
          </div>
          <div className='flex h-7 max-h-7 min-h-7 w-full flex-row items-center justify-start md:hidden'>
            <CreateACartBlock forDropdown={true} clickHandler={handleClick} />
          </div>
          <div className='flex w-full flex-col items-start justify-start gap-1 lgXl:hidden'>
            <SacPlusBlock forDropdown={true} clickHandler={handleClick} />
            <NavLink
              to={`${process.env.REACT_APP_LIVE_URL}/plugins`}
              reloadDocument
              onClick={handleClick}
              className='inline-flex w-full flex-row flex-nowrap gap-2 whitespace-nowrap rounded-md px-1 py-0.5 text-primary transition-all hover:bg-gray-300/60 active:scale-95'
            >
              {t('navbar.get_plugins')}
            </NavLink>
          </div>

          {/* Rather than creating a conditional for each, create a 'section' for logged in buttons and stuff */}
          {user ? (
            <>
              <NavLink
                className='w-full whitespace-nowrap rounded-md px-1 py-0.5 text-left text-primary transition-all hover:bg-gray-300/60'
                to='/plus/cart-history'
                onClick={handleClick}
              >
                {t('navbar.my_carts')}
              </NavLink>
              {/* <NavLink
                className='w-full whitespace-nowrap py-0.5 text-left text-primary transition-all hover:bg-gray-300/60 px-1 rounded-md'
                to='/plus/profile'
                onClick={handleClick}
              >
                Public Profile
              </NavLink> */}
              <NavLink
                className='w-full whitespace-nowrap rounded-md px-1 py-0.5 text-left text-primary transition-all hover:bg-gray-300/60'
                to='/plus/account-details'
                onClick={handleClick}
              >
                {t('navbar.account_details')}
              </NavLink>
              {/* <button */}
              <NavLink
                className='w-full cursor-pointer whitespace-nowrap rounded-md px-1 py-0.5 text-left text-primary transition-all hover:bg-gray-300/60'
                // onClick={() => {
                //   fetch(`${
                //     process.env.REACT_APP_LIVE_URL
                //   }/api/subscribe/create-customer-portal-session`, {
                //     method: 'POST',
                //     credentials: 'include',
                //   }).then((res) => res.json()).then((data) => {
                //     window.location.href = data.url;
                //   });
                //   handleClick();
                // }}
                onClick={handleClick}
                to='/plus/manage-subscription'
              >
                {t('common.manage_subscription')}
                {/* </button> */}
              </NavLink>
              <button
                className='w-full whitespace-nowrap rounded-md px-1 py-0.5 text-left text-primary transition-all hover:bg-gray-300/60'
                onClick={() => {
                  handleLogout();
                  handleClick();
                }}
              >
                {t('navbar.logout')}
              </button>
            </>
          ) : (
            <NavLink
              className='w-full whitespace-nowrap rounded-md px-1 py-0.5 text-left text-primary transition-all hover:bg-gray-300/60'
              to='/login'
              onClick={handleClick}
            >
              {t('navbar.login')}
            </NavLink>
          )}
        </div>
      </CustomDropdown>
    </>
  );
};
