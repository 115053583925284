import { useAtom } from 'jotai';
import { whatForSelectedAtom, whatForTextAtom } from '../../../../../store';
import type { WhatForOpts } from '../../../../../types';
import {
  WelcomeModalTitle,
  WelcomeModalOptionalTextInput,
} from '../WelcomeModalBlocks';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  step: 1 | 2 | 3 | 4 | 5 | 6;
};

const whatForOpts = ['Personal', 'Business', 'Other'];

export const WelcomeModalStep2 = ({ step }: Props) => {
  const [whatForSelected, setWhatForSelected] = useAtom(whatForSelectedAtom);
  const [whatForText, setWhatForText] = useAtom(whatForTextAtom);
  const [showOtherTextInput, setShowOtherTextInput] = useState<boolean>(false);

  const { t } = useTranslation();

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, value: name } = e.target;

    if (checked) {
      setWhatForSelected([...whatForSelected, name as WhatForOpts]);
    } else {
      setWhatForSelected(whatForSelected.filter((option) => option !== name));
    }
  };

  const handleContainerClick = (e: React.MouseEvent<HTMLLIElement>) => {
    const { id } = e.currentTarget;

    if (whatForSelected.includes(id as WhatForOpts)) {
      setWhatForSelected(whatForSelected.filter((option) => option !== id));
    } else {
      setWhatForSelected([...whatForSelected, id as WhatForOpts]);
    }
  };

  useEffect(() => {
    if (whatForSelected.includes('Other')) {
      return setShowOtherTextInput(true);
    }

    setShowOtherTextInput(false);
    setWhatForText('');
  }, [whatForSelected, setWhatForText]);

  return (
    <>
      <WelcomeModalTitle step={step}>
        {t('popup.i_use_sac_for')}
      </WelcomeModalTitle>

      <ul className='flex w-fit flex-col items-start justify-center gap-4'>
        {whatForOpts.map((option, i) => (
          <li
            key={i}
            id={option}
            onClick={(e) => handleContainerClick(e)}
            className='text-300 flex cursor-pointer flex-row items-center justify-center gap-4'
          >
            <input
              type='checkbox'
              value={option}
              checked={whatForSelected.includes(option as WhatForOpts)}
              onChange={handleCheckboxChange}
              className='green-checkbox bg-cloudGray'
            />
            {option}
          </li>
        ))}
      </ul>

      <div className='w-[240px] max-w-[360px]'>
        <WelcomeModalOptionalTextInput
          show={showOtherTextInput}
          value={whatForText}
          changeHandler={(e) => setWhatForText(e.target.value)}
        />
      </div>
    </>
  );
};
