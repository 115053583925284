import styled from '@emotion/styled';
import { Dialog } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { SimpleSpinner } from '../SimpleSpinner';
import { useTranslation } from 'react-i18next';

type Props = {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  onClickPrimary?: () => void;
  onClickPrimaryAsync?: (e: React.FormEvent) => Promise<void>;
  onClickSecondary?: () => void;
  bodyText: string | React.ReactNode;
  titleText?: string;
  showSpinner?: boolean;
  disableButtons?: boolean;
  primaryBtnText?: string;
  secondaryBtnText?: string;
  variant?: 'danger' | 'confirm';
};

export const ConfirmActionModal = ({
  titleText,
  bodyText,
  onClickPrimary,
  onClickPrimaryAsync,
  onClickSecondary,
  show,
  setShow,
  showSpinner,
  disableButtons,
  primaryBtnText,
  secondaryBtnText,
  variant,
}: Props) => {
  const { t } = useTranslation();

  const handleClickSecondary = () => {
    if (onClickSecondary) {
      onClickSecondary();
    }

    setShow(false);
  };

  return (
    <ConfirmDeleteDialogStyed open={show} onClose={() => setShow(false)}>
      <div className='relative flex flex-col items-center justify-center gap-8 p-4'>
        <div
          onClick={() => setShow(false)}
          className='absolute right-3 top-3 cursor-pointer fill-darkSteel hover:fill-gray-400'
        >
          <CloseIcon
            className='transition-all'
            style={{ fontSize: '24px', fill: 'inherit' }}
          />
        </div>
        <h2 className='heading-400 text-balance px-5 text-center'>
          {titleText || 'Are you sure?'}
        </h2>
        <p className='text-200 whitespace-pre-line text-center'>{bodyText}</p>

        {showSpinner && (
          <SimpleSpinner
            customPosition='block'
            customWidth='w-10'
            customHeight='h-10'
            customColor='border-rose-500'
          />
        )}

        <div className='flex w-full flex-row flex-wrap items-center justify-center gap-2'>
          <button
            onClick={() => handleClickSecondary()}
            disabled={disableButtons}
            className='button-secondary h-12 w-full sm:w-fit'
          >
            {secondaryBtnText ? secondaryBtnText : t('common.cancel')}
          </button>
          <button
            onClick={(e) =>
              onClickPrimary
                ? onClickPrimary()
                : onClickPrimaryAsync
                  ? onClickPrimaryAsync(e)
                  : ''
            }
            disabled={disableButtons}
            className={`button-primary h-12 w-full sm:w-fit ${variant === 'danger' || !variant ? 'bg-rose-500 hover:bg-rose-500/70' : ''}`}
          >
            {primaryBtnText ? primaryBtnText : t('common.delete')}
          </button>
        </div>
      </div>
    </ConfirmDeleteDialogStyed>
  );
};

const ConfirmDeleteDialogStyed: React.ComponentType<any> = styled(Dialog)(
  ({ theme }) => ({
    '& .MuiDialog-paper': {
      // padding: 32,
      borderRadius: 16,
      backgroundColor: '#F1F1F1',
      position: 'relative',
      width: '90%',
      maxWidth: 500,
      minWidth: 294,
    },
  }),
);
