import { useTranslation } from 'react-i18next';
import { useAtom } from 'jotai';
import { HistorySortByEnum } from 'src/types';
import { historySortByAtom } from 'src/store';

type Props = {
  handleClick: () => void;
};

export const SortItems = ({ handleClick }: Props) => {
  const [sortBy, setSortBy] = useAtom(historySortByAtom);

  const { t } = useTranslation();

  return (
    <>
      {Object.keys(HistorySortByEnum).map((sort, i) => (
        <button
          key={`sort-${i}`}
          className={`w-full whitespace-nowrap py-0.5 text-left transition-all ${
            sortBy === sort
              ? 'text-primary-dark hover:text-primary'
              : 'text-primary hover:text-primary-dark'
          }`}
          name={sort}
          onClick={(e) => {
            setSortBy(e.currentTarget.name as keyof typeof HistorySortByEnum);
            handleClick();
          }}
        >
          {t(`history.${sort}`)}
        </button>
      ))}
    </>
  );
};
