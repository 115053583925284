import Lottie from 'lottie-react';
import { ReactComponent as Logo } from '../../images/logos/logo.svg';
import { Link } from 'react-router-dom';
import { AlertPing, SimpleSpinner, ToastStyled } from '../reusableBlocks';
import { FormEvent, useLayoutEffect, useRef, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'sonner';
import { Trans, useTranslation } from 'react-i18next';

const cartAnimation = require('../../images/lotties/footer-carts.json');

export const Footer = () => {
  const [email, setEmail] = useState<string>('');

  const footerLinksContRef = useRef<HTMLDivElement>(null);
  const footerLinksOneRef = useRef<HTMLLIElement>(null);
  const footerLinksTwoRef = useRef<HTMLLIElement>(null);

  const { t, i18n } = useTranslation();

  const inputRef = useRef<HTMLInputElement>(null);

  const i18nLang = i18n.resolvedLanguage
    ? i18n.resolvedLanguage.toLowerCase()
    : i18n.language.toLowerCase();

  const subscribeEmail = useMutation({
    mutationFn: async () => {
      const trimmedEmail = email.trim();
      const regex = /\S+@\S+\.\S+/;

      if (!trimmedEmail || !regex.test(trimmedEmail)) {
        throw new Error('Invalid email');
      }

      await fetch(
        `${process.env.REACT_APP_LIVE_URL}/api/contact/newsletter/register`,
        {
          headers: {
            'Content-Type': 'application/json',
            credentials: 'include',
          },
          method: 'POST',
          body: JSON.stringify({ email: trimmedEmail }),
        },
      );
    },
    onSuccess: () => {
      setEmail('');
      toast.custom((toastBlock) => (
        <ToastStyled
          variant='success'
          handleClose={() => toast.dismiss(toastBlock)}
        >
          <Trans
            i18nKey={t('footer.subscription_success')}
            components={[<br />, <strong />]}
          />
        </ToastStyled>
      ));
    },
    onError: (error) => {
      toast.custom((toastBlock) => (
        <ToastStyled
          variant='error'
          handleClick={() => {
            inputRef.current?.focus();
          }}
          handleClose={() => toast.dismiss(toastBlock)}
        >
          {t('common.something_went_wrong')}
        </ToastStyled>
      ));
    },
  });

  const handleSubmit = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();

    subscribeEmail.mutate();
  };

  useLayoutEffect(() => {
    if (
      !footerLinksContRef.current ||
      !footerLinksOneRef.current ||
      !footerLinksTwoRef.current
    ) {
      return;
    }

    const resizeObserver = new ResizeObserver(() => {
      const footerLinksHeight = footerLinksContRef.current!.clientHeight;

      const flic1Height = footerLinksOneRef.current!.clientHeight;
      const flic1Width = footerLinksOneRef.current!.clientWidth;

      const flic2Height = footerLinksTwoRef.current!.clientHeight;
      const flic2Width = footerLinksTwoRef.current!.clientWidth;

      const tallestFooterLinks = Math.max(flic1Height, flic2Height);

      // if the height of the footer links container is larger than the tallest footer link
      // then we know the links are still stacked on top of each other, meaning we should
      // make sure they're the same width
      if (footerLinksHeight > tallestFooterLinks) {
        if (flic1Width > flic2Width) {
          footerLinksTwoRef.current!.style.width = `${flic1Width}px`;
        } else if (flic2Width > flic1Width) {
          footerLinksOneRef.current!.style.width = `${flic2Width}px`;
        }
      }
    });

    resizeObserver.observe(footerLinksContRef.current);

    return () => resizeObserver.disconnect();
  }, []);

  return (
    <div className='flex shrink-0 flex-col items-center justify-center gap-6 bg-white pt-8 font-secondary text-base font-semibold text-primary-dark sm:p-12 lg:p-20 lg:pt-12'>
      <span className='p-2 text-center font-secondary text-[13px] font-normal text-blueGray'>
        {t('footer.affiliate_disclaimer')}
      </span>
      <Link
        to={`${process.env.REACT_APP_LIVE_URL}/`}
        reloadDocument
        className='mb-4 flex flex-row px-1 py-1 transition-all active:scale-[97%]'
      >
        <Logo title='Share-A-Cart Logo' />
      </Link>
      <div className='flex w-full flex-col flex-wrap items-center justify-center gap-y-12 p-8 pt-0 xs:flex-row md:flex-row'>
        <nav
          ref={footerLinksContRef}
          className={`flex w-full flex-col flex-wrap items-start justify-evenly gap-12 md:gap-10 lg:flex-nowrap lg:gap-14 xl:mr-auto xl:w-auto
        ${i18nLang === 'ru' ? 'sm:flex-row' : 'xs:flex-row'}`}
        >
          <li
            ref={footerLinksOneRef}
            className='flex flex-col justify-center gap-y-6'
          >
            <Link
              to={`${process.env.REACT_APP_LIVE_URL}/plus`}
              reloadDocument
              className='max-w-max px-1 leading-[25.6px] transition-all hover:text-primary active:scale-[97%]'
            >
              {t('footer.get_sac_plus')}
            </Link>

            <Link
              to={`${process.env.REACT_APP_LIVE_URL}/guide`}
              reloadDocument
              className='max-w-max px-1 leading-[25.6px] transition-all hover:text-primary active:scale-[97%]'
            >
              {t('footer.how_to_guides')}
            </Link>

            <Link
              to={`${process.env.REACT_APP_LIVE_URL}/faq`}
              reloadDocument
              className='max-w-max px-1 leading-[25.6px] transition-all hover:text-primary active:scale-[97%]'
            >
              {t('footer.faqs')}
            </Link>

            <Link
              to={`${process.env.REACT_APP_LIVE_URL}/plugins`}
              reloadDocument
              className='max-w-max px-1 leading-[25.6px] transition-all hover:text-primary active:scale-[97%]'
            >
              {t('footer.plugins')}
            </Link>

            <Link
              to='/get'
              className='max-w-max px-1 leading-[25.6px] transition-all hover:text-primary active:scale-[97%]'
            >
              {t('common.receive_a_cart')}
            </Link>

            <Link
              to={`${process.env.REACT_APP_LIVE_URL}/make`}
              reloadDocument
              className='max-w-max px-1 leading-[25.6px] transition-all hover:text-primary active:scale-[97%]'
            >
              {t('common.create_a_cart')}
            </Link>

            <Link
              to={`${process.env.REACT_APP_LIVE_URL}/about#contactform`}
              reloadDocument
              className='max-w-max px-1 leading-[25.6px] transition-all hover:text-primary active:scale-[97%]'
            >
              {t('footer.contact_us')}
            </Link>
          </li>
          <li
            ref={footerLinksTwoRef}
            className='flex flex-col justify-center gap-y-6'
          >
            <Link
              to={`${process.env.REACT_APP_LIVE_URL}/`}
              reloadDocument
              className='max-w-max px-1 leading-[25.6px] transition-all hover:text-primary active:scale-[97%]'
            >
              {t('footer.home')}
            </Link>

            <Link
              to={`${process.env.REACT_APP_LIVE_URL}/about`}
              reloadDocument
              className='max-w-max px-1 leading-[25.6px] transition-all hover:text-primary active:scale-[97%]'
            >
              {t('footer.about')}
            </Link>

            <Link
              to={`${process.env.REACT_APP_LIVE_URL}/blog`}
              reloadDocument
              className='max-w-max px-1 leading-[25.6px] transition-all hover:text-primary active:scale-[97%]'
            >
              {t('footer.blog')}
            </Link>

            <Link
              to='/changelog'
              className='max-w-max px-1 leading-[25.6px] transition-all hover:text-primary active:scale-[97%]'
            >
              {t('footer.changelog')}
            </Link>

            <Link
              to={`${process.env.REACT_APP_LIVE_URL}/business-solutions`}
              reloadDocument
              className='max-w-max px-1 leading-[25.6px] transition-all hover:text-primary active:scale-[97%]'
            >
              {t('footer.business_solutions')}
            </Link>

            <Link
              to={`${process.env.REACT_APP_LIVE_URL}/privacy`}
              reloadDocument
              className='max-w-max px-1 leading-[25.6px] transition-all hover:text-primary active:scale-[97%]'
            >
              {t('footer.privacy_policy')}
            </Link>

            <Link
              to={`${process.env.REACT_APP_LIVE_URL}/terms-of-service`}
              reloadDocument
              className='max-w-max px-1 leading-[25.6px] transition-all hover:text-primary active:scale-[97%]'
            >
              {t('footer.terms_of_service')}
            </Link>
          </li>
        </nav>

        <div className='mb-auto flex w-fit flex-col items-start justify-center font-normal'>
          <span className='text-200 w-full text-pretty text-center md:max-w-[440px] md:text-left'>
            {t('footer.subscribe_to_newsletter')}:
          </span>
          <form
            onSubmit={handleSubmit}
            className='mx-auto my-2 flex w-full max-w-[420px] flex-col items-center justify-start gap-x-2.5 gap-y-2 md:mx-0 md:flex-row lg:max-w-full'
          >
            <div className='group relative h-10 w-full min-w-[200px] lg:w-[260px] xl:w-[300px]'>
              <input
                type='text'
                placeholder={t('footer.your_email')}
                value={email}
                ref={inputRef}
                onChange={(e) => setEmail(e.target.value)}
                onBlur={() => {
                  if (subscribeEmail.isError) subscribeEmail.reset();
                }}
                disabled={subscribeEmail.isPending}
                className={`h-10 w-full min-w-[200px] rounded border-none bg-cloudGray px-3 py-2 font-secondary font-semibold leading-[24px] text-primary-dark outline-none ring-2 transition-all selection:bg-primary selection:text-cloudGray placeholder:font-normal placeholder:text-darkGray hover:bg-gray-200 focus:ring-2 focus:hover:bg-cloudGray disabled:bg-gray-300 disabled:opacity-60 disabled:ring-primary lg:w-[260px] xl:w-[300px] ${
                  subscribeEmail.isError
                    ? 'bg-rose-100 ring-rose-500 hover:bg-rose-50 focus:hover:bg-rose-100'
                    : subscribeEmail.isPending
                      ? 'focus:ring-primary'
                      : 'bg-cloudGray ring-transparent hover:bg-gray-200 focus:ring-primary focus:hover:bg-cloudGray'
                }`}
              />

              {subscribeEmail.isError ? (
                <AlertPing
                  variant='error'
                  customX='-right-2'
                  customY='-top-2'
                />
              ) : subscribeEmail.isPending ? (
                <SimpleSpinner customX='-right-2' customY='-top-2' />
              ) : (
                ''
              )}
            </div>

            <button
              className='button-secondary h-10 w-full md:w-fit'
              type='submit'
            >
              {t('footer.subscribe')}
            </button>
          </form>
          <span className='text-100 w-full text-pretty text-center md:max-w-[440px] md:text-left'>
            {t('footer.never_spam')}
          </span>
        </div>
      </div>

      <div className='flex w-full flex-col items-center'>
        <Lottie
          animationData={cartAnimation}
          style={{ width: 'full', maxWidth: '400px', height: 'auto' }}
        />
        <div className='h-1 w-full border-t border-black'></div>
        <span className='mt-1 w-full text-left text-sm font-normal'>
          © 2025, Share-A-Cart
        </span>
      </div>
    </div>
  );
};
