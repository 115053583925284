import { UseQueryResult } from '@tanstack/react-query';
import { CartGetData } from '../../../types';
import { GetCompareCard } from './GetCompareCard';
import { PriceDisclaimerBlock } from '../reuseables';
import { Trans, useTranslation } from 'react-i18next';
import { formatPrice } from 'src/utils/formatPrice';

type Props = {
  cartQuery: UseQueryResult<CartGetData, Error>;
  handleContinue: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void;
};

export const GetCompareHeader = ({ cartQuery, handleContinue }: Props) => {
  const { t } = useTranslation();

  if (cartQuery.isLoading) {
    return null;
  }

  const locale = cartQuery.data?.locale || 'en';
  const ccy = cartQuery.data?.cartCCY || 'USD';
  const ccys = cartQuery.data?.cartCCYS || '$';

  const splitPriceDiff =
    cartQuery.data?.matchedVendorCart.splitPriceDiff &&
    typeof cartQuery.data?.matchedVendorCart.splitPriceDiff === 'number'
      ? cartQuery.data?.matchedVendorCart.splitPriceDiff
      : parseFloat(cartQuery.data?.matchedVendorCart.splitPriceDiff!);
  const formattedSplitPriceDiff = formatPrice(
    splitPriceDiff,
    locale,
    ccy,
    ccys,
  );

  return (
    <div className='flex flex-col flex-wrap items-center justify-between gap-y-4 bg-primary-dark p-4 pb-8 pt-0 xs:p-6 smMed:gap-x-4 md:px-10 lg:px-12'>
      <h1 className='heading-400 whitespace-normal text-center text-offWhite transition-all'>
        {t('get.savings_found')}
      </h1>
      <p className='text-200 text-center text-blueGray'>
        <Trans
          i18nKey={t('get.you_would_save')}
          count={cartQuery.data?.matchedVendorCart.items.length}
          values={{
            ccys,
            splitPriceDiff: formattedSplitPriceDiff,
            matchedVendorName:
              cartQuery.data?.matchedVendorCart.matchToDisplayName,
            originalVendorName: cartQuery.data?.vendorDisplayName,
          }}
          components={{
            1: <b className='text-offWhite'>{formattedSplitPriceDiff}</b>,
          }}
        />
        :
      </p>

      {/* COMPARE CART SAVINGS */}
      <div className='flex w-full max-w-[372px] flex-col items-center justify-center gap-4 lg:max-w-full lg:flex-row'>
        {/* CHEAPER CART DETAILS */}
        <GetCompareCard
          title={t('get.savings_found')}
          vendor={cartQuery.data?.matchedVendorCart.matchTo!}
          vendorDisplayName={
            cartQuery.data?.matchedVendorCart.matchToDisplayName!
          }
          ccy={ccy}
          ccys={ccys}
          locale={locale}
          price={cartQuery.data?.matchedVendorCart.splitItemsTotalPrice || 0}
          itemsLength={cartQuery.data?.cheaperItemsQty!}
          allText={false}
          btnName={'splitNew'}
          handleContinue={handleContinue}
        />

        {/* 
        ORIGINAL VENDOR MINUS ITEMS FOUND CHEAPER
        If all items cheaper elsewhere, don't display this
      */}
        {cartQuery.data?.originalVendorCart.items.length! > 0 && (
          <GetCompareCard
            title={t('get.no_extra_savings')}
            vendor={cartQuery.data?.originalVendorCart.matchTo!}
            vendorDisplayName={
              cartQuery.data?.originalVendorCart.matchToDisplayName!
            }
            ccy={ccy}
            ccys={ccys}
            locale={locale}
            price={
              cartQuery.data?.originalVendorCart.splitOriginalTotalPrice || 0
            }
            itemsLength={cartQuery.data?.originalVendorCart.originalItemsQty!}
            allText={false}
            btnName={'splitOriginal'}
            handleContinue={handleContinue}
          />
        )}

        {/* OR and dashed red lines */}
        <div className='flex w-full flex-row items-center justify-center self-stretch lg:w-auto lg:flex-col'>
          <div className='block h-0.5 w-auto flex-grow border-2 border-dashed border-red-500'></div>
          <p className='text-200 px-2 text-offWhite lg:-rotate-90 lg:px-0 lg:py-2'>
            {t('get.or')}
          </p>
          <div className='block h-0.5 w-auto flex-grow border-2 border-dashed border-red-500'></div>
        </div>

        {/* ORIGINAL CART DETAILS */}
        <GetCompareCard
          title={t('get.higher_price_cart')}
          vendor={cartQuery.data?.vendor!}
          vendorDisplayName={cartQuery.data?.vendorDisplayName!}
          ccy={ccy}
          ccys={ccys}
          locale={locale}
          price={cartQuery.data?.cartTotalPrice || 0}
          itemsLength={cartQuery.data?.cartTotalQty!}
          allText={true}
          btnName={'all'}
          handleContinue={handleContinue}
        />
      </div>
      <PriceDisclaimerBlock
        timestamp={cartQuery.data?.timestamp || 0}
        itemCount={cartQuery.data?.cartTotalQty!}
        forCompare={true}
        cartVendor={cartQuery.data?.vendor!}
      />
    </div>
  );
};
