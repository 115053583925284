import { WelcomeModalTitle } from '../WelcomeModalBlocks';
import { howLongSelectedAtom } from '../../../../../store';
import { useAtom } from 'jotai';
import type { HowLongOpts } from '../../../../../types';

type Props = {
  step: 1 | 2 | 3 | 4 | 5 | 6;
};

const howLongOpts = [
  '1 Week or Less',
  '1 Month or Less',
  '6 Months or Less',
  'More than 6 Months',
];

export const WelcomeModalStep3 = ({ step }: Props) => {
  const [howLongSelected, setHowLongSelected] = useAtom(howLongSelectedAtom);

  const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setHowLongSelected(e.target.value as HowLongOpts);
  };

  const handleContainerClick = (e: React.MouseEvent<HTMLLIElement>) => {
    const { id } = e.currentTarget;

    setHowLongSelected(id as HowLongOpts);
  };

  return (
    <>
      <WelcomeModalTitle step={step}>
        I've been using Share-A-Cart for:
      </WelcomeModalTitle>

      <ul className='flex w-fit flex-col items-start justify-center gap-4'>
        {howLongOpts.map((option, i) => (
          <li
            key={i}
            id={option}
            onClick={(e) => handleContainerClick(e)}
            className='cursor-pointer text-300 flex flex-row items-center justify-center gap-4'
          >
            <input
              type='radio'
              value={option}
              checked={howLongSelected === (option as HowLongOpts)}
              onChange={handleRadioChange}
              className='green-radio-btn bg-cloudGray'
            />
            {option}
          </li>
        ))}
      </ul>
    </>
  );
};
