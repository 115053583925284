import { useAtom } from 'jotai';
import { globalCartTagsAtom } from '../../../../store';
import { predefinedTags } from '../../../../utils';
import { BaseCartTag } from '.';
import { TooltipStyled } from 'src/components';
import { useTranslation } from 'react-i18next';

type Props = {
  currentTags: string[];
  tagInput: string;
  addGlobalTag: (tag: string) => void;
};

export const GlobalCartTagsListDisplay = ({
  currentTags,
  tagInput,
  addGlobalTag,
}: Props) => {
  const [globalCartTags] = useAtom(globalCartTagsAtom);

  const { t } = useTranslation();

  return (
    <div className='custom-scrollbar max-h-[90px] min-h-[90px] w-full overflow-y-scroll pr-2'>
      <ul className='flex flex-row flex-wrap items-start justify-start gap-2'>
        {/*
            render predefined tags here,
            if predefined tag isn't present in currentTags AND
            if tagInput is empty or the predefined tag includes the tagInput,
            we render it as part of the list of tags
            the user can click to add .

            Tags from the history page won't be populated if the user hasn't visited the history page
        */}
        {[predefinedTags, globalCartTags].map((list) =>
          list.map((existingTag) => {
            if (
              currentTags.findIndex((tag) =>
                tag.toLowerCase().includes(existingTag.toLowerCase()),
              ) === -1 &&
              (!tagInput ||
                existingTag.toLowerCase().includes(tagInput.toLowerCase()))
            ) {
              return (
                <TooltipStyled
                  title={t('popup.click_to_add')}
                  key={existingTag}
                  enterDelay={200}
                  disableInteractive
                >
                  <div>
                    <BaseCartTag
                      tag={existingTag}
                      addGlobalTag={addGlobalTag}
                    />
                  </div>
                </TooltipStyled>
              );
            }

            return null;
          }),
        )}
      </ul>
    </div>
  );
};
