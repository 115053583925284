import { atom } from 'jotai';
import {
  HowLongOpts,
  SACFlavor,
  TypesOfGoodsOpts,
  WhatForOpts,
  WithWhoOpts,
  type ChangelogFilterByValue,
  type HistoryFilterByValue,
  type HistorySortByValue,
  type UserProfile,
} from './types';
// import { atomWithQuery } from 'jotai-tanstack-query';
// import { getCart } from './api';

// Jotai store implementation
export const authUserAtom = atom<UserProfile | null>(null);

export const changelogFilterByAtom = atom<ChangelogFilterByValue[]>(['all']);
export const changelogSearchByAtom = atom<string>('');

export const historyFilterByAtom =
  atom<HistoryFilterByValue>('sent_and_received');
export const historySortByAtom = atom<HistorySortByValue>('timestamp|desc');
export const historySearchByAtom = atom<string>('');

export const globalCartTagsAtom = atom<string[]>([]);

export const extExistsAtom = atom<boolean>(false);
export const extsPresentAtom = atom<SACFlavor[]>([]);
export const extUpToDateAtom = atom<boolean>(false);

export const getStepAtom = atom<1 | 2 | 3 | 4>(1);
export const getCartIdAtom = atom<string>('');

export const showEmbedModalAtom = atom<boolean>(false);

export const showExtensionLoadingModalAtom = atom<boolean>(false);
export const showExtensionMissingModalAtom = atom<boolean>(false);

export const showChunkCartModalAtom = atom<boolean>(false);
export const chunkLinksAtom = atom<string[]>([]);

// Cart History Import States
export const showCartsImportedModalAtom = atom<boolean>(false);
export const numberOfCartsImportedAtom = atom<number | null>(null);
export const cartImportErrorAtom = atom<boolean>(false);
export const cartImportPendingAtom = atom<boolean>(false);

// User Survey States
export const whatForSelectedAtom = atom<WhatForOpts[]>([]);
export const whatForTextAtom = atom<string>('');

export const howLongSelectedAtom = atom<HowLongOpts | undefined>(undefined);

export const withWhoSelectedAtom = atom<WithWhoOpts[]>([]);
export const withWhoTextAtom = atom<string>('');

export const typesOfGoodsSelectedAtom = atom<TypesOfGoodsOpts[]>([]);
export const typesOfGoodsTextAtom = atom<string>('');

// list or grid view state
// cartslist?
