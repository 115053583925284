import { useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  CartDate,
  CartDetailsModal,
  CartNotes,
  // CartPublic,
  CartTags,
} from '../../reusableBlocks/cartBlocks';
import { ReactComponent as EditIcon } from '../../../images/icons/edit-icon.svg';
import { ReactComponent as TrashIcon } from '../../../images/icons/trash-icon.svg';
import { useAtom } from 'jotai';
import { authUserAtom, getCartIdAtom } from '../../../store';
import { ToastStyled } from '../../reusableBlocks';
import { toast } from 'sonner';
import type {
  CartGetData,
  CartGetItem,
  // CartHistoryCacheUpdate,
  // CartHistoryData,
  UserProfile,
} from '../../../types';
import { ConfirmActionModal } from '../../reusableBlocks/modals';
import { useTranslation } from 'react-i18next';

type Props = {
  cartTimestamp: number;
  cartListed: boolean;
  cartNotes: string;
  cartTags: string[];
  cartTitle: string | undefined;
  cartCreated: boolean;
  cartStore: string | undefined;
  cartCCY: string;
  cartCCYS: string;
  cartItems: CartGetItem[];
};

export const GetReviewOwnership = ({
  cartTimestamp,
  cartListed,
  cartNotes,
  cartTags,
  cartTitle,
  cartCreated,
  cartStore,
  cartCCY,
  cartCCYS,
  cartItems,
}: Props) => {
  const [showDetailsModal, setShowDetailsModal] = useState<boolean>(false);
  const [showConfirmActionModal, setShowConfirmActionModal] =
    useState<boolean>(false);
  const [cartId] = useAtom(getCartIdAtom);
  const [, setUser] = useAtom(authUserAtom);

  const { t } = useTranslation();

  const queryClient = useQueryClient();

  const disassociateCart = useMutation({
    mutationFn: async ({ cartId }: { cartId: string }): Promise<boolean> => {
      const res = await fetch(
        `${process.env.REACT_APP_LIVE_URL}/user/cart/delete/${cartId}`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
          method: 'DELETE',
          credentials: 'include',
        },
      );

      if (!res.ok) {
        throw new Error('Failed to delete cart');
      }

      const { status } = await res.json();

      // since status is an empty string if successful, we can check
      // to see if status exists, if it does, it means there was an error
      if (status) {
        throw new Error('Error updating cart... status...');
      }

      // return true to trigger the onSuccess below
      return true;
    },
    onSuccess: (mutationReturn, variables) => {
      // update cart list queries to remove deleted cart
      // update cart cartId to remove ownership data
      toast.custom((toastBlock) => (
        <ToastStyled
          variant='success'
          handleClose={() => toast.dismiss(toastBlock)}
        >
          {t('get.cart_removed_from_your_cart_history')}
        </ToastStyled>
      ));
      queryClient.setQueryData(['currentUser'], (oldUserData: UserProfile) => {
        // remove cartId from user's cart list in cache & update user atom
        const newUserData = { ...oldUserData };

        newUserData.carts = newUserData.carts.filter(
          (cartId) => cartId !== variables.cartId,
        );

        setUser(newUserData);
        return newUserData;
      });
      queryClient.setQueryData(['cart', cartId], (oldData: CartGetData) => {
        // remove ownership data
        const newData = { ...oldData };
        delete newData.ownershipData;
        return newData;
      });
      queryClient.invalidateQueries({
        queryKey: ['cart', 'list'],
        exact: false,
        type: 'all',
      });
      // queryClient.setQueriesData(
      //   { queryKey: ['cart', 'list'], exact: false, type: 'all' },
      //   (cartsCache: CartHistoryCacheUpdate) => {
      //     if (!cartsCache) {
      //       return undefined;
      //     }

      //     const updatedCartsCache: CartHistoryCacheUpdate = {
      //       pages: [],
      //       pageParams: [],
      //     };

      //     // if cartId is found in the cart history caches, remove it
      //     cartsCache.pages.forEach((cartPage, i) => {
      //       const page = cartPage.carts.filter(
      //         (iteratedCart) => iteratedCart.cart.id !== variables.cartId,
      //       );

      //       updatedCartsCache.pages.push({
      //         carts: page as CartHistoryData[],
      //       });

      //       updatedCartsCache.pageParams.push(i);
      //     });

      //     return { ...updatedCartsCache };
      //   },
      // );
    },
    onError: (error) => {
      toast.custom((toastBlock) => (
        <ToastStyled
          variant='error'
          handleClose={() => toast.dismiss(toastBlock)}
        >
          {t('get.something_went_wrong_trying_to_remove_cart_from_history')}
        </ToastStyled>
      ));
    },
  });

  const handleDelete = () => {
    disassociateCart.mutate({ cartId });
    setShowConfirmActionModal(false);
  };

  return (
    <div className='flex min-h-[52px] w-full max-w-full flex-row flex-wrap items-start justify-between gap-x-2 gap-y-2 bg-cloudGray px-2 py-2 xs:gap-x-4 xs:px-4 sm:flex-nowrap md:px-10 lg:px-12'>
      <div className='order-1 flex min-h-[36px] min-w-min grow-0 flex-row items-center justify-start gap-4'>
        <CartDate cartTimestamp={cartTimestamp} forGetPage={true} />
        {/* <CartPublic cartId={cartId} cartListed={cartListed} forGetPage={true} /> */}
        <CartNotes cartId={cartId} cartNotes={cartNotes} forGetPage={true} />
      </div>
      <div className='grow-1 order-3 mr-auto flex w-full flex-row items-center justify-start sm:order-2'>
        <CartTags cartId={cartId} cartTags={cartTags} forGetPage={true} />
      </div>
      <div className='order-2 flex grow-0 flex-row items-center justify-center gap-2 xs:gap-4 sm:order-3'>
        <button
          onClick={() => setShowDetailsModal(true)}
          className='group button-secondary h-9 gap-1 px-4 text-sm leading-[14px] xs:gap-2 xs:px-6'
        >
          <EditIcon className='h-5 w-5 text-primary' />
          {t('get.details')}
        </button>
        <TrashIcon
          onClick={() => setShowConfirmActionModal(true)}
          className='h-auto w-5 cursor-pointer stroke-darkSteel transition-all hover:stroke-gray-400'
        />
      </div>
      <CartDetailsModal
        cartId={cartId}
        cartTitle={cartTitle || ''}
        cartListed={cartListed}
        cartNotes={cartNotes}
        cartTags={cartTags}
        cartCreated={cartCreated}
        show={showDetailsModal}
        setShow={setShowDetailsModal}
        cartCCY={cartCCY}
        cartCCYS={cartCCYS}
        cartItems={cartItems}
        cartTimestamp={cartTimestamp}
        cartStore={cartStore}
      />

      <ConfirmActionModal
        show={showConfirmActionModal}
        setShow={setShowConfirmActionModal}
        onClickPrimary={handleDelete}
        titleText={t('get.are_you_sure')}
        bodyText={t('get.deleting_cart_will_remove_it_from_your_cart_history')}
      />
    </div>
  );
};
