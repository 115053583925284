import { useTranslation } from 'react-i18next';
import { ChangelogFilterByEnum, type ChangelogFilterByValue } from 'src/types';
import { changelogTagVariants } from '.';
import { cn } from 'src/utils';

type Props = {
  handleUpdateFilterBy: (clickedFilter: ChangelogFilterByValue) => void;
  filterBy: ChangelogFilterByValue[];
  showAllTag: boolean;
};

export const ChangelogFilterItems = ({
  handleUpdateFilterBy,
  filterBy,
  showAllTag,
}: Props) => {
  const { t } = useTranslation();

  return (
    <>
      {Object.keys(ChangelogFilterByEnum).map((filter, i) => {
        if (!showAllTag && filter === 'all') {
          return null;
        }

        return (
          <li
            key={`filter-${i}`}
            id={filter}
            onClick={(e) => {
              e.stopPropagation();
              handleUpdateFilterBy(
                filter as keyof typeof ChangelogFilterByEnum,
              );
            }}
            className='group flex w-full cursor-pointer list-none flex-row items-center justify-start gap-4 whitespace-nowrap border-blueGray transition-all first:border-b first:pb-0.5'
          >
            <input
              readOnly
              type='checkbox'
              checked={filterBy.includes(
                filter as keyof typeof ChangelogFilterByEnum,
              )}
              className='green-checkbox bg-[#CFCFD0]'
            />
            <span
              className={cn(
                changelogTagVariants({
                  variant: filter as keyof typeof ChangelogFilterByEnum,
                }),
              )}
            >
              {t(`changelog.tags.${filter}`)}
            </span>
          </li>
        );
      })}
    </>
  );
};
