import { useQuery } from '@tanstack/react-query';
import { LoadingCartFullPage } from '../components';
import { useAtom } from 'jotai';
import { authUserAtom } from '../store';

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [, setUser] = useAtom(authUserAtom);

  const { isPending, error } = useQuery({
    queryKey: ['currentUser'],
    queryFn: async () => {
      const res = await fetch(
        `${process.env.REACT_APP_LIVE_URL}/user/currentUser`,
        {
          credentials: 'include',
        },
      );
      if (!res.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await res.json();

      if (!data.user) {
        return null;
      }

      if (!data.user.carts) {
        data.user.carts = [];
      }

      if (!data.user.subscription) {
        data.user.subscription = 'free';
      }

      // TODO: Remove this once we have a real subscription
      const betaUser = data.user;
      betaUser.subscription = {
        level: 'personal',
      };

      setUser(betaUser);
      return betaUser;

      // setUser(data.user);
      // return data.user;
    },
    staleTime: Infinity,
  });

  if (isPending) {
    return (<LoadingCartFullPage />);
  }

  if (error) {
    console.error(`Error getting user: ${error.message}`);
  }

  return <>{children}</>;
};
