import {
  HandleUpdateCart,
  CartGetData,
  CartHistoryCacheUpdate,
} from '../types';

export const updateCartMutation = async ({
  cartId,
  propertyToUpdate,
  updatedValue,
}: HandleUpdateCart): Promise<boolean> => {
  const body = {
    cartid: cartId,
    [propertyToUpdate]:
      propertyToUpdate === 'tags' ? JSON.stringify(updatedValue) : updatedValue,
  };

  const res = await fetch(
    `${process.env.REACT_APP_LIVE_URL}/user/cart/update`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify(body),
    },
  );

  if (!res.ok) {
    throw new Error('Error updating cart... res not ok...');
  }

  // status will be an empty string if successful
  const { status } = await res.json();

  // since status is an empty string if successful, we can check
  // to see if status exists, if it does, it means there was an error
  if (status) {
    throw new Error('Error updating cart... status...');
  }

  // return true to trigger the onSuccess below
  return true;
};

export const updateCartHistoryMutationSuccess = (
  cartsCache: CartHistoryCacheUpdate,
  mutationVariables: HandleUpdateCart,
) => {
  if (!cartsCache) {
    return undefined;
  }

  // if the cart is not in the cache, return undefined to trigger refetch/invalidation
  if (
    !cartsCache.pages.some((cartPage) =>
      cartPage.carts.some(
        (iteratedCart) => iteratedCart.cart.id === mutationVariables.cartId,
      ),
    )
  ) {
    return undefined;
  }

  const updatedCartsCache: CartHistoryCacheUpdate = {
    pages: [],
    pageParams: [],
  };

  cartsCache.pages.forEach((cartPage, i) => {
    const page = cartPage.carts.map((iteratedCart) => {
      if (iteratedCart.cart.id === mutationVariables.cartId) {
        if (mutationVariables.propertyToUpdate === 'title') {
          const updatedCartCart = {
            ...iteratedCart.cart,
            title: mutationVariables.updatedValue as string,
          };

          return { ...iteratedCart, cart: updatedCartCart };
        }

        return {
          ...iteratedCart,
          [mutationVariables.propertyToUpdate]: mutationVariables.updatedValue,
        };
      }

      return { ...iteratedCart };
    });

    updatedCartsCache.pages.push({
      carts: page,
    });

    updatedCartsCache.pageParams.push(i);
  });

  return { ...updatedCartsCache };
};

export const updateCartGetMutationSuccess = (
  oldData: CartGetData,
  mutationVariables: HandleUpdateCart,
  titleGenerated: boolean,
) => {
  if (!oldData) {
    return undefined;
  }

  if (mutationVariables.propertyToUpdate === 'title') {
    return {
      ...oldData,
      title: titleGenerated ? '' : (mutationVariables.updatedValue as string),
    };
  }

  const updatedCart = { ...oldData };

  if (!updatedCart.ownershipData) {
    return undefined;
  }

  updatedCart.ownershipData = {
    ...updatedCart.ownershipData,
    [mutationVariables.propertyToUpdate]: mutationVariables.updatedValue,
  };

  return { ...updatedCart };
};
