import { useState } from 'react';
import { predefinedTags, randomTagColorGenerator } from '../../../../utils';
import CloseIcon from '@mui/icons-material/Close';
import { TooltipStyled } from '../../TooltipStyled';
import { useTranslation } from 'react-i18next';

type Props = {
  tag: string;
  removeTag: (tag: string) => void;
};

export const TempCartTag = ({ tag, removeTag }: Props) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);

  const { t } = useTranslation();

  let lightColor, darkColor;
  const lowercaseTag = tag.toLowerCase();

  if (
    predefinedTags.findIndex((predefinedTag) =>
      lowercaseTag.includes(predefinedTag.toLowerCase()),
    ) === -1
  ) {
    [lightColor, darkColor] = randomTagColorGenerator(lowercaseTag);
  }

  return (
    <TooltipStyled
      title={t('popup.click_to_remove')}
      enterDelay={200}
      disableInteractive
    >
      <li
        {...(lightColor
          ? {
              style: {
                background: lightColor,
                color: darkColor,
                fill: darkColor,
                ...(isHovered && { border: `1px solid ${darkColor}` }),
              },
              onMouseEnter: () => setIsHovered(true),
              onMouseLeave: () => setIsHovered(false),
            }
          : {})}
        onClick={(e) => {
          e.stopPropagation();
          removeTag(tag);
          return;
        }}
        className={`flex h-[32px] items-center justify-center gap-3 whitespace-nowrap rounded border border-transparent py-2 pl-4 pr-3 font-secondary text-sm font-semibold transition-all hover:cursor-pointer ${
          lowercaseTag.includes('pending')
            ? 'bg-skyBlue fill-[#425FA9] text-[#425FA9] hover:border-[#425FA9]'
            : lowercaseTag.includes('purchased')
              ? 'bg-[#7CFDD7] fill-[#207259] text-[#207259] hover:border-[#207259]'
              : lowercaseTag.includes('received')
                ? 'bg-[#9cf2fc] fill-[#046f7b] text-[#046f7b] hover:border-[#046f7b]'
                : lowercaseTag.includes('completed')
                  ? 'bg-[#78edb7] fill-[#0f7044] text-[#0f7044] hover:border-[#0f7044]'
                  : lowercaseTag.includes('not sent')
                    ? 'bg-accentDuskyPink fill-[#7D5D57] text-[#7D5D57] hover:border-[#7D5D57]'
                    : lowercaseTag.includes('cancelled')
                      ? 'bg-[#fd9ba7] fill-[#7d0212] text-[#7d0212] hover:border-[#7d0212]'
                      : lowercaseTag.includes('placed')
                        ? 'bg-[#FFE9AF] fill-[#78683D] text-[#78683D] hover:border-[#78683D]'
                        : lowercaseTag.includes('ordered')
                          ? 'bg-[#9baefd] fill-[#021b7d] text-[#021b7d] hover:border-[#021b7d]'
                          : lowercaseTag.includes('shipped')
                            ? 'bg-[#f4cdfe] fill-[#65037d] text-[#65037d] hover:border-[#65037d]'
                            : ''
        }`}
      >
        <span className='max-w-[160px] truncate xs:max-w-[240px]'>{tag}</span>
        <div
          id={tag}
          onClick={(e) => {
            e.stopPropagation();
            removeTag(tag);
            return;
          }}
          style={{ fill: darkColor }}
          className={`flex items-center justify-center ${
            lowercaseTag.includes('pending')
              ? 'fill-[#425FA9] hover:fill-[#6882c3]'
              : lowercaseTag.includes('purchased')
                ? 'fill-[#207259] hover:fill-[#3b9b7e]'
                : lowercaseTag.includes('received')
                  ? 'fill-[#046f7b] transition hover:opacity-50'
                  : lowercaseTag.includes('completed')
                    ? 'fill-[#0f7044] transition hover:opacity-50'
                    : lowercaseTag.includes('not sent')
                      ? 'fill-[#7D5D57] hover:fill-[#ad867f]'
                      : lowercaseTag.includes('cancelled')
                        ? 'fill-[#7d0212] transition hover:opacity-50'
                        : lowercaseTag.includes('placed')
                          ? 'fill-[#78683D] hover:fill-[#a08e5f]'
                          : lowercaseTag.includes('ordered')
                            ? 'fill-[#021b7d] transition hover:opacity-50'
                            : lowercaseTag.includes('shipped')
                              ? 'fill-[#65037d] transition hover:opacity-50'
                              : 'transition hover:opacity-50'
          }`}
        >
          <CloseIcon
            className='cursor-pointer transition-all'
            style={{ fontSize: '16px', fill: 'inherit' }}
          />
        </div>
      </li>
    </TooltipStyled>
  );
};
