type Props = {
  cartTags: string[];
  tagInput: string;
  setTagInput: React.Dispatch<React.SetStateAction<string>>;
  tempTags: string[];
  setTempTags: React.Dispatch<React.SetStateAction<string[]>>;
};

export const useTagHooks = ({
  cartTags,
  tagInput,
  setTagInput,
  tempTags,
  setTempTags,
}: Props) => {
  const addTag = () => {
    if (!tagInput) return;
    const trimmedTag = tagInput.trim();

    if (
      tempTags?.some(
        (tempTag: string) => tempTag.toLowerCase() === trimmedTag.toLowerCase(),
      )
    ) {
      setTagInput('');
      return;
    }

    if (!tempTags) {
      setTempTags([trimmedTag]);
    } else {
      setTempTags([...tempTags, trimmedTag]);
    }

    setTagInput('');
  };

  const addGlobalTag = (tag: string) => {
    if (!tempTags) {
      setTempTags([tag]);
    } else {
      setTempTags([...tempTags, tag]);
    }
  };

  const checkTagEquality = (): boolean =>
    ((!cartTags || cartTags.length === 0) && tempTags.length === 0) ||
    (cartTags &&
      cartTags.length === tempTags.length &&
      cartTags.every(
        (tag, i) => tag.toLowerCase() === tempTags[i].toLowerCase(),
      ))
      ? true
      : false;

  const removeTag = (selectedTag: string) => {
    // remove tag from tags array
    const updatedTags = tempTags.filter((tag) => selectedTag !== tag);
    setTempTags(updatedTags);
  };

  return { addTag, addGlobalTag, checkTagEquality, removeTag };
};
