import { forwardRef } from 'react';
import Lottie, { LottieRefCurrentProps } from 'lottie-react';
import { useTranslation } from 'react-i18next';

const cartAnimation = require('../../../images/lotties/continue-cart.json');

type Props = {
  handleContinue: () => void;
  totalPrice: string;
  totalQty: number;
  forReviewFooter: boolean;
  isLoading: boolean;
};

export const ContinueTotalBlock = forwardRef<LottieRefCurrentProps, Props>(
  (
    { handleContinue, totalPrice, totalQty, forReviewFooter, isLoading },
    ref,
  ) => {
    const { t } = useTranslation();
    // const continueWith = useContinueWith();

    return (
      <div
        className={`relative ${forReviewFooter ? 'ml-auto md:w-fit' : '-mb-[9px] lg:w-fit'} flex w-full flex-col items-start justify-center gap-2 smMed:flex-row smMed:items-center smMed:justify-end smMed:gap-6 sm:mr-[88px] md:mr-[100px] lg:ml-auto`}
      >
        <div
          className={`z-[1] inline-flex w-fit items-center justify-start gap-2 whitespace-nowrap rounded-full pr-0.5 smMed:w-min ${
            forReviewFooter ? 'bg-white' : 'bg-primary-dark'
          }`}
        >
          <span
            className={`text-200 ${forReviewFooter ? '' : 'text-offWhite'}`}
          >
            {t('common.cart_total')}
          </span>
          {isLoading ? (
            <div
              className={`${forReviewFooter ? 'skeleton-light' : 'skeleton-dark'} h-[26px] w-[110px]`}
            ></div>
          ) : (
            <span
              className={`heading-200 ${forReviewFooter ? '' : 'text-offWhite'}`}
            >
              {totalPrice}
            </span>
          )}
        </div>
        {isLoading ? (
          <div
            className={`${forReviewFooter ? 'skeleton-light' : 'skeleton-dark'} h-12 w-full smMed:w-[216px] sm:max-w-[260px]`}
          ></div>
        ) : (
          <button
            onClick={handleContinue}
            className='button-primary h-12 w-full smMed:max-w-fit sm:max-w-[260px]'
          >
            {t('common.continue_with_items', { count: totalQty })}
          </button>
        )}
        <div
          className={`absolute -right-[76px] -top-8 z-0 w-40 xs:-top-[46px] xs:w-48 smMed:-right-[88px] smMed:-top-[81px] sm:-right-[200px] sm:-top-[32px] md:-right-[240px] md:w-60 ${
            forReviewFooter ? 'md:-top-[42px]' : 'md:-top-[53px]'
          }`}
        >
          <Lottie
            // @ts-expect-error - lottieRef is not a valid prop
            lottieRef={ref}
            animationData={cartAnimation}
            style={{ width: 'full', height: 'auto' }}
            autoplay={false}
            loop={false}
          />
        </div>
      </div>
    );
  },
);
