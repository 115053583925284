import { type CartGetItem, type CallExtensionArgs } from '../../../types';

type Args = {
  cartId: string;
  cartItems: CartGetItem[];
  cartAp: string;
  sendCartToExtension: (args: CallExtensionArgs) => void;
  loggerUrl: string;
};

export const sendCartToShopify = ({
  cartId,
  cartItems,
  cartAp,
  sendCartToExtension,
  loggerUrl,
}: Args) => {
  let cartUrl = '';
  let itemParams = '';

  // Check if Shopify app is installed
  // fetch(`https://sac-shopify-app.onrender.com/internal/installed`)
  fetch(`${process.env.REACT_APP_LIVE_URL}/api/make/shopify-installed`)
    .then((res) => res.json())
    .then((data) => {
      if (data.installed.indexOf(`${cartAp.replace('https://', '')}`) >= 0) {
        cartUrl = `${cartAp}/?sacId=${cartId}`;

        window.open(cartUrl);
      } else {
        for (let i = 0; i < cartItems.length; i++) {
          // TODO - functionality of selecting items on a per vendor basis is not supported at the moment
          // if ((handleSplitNewVendor || handleSplitOriginalVendor) && !cartItems[i].selected == true) {
          //   continue;
          // }

          itemParams += `${cartItems[i].asin}:${cartItems[i].quantity}`;

          if (i < cartItems.length - 1) {
            itemParams += ',';
          }
        }

        cartUrl = `${cartAp}/cart/${itemParams}`;

        // Wrapping into a partner link
        cartUrl = `${loggerUrl}${encodeURIComponent(cartUrl)}`;

        window.open(cartUrl);
      }
    })
    .catch((err) => {
      sendCartToExtension({ cartId, vendor: 'shopify' });
    });

  return;
};
