// import { UseMutationResult } from '@tanstack/react-query';
import type { CartHistoryData /* HandleUpdateCart */ } from '../../../types';

type Props = {
  cartsList:
    | {
        carts: CartHistoryData[];
        pageParam: number;
      }[]
    | undefined;
  fetchingNextPage: boolean;
};

export const CartsDisplayGrid = ({ cartsList, fetchingNextPage }: Props) => {
  return <div>CartsDisplayList</div>;
};
