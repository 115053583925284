import { Link } from 'react-router-dom';
import { type ExtensionsList } from './extensionsList';
import { useTranslation } from 'react-i18next';

export const ExtButton = ({
  details,
  primary,
}: {
  details: ExtensionsList;
  primary: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <Link
      to={details.link}
      target='_blank'
      style={{ overflowWrap: 'anywhere' }}
      className={`h-auto max-h-24 min-h-12 gap-2 ${primary ? 'button-primary w-full whitespace-normal text-center' : 'button-secondary flex-1 bg-offWhite'}`}
    >
      <img
        src={details.Icon}
        className={`${primary ? 'w-7' : 'w-8'}`}
        alt={`${details.name}`}
      />
      {primary &&
        t('popup.install_sac_on_browser_name', { browserName: details.name })}
    </Link>
  );
};
