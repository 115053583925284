import { NavLink } from 'react-router-dom';
import { ReactComponent as Logo } from '../../images/logos/logo+icon_svg.svg';
import {
  CreateACartBlock,
  NavUserOrLoginBlock,
  ReceiveACartBlock,
  SacPlusBlock,
} from '.';
import { LanguageSwitcher } from '../reusableBlocks';
import { useTranslation } from 'react-i18next';

export const NavbarFull = () => {
  const { t } = useTranslation();

  return (
    <nav className='inline-flex w-full flex-nowrap items-center justify-between gap-x-4 gap-y-2 overflow-x-hidden bg-primary-dark px-2 py-6 font-primary text-sm font-semibold text-skyBlue xs:px-4 sm:p-6 md:px-8 medLg:px-4 lg:gap-x-6 lg:px-10 xl:px-12'>
      <div className='flex h-12 w-full flex-row flex-nowrap items-center justify-between gap-4 xs:w-auto xs:justify-start lg:gap-6 xl:gap-12'>
        <NavLink
          to={`${process.env.REACT_APP_LIVE_URL}/`}
          reloadDocument
          className='flex flex-row px-1 py-1 transition-all active:scale-[97%]'
        >
          <Logo title='Share-A-Cart Logo' />
        </NavLink>
        <div className='hidden items-center justify-center gap-2 md:inline-flex lg:gap-4 xl:gap-8'>
          <ReceiveACartBlock />
          <CreateACartBlock />
        </div>
      </div>

      <div className='flex w-full max-w-fit flex-row flex-nowrap items-center justify-center gap-2 lg:gap-4 xl:gap-8'>
        <div className='hidden items-center justify-center gap-2 lg:gap-4 lgXl:inline-flex xl:gap-8'>
          <NavLink
            to={`${process.env.REACT_APP_LIVE_URL}/plugins`}
            reloadDocument
            className='flex-row flex-nowrap whitespace-nowrap px-1 py-1 text-center transition-all hover:text-white active:scale-95'
          >
            {t('navbar.get_plugins')}
          </NavLink>

          <SacPlusBlock />
        </div>

        <NavUserOrLoginBlock />

        <div className='h-10 w-[1px] border-l border-skyBlue opacity-40'></div>

        <LanguageSwitcher />
      </div>
    </nav>
  );
};
