import CloseIcon from '@mui/icons-material/Close';
import { Grow } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { forwardRef, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useContinueWithItems } from 'src/hooks';
import { DialogStyled } from '../DialogStyled';
import React from 'react';
import { ReactComponent as FatCartIcon } from '../../../images/icons/fat-cart.svg';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CheckIcon from '@mui/icons-material/Check';
import { useAtom } from 'jotai';
import { chunkLinksAtom, showChunkCartModalAtom } from 'src/store';

type Props = {
  cartId: string;
  cartQueryData: any;
  page: 'get' | 'embed';
};

export const ChunkCartModal = ({ cartId, cartQueryData, page }: Props) => {
  const [show, setShow] = useAtom<boolean>(showChunkCartModalAtom);
  const [userAcknowledgedReturning, setUserAcknowledgedReturning] =
    useState(false);
  const [chunkStep, setChunkStep] = useState<number>(1);
  const [chunkLinks] = useAtom(chunkLinksAtom);
  const [numberOfChunks, setNumberOfChunks] = useState(chunkLinks.length);

  const sendItems = useContinueWithItems();

  const { t } = useTranslation();

  useEffect(() => {
    setNumberOfChunks(chunkLinks.length);
  }, [chunkLinks.length]);

  const handleSendChunk = () => {
    sendItems({
      id: cartId,
      cart: cartQueryData,
      handleSplitNewVendor: false,
      handleSplitOriginalVendor: false,
      isChunk: true,
      doneWithChunks: chunkStep - 1 === numberOfChunks,
      chunkUrl: chunkLinks[chunkStep - 1] || undefined,
      page,
    });

    // if this triggers here, we already sent the above to determine if the feedback page will be shown
    if (chunkStep - 1 === numberOfChunks) {
      setShow(false);
      return;
    }

    setChunkStep(chunkStep + 1);
  };

  return (
    <DialogStyled
      open={show}
      TransitionComponent={Transition}
      aria-describedby='You have a large cart, we need to send it in chunks.'
    >
      <div
        onClick={() => setShow(false)}
        className='absolute right-3 top-2.5 cursor-pointer fill-darkSteel hover:fill-gray-400'
      >
        <CloseIcon
          className='transition-all'
          style={{ fontSize: '24px', fill: 'inherit' }}
        />
      </div>
      <div className='flex w-full flex-col items-center justify-center gap-4 p-4 sm:py-6 md:gap-6 md:px-6 md:py-8'>
        <div className='flex w-full flex-col items-center justify-center gap-8'>
          <FatCartIcon />

          <div className='flex w-full flex-col items-center justify-center gap-4'>
            <div className='flex w-full flex-row flex-nowrap items-center justify-center'>
              {[...Array(numberOfChunks)].map((_, i) => (
                <React.Fragment key={i}>
                  <div
                    className={`flex h-8 max-h-8 min-h-8 w-8 min-w-8 max-w-8 items-center justify-center gap-2 rounded-full ${chunkStep === i + 1 ? 'bg-primary' : chunkStep > i + 1 ? 'bg-grassGreen' : 'bg-[#DBDBE7]'}`}
                  >
                    {chunkStep > i + 1 && (
                      <CheckIcon style={{ color: '#FEFEFE' }} />
                    )}
                  </div>
                  {i < numberOfChunks - 1 && (
                    <div
                      className={`h-0.5 w-6 ${chunkStep > i + 1 ? 'bg-grassGreen' : 'bg-[#DBDBE7]'}`}
                    ></div>
                  )}
                </React.Fragment>
              ))}
            </div>
            <h3 className='heading-400 text-balance text-center'>
              {chunkStep - 1 < numberOfChunks
                ? t('get.send_chunk_x_of_y', {
                    chunk: chunkStep,
                    totalChunks: numberOfChunks,
                  })
                : t('get.all_done_excl')}
            </h3>
          </div>
        </div>

        <div className='flex w-full flex-col items-center justify-center gap-6'>
          <div
            className={`flex w-full flex-col items-center justify-center gap-3 rounded-lg p-3 transition-all ${chunkStep < numberOfChunks ? 'bg-[#FFE9AF]' : ''}`}
          >
            {!userAcknowledgedReturning ? (
              <>
                <div className='inline-flex flex-nowrap items-center justify-center gap-2 self-start'>
                  <InfoOutlinedIcon
                    style={{ fill: '#78683D' }}
                    className='inline-block h-5 max-h-5 min-h-[20px] w-5 min-w-[20px] max-w-5 align-middle'
                  />
                  <h5 className='font-primary text-sm font-semibold leading-[16.8px] text-[#78683D]'>
                    {t('get.important_caps')}
                  </h5>
                </div>
                <p className='-mt-1 ml-7 font-secondary text-sm leading-[22.4px] text-[#78683D]'>
                  <Trans
                    i18nKey={t(
                      'get.your_cart_is_big_and_will_have_to_be_loaded_into_vendor_in_chunks',
                    )}
                    values={{
                      vendorDisplayName: cartQueryData?.vendorDisplayName || '',
                    }}
                    components={[<br />, <strong className='font-bold' />]}
                  />
                </p>
                <div className='flex flex-row flex-nowrap items-center justify-center gap-4 self-start'>
                  <input
                    type='checkbox'
                    onChange={() =>
                      setUserAcknowledgedReturning(!userAcknowledgedReturning)
                    }
                    className='green-checkbox bg-offWhite'
                  />
                  <span className='text-300 text-[#78683D]'>
                    {t('get.ok_got_it')}
                  </span>
                </div>
              </>
            ) : chunkStep < numberOfChunks ? (
              <span className='text-200 text-[#78683D]'>
                <Trans
                  i18nKey={t(
                    'get.return_here_afterwards_to_load_remaining_chunks',
                  )}
                  components={[<strong className='font-bold' />]}
                />
              </span>
            ) : chunkStep <= numberOfChunks ? (
              <span className='text-200'>
                {t('get.continue_as_normal_in_vendor_after_this_step', {
                  vendorDisplayName: cartQueryData?.vendorDisplayName || '',
                })}
              </span>
            ) : (
              <span className='text-200'>
                {t('get.your_full_cart_has_been_loaded_into_vendor', {
                  vendorDisplayName: cartQueryData?.vendorDisplayName || '',
                })}
              </span>
            )}
          </div>
          {chunkStep - 1 < numberOfChunks ? (
            <button
              disabled={!userAcknowledgedReturning}
              onClick={handleSendChunk}
              className='button-primary h-12 w-full disabled:opacity-20 sm:w-auto'
            >
              {t('get.send_chunk_x_to_vendor', {
                chunk: chunkStep,
                vendorDisplayName: cartQueryData?.vendorDisplayName || '',
              })}
            </button>
          ) : (
            <button
              onClick={handleSendChunk}
              className='button-primary h-12 w-full disabled:opacity-20 sm:w-auto'
            >
              {t('get.all_done')}
            </button>
          )}
        </div>
      </div>
    </DialogStyled>
  );
};

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Grow ref={ref} {...props} />;
});
