import { useEffect } from 'react';
import { useAtom } from 'jotai';
import {
  cartImportErrorAtom,
  cartImportPendingAtom,
  extExistsAtom,
  extsPresentAtom,
  numberOfCartsImportedAtom,
  showCartsImportedModalAtom,
  showExtensionLoadingModalAtom,
  showExtensionMissingModalAtom,
} from '../store';
import { setCookie } from 'src/utils';
import { type CallExtensionArgs, type SACFlavor } from '../types/ext';

export const useExtension = () => {
  const [extExists, setExtExists] = useAtom(extExistsAtom);
  const [extsPresent, setExtsPresent] = useAtom(extsPresentAtom);
  const [, setShowExtensionLoadingModal] = useAtom(
    showExtensionLoadingModalAtom,
  );
  const [, setShowExtensionMissingModal] = useAtom(
    showExtensionMissingModalAtom,
  );
  const [, setShowCartsImportedModal] = useAtom(showCartsImportedModalAtom);
  const [, setNumberOfCartsImported] = useAtom(numberOfCartsImportedAtom);
  const [, setCartImportPending] = useAtom(cartImportPendingAtom);
  const [, setCartImportError] = useAtom(cartImportErrorAtom);

  // Send messages to the extension, depending on the browser
  const sendMessageToExtension = (data: {}) => {
    window.postMessage({
      direction: 'from-page-script',
      message: data,
    });
  };

  const pingExtension = () => {
    sendMessageToExtension({
      action: 'ping',
    });
  };

  useEffect(() => {
    const extListeners = (event: MessageEvent) => {
      if (
        event.source === window &&
        event.data.direction &&
        event.data.direction === 'from-extension'
      ) {
        const messageAction = event.data.message.action || undefined;

        if (messageAction && messageAction === 'import-complete') {
          setNumberOfCartsImported(event.data.message.imported);
          setCartImportPending(false);
          setShowCartsImportedModal(true); // user could've exited the modal by now, set it to true again just in case
          setCookie('cartsImported', 'true');
        } else if (
          messageAction &&
          (messageAction === 'ping' || messageAction === 'pong') // 'ping' is sent by the extension, 'pong' is sent by the ext in response to receiving the message from pingExtension()
        ) {
          const extFlavor = event.data.message.support;

          setExtExists(true);

          setExtsPresent((prev) => {
            if (!prev.includes(extFlavor)) {
              return [...prev, extFlavor];
            }
            return prev;
          });
        }
      }
    };

    window.addEventListener('message', extListeners);
    return () => window.removeEventListener('message', extListeners);
  }, [
    setCartImportPending,
    setNumberOfCartsImported,
    setShowCartsImportedModal,
    setExtExists,
    setExtsPresent,
  ]);

  /**
   * Convert the vendor to a flavor. 'amazon' -> 'amazon', 'walmart' -> 'walmart', 'aliexpress' -> 'aliexpress', 'instacart' -> 'instacart', 'other' -> 'sac'.
   *
   * Then, if the main ext is present, send the cart to the main ext.
   * Otherwise, if the vendor is a SAC Flavor vendor and the specific
   * vendor ext is present, send the cart to the specific vendor ext.
   * If the vendor is not a SAC Flavor vendor and the main ext is not
   * present, show the extension missing modal.
   */
  const sendCartToExtension = async ({
    cartId,
    cartItems,
    vendor,
  }: CallExtensionArgs) => {
    const vendorAsFlavor: SACFlavor = (() => {
      switch (vendor) {
        case 'amazon':
          return 'amazon';
        case 'walmart':
          return 'walmart';
        case 'ali':
          return 'aliexpress';
        case 'instacart':
          return 'instacart';
        default:
          return 'sac';
      }
    })();

    if (extsPresent.includes('sac') || extsPresent.includes(vendorAsFlavor)) {
      setShowExtensionLoadingModal(true);

      sendMessageToExtension({
        action: 'load-cart-from-site',
        vendor: vendorAsFlavor,
        cartId,
        cartItems,
      });
    } else {
      setShowExtensionMissingModal(true);
    }
  };

  const importCartsFromExtension = async (): Promise<void> => {
    setCartImportError(false);

    if (extExists) {
      setCartImportPending(true);
      setShowCartsImportedModal(true);

      return sendMessageToExtension({
        action: 'import-start-from-sac-plus-web',
      });
    }

    setCartImportPending(false);
    setShowExtensionMissingModal(true);
  };

  return {
    extExists,
    sendCartToExtension,
    pingExtension,
    importCartsFromExtension,
  };
};
