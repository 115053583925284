import { LoadingCart } from '.';

export const LoadingCartFullPage = () => {
  return (
    <div className='flex h-screen w-screen items-start justify-center bg-primary-dark'>
      <div className='flex w-40 flex-col items-center justify-center gap-2'>
        <LoadingCart />
      </div>
    </div>
  );
};
