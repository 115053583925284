import { toast } from 'sonner';
import { ToastStyled, TooltipStyled } from '../../..';
import { Trans, useTranslation } from 'react-i18next';

type Props = {
  cartId: string;
};

export const CartId = ({ cartId }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <TooltipStyled title={t('popup.copy_to_clipboard')}>
        <span
          className='min-w-[64px] cursor-pointer uppercase transition-all hover:opacity-70'
          onClick={() => {
            navigator.clipboard.writeText(cartId);
            toast.custom(
              (toastBlock) => (
                <ToastStyled
                  variant='info'
                  toastTitle={t('popup.copied_to_clipboard')}
                  handleClose={() => toast.dismiss(toastBlock)}
                >
                  <Trans
                    i18nKey={t('popup.cart_copied_to_clipboard')}
                    values={{ cartId }}
                    components={[<strong />]}
                  />
                </ToastStyled>
              ),
              {
                duration: 2000,
              },
            );
          }}
        >
          {cartId}
        </span>
      </TooltipStyled>
    </>
  );
};
