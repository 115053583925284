import { useMutation } from '@tanstack/react-query';
import { useAtom } from 'jotai';
import {
  whatForSelectedAtom,
  whatForTextAtom,
  howLongSelectedAtom,
  withWhoSelectedAtom,
  withWhoTextAtom,
  typesOfGoodsSelectedAtom,
  typesOfGoodsTextAtom,
  authUserAtom,
} from '../store';

export type SubmitData = {
  whatFor: string[] | string;
  howLong: string;
  withWho: string[] | string;
  typesOfGoods: string[] | string;
  userEmail: string;
  userName: string;
};

export const useSubmitSurvey = () => {
  const [user] = useAtom(authUserAtom);
  const [whatForSelected] = useAtom(whatForSelectedAtom);
  const [whatForText] = useAtom(whatForTextAtom);
  const [howLongSelected] = useAtom(howLongSelectedAtom);
  const [withWhoSelected] = useAtom(withWhoSelectedAtom);
  const [withWhoText] = useAtom(withWhoTextAtom);
  const [typesOfGoodsSelected] = useAtom(typesOfGoodsSelectedAtom);
  const [typesOfGoodsText] = useAtom(typesOfGoodsTextAtom);

  return useMutation({
    mutationFn: async () => {
      // check if we even need to submit the survey
      if (
        whatForSelected.length === 0 &&
        !whatForText &&
        !howLongSelected &&
        withWhoSelected.length === 0 &&
        !withWhoText &&
        typesOfGoodsSelected.length === 0 &&
        !typesOfGoodsText
      ) {
        return;
      }

      const whatFor: string[] = [...whatForSelected];
      const withWho: string[] = [...withWhoSelected];
      const typesOfGoods: string[] = [...typesOfGoodsSelected];
      if (whatForText) whatFor.push(whatForText.trim());
      if (withWhoText) withWho.push(withWhoText.trim());
      if (typesOfGoodsText) typesOfGoods.push(typesOfGoodsText.trim());

      const submitData: SubmitData = {
        whatFor: whatFor.join(', ') || '',
        howLong: howLongSelected || '',
        withWho: withWho.join(', ') || '',
        typesOfGoods: typesOfGoods.join(', ') || '',
        userEmail: user && user.email ? user.email : '',
        userName: user && user.name ? user.name : '',
      };

      const res = await fetch(`${process.env.REACT_APP_LIVE_URL}/user/survey`, {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify(submitData),
      });

      if (!res.ok) {
        throw new Error('Failed to submit survey');
      }

      const { result } = await res.json();

      if (result !== 'Sucess') {
        throw new Error('Failed to submit survey');
      }

      return true;
    },
  });
};
