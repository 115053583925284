import { useEffect, useState } from 'react';
import { CartHistoryHeader, CartsDisplay, LoadingCart } from '../components';
import { useAtom } from 'jotai';
import {
  cartImportPendingAtom,
  historySearchByAtom,
  showCartsImportedModalAtom,
} from '../store';
import {
  useAssociateCartCookie,
  useCartListQuery,
  useDebounce,
} from '../hooks';
import { WelcomeModal } from '../components/reusableBlocks/modals';

export const CartHistory = () => {
  const [searchBy] = useAtom(historySearchByAtom);

  const [cartImportPending] = useAtom(cartImportPendingAtom);
  const [, setShowCartsImportedModal] = useAtom(showCartsImportedModalAtom);
  const [isListView, setIsListView] = useState<boolean>(true);
  const [displayedCartsLength, setDisplayedCartsLength] = useState<
    number | null
  >(null);

  const [debounceDelay, setDebounceDelay] = useState<number>(1000);
  const debouncedSearchBy = useDebounce(searchBy, debounceDelay);

  useAssociateCartCookie();

  const cartsQuery = useCartListQuery({ debouncedSearchBy });

  useEffect(() => {
    if (!cartsQuery.isLoading) {
      setDisplayedCartsLength(
        cartsQuery.data?.pages.reduce(
          (acc, { carts }) => acc + carts.length,
          0,
        ) || 0,
      );
    }
  }, [cartsQuery]);

  useEffect(() => {
    if (cartImportPending) {
      setShowCartsImportedModal(true);
    }
  }, [cartImportPending, setShowCartsImportedModal]);

  return (
    <>
      <CartHistoryHeader
        cartsQuery={cartsQuery}
        displayedCartsLength={displayedCartsLength || 0}
        isListView={isListView}
        setIsListView={setIsListView}
        setDebounceDelay={setDebounceDelay}
      />
      {cartsQuery.isLoading || displayedCartsLength === null ? (
        <div className='mx-auto w-full border-b border-blueGray pb-4'>
          <div className='mx-auto w-40'>
            <LoadingCart
              customTextColor={'text-primary-dark'}
              customBgColor='bg-transparent'
            />
          </div>
        </div>
      ) : (
        <CartsDisplay
          cartsList={cartsQuery.data?.pages || undefined}
          displayedCartsLength={displayedCartsLength || 0}
          isListView={isListView}
          queryError={cartsQuery.isError}
          loadMoreCarts={cartsQuery.fetchNextPage}
          fetchingNextPage={cartsQuery.isFetchingNextPage}
          hasNextPage={cartsQuery.hasNextPage}
        />
      )}

      <WelcomeModal />
    </>
  );
};
