import { Link } from 'react-router-dom';
import { useActiveBrowser } from '../../../hooks';
import {
  ExtButton,
  extensionsList,
} from '../modals/ExtensionMissingModalBlocks';
import { useTranslation } from 'react-i18next';

type Props = {
  forGetThanks?: boolean;
};

export const ExtDownloads = ({ forGetThanks }: Props) => {
  const activeBrowser = useActiveBrowser();

  const { t } = useTranslation();

  return (
    <>
      {forGetThanks ? (
        <>
          <div className='flex w-fit flex-col flex-nowrap items-center justify-center gap-6 rounded bg-offWhite p-4 xs:flex-row xs:gap-4 sm:gap-6'>
            <h4 className='heading-100 order-first w-fit whitespace-nowrap text-center'>
              {t('popup.available_for')}:
            </h4>
            {extensionsList.map((ext, i) => {
              return (
                <Link
                  key={i}
                  to={ext.link}
                  target='_blank'
                  className={`h-10 w-10 rounded-full bg-transparent p-1 transition-all active:scale-90 ${ext.name === activeBrowser ? 'order-first' : ''}`}
                >
                  <img src={ext.Icon} alt={ext.name} className='h-8 w-8' />
                </Link>
              );
            })}
          </div>
        </>
      ) : (
        <div className='flex w-full max-w-[360px] flex-col items-center justify-center gap-2'>
          {/* Extension link that is the active browser */}
          <ExtButton
            details={extensionsList.find((ext) => ext.name === activeBrowser)!}
            primary={true}
          />

          {/* Extension links that are not the active browser */}
          <div className='flex w-full flex-row flex-nowrap items-center justify-center gap-2'>
            {extensionsList.map((ext, i) => {
              if (ext.name === activeBrowser) return null;
              return <ExtButton key={i} details={ext} primary={false} />;
            })}
          </div>
        </div>
      )}
    </>
  );
};
