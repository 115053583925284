import { adjectives, nouns } from '.';

export const randomTitleGenerator = (cartId: string, cartVendor: string) => {
  const [idCharSum, vendorCharSum] = [cartId, cartVendor].map((name) => {
    return name.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0);
  });

  // Use the char sums to pick an adjective and noun
  // Doing it this way means adjectives will have more variety
  // because cartId's are random and unique
  // Vendors will have the same noun for each cart.
  const adjective = adjectives[(idCharSum * 100) % (adjectives.length - 1)];
  const noun = nouns[vendorCharSum % (nouns.length - 1)];

  return `My ${adjective} ${noun}`;
};
