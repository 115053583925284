import { Changelog } from '../types';

export const getChangelog = async (): Promise<Changelog[]> => {
  const res = await fetch(
    `${process.env.REACT_APP_LIVE_URL}/api/changelog/ext/issues`,
    {
      credentials: 'include',
    },
  );
  if (!res.ok) {
    throw new Error(`Error fetching Changelog data...`);
  }

  const data = await res.json();

  if (data.error) {
    throw new Error(`Error fetching Changelog data...`);
  }

  return data;
};
