import { useEffect } from 'react';
import { useAtom } from 'jotai';
import {
  whatForSelectedAtom,
  whatForTextAtom,
  howLongSelectedAtom,
  withWhoSelectedAtom,
  withWhoTextAtom,
  typesOfGoodsSelectedAtom,
  typesOfGoodsTextAtom,
} from '../store';

type Props = {
  step: 1 | 2 | 3 | 4 | 5 | 6;
  setDisableContinueBtn: React.Dispatch<React.SetStateAction<boolean>>;
};

export const useWelcomeModalContinueButtonState = ({
  step,
  setDisableContinueBtn,
}: Props) => {
  const [whatForSelected] = useAtom(whatForSelectedAtom);
  const [whatForText] = useAtom(whatForTextAtom);
  const [howLongSelected] = useAtom(howLongSelectedAtom);
  const [withWhoSelected] = useAtom(withWhoSelectedAtom);
  const [withWhoText] = useAtom(withWhoTextAtom);
  const [typesOfGoodsSelected] = useAtom(typesOfGoodsSelectedAtom);
  const [typesOfGoodsText] = useAtom(typesOfGoodsTextAtom);

  return useEffect(() => {
    if (step === 1 || step === 6) {
      setDisableContinueBtn(false);
    } else if (step === 2) {
      if (whatForSelected.length === 0 && !whatForText) {
        setDisableContinueBtn(true);
      } else {
        setDisableContinueBtn(false);
      }
    } else if (step === 3) {
      if (!howLongSelected) {
        setDisableContinueBtn(true);
      } else {
        setDisableContinueBtn(false);
      }
    } else if (step === 4) {
      if (withWhoSelected.length === 0 && !withWhoText) {
        setDisableContinueBtn(true);
      } else {
        setDisableContinueBtn(false);
      }
    } else if (step === 5) {
      if (typesOfGoodsSelected.length === 0 && !typesOfGoodsText) {
        setDisableContinueBtn(true);
      } else {
        setDisableContinueBtn(false);
      }
    }
  }, [
    step,
    whatForSelected,
    whatForText,
    howLongSelected,
    withWhoSelected,
    withWhoText,
    typesOfGoodsSelected,
    typesOfGoodsText,
    setDisableContinueBtn,
  ]);
};
