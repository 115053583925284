import { useEffect, useRef } from 'react';
import { ReactComponent as SacPlusIcon } from '../../../../../images/icons/sac-plus-fire-cart.svg';
import Lottie, { LottieRefCurrentProps } from 'lottie-react';

const animation = require('../../../../../images/lotties/cart-sending-bar.json');

type Props = {
  step: 1 | 2 | 3 | 4 | 5 | 6;
};

export const WelcomeModalIcon = ({ step }: Props) => {
  const lottieRef = useRef<LottieRefCurrentProps | null>(null);

  // 189 frames total
  // 6.3 seconds total
  useEffect(() => {
    if (lottieRef.current) {
      if (step === 1 || step === 2) {
        return;
      } else if (step === 3) {
        lottieRef.current.playSegments([0, 44], true);
      } else if (step === 4) {
        lottieRef.current.playSegments([44, 94], true);
      } else if (step === 5) {
        lottieRef.current.playSegments([94, 130], true);
      } else if (step === 6) {
        lottieRef.current.playSegments([130, 189], true);
      }
    }
  }, [lottieRef, step]);

  return (
    <>
      {step === 1 ? (
        <SacPlusIcon
          title='Full cart with fire emoji coming out the back.'
          className='h-auto w-[135px]'
        />
      ) : (
        <Lottie
          lottieRef={lottieRef}
          animationData={animation}
          loop={false}
          autoplay={false}
          style={{ width: 352, height: 'auto', marginTop: -24 }}
        />
      )}
    </>
  );
};
