import { useTranslation } from 'react-i18next';
import { VendorLogoBlock } from '../reuseables';
import { formatPrice } from 'src/utils/formatPrice';

type Props = {
  title?: string;
  vendor: string;
  vendorDisplayName: string;
  ccy: string;
  ccys: string;
  locale: string;
  price: number | string;
  itemsLength: number;
  allText: boolean;
  btnName: 'all' | 'splitNew' | 'splitOriginal';
  handleContinue: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void;
};
export const GetCompareCard = ({
  title,
  vendor,
  vendorDisplayName,
  ccy,
  ccys,
  locale,
  price,
  itemsLength,
  allText,
  btnName,
  handleContinue,
}: Props) => {
  const { t } = useTranslation();

  const priceNumber = typeof price === 'number' ? price : parseFloat(price);
  const formattedPrice = formatPrice(priceNumber, locale, ccy, ccys);

  return (
    <div className='compare-card'>
      {title && <h3 className='heading-200 text-center'>{title}</h3>}
      <div className='flex flex-col items-center justify-center gap-x-2 sm:flex-row sm:flex-wrap lg:flex-col lgXl:flex-row'>
        <div className='flex flex-row flex-nowrap items-center justify-center gap-2 text-center'>
          <VendorLogoBlock vendor={vendor} />
          <span className='text-200 whitespace-normal text-left'>
            {vendorDisplayName}&nbsp;{t('common.cart_total')}
          </span>
        </div>
        <span className='whitespace-nowrap font-primary text-base font-bold lg:text-lg'>
          {formattedPrice}
        </span>
      </div>
      <button
        onClick={(e) => handleContinue(e)}
        name={btnName}
        className='button-primary min-h-12 whitespace-normal py-3'
      >
        {t('get.send_items_to_vendor', {
          count: itemsLength,
          context: allText ? 'all' : false,
          vendorDisplayName,
        })}
      </button>
    </div>
  );
};
