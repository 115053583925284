import { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from '@stripe/react-stripe-js';
import { useAtom } from 'jotai';
import { authUserAtom } from '../store';
import { Navigate } from 'react-router-dom';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// This is your test public API key.
const stripePromise = loadStripe(
  'pk_test_51N9IxtAhnRXcgePmeOWBW2hOtyzAyjUskSPw0fHcNlthkYHCJK33upz2MIRzr1Bo2k41EKmQSfaiPKJsxGNrbCPt0001CY7YeA',
);

export const Subscribe = () => {
  const [user] = useAtom(authUserAtom);
  const [clientSecret, setClientSecret] = useState('');

  useEffect(() => {
    // Create a Checkout Session as soon as the page loads
    fetch(
      `${process.env.REACT_APP_LIVE_URL}/api/subscribe/create-checkout-session`,
      {
        method: 'POST',
        credentials: 'include',
      },
    )
      .then((res) => res.json())
      .then((data) => setClientSecret(data.clientSecret));
  }, []);

  if (!user) {
    return <Navigate to='/login' />;
  }

  return (
    <div id='checkout' className='py-4'>
      {clientSecret && (
        <EmbeddedCheckoutProvider
          stripe={stripePromise}
          options={{ clientSecret }}
        >
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      )}
    </div>
  );
};
