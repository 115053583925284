import { CartGetItem } from '../../../types';

type Args = {
  cartId: string;
  cartItems: CartGetItem[];
  cartAp?: string;
};

export const sendCartToInstacart = ({ cartId, cartItems, cartAp }: Args) => {
  const params = [];
  let cartUrl = '';

  params.push(
    `title=${encodeURIComponent('Share-A-Cart Shopping Light -- For full cart-to-cart transfer, please use Share-A-Cart extension -- Get the right quantitites, items, and even store selection')}`,
  );
  params.push(
    `recipe_url=${encodeURIComponent('https://share-a-cart.com/get/' + cartId)}`,
  );
  params.push(
    `partner_name=${encodeURIComponent('Share-A-Cart [' + cartId + ']')}`,
  );
  params.push(
    `image_url=${encodeURIComponent('https://share-a-cart.com/images/cart_various-tall.png')}`,
  );

  for (let i = 0; i < cartItems.length; i++) {
    // TODO - functionality of selecting items on a per vendor basis is not supported at the moment
    // if ((handleSplitNewVendor || handleSplitOriginalVendor) && !cartItems[i].selected == true) {
    //   continue;
    // }

    params.push(`ingredients[]=${encodeURIComponent(cartItems[i].title)}`);
  }

  cartUrl = `${encodeURIComponent('https://www.instacart.com/store/partner_recipe?')}${encodeURIComponent(params.join('&'))}`;

  if (cartAp && cartAp.endsWith('.ca')) {
    cartUrl = `${encodeURIComponent('https://www.instacart.ca/store/partner_recipe?')}${params.join('&')}`;
  }

  return cartUrl;
};
