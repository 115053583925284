import { useState, type SetStateAction, useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import { ReactComponent as SacPlusIcon } from '../../../../images/icons/sac-plus-fire-cart.svg';
import { Link } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';

type SetAtom<Args extends unknown[], Result> = (...args: Args) => Result;

type Props = {
  numberOfCarts: number;
  setShow: SetAtom<[SetStateAction<boolean>], void>;
};

export const ImportCartsSuccess = ({ numberOfCarts, setShow }: Props) => {
  const [onHistoryPage, setOnHistoryPage] = useState<boolean>(true);
  const queryClient = useQueryClient();

  const { t } = useTranslation();

  // importing carts succeeded, so we need to refetch the currentUser and cart queries
  useEffect(() => {
    queryClient.refetchQueries({ queryKey: ['currentUser'] });
    queryClient.refetchQueries({ queryKey: ['cart', 'list'], exact: false });
  }, [queryClient]);

  // if user navigated away from history page, offer them the option to go back.
  useEffect(() => {
    if (window.location.pathname === '/plus/cart-history') {
      setOnHistoryPage(true);
    } else {
      setOnHistoryPage(false);
    }
  }, []);

  return (
    <>
      <div className='flex flex-col items-center justify-center gap-2'>
        <SacPlusIcon className='-mb-2 -ml-2 h-auto w-[130px]' />
        <h2 className='heading-400 text-center'>
          {t('popup.number_of_carts_imported', { count: numberOfCarts })}
        </h2>
      </div>

      <p className='text-300 text-center'>
        <Trans
          i18nKey={t('popup.extension_synced', { count: numberOfCarts })}
          components={[<b></b>]}
        />
      </p>

      <div className='flex w-full flex-col items-center justify-center gap-2 sm:flex-row'>
        {numberOfCarts === 0 ? (
          <Link
            to={`${process.env.REACT_APP_LIVE_URL}/make`}
            reloadDocument
            className='button-primary h-auto max-h-24 min-h-12 w-full max-w-[280px] whitespace-normal text-balance text-center sm:w-auto'
            style={{ overflowWrap: 'anywhere' }}
            onClick={() => setShow(false)}
          >
            {t('popup.make_a_cart')}
          </Link>
        ) : onHistoryPage ? (
          <button
            className='button-secondary h-auto max-h-24 min-h-12 w-full max-w-[280px] whitespace-normal text-balance text-center sm:w-auto'
            style={{ overflowWrap: 'anywhere' }}
            onClick={() => setShow(false)}
          >
            {t('popup.got_it')}
          </button>
        ) : (
          <Link
            to='/plus/cart-history'
            className='button-primary h-auto max-h-24 min-h-12 w-full max-w-[280px] whitespace-normal text-balance text-center sm:w-auto'
            style={{ overflowWrap: 'anywhere' }}
            onClick={() => setShow(false)}
          >
            {t('popup.take_me_to_my_cart_history')}
          </Link>
        )}
      </div>
    </>
  );
};
