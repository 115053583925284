import { useAtom } from 'jotai';
import { authUserAtom } from '../store';

export const useUserIsAdmin = (): boolean => {
  const [user] = useAtom(authUserAtom);

  if (!user || !user.email) {
    return false;
  }

  if (user.email.endsWith('@share-a-cart.com')) {
    return true;
  } else {
    return false;
  }
};
