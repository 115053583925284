import { forwardRef } from 'react';
import { Dialog, Grow, styled } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import CloseIcon from '@mui/icons-material/Close';
import { useAtom } from 'jotai';
import {
  cartImportErrorAtom,
  numberOfCartsImportedAtom,
  showCartsImportedModalAtom,
} from '../../../store';

import {
  ImportCartsError,
  ImportCartsPending,
  ImportCartsSuccess,
} from './ImportCartsModalBlocks';

export const ImportCartsModal = () => {
  const [show, setShow] = useAtom(showCartsImportedModalAtom);
  const [numberOfCarts] = useAtom(numberOfCartsImportedAtom);
  const [cartImportError] = useAtom(cartImportErrorAtom);

  // do something else if error
  // do something if carts imported is 0
  return (
    <CartsImportedDialogStyled
      open={show}
      onClose={() => setShow(false)}
      TransitionComponent={Transition}
      aria-describedby='Embed this widget into your website.'
    >
      <div
        onClick={() => setShow(false)}
        className='absolute right-3 top-2.5 z-[10] cursor-pointer fill-darkSteel hover:fill-gray-400'
      >
        <CloseIcon
          className='transition-all'
          style={{ fontSize: '24px', fill: 'inherit' }}
        />
      </div>
      <div className='flex flex-col items-center justify-center gap-6 p-4 sm:py-6 md:gap-6 md:px-6 md:py-8'>
        {/* number of carts will be undefined until import is done */}
        {cartImportError ? (
          <ImportCartsError setShow={setShow} />
        ) : numberOfCarts === null ? (
          <ImportCartsPending setShow={setShow} />
        ) : (
          <ImportCartsSuccess numberOfCarts={numberOfCarts} setShow={setShow} />
        )}
      </div>
    </CartsImportedDialogStyled>
  );
};

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Grow ref={ref} {...props} />;
});

const CartsImportedDialogStyled = styled(Dialog)(() => ({
  '& .MuiDialog-paper': {
    borderRadius: 16,
    backgroundColor: '#F1F1F1',
    position: 'relative',
    width: '90%',
    maxWidth: 672,
    minWidth: 294,
    overflowX: 'hidden',
  },
}));
