import { useAtom } from 'jotai';
import { useState, useEffect } from 'react';
import { withWhoSelectedAtom, withWhoTextAtom } from '../../../../../store';
import type { WithWhoOpts } from '../../../../../types';
import {
  WelcomeModalTitle,
  WelcomeModalOptionalTextInput,
} from '../WelcomeModalBlocks';

type Props = {
  step: 1 | 2 | 3 | 4 | 5 | 6;
};

const withWhoOpts = [
  'Family',
  'Parents',
  'Friends',
  'Gift Givers',
  'Coworkers',
  'Donors/Patrons',
  'Followers',
  'Purchasing Departments',
  'Other',
];

export const WelcomeModalStep4 = ({ step }: Props) => {
  const [withWhoSelected, setWithWhoSelected] = useAtom(withWhoSelectedAtom);
  const [withWhoText, setWithWhoText] = useAtom(withWhoTextAtom);
  const [showOtherTextInput, setShowOtherTextInput] = useState<boolean>(false);

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, value: name } = e.target;

    if (checked) {
      setWithWhoSelected([...withWhoSelected, name as WithWhoOpts]);
    } else {
      setWithWhoSelected(withWhoSelected.filter((option) => option !== name));
    }
  };

  const handleContainerClick = (e: React.MouseEvent<HTMLLIElement>) => {
    const { id } = e.currentTarget;

    if (withWhoSelected.includes(id as WithWhoOpts)) {
      setWithWhoSelected(withWhoSelected.filter((option) => option !== id));
    } else {
      setWithWhoSelected([...withWhoSelected, id as WithWhoOpts]);
    }
  };

  useEffect(() => {
    if (withWhoSelected.includes('Other')) {
      return setShowOtherTextInput(true);
    }

    setShowOtherTextInput(false);
    setWithWhoText('');
  }, [withWhoSelected, setWithWhoText]);

  return (
    <>
      <WelcomeModalTitle step={step}>
        Who do you share carts with?
      </WelcomeModalTitle>

      <ul className='grid auto-cols-max gap-x-8 gap-y-4 xs:grid-cols-[min-content_min-content] smMed:grid-cols-[min-content_max-content]'>
        {withWhoOpts.map((option, i) => (
          <li
            key={i}
            id={option}
            onClick={(e) => handleContainerClick(e)}
            className='text-300 flex w-fit cursor-pointer flex-row items-center justify-start gap-4'
          >
            <input
              type='checkbox'
              value={option}
              checked={withWhoSelected.includes(option as WithWhoOpts)}
              onChange={handleCheckboxChange}
              className='green-checkbox bg-cloudGray'
            />
            {option}
          </li>
        ))}
        <li className=''>
          <WelcomeModalOptionalTextInput
            show={showOtherTextInput}
            value={withWhoText}
            changeHandler={(e) => setWithWhoText(e.target.value)}
          />
        </li>
      </ul>
    </>
  );
};
