/**
 * @param tag string, tag to generate color for
 * @returns array of two hsl color values, first for text and hover border, second for background
 */
export const randomTagColorGenerator = (tag: string): string[] => {
  const tagSum = tag
    .split('')
    .reduce((acc, char) => acc + char.charCodeAt(0), 0);

  return [
    `hsl(${tagSum % 360}deg, ${tagSum % 50 + 50}%, 80%)`,
    `hsl(${tagSum % 360}deg, ${tagSum % 50 + 50}%, 25%)`,
  ];
};
