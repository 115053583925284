import { useMutation } from '@tanstack/react-query';
import { toast } from 'sonner';
import { ToastStyled } from '../components';
import { useAtom } from 'jotai';
import { authUserAtom } from '../store';
import { redirect } from 'react-router-dom';

export const useDeleteAccount = () => {
  const [, setUser] = useAtom(authUserAtom);

  return useMutation({
    mutationFn: async () => {
      const res = await fetch(`${process.env.REACT_APP_LIVE_URL}/user/delete`, {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'DELETE',
        credentials: 'include',
      });

      if (!res.ok) {
        throw new Error('Failed to delete cart');
      }

      const { status } = await res.json();

      // since status is an empty string if successful, we can check
      // to see if status exists, if it does, it means there was an error
      if (status) {
        throw new Error('Error updating cart... status...');
      }

      return true;
    },
    onSuccess: () => {
      setUser(null);
      redirect(`${process.env.REACT_APP_LIVE_URL}/`);
    },
    onError: () => {
      toast.custom((t) => (
        <ToastStyled variant='error' handleClose={() => toast.dismiss(t)}>
          Something went wrong while trying to delete your account. Please try
          again.
        </ToastStyled>
      ));
    },
  });
};
