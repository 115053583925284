import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { cn } from 'src/utils';
import { changelogTagVariants } from './changelogTagVariants';
import { ChangelogItemCreateOrEdit } from './ChangelogItemCreateOrEdit';
import { type Changelog, ChangelogFilterByEnum } from 'src/types';
import { ReactComponent as EditIcon } from 'src/images/icons/edit-icon.svg';
import { ReactComponent as TrashIcon } from 'src/images/icons/trash-icon.svg';
import { useDeleteChangelog, useUserIsAdmin } from 'src/hooks';
import { ConfirmActionModal } from '../reusableBlocks';

type Props = {
  log: Changelog;
  logIndex: number;
};

export const ChangelogListItem = ({ log, logIndex }: Props) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const { t } = useTranslation();

  const isNewUpdate = dayjs().diff(dayjs(log.closed_at), 'month') < 1;
  const isNewRetailer = log.labels.some(
    (label) => label.name === 'retailer_added',
  );

  const deleteChangelog = useDeleteChangelog({
    logIndex,
    setOpen: setShowDeleteModal,
  });

  const userIsAdmin = useUserIsAdmin();

  // const shareUrl = `https://share-a-cart.com/changelog#${log.id}`;

  const handleDelete = () => {
    deleteChangelog.mutate(log.id);
  };

  return (
    <>
      <li
        id={log.id.toString()}
        className={`group relative flex w-full max-w-[720px] scroll-mt-1 flex-col items-start justify-center gap-6 overflow-hidden rounded-2xl border p-6 ${isNewUpdate ? 'border-primary bg-skyBlue/10' : 'border-gray-200'}`}
      >
        {isNewUpdate && (
          <span className='absolute right-0 top-0 rounded-bl bg-primary px-4 py-2 text-center font-secondary text-sm font-semibold leading-[14px] text-offWhite'>
            {t('changelog.new')}
          </span>
        )}
        {log.labels.length > 0 && (
          <ul className='flex w-fit flex-row flex-wrap items-center justify-start gap-2'>
            {log.labels.map((label) => {
              return (
                <li
                  key={label.id}
                  id={log.id.toString()}
                  className={cn(
                    changelogTagVariants({
                      variant: label.name as keyof typeof ChangelogFilterByEnum,
                    }),
                  )}
                >
                  {t(`changelog.tags.${label.name}`)}
                </li>
              );
            })}
          </ul>
        )}

        <h3 className='heading-300 text-primary-dark'>{log.title}</h3>

        {!isNewRetailer && (
          <p
            style={{ overflowWrap: 'anywhere' }}
            className='text-200 whitespace-pre-line text-wrap text-primary-dark'
          >
            {log.body}
          </p>
        )}

        <div className='flex w-full flex-row flex-nowrap items-center justify-between gap-2 sm:gap-6'>
          <span className='text-200 whitespace-nowrap text-primary-dark'>
            {dayjs(log.closed_at).format('MMM D, YYYY')}
          </span>
          {/* <div className='ml-auto flex w-fit flex-row flex-nowrap items-center justify-between gap-2 sm:gap-6'> */}
          {log.vendorLink && log.vendorDisplayName && (
            <Link
              to={log.vendorLink}
              target='_blank'
              rel='noopener noreferrer'
              className='heading-100 w-fit text-center text-primary transition hover:text-primary/70'
            >
              {t('changelog.shop_at_vendor', {
                vendorDisplayName: log.vendorDisplayName,
              })}
            </Link>
          )}
          {/* <ChangelogShareItem shareUrl={shareUrl} /> */}
          {/* </div> */}
        </div>

        {userIsAdmin && (
          <div className='flex flex-row items-center justify-center gap-2'>
            <EditIcon
              className='text-primary hover:cursor-pointer'
              onClick={() => setShowModal((prev) => !prev)}
            />
            <TrashIcon
              className='stroke-rose-500 hover:cursor-pointer'
              onClick={() => setShowDeleteModal(true)}
            />
            <ChangelogItemCreateOrEdit
              logIndex={logIndex}
              log={log}
              open={showModal}
              setOpen={setShowModal}
            />
            <ConfirmActionModal
              show={showDeleteModal}
              setShow={setShowDeleteModal}
              titleText={'You Sure?'}
              bodyText={
                'Deleting the log will remove it from the changelog for everybody!'
              }
              onClickPrimary={() => {
                handleDelete();
              }}
            />
          </div>
        )}
      </li>
    </>
  );
};
