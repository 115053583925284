import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { TooltipProps, Tooltip, tooltipClasses } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import styled from '@emotion/styled';
import dayjs from 'dayjs';
import { Trans, useTranslation } from 'react-i18next';

type Props = {
  timestamp: number;
  itemCount: number;
  displaySourceSite?: string;
  vendorDisplayName?: string;
  forCompare?: boolean;
  cartStore?: string;
  cartVendor: string;
};

export const PriceDisclaimerBlock = ({
  timestamp,
  itemCount,
  displaySourceSite,
  vendorDisplayName,
  forCompare,
  cartStore,
  cartVendor,
}: Props) => {
  const [sourceSite, setSourceSite] = useState<string>('');
  const [displayName, setDisplayName] = useState(vendorDisplayName || '');
  const isVowel = vendorDisplayName?.match(/^[aeiou]/i);

  const { t, i18n } = useTranslation();

  const i18nLang = i18n.resolvedLanguage
    ? i18n.resolvedLanguage.toLowerCase()
    : i18n.language.toLowerCase();

  useEffect(() => {
    if (
      cartVendor === 'amazon' &&
      cartStore &&
      (cartStore.endsWith('localmarket') || cartStore?.endsWith('fresh'))
    ) {
      if (cartStore.toLowerCase().includes('amazon.com/cart/localmarket')) {
        setDisplayName(`${vendorDisplayName} Whole Foods`);
      } else {
        setDisplayName(`${vendorDisplayName} Fresh`);
      }
    }
  }, [cartStore, cartVendor, vendorDisplayName]);

  useEffect(() => {
    if (displaySourceSite) {
      if (!displaySourceSite.startsWith('http')) {
        setSourceSite(`https://${displaySourceSite}`);
      } else {
        setSourceSite(displaySourceSite);
      }
    }
  }, [displaySourceSite]);

  return (
    <div
      className={`text-100 z-[1] inline-flex w-fit flex-grow items-center justify-end gap-2 xs:justify-start ${forCompare ? '' : 'smMed:mr-24 sm:mr-0'}`}
    >
      <span
        style={{ overflowWrap: 'anywhere' }}
        className='whitespace-normal text-balance text-right text-blueGray'
      >
        {displayName && (
          <>
            <Trans
              i18nKey={
                i18nLang === 'en' && isVowel
                  ? t('common.this_is_an_vendor_list')
                  : t('common.this_is_a_vendor_list')
              }
              context={sourceSite ? 'sourceSite' : 'noSourceSite'}
              values={{
                vendorDisplayName: displayName,
              }}
              components={{
                1: sourceSite ? <SourceSite sourceSite={sourceSite} /> : <></>,
              }}
            />
            &nbsp;
          </>
        )}
        {t('common.prices_as_of', { count: itemCount })}&nbsp;
        {dayjs(cartVendor === 'amazon' ? Date.now() : timestamp).format(
          'M/D/YYYY, h:mm A',
        )}
      </span>
      <DisclaimerTooltip
        title={
          <p>
            <Trans
              i18nKey={t('common.price_disclaimer_tooltip')}
              components={[<br />, <br />]}
            />
          </p>
        }
        placement='top'
        arrow
      >
        <div className='flex h-[22.4px] items-center justify-center fill-offGray hover:fill-gray-300'>
          <InfoOutlinedIcon
            className='transition-all'
            style={{ fontSize: 20, fill: 'inherit' }}
          />
        </div>
      </DisclaimerTooltip>
    </div>
  );
};

const SourceSite = ({ sourceSite }: { sourceSite: string | undefined }) => {
  if (!sourceSite) return null;

  return (
    <Link
      to={`${process.env.REACT_APP_LIVE_URL}/u/${encodeURIComponent(sourceSite)}`}
      target='_blank'
      rel='noopener noreferrer'
      className='text-100 break-all font-bold text-grassGreen underline transition-all hover:text-grassGreen/70'
    >
      {sourceSite}
      <OpenInNewOutlinedIcon
        className='ml-0.5 fill-grassGreen hover:fill-grassGreen/70'
        style={{ fontSize: 20 }}
      />
    </Link>
  );
};

const DisclaimerTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#F1F1F1',
    color: '#04033C',
    fontSize: 14,
    fontWeight: 500,
    borderRadius: 8,
    borderWidth: 2,
    borderColor: '#3032DC',
    padding: 16,
    fontFamily: 'Open Sans',
    marginInline: 4,
    marginBottom: '9px !important',
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25)',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: '#F1F1F1',
    fontSize: 20,
    '&:before': {
      border: '2px solid #3032DC',
    },
  },
  [`& .${tooltipClasses.popper}`]: {
    color: '#F1F1F1',
  },
  [`& .${tooltipClasses.popperArrow}`]: {
    color: '#F1F1F1',
  },
}));
