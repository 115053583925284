import { forwardRef, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAtom } from 'jotai';
import { TransitionProps } from '@mui/material/transitions';
import CloseIcon from '@mui/icons-material/Close';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { Dialog, Grow, styled } from '@mui/material';
import TagManager from 'react-gtm-module';

import { showExtensionMissingModalAtom } from '../../../store';
import { CartGetItem } from '../../../types';
import { ExtDownloads } from '../extBlocks';
import { Trans, useTranslation } from 'react-i18next';

const SacExtLogo = require('../../../images/logos/ext_logo.png');

type Props = {
  numOfItems?: number;
  items?: CartGetItem[];
  cartId?: string;
  cartVendor?: string;
  cartValue?: number;
  cartCCY?: string;
  forImport?: boolean;
  page?: 'get' | 'embed';
};

export const ExtensionMissingModal = ({
  numOfItems,
  items,
  cartId,
  cartVendor,
  cartValue,
  cartCCY,
  forImport,
  page,
}: Props) => {
  const [show, setShow] = useAtom(showExtensionMissingModalAtom);
  const [highlightMarks, setHighlightMarks] = useState<boolean>(false);

  const { t } = useTranslation();

  useEffect(() => {
    if (show) {
      setTimeout(() => setHighlightMarks(true), 1000);
    } else {
      setHighlightMarks(false);
    }
  }, [show]);

  const handleOpenItemsInNewTab = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'Manual Send to Vendor',
        eventCategory: 'engagement',
        eventLabel: `${page && page === 'embed' ? 'Embed' : 'Get'} Page (Ext Missing Modal)`,
        eventValue: {
          cartId,
          cartValue,
          cartCCY,
          cartVendor,
        },
      },
    });
    items!.forEach((item) => {
      window.open(`https://share-a-cart.com${item.url}`, '_blank');
    });
  };

  return (
    <ExtensionMissingDialogStyled
      open={show}
      onClose={() => setShow(false)}
      TransitionComponent={Transition}
      aria-describedby='Embed this widget into your website.'
    >
      <div className='custom-scrollbar h-full w-full overflow-y-auto'>
        <div
          onClick={() => setShow(false)}
          className='absolute right-3 top-2.5 cursor-pointer fill-darkSteel hover:fill-gray-400'
        >
          <CloseIcon
            className='transition-all'
            style={{ fontSize: '24px', fill: 'inherit' }}
          />
        </div>
        <div className='flex flex-col items-center justify-center gap-4 p-4 sm:py-6 md:gap-6 md:px-6 md:py-8'>
          <div className='flex flex-col items-center justify-center gap-2 md:gap-3'>
            <div className='flex h-12 w-12 items-center justify-center rounded-full bg-yellow-200'>
              <ErrorOutlineOutlinedIcon
                style={{ fontSize: 32 }}
                className='text-yellow-600'
              />
            </div>

            <h3 className='heading-300 text-center'>
              {forImport
                ? t('popup.extension_required')
                : t('popup.vendor_limitation')}
            </h3>
            <p className='text-200 text-center xs:px-2 sm:px-4'>
              <Trans
                i18nKey={
                  forImport
                    ? t('popup.importing_carts_must_be_done_using_ext')
                    : t('popup.you_must_use_ext')
                }
                components={[<b></b>]}
              />
            </p>
          </div>

          <div className='flex flex-col items-center justify-center gap-1'>
            <img src={SacExtLogo} alt='Share-A-Cart Extension Logo' />
            <h4 className='font-norwester text-2xl text-standardGray'>
              Share-A-Cart
            </h4>
          </div>

          <ExtDownloads />

          <div className='w-full'>
            {forImport ? (
              ''
            ) : (
              <>
                <p className='text-200 text-left xs:px-2 sm:px-4'>
                  {t('popup.once_the_ext_is_installed_reload_page')}
                </p>
                <p className='text-200 mt-3 xs:px-2 sm:px-4'>
                  <Trans
                    i18nKey={t('popup.open_items_in_a_new_tab_allow_popups')}
                    components={[
                      <mark
                        className={`transition-all duration-1000 ${!highlightMarks ? 'bg-offWhite' : 'bg-[#FFFF00]'}`}
                      ></mark>,
                    ]}
                  />
                  &nbsp;
                  <Link
                    to={
                      'https://kb.salisbury.edu/display/TSC/Turning+off+popup+blockers+in+popular+web+browsers'
                    }
                    target='_blank'
                    className='text-primary underline transition hover:text-primary/70'
                  >
                    {t('common.learn_more')}
                  </Link>
                </p>
              </>
            )}
          </div>

          {forImport ? (
            ''
          ) : (
            <div className='flex w-full flex-col items-center gap-2 pt-4 xs:ml-auto xs:flex-row-reverse xs:pt-0'>
              <button
                onClick={() => handleOpenItemsInNewTab()}
                className='button-primary h-auto max-h-24 min-h-12 w-full whitespace-normal text-balance xs:w-auto'
                style={{ overflowWrap: 'anywhere' }}
              >
                {t('popup.open_items_in_new_tab', { count: numOfItems })}
              </button>
              <button
                onClick={() => setShow(false)}
                className='button-secondary h-12 w-full xs:max-w-[120px]'
              >
                {t('common.close')}
              </button>
            </div>
          )}
        </div>
      </div>
    </ExtensionMissingDialogStyled>
  );
};

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Grow ref={ref} {...props} />;
});

const ExtensionMissingDialogStyled = styled(Dialog)(() => ({
  '& .MuiDialog-paper': {
    // padding: 32,
    borderRadius: 16,
    backgroundColor: '#FEFEFE',
    position: 'relative',
    width: '90%',
    maxWidth: 512,
    minWidth: 294,
  },
}));
