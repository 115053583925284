import { useAtom } from 'jotai';
import { Navigate } from 'react-router-dom';
import { authUserAtom } from '../store';

// Use the Auth State from Jotai here to check if the user is logged in
// Redirect to login page if the user is not logged in
export const RequireAuth = ({ children }: { children: React.ReactNode }) => {
  const [user] = useAtom(authUserAtom);

  return user ? <>{children}</> : <Navigate to='/login' replace />;
};
