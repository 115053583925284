import { useInfiniteQuery } from '@tanstack/react-query';
import { useAtom } from 'jotai';
import {
  authUserAtom,
  historyFilterByAtom,
  historySearchByAtom,
  historySortByAtom,
} from '../store';
import { randomTitleGenerator } from '../utils/randomTitleGenerator';
import { CartHistoryData } from '../types';
import { useGlobalCartTags } from '.';
import { formatPrice } from 'src/utils/formatPrice';

export const useCartListQuery = ({
  debouncedSearchBy,
}: {
  debouncedSearchBy: string;
}) => {
  const [user] = useAtom(authUserAtom);
  const [filterBy] = useAtom(historyFilterByAtom);
  const [sortBy] = useAtom(historySortByAtom);
  const [searchBy] = useAtom(historySearchByAtom);

  const globalCartTagsHandler = useGlobalCartTags();

  return useInfiniteQuery({
    queryKey: [
      'cart',
      'list',
      { filterBy, sortBy, searchBy: debouncedSearchBy },
    ],
    queryFn: async ({ pageParam }) => {
      const res = await fetch(
        `${process.env.REACT_APP_LIVE_URL}/user/cart/list?sort=${sortBy}&filter=${filterBy}&search=${searchBy}&page=${pageParam}`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
          method: 'GET',
          credentials: 'include',
        },
      );
      if (!res.ok) {
        throw new Error('Error fetching Cart History data...');
      }

      const data = await res.json();

      // Get CCY, CCYS, Cart Total Price and Cart Total Qty
      const carts = data.map((cartBase: CartHistoryData) => {
        if (!cartBase.cart.cartCCYS) {
          cartBase.cart.cartCCYS = '$';
        }
        if (!cartBase.cart.cartCCY) {
          cartBase.cart.cartCCY = 'USD';
        }

        if (!cartBase.cart.title) {
          cartBase.cart.title = randomTitleGenerator(
            cartBase.cart.id,
            cartBase.vendorDisplayName || '18',
          ); // will be 'Rad' if vendorDisplayName is not available
        }

        if (!cartBase.timestamp) {
          cartBase.timestamp = 0;
        }

        if (cartBase.tags && cartBase.tags.length > 0) {
          globalCartTagsHandler(cartBase.tags);
        }

        if (!cartBase.cart.cartTotalPrice || !cartBase.cart.cartTotalQty) {
          const totalsData = cartBase.cart.items?.reduce(
            (acc, { quantity, price, ccyS, priceccy }) => {
              if (ccyS) cartBase.cart.cartCCYS = ccyS;
              if (priceccy) cartBase.cart.cartCCY = priceccy;

              const itemTotal =
                price && price !== '-1' && price !== '-1.00'
                  ? Number(price) * Number(quantity)
                  : 0;

              return {
                cartTotalPrice: acc.cartTotalPrice + itemTotal,
                cartTotalQty: acc.cartTotalQty + Number(quantity),
              };
            },
            { cartTotalPrice: 0, cartTotalQty: 0 },
          );

          cartBase.cart.cartTotalPrice =
            totalsData?.cartTotalPrice.toFixed(2) || 0;
          cartBase.cart.cartTotalQty = totalsData?.cartTotalQty || 0;
        }

        const cartTotalPriceNum =
          typeof cartBase.cart.cartTotalPrice === 'number'
            ? cartBase.cart.cartTotalPrice
            : parseFloat(cartBase.cart.cartTotalPrice);

        cartBase.formattedPrice = formatPrice(
          cartTotalPriceNum,
          cartBase.cart.locale || 'us',
          cartBase.cart.cartCCY,
          cartBase.cart.cartCCYS,
        );

        return { ...cartBase };
      });

      return { carts, pageParam };
    },
    staleTime: 1000 * 60 * 5, // data stays in cache for 5 minutes before needing to be refetched
    initialPageParam: 0,
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      // if the last page has less than 5/20 (free/personal subscription) items, then there are no more pages
      const maxCartsPerPage = user?.subscription.level === 'free' ? 5 : 20;

      if (lastPage.carts.length < maxCartsPerPage) {
        return undefined;
      }

      return lastPageParam + 1;
    },
  });
};
