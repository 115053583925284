import { useAtom } from "jotai";
import { useEffect } from "react";
import { authUserAtom } from "../store";

type Props = {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const useShowWelcomeModal = ({ setOpen }: Props) => {
  const [user] = useAtom(authUserAtom);

  return useEffect(() => {
    if (
      user!.createTimestamp &&
      user!.createTimestamp > Date.now() - 1000 * 60 * 60 * 24 * 3 // check if user creation timestamp is within the 3 days
    ) {
      const welcomeModalSeen = localStorage.getItem('welcomeModalSeen');

      if (!welcomeModalSeen || welcomeModalSeen === 'false') {
        localStorage.setItem('welcomeModalSeen', 'true');
        setOpen(true);
      }
    }
  }, [setOpen, user]);
}
