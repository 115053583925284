import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import type { TReviewFormState } from '../../../types';
import { useActiveBrowser, useFeedbackReviewMutation } from '../../../hooks';
import { Link } from 'react-router-dom';
import { extensionsList } from '../../reusableBlocks/modals/ExtensionMissingModalBlocks';
import thankYouGif from '../../../images/other/dwight-thank-you.gif';
import { useTranslation } from 'react-i18next';

const schema = z.object({
  name: z
    .string()
    .min(1, { message: 'Name required' })
    .max(50, { message: 'Name too long' }),
  email: z.string().email({ message: 'Invalid email address' }),
});

export type HappyFeedbackForm = z.infer<typeof schema>;

type Props = {
  setReviewFormState: React.Dispatch<React.SetStateAction<TReviewFormState>>;
};

export const GetFeedbackHappy = ({ setReviewFormState }: Props) => {
  const mutation = useFeedbackReviewMutation(setReviewFormState);
  const activeBrowser = useActiveBrowser();
  const currentExtension =
    extensionsList.find((ext) => ext.name === activeBrowser) ||
    extensionsList[0];

  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<HappyFeedbackForm>({ resolver: zodResolver(schema) });

  const onSubmit = (data: HappyFeedbackForm) => {
    mutation.mutate(data);
  };

  return (
    <div className='flex w-full max-w-[400px] flex-col items-center justify-center gap-4'>
      <img src={thankYouGif} width='150px' height='125px' alt='Thank you GIF' />
      <h3 className='heading-400 text-center text-offWhite'>
        {t('get.youre_too_kind')} 🙏
      </h3>
      <p className='text-300 text-balance text-center text-cloudGray'>
        {t('get.reviews_help_us')}
      </p>
      <div className='flex w-full flex-col items-center justify-center gap-6'>
        <Link
          to={currentExtension.link}
          target='_blank'
          className='button-primary h-14 w-full gap-3 text-center font-secondary text-lg font-bold leading-[18px] text-offWhite'
        >
          {t('get.leave_us_a_review')}
          <OpenInNewIcon className='h-[18px] w-[18px] text-[#B0B0D1]' />
        </Link>

        <ArrowDownwardIcon className='text-skyBlue' style={{ fontSize: 32 }} />

        <form
          onSubmit={handleSubmit(onSubmit)}
          className='flex w-full max-w-[360px] flex-col items-center justify-center gap-4'
        >
          <h5 className='heading-200 w-full text-center text-offWhite'>
            {t('get.claim_your')}&nbsp;
            <span className='text-customYellow'>{t('get.5_dollar')}</span>
            &nbsp;{t('get.gift_card')}
          </h5>

          <div className='flex w-full flex-col items-start justify-center gap-2'>
            <label className='text-100 w-full text-offWhite'>
              {t('get.whats_the_name_on_your_review')}
            </label>
            <div className='flex w-full flex-col items-center justify-start gap-0.5'>
              <input
                placeholder='Name'
                className='h-12 w-full rounded border-none bg-primary-off px-3 py-4 font-secondary text-base leading-4 text-offWhite placeholder:text-cloudGray/70 focus:ring-2 focus:ring-primary'
                {...register('name')}
              />
              <span className='w-full text-left text-red-500'>
                {errors.name?.message?.toString()}
              </span>
            </div>
          </div>

          <div className='flex w-full flex-col items-center justify-start gap-2'>
            <label className='text-100 w-full text-offWhite'>
              {t('get.where_should_we_send_your_gift_card')}
            </label>
            <div className='flex w-full flex-col items-center justify-start gap-0.5'>
              <input
                placeholder='Email Address'
                className='h-12 w-full rounded border-none bg-primary-off px-3 py-4 font-secondary text-base leading-4 text-offWhite placeholder:text-cloudGray/70 focus:ring-2 focus:ring-primary'
                {...register('email')}
              />
              <span className='w-full text-left text-red-500'>
                {errors.email?.message?.toString()}
              </span>
            </div>
          </div>

          <button
            type='submit'
            disabled={mutation.isPending}
            className='button-primary h-10 w-full text-wrap bg-grassGreen text-center font-secondary text-base font-bold leading-4 text-primary-dark hover:bg-grassGreen/80'
          >
            {t('get.send_me_my_gift_card')}
          </button>
        </form>
      </div>
    </div>
  );
};
