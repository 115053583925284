import { useAtom } from 'jotai';
import type { Changelog } from '../../types';
import { ChangelogListItem } from './ChangelogListItem';
import { changelogFilterByAtom, changelogSearchByAtom } from 'src/store';

type Props = {
  changelogs: Changelog[] | undefined;
};

export const ChangelogList = ({ changelogs }: Props) => {
  const [filterBy] = useAtom(changelogFilterByAtom);
  const [searchBy] = useAtom(changelogSearchByAtom);

  if (!changelogs) return null;

  return (
    <ol className='flex w-full flex-col items-center justify-center gap-6 bg-offWhite p-6 sm:gap-8'>
      {changelogs.map((log, i) => {
        if (!filterBy.includes('all')) {
          if (!log.labels.some((label) => filterBy.includes(label.name))) {
            return null;
          }
        }

        // if searchby isn't empty, we need to check if the title, body, or vendorDisplayName includes the search term
        // if it does, we should display the item, if it doesn't, we should return null;
        if (
          searchBy &&
          !log.title.toLowerCase().includes(searchBy.toLowerCase()) &&
          !log.body?.toLowerCase().includes(searchBy.toLowerCase()) &&
          !log.vendorDisplayName?.toLowerCase().includes(searchBy.toLowerCase())
        ) {
          return null;
        }

        // WE ADD 1 TO INDEX TO PREVENT ERRORS WHEN SENDING TO BACKEND
        return <ChangelogListItem key={log.id} log={log} logIndex={i + 1} />;
      })}
    </ol>
  );
};
