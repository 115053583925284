import { useQueryClient, useMutation } from '@tanstack/react-query';
import {
  updateCartMutation,
  updateCartHistoryMutationSuccess,
  updateCartGetMutationSuccess,
} from '../api';
import { CartGetData, CartHistoryCacheUpdate } from '../types';
import { toast } from 'sonner';
import { ToastStyled } from '../components/reusableBlocks';
import { randomTitleGenerator } from '../utils/randomTitleGenerator';
import { useGlobalCartTags } from '.';

export const useMutateCart = (handleClick?: () => void) => {
  const queryClient = useQueryClient();
  const globalCartTagsHandler = useGlobalCartTags();

  return useMutation({
    mutationFn: updateCartMutation,
    onSuccess: (mutationReturn, variables) => {
      const { cartId, cartVendor } = variables;
      let titleGenerated = false;

      if (
        variables.propertyToUpdate === 'title' &&
        variables.updatedValue === ''
      ) {
        titleGenerated = true;
        variables.updatedValue = randomTitleGenerator(cartId, cartVendor);
      }

      // we can add tags to the global tags list but we cannot take away. That will require a rerender
      if (
        variables.propertyToUpdate === 'tags' &&
        Array.isArray(variables.updatedValue) &&
        variables.updatedValue.length > 0
      ) {
        globalCartTagsHandler(variables.updatedValue);
      }

      toast.custom((t) => (
        <ToastStyled variant='success' handleClose={() => toast.dismiss(t)}>
          Cart <b>{cartId}</b> updated{' '}
          {variables.propertyToUpdate === 'listed' ? (
            <>
              to <b>{variables.updatedValue ? 'public' : 'private'}</b>
            </>
          ) : variables.propertyToUpdate === 'title' ? (
            <b>title</b>
          ) : variables.propertyToUpdate === 'note' ? (
            <b>notes</b>
          ) : variables.propertyToUpdate === 'tags' ? (
            <b>tags</b>
          ) : (
            ''
          )}{' '}
          successfully!
        </ToastStyled>
      ));

      // update cart in cache to prevent an extra API call
      queryClient.setQueryData(['cart', cartId], (oldData: CartGetData) =>
        updateCartGetMutationSuccess(oldData, variables, titleGenerated),
      );
      queryClient.setQueriesData(
        { queryKey: ['cart', 'list'], exact: false, type: 'all' },
        (oldData: CartHistoryCacheUpdate) => {
          const updatedCache = updateCartHistoryMutationSuccess(
            oldData,
            variables,
          );

          if (updatedCache !== undefined) {
            return updatedCache;
          }

          queryClient.refetchQueries({
            queryKey: ['cart', 'list'],
            exact: false,
            type: 'all',
          });

          return;
        },
      );

      return;
    },
    onError: (error, variables) =>
      toast.custom((t) => (
        <ToastStyled
          variant='error'
          handleClick={handleClick}
          handleClose={() => toast.dismiss(t)}
        >
          There was an issue updating cart <b>{variables.cartId}</b>
          {variables.propertyToUpdate === 'listed' ? (
            <>
              {' '}
              to <b>{variables.updatedValue ? 'public' : 'private'}</b>
            </>
          ) : variables.propertyToUpdate === 'title' ? (
            <>
              's <b>title</b>
            </>
          ) : variables.propertyToUpdate === 'note' ? (
            <>
              's <b>notes</b>
            </>
          ) : variables.propertyToUpdate === 'tags' ? (
            <>
              's <b>tags</b>
            </>
          ) : (
            ''
          )}
          .
        </ToastStyled>
      )),
  });
};
