type Props = {
  children: React.ReactNode;
  customHeight?: string;
  customWidth?: string;
  customPaddingX?: string;
  customPaddingY?: string;
  customStyles?: string;
};

export const ButtonPrimary = ({
  children,
  customHeight,
  customWidth,
  customStyles,
  customPaddingX,
  customPaddingY,
}: Props) => {
  return (
    <button
      className={`inline-flex cursor-pointer flex-nowrap items-center justify-center whitespace-nowrap rounded-full bg-primary font-secondary font-bold text-offWhite transition-all hover:bg-primary-hover active:scale-95 ${customStyles} flex ${
        customHeight ? customHeight : 'h-full'
      } ${customWidth ? customWidth : 'w-full'} ${
        customPaddingX ? customPaddingX : 'px-6'
      } ${customPaddingY ? customPaddingY : ''}`}
    >
      {children}
    </button>
  );
};
