import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

type Props = {
  cartIdParam: string | undefined;
  setCartId: (cartId: string) => void;
  searchParams: URLSearchParams;
  pathname: 'get' | 'embed';
};

// match any lowercase letter
const regex = new RegExp(/[a-z]/);

export const useRedirectToUpperCase = ({
  cartIdParam,
  setCartId,
  searchParams,
  pathname,
}: Props) => {
  const navigate = useNavigate();

  // if cartId has any lowercase letters, redirect to the uppercase version
  return useEffect(() => {
    if (cartIdParam) {
      if (cartIdParam.length > 9) {
        // redirect to the make page with the cartId that looks like an item query
        window.location.href = `https://share-a-cart.com/make/?vendor=amazon&keywords=${encodeURIComponent(cartIdParam)}`;
      } else if (regex.test(cartIdParam)) {
        // redirect to the uppercase version of the cartId
        setCartId(cartIdParam.toUpperCase());

        navigate(
          {
            pathname: `/${pathname}/${cartIdParam.toUpperCase()}`,
            search: searchParams.toString(),
          },
          { replace: true },
        );
      } else {
        setCartId(cartIdParam);
      }
    } else {
      setCartId('');
    }
  }, [cartIdParam, navigate, searchParams, setCartId, pathname]);
};
