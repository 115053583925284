import Lottie from 'lottie-react';
import { useTranslation } from 'react-i18next';

const LoadingAnimation = require('../../images/lotties/CTA-cart.json');

type Props = {
  customTextColor?: string;
  customBgColor?: string;
};

export const LoadingCart = ({ customTextColor, customBgColor }: Props) => {
  const { t } = useTranslation();

  return (
    <div
      className={`flex flex-col items-center justify-center ${
        customBgColor ? customBgColor : 'bg-primary-dark'
      }`}
    >
      <Lottie
        animationData={LoadingAnimation}
        style={{ width: 'full', maxWidth: '400px', height: 'auto' }}
      />
      <span
        className={`animate-pulse font-primary text-3xl font-extrabold ${
          customTextColor ? customTextColor : 'text-white'
        }`}
      >
        {t('common.loading')}
      </span>
    </div>
  );
};
