import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Grow } from '@mui/material';

import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { ReactComponent as HelpIcon } from '../../images/icons/help.svg';
import { ReactComponent as EditIcon } from '../../images/icons/edit-icon.svg';
import { ReactComponent as EmbedIcon } from '../../images/icons/embed.svg';
import { ReactComponent as LockIcon } from '../../images/icons/password.svg';
import { ReactComponent as ExportIcon } from '../../images/icons/export.svg';
import { CartGetItem } from '../../types';
import { exportCartToExcel } from '../../utils';
import { useTranslation } from 'react-i18next';
import { useAtom } from 'jotai';
import { showEmbedModalAtom } from 'src/store';

type Props = {
  cartId: string;
  cartCCY: string;
  cartCCYS: string;
  cartTitle: string | undefined;
  cartItems: CartGetItem[];
  cartTimestamp: number;
  cartStore: string | undefined;
  isLoading: boolean;
};

export const ListActions = ({
  cartId,
  cartCCY,
  cartCCYS,
  cartTitle,
  cartItems,
  cartTimestamp,
  cartStore,
  isLoading,
}: Props) => {
  const [open, setOpen] = useState<boolean>(false);
  const [animationDone, setAnimationDone] = useState<boolean>(true);
  const [, setShowEmbedModal] = useAtom<boolean>(showEmbedModalAtom);

  const { t } = useTranslation();

  const handleClick = () => {
    if (!open) {
      setAnimationDone(false);
      return setOpen(true);
    }

    setTimeout(() => {
      setAnimationDone(true);
    }, 150);
    setOpen(false);
  };

  const exportCartGetData = () => {
    exportCartToExcel(
      cartId,
      cartCCY,
      cartCCYS,
      cartItems,
      cartTitle,
      cartTimestamp,
      cartStore,
    );
  };

  return (
    <>
      <ul className='z-[1] flex flex-row flex-wrap items-start justify-start gap-3'>
        <button
          disabled={isLoading}
          onClick={handleClick}
          className={`button-secondary flex h-12 flex-row items-center justify-center gap-2 transition-all ${!animationDone ? 'w-12 p-0' : ''}`}
        >
          {!animationDone ? (
            <CloseIcon className='text-primary' style={{ fontSize: 24 }} />
          ) : (
            <>
              <SettingsOutlinedIcon
                className='text-2xl text-primary'
                style={{ fontSize: 24 }}
              />
              {t('common.list_actions')}
            </>
          )}
        </button>
        <Grow in={open} unmountOnExit {...(open ? { timeout: 150 } : {})}>
          <li>
            <Link
              to={`${process.env.REACT_APP_LIVE_URL}/guide/loading-a-list`}
              reloadDocument
            >
              <button className='button-secondary flex h-12 flex-row items-center justify-center gap-2'>
                <div className='text-primary'>
                  <HelpIcon />
                </div>
                {t('common.help')}
              </button>
            </Link>
          </li>
        </Grow>

        <Grow in={open} unmountOnExit {...(open ? { timeout: 350 } : {})}>
          <li>
            <Link
              to={`${process.env.REACT_APP_LIVE_URL}/make/${cartId}`}
              reloadDocument
            >
              <button className='button-secondary flex h-12 flex-row items-center justify-center gap-2'>
                <EditIcon className='text-primary' />
                {t('common.edit')}
              </button>
            </Link>
          </li>
        </Grow>

        <Grow in={open} unmountOnExit {...(open ? { timeout: 550 } : {})}>
          <li>
            <Link
              to={`${process.env.REACT_APP_LIVE_URL}/share/${cartId}`}
              reloadDocument
            >
              <button className='button-secondary flex h-12 flex-row items-center justify-center gap-2'>
                <ShareOutlinedIcon className='text-primary' />
                {t('common.share')}
              </button>
            </Link>
          </li>
        </Grow>

        <Grow in={open} unmountOnExit {...(open ? { timeout: 750 } : {})}>
          <li>
            <button
              onClick={() => setShowEmbedModal(true)}
              className='button-secondary flex h-12 flex-row items-center justify-center gap-2'
            >
              <div className='text-primary'>
                <EmbedIcon />
              </div>
              {t('common.embed')}
            </button>
          </li>
        </Grow>

        <Grow in={open} unmountOnExit {...(open ? { timeout: 950 } : {})}>
          <li>
            <Link
              to={`${process.env.REACT_APP_LIVE_URL}/make/${cartId}#password`}
              reloadDocument
            >
              <button className='button-secondary flex h-12 flex-row items-center justify-center gap-2'>
                <div className='text-primary'>
                  <LockIcon />
                </div>
                {t('common.password')}
              </button>
            </Link>
          </li>
        </Grow>

        <Grow in={open} unmountOnExit {...(open ? { timeout: 1150 } : {})}>
          <li>
            <button
              onClick={exportCartGetData}
              className='button-secondary flex h-12 flex-row items-center justify-center gap-2'
            >
              <div className='text-primary'>
                <ExportIcon />
              </div>
              {t('common.export')}
            </button>
          </li>
        </Grow>
      </ul>
    </>
  );
};
