import { z } from 'zod';
import { Changelog, ChangelogFilterByValue } from 'src/types';
import CloseIcon from '@mui/icons-material/Close';
import { forwardRef, useState } from 'react';
import { Grow } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { DialogStyled } from '../reusableBlocks';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { ChangelogFilterItems } from './ChangelogFilterItems';
import { useChangelogMutation } from 'src/hooks/useChangelogMutation';

const schema = z.object({
  labels: z
    .array(
      z.object({
        id: z.number(),
        name: z.custom<ChangelogFilterByValue>(),
      }),
    )
    .optional(),
  title: z.string(),
  body: z.string().nullable(),
  vendorDisplayName: z.string().optional(),
  vendorLink: z.string().optional(),
});

export type ChangelogForm = z.infer<typeof schema>;

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  logIndex?: number;
  log?: Changelog;
};

export const ChangelogItemCreateOrEdit = ({
  open,
  setOpen,
  logIndex,
  log,
}: Props) => {
  const [logLabels, setLogLabels] = useState<
    { id: number; name: ChangelogFilterByValue }[]
  >(log && log.labels ? log.labels : []);

  const { register, handleSubmit, reset } = useForm<ChangelogForm>({
    defaultValues: {
      labels: logLabels || [],
      title: log?.title || '',
      body: log?.body || '',
      vendorDisplayName: log?.vendorDisplayName || '',
      vendorLink: log?.vendorLink || '',
    },
    resolver: zodResolver(schema),
  });

  const mutation = useChangelogMutation({
    logIndex,
    setOpen,
    resetForm: reset,
  });

  const onSubmit = async (data: ChangelogForm) => {
    mutation.mutate({
      ...data,
      labels: logLabels,
      id: log?.id || Math.floor(Math.random() * 10000),
    });
  };

  const handleUpdateLabels = (clickedLabel: ChangelogFilterByValue) => {
    if (logLabels.findIndex((label) => label.name === clickedLabel) === -1) {
      setLogLabels([
        ...logLabels,
        { id: Math.floor(Math.random() * 1000), name: clickedLabel },
      ]);
    } else {
      setLogLabels(logLabels.filter((label) => label.name !== clickedLabel));
    }
  };

  return (
    <DialogStyled
      open={open}
      onClose={() => setOpen(false)}
      TransitionComponent={Transition}
    >
      <div className='custom-scrollbar h-full w-full overflow-y-auto'>
        <div
          onClick={() => setOpen(false)}
          className='absolute right-3 top-2.5 cursor-pointer fill-darkSteel hover:fill-gray-400'
        >
          <CloseIcon
            className='transition-all'
            style={{ fontSize: '24px', fill: 'inherit' }}
          />
        </div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className='flex w-full flex-col items-center justify-center gap-6 p-6'
        >
          <div className='flex w-full flex-col items-start justify-center gap-2'>
            <label>Title</label>
            <input className='w-full rounded' {...register('title')} />
          </div>

          <div className='flex w-full flex-col items-start justify-center gap-2'>
            <label>
              Body&nbsp;
              <span className='text-sm text-gray-500'>
                (not shown when 'Retailer Added' label is present)
              </span>
            </label>
            <textarea className='w-full rounded' {...register('body')} />
          </div>

          <div className='flex w-full flex-col items-start justify-center gap-2'>
            <label>
              Vendor Display Name&nbsp;
              <span className='text-sm text-gray-500'>
                (used for new retailers)
              </span>
            </label>
            <input
              className='w-full rounded'
              {...register('vendorDisplayName')}
            />
          </div>

          <div className='flex w-full flex-col items-start justify-center gap-2'>
            <label>
              Vendor Link&nbsp;
              <span className='text-sm text-gray-500'>
                (used for new retailers)
              </span>
            </label>
            <input
              type='url'
              className='w-full rounded'
              {...register('vendorLink')}
            />
          </div>

          <div className='flex w-full flex-col items-start justify-center gap-2'>
            <label>Labels</label>
            <ChangelogFilterItems
              filterBy={logLabels.map((label) => label.name)}
              handleUpdateFilterBy={handleUpdateLabels}
              showAllTag={false}
            />
          </div>
          <div className='flex w-full flex-col items-center gap-2 pt-4 xs:ml-auto xs:flex-row-reverse xs:pt-0'>
            <button
              type='submit'
              // disabled={mutation.isPending}
              className='button-primary h-10 w-full xs:w-auto'
            >
              {logIndex ? 'Update Log' : 'Create Log'}
            </button>
            <button
              onClick={() => {
                setOpen(false);
                reset();
              }}
              type='reset'
              className='button-secondary h-10 w-full xs:w-auto'
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </DialogStyled>
  );
};

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Grow ref={ref} {...props} />;
});
