import { Fragment, forwardRef, useCallback, useEffect, useState } from 'react';
import { Grow, TextareaAutosize } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import CloseIcon from '@mui/icons-material/Close';
// import NotesIcon from '@mui/icons-material/Notes';
// import TagIcon from '@mui/icons-material/LocalOfferOutlined';
import SearchIcon from '@mui/icons-material/Search';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'sonner';
import { DialogStyled } from '../DialogStyled';
// import { ReactComponent as EmptyCartIcon } from '../../../images/icons/empty-cart-icon.svg';
// import { ReactComponent as PeopleOutlineIcon } from '../../../images/icons/people_outline_icon.svg';
import {
  SwitchStyled,
  ToastStyled,
  ToastContentBlock,
  FurtherActions,
  ConfirmActionModal,
} from '..';
import {
  updateCartDetailsMutation,
  updateCartDetailsMutationSuccess,
  updateCartGetDetailsMutationSuccess,
} from '../../../api';
import type {
  CartGetData,
  CartGetItem,
  CartHistoryCacheUpdate,
  HandleUpdateCartDetails,
} from '../../../types';
import { GlobalCartTagsListDisplay, TempCartTag } from './cartTagBlocks';
import { useGlobalCartTags, useTagHooks, useWindowSize } from '../../../hooks';
import { Trans, useTranslation } from 'react-i18next';

type Props = {
  cartId: string;
  cartTitle: string;
  cartListed: boolean;
  cartNotes: string;
  cartTags: string[];
  show: boolean;
  setShow: (value: boolean) => void;
  cartCreated: boolean;
  cartCCY: string;
  cartCCYS: string;
  cartItems: CartGetItem[];
  cartTimestamp: number;
  cartStore: string | undefined;
};

export const CartDetailsModal = ({
  cartId,
  cartTitle,
  cartListed,
  cartNotes,
  cartTags,
  show,
  setShow,
  cartCreated,
  cartCCY,
  cartCCYS,
  cartItems,
  cartTimestamp,
  cartStore,
}: Props) => {
  const [inputTitle, setInputTitle] = useState<string>(cartTitle);
  const [isPublic, setIsPublic] = useState<boolean>(cartListed || false);
  const [inputNote, setInputNote] = useState<string>(cartNotes);
  const [tempTags, setTempTags] = useState<string[]>(cartTags);
  const [tagInput, setTagInput] = useState<string>('');
  const [showSaveBeforeClose, setShowSaveBeforeClose] =
    useState<boolean>(false);
  const [storedCallbackFn, setStoredCallbackFn] = useState<() => void>(
    () => {},
  );

  const { t } = useTranslation();

  const { windowWidth } = useWindowSize();

  const globalCartTagsHandler = useGlobalCartTags();
  const queryClient = useQueryClient();

  const { addTag, addGlobalTag, checkTagEquality, removeTag } = useTagHooks({
    cartTags,
    tagInput,
    setTagInput,
    tempTags,
    setTempTags,
  });

  useEffect(() => {
    setIsPublic(cartListed);
  }, [cartListed]);

  useEffect(() => {
    setInputNote(cartNotes);
  }, [cartNotes]);

  useEffect(() => {
    setTempTags(cartTags);
  }, [cartTags]);

  useEffect(() => {
    setInputTitle(cartTitle);
  }, [cartTitle]);

  const valuesChanged = useCallback(() => {
    if (
      cartTitle !== inputTitle ||
      cartNotes !== inputNote ||
      !checkTagEquality() ||
      isPublic !== cartListed
    ) {
      return true;
    }

    return false;
  }, [
    cartListed,
    cartNotes,
    cartTitle,
    checkTagEquality,
    inputNote,
    inputTitle,
    isPublic,
  ]);

  const mutation = useMutation({
    mutationFn: updateCartDetailsMutation,
    onSuccess: (mutationReturn, variables) => {
      const { cartId, tags } = variables;

      if (tags.length > 0) {
        globalCartTagsHandler(tags);
      }

      toast.custom((toastBlock) => (
        <ToastStyled
          variant='success'
          handleClose={() => toast.dismiss(toastBlock)}
        >
          <Trans
            i18nKey={t('popup.cart_id_updated_successfully')}
            values={{ cartId: variables.cartId }}
            components={[<b></b>]}
          />
        </ToastStyled>
      ));

      // update cart in cache to prevent an extra API call
      queryClient.setQueryData(['cart', cartId], (oldData: CartGetData) =>
        updateCartGetDetailsMutationSuccess(oldData, variables),
      );
      queryClient.setQueriesData(
        { queryKey: ['cart', 'list'], exact: false, type: 'all' },
        (oldData: CartHistoryCacheUpdate) => {
          const updatedCache = updateCartDetailsMutationSuccess(
            oldData,
            variables,
          );

          if (updatedCache !== undefined) {
            return updatedCache;
          }

          queryClient.invalidateQueries({
            queryKey: ['cart', 'list'],
            exact: false,
            type: 'all',
          });

          return;
        },
      );

      setShow(false);

      return;
    },
    onError: (error, variables) =>
      toast.custom((toastBlock) => (
        <ToastStyled
          variant='error'
          handleClick={() => setShow(true)}
          handleClose={() => toast.dismiss(toastBlock)}
        >
          {t('popup.there_was_an_issue_updating_your_cart')}
        </ToastStyled>
      )),
  });

  // if this returns true, submission was successful or there was nothing to update
  // we set checkValues to false when we're closing based on the save before close dialog
  const handleSubmit = async (
    e: React.FormEvent,
    checkValues?: boolean,
  ): Promise<boolean> => {
    e.preventDefault();

    if (checkValues && !valuesChanged()) {
      handleClose(false, true);
      toast.custom((toastBlock) => (
        <ToastStyled
          variant='info'
          handleClose={() => toast.dismiss(toastBlock)}
        >
          {t('popup.nothing_to_update')}
        </ToastStyled>
      ));
      return true;
    }

    const updateObj: HandleUpdateCartDetails = {
      cartId,
      tags: tempTags,
      note: inputNote,
      listed: isPublic,
    };

    // only grab title if cart is created, otherwise the user cannot change the title
    if (cartCreated) {
      updateObj.title = inputTitle.trim();
    }

    try {
      await mutation.mutateAsync(updateObj);
    } catch (error) {
      return false;
    }

    return true;
  };

  /**
   *
   * @param clear set to true to clear the form
   * @param ignoreSaveBeforeClose set to true to ignore the save before close dialog
   * @returns void
   */
  const handleClose = (
    clear: boolean,
    ignoreSaveBeforeClose: boolean,
    callbackFn?: () => void,
  ): void => {
    if (!ignoreSaveBeforeClose && valuesChanged()) {
      setShowSaveBeforeClose(true);

      // update stored callback function if one is passed, otherwise set it to an empty function
      if (callbackFn) {
        setStoredCallbackFn(() => callbackFn);
      } else {
        setStoredCallbackFn(() => {});
      }

      return;
    }

    // only run callback if we're ignoring the save before close modal
    // OR if the user has come from the save before close modal
    if (callbackFn) {
      setShow(false);
      callbackFn();
      return;
    }

    // if we aren't clearing the form, simply return and close the modal
    if (!clear) {
      setShow(false);
      return;
    }

    // avoid UI values flickering when closing modal, change them after modal has closed.
    setTimeout(() => {
      setInputTitle(cartTitle);
      setIsPublic(cartListed);
      setInputNote(cartNotes);
      setTempTags(cartTags);
      setTagInput('');
    }, 200);

    if (mutation.isError) {
      mutation.reset();
    }

    setShow(false);
    return;
  };

  return (
    <DialogStyled
      open={show}
      onClose={() => setShow(false)}
      TransitionComponent={Transition}
      aria-describedby='Edit details of your cart'
    >
      <div className='custom-scrollbar h-full w-full overflow-y-auto'>
        <div
          onClick={() => handleClose(true, true)}
          className='absolute right-3 top-2.5 cursor-pointer fill-darkSteel hover:fill-gray-400'
        >
          <CloseIcon
            className='transition-all'
            style={{ fontSize: '24px', fill: 'inherit' }}
          />
        </div>

        <div className='flex flex-col items-center justify-center gap-4 p-4 sm:py-6 md:gap-6 md:px-6 md:py-8'>
          <h3 className='heading-300 px-4 text-center'>
            {t('popup.edit_cart_details')}
          </h3>

          <form
            action='submit'
            onSubmit={handleSubmit}
            className='flex w-full flex-col items-start justify-center gap-4 md:gap-6'
          >
            <div className='flex flex-col flex-nowrap items-start justify-start gap-4 sm:flex-row sm:items-start sm:justify-start sm:gap-6'>
              <div className='flex w-full flex-col items-start justify-center gap-2 md:gap-4'>
                <label className='inline-flex w-full items-center justify-start gap-2'>
                  {/* <EmptyCartIcon className='w-5 text-darkSteel' /> */}
                  <h3 className='heading-100'>{t('common.cart_name')}</h3>
                </label>
                <div className='flex w-full flex-col items-start justify-center gap-0'>
                  <input
                    type='text'
                    placeholder={t('history.example_cart_name')}
                    value={inputTitle}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault();
                      }
                    }}
                    disabled={!cartCreated || mutation.isPending}
                    onChange={(e) => {
                      setInputTitle(e.target.value);
                    }}
                    className='h-12 w-full rounded border-none bg-offWhite px-3 py-2 font-secondary font-semibold leading-[24px] text-primary-dark outline-none transition-all selection:bg-primary selection:text-offWhite placeholder:font-normal placeholder:text-[#636E7A] hover:bg-gray-200 focus:ring-2 focus:ring-primary hover:focus:bg-offWhite disabled:cursor-text disabled:bg-gray-200'
                  />
                  {/* Tip displayed in UI if attempting to edit cart they're not the creator of. */}
                  {!cartCreated && (
                    <span className='text-100 font-secondary text-[13px] text-rose-500 xs:text-sm'>
                      {t('history.cannot_edit_title')}
                    </span>
                  )}
                </div>
              </div>

              <div className='flex w-full flex-col items-start justify-center gap-2 md:gap-4'>
                <label className='inline-flex w-full items-center justify-start gap-2'>
                  {/* <PeopleOutlineIcon className='fill-darkSteel' /> */}
                  <h3 className='heading-100'>{t('history.public_cart')}</h3>
                </label>
                <div className='flex flex-col items-start justify-start gap-1 md:gap-2'>
                  <div className='flex flex-row flex-nowrap items-center justify-center gap-0 md:gap-1'>
                    <SwitchStyled
                      checked={isPublic}
                      onChange={() => setIsPublic(!isPublic)}
                      disabled={mutation.isPending}
                    />
                    <b className='font-secondary text-sm font-bold leading-[22.4px]'>
                      {isPublic ? t('common.on') : t('common.off')}
                    </b>
                  </div>
                  <p className='text-100'>
                    {isPublic
                      ? t('common.cart_will_appear_on_your_public_profile')
                      : t(
                          'common.only_accessible_to_you_and_people_you_choose_to_share_it_with',
                        )}
                  </p>
                </div>
              </div>
            </div>

            <div className='flex w-full flex-col items-start justify-center gap-1 md:gap-2'>
              <label className='inline-flex w-full items-center justify-start gap-2'>
                {/* <TagIcon className='text-darkSteel' style={{ fontSize: 20 }} /> */}
                <h3 className='heading-100'>{t('history.tags')}</h3>
              </label>

              <div className='inline-flex w-full flex-wrap items-center justify-start gap-3 border-b border-[#25796D] pb-4'>
                {!tempTags || tempTags.length === 0 ? (
                  <span className='text-100 pb-[5px] pt-[10.61px]'>
                    {t('history.no_tags_added_yet')}
                  </span>
                ) : (
                  tempTags.map((tempTag, i) => (
                    <Fragment key={i}>
                      <TempCartTag tag={tempTag} removeTag={removeTag} />
                    </Fragment>
                  ))
                )}
              </div>
              <div className='relative w-full'>
                <input
                  type='text'
                  placeholder={
                    windowWidth < 490
                      ? t('history.find_or_create_a_tag')
                      : t('history.type_to_find_or_create_a_tag')
                  }
                  value={tagInput}
                  disabled={mutation.isPending}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      addTag();
                    }
                  }}
                  onChange={(e) => setTagInput(e.target.value)}
                  className='h-12 w-full rounded border-none bg-offWhite px-3 py-2 font-secondary font-semibold leading-[24px] text-primary-dark outline-none transition-all selection:bg-primary selection:text-offWhite placeholder:font-normal placeholder:text-[#636E7A] hover:bg-gray-200 focus:ring-2 focus:ring-primary hover:focus:bg-offWhite disabled:bg-gray-200'
                />
                <SearchIcon className='absolute right-2 top-2 cursor-text text-darkSteel xs:top-3' />
              </div>
              <div className='w-full pt-1'>
                <GlobalCartTagsListDisplay
                  currentTags={tempTags}
                  tagInput={tagInput}
                  addGlobalTag={addGlobalTag}
                />
              </div>
            </div>

            <div className='flex w-full flex-col items-start justify-center gap-1 md:gap-2'>
              <label className='inline-flex w-full items-center justify-start gap-2'>
                {/* <NotesIcon className='h-6 min-w-[24px] text-darkSteel' /> */}
                <h3 className='heading-100'>{t('history.notes')}</h3>
              </label>
              <TextareaAutosize
                name='cart-note'
                cols={60}
                minRows={2}
                maxLength={1024}
                defaultValue={inputNote ? inputNote : ''}
                placeholder={t('history.add_any_notes_youd_like')}
                onChange={(e) => setInputNote(e.target.value)}
                disabled={mutation.isPending}
                onFocus={(e) => {
                  const val = e.target.value;
                  e.target.value = '';
                  e.target.value = val;
                }}
                className='w-full rounded border-none bg-offWhite px-3 py-4 font-secondary font-semibold leading-[24px] text-primary-dark outline-none transition-all selection:bg-primary selection:text-offWhite placeholder:font-normal placeholder:text-[#636E7A] hover:bg-gray-200 focus:ring-2 focus:ring-primary hover:focus:bg-offWhite disabled:bg-gray-200'
              />
            </div>

            {mutation.isError && (
              <ToastContentBlock variant='error'>
                {t(
                  'popup.there_was_an_issue_updating_your_cart_please_try_again',
                )}
              </ToastContentBlock>
            )}
            <div className='flex w-full flex-col items-start justify-center gap-2 pt-2 xs:flex-row xs:flex-wrap xs:items-center xs:justify-start xs:pt-4'>
              <FurtherActions
                cartId={cartId}
                isLoading={false}
                handleClose={handleClose}
                cartCCY={cartCCY}
                cartCCYS={cartCCYS}
                cartItems={cartItems}
                cartTimestamp={cartTimestamp}
                cartStore={cartStore}
                cartTitle={cartTitle}
              />
              <div className='flex w-full flex-col items-center gap-2 xs:ml-auto xs:w-fit xs:flex-row-reverse'>
                <button
                  type='submit'
                  className='button-primary h-12 w-full xs:w-auto'
                >
                  {t('popup.save_details')}
                </button>
                <button
                  onClick={() => handleClose(true, true)}
                  type='reset'
                  className='button-secondary h-12 w-full xs:w-auto'
                >
                  {t('common.cancel')}
                </button>
              </div>
            </div>
            <ConfirmActionModal
              show={showSaveBeforeClose}
              setShow={setShowSaveBeforeClose}
              onClickPrimaryAsync={async (e: React.FormEvent) => {
                const submitSuccess = await handleSubmit(e, false);

                if (submitSuccess) {
                  queryClient.invalidateQueries({ queryKey: ['cart', cartId] });
                  handleClose(true, true, storedCallbackFn);
                }

                setShowSaveBeforeClose(false);
              }}
              onClickSecondary={() =>
                handleClose(false, true, storedCallbackFn)
              }
              titleText={t('popup.save_before_continuing')}
              bodyText={t('popup.want_to_save_your_changes_before_leaving')}
              primaryBtnText={t('popup.save_and_continue')}
              secondaryBtnText={t('popup.discard_changes')}
              variant='confirm'
            />
          </form>
        </div>
      </div>
    </DialogStyled>
  );
};

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Grow ref={ref} {...props} />;
});
