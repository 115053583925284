import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as EditIcon } from '../../../../images/icons/edit-icon.svg';
import { ReactComponent as EmptyCartIcon } from '../../../../images/icons/empty-cart-icon.svg';
import { Transition } from '@headlessui/react';
import {
  AlertPing,
  ButtonPrimary,
  ButtonSecondary,
  PopoverStyled,
  SimpleSpinner,
  ToastContentBlock,
} from '../../..';
import CloseIcon from '@mui/icons-material/Close';
import { useMutateCart } from '../../../../hooks';
import { Trans, useTranslation } from 'react-i18next';

type Props = {
  cartId: string;
  cartTitle: string;
  cartCreated: boolean;
  forGetPage: boolean;
  cartVendorDisplayName: string;
};

export const CartTitle = ({
  cartId,
  cartTitle,
  cartCreated,
  forGetPage,
  cartVendorDisplayName,
}: Props) => {
  const [showEditIcon, setShowEditIcon] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [inputTitle, setInputTitle] = useState<string>(cartTitle);

  const { t } = useTranslation();

  const handleOpen = () => {
    setAnchorEl(anchorEl ? null : containerRef.current);
  };

  const mutation = useMutateCart(handleOpen);

  const containerRef = useRef<HTMLDivElement>(null);

  const open = Boolean(anchorEl);
  const id = open ? 'cart-title-popover' : undefined;

  useEffect(() => {
    setInputTitle(cartTitle);
  }, [cartTitle]);

  /**
   * CLEAR will be true if Cancel button was clicked, X button is clicked, or save pressed when titles were equal.
   * CLEAR will only be false if popover was clicked away from.
   * @param clear - if true, will clear the input and reset the title to the original value.
   */
  const handleClose = (clear = false, saving = false) => {
    setAnchorEl(null);

    // if there's an error and we're not saving (coming from handleUpdate func), reset the mutation
    if (mutation.isError && !saving) {
      // setTimeout to avoid UI flickering while popover is closing
      setTimeout(() => {
        mutation.reset();
      }, 200);
    }

    if (clear) {
      // setTimeout to avoid UI flickering while popover is closing
      setTimeout(() => {
        setInputTitle(cartTitle || '');
      }, 200);
    }

    setShowEditIcon(false);
  };

  const checkTitleEquality = (trimmedTitle: string): boolean =>
    cartTitle && cartTitle === trimmedTitle ? true : false;

  const handleUpdate = () => {
    const trimmedTitle = inputTitle.trim();
    const titleEquality = checkTitleEquality(trimmedTitle);

    if (titleEquality) {
      handleClose();
      return;
    }

    mutation.mutate({
      cartId,
      propertyToUpdate: 'title',
      updatedValue: trimmedTitle,
      cartVendor: cartVendorDisplayName,
    });

    handleClose(false, true);
  };

  return (
    <>
      <div
        onMouseEnter={() => setShowEditIcon(true)}
        onMouseLeave={() => {
          if (!open && !mutation.isPending && !mutation.isError) {
            setShowEditIcon(false);
          }
        }}
        className={`inline-flex flex-nowrap items-center justify-start gap-2 ${forGetPage ? 'w-fit' : 'w-full'}`}
      >
        {!forGetPage ? (
          <Link
            to={`/get/${cartId}`}
            state={{ from: 'history' }}
            className={`truncate py-1.5 text-base font-semibold transition-all hover:cursor-pointer hover:underline
          ${
            open
              ? !mutation.isError // if open and not error, show blue, else show red
                ? 'text-primary/70'
                : 'text-rose-300'
              : mutation.isPending // if not open and pending, show blue
                ? 'text-primary/70'
                : !mutation.isError // if not open and not pending and not error, show blue
                  ? 'text-primary hover:text-primary/70'
                  : 'text-rose-500 hover:text-rose-300'
          }`}
          >
            {cartTitle ? cartTitle : 'Example Cart Name'}
          </Link>
        ) : (
          <h1
            style={{ overflowWrap: 'anywhere' }}
            className={`heading-400 whitespace-normal transition-all ${
              open && !mutation.isError
                ? 'text-[#CCCCD7]'
                : open && mutation.isError
                  ? 'text-rose-300'
                  : ''
            } ${
              !open && mutation.isPending
                ? forGetPage
                  ? 'text-[#CCCCD7]'
                  : 'text-primary/70'
                : !open && mutation.isError
                  ? 'text-rose-500'
                  : 'text-offWhite'
            }`}
          >
            {cartTitle ? cartTitle : 'Example Cart Name'}
          </h1>
        )}
        <div
          ref={containerRef}
          className='relative min-w-[24px]'
          aria-describedby={id}
          onClick={handleOpen}
        >
          <Transition
            as={'div'}
            show={showEditIcon || mutation.isPending || mutation.isError}
            enter='transition ease-out duration-150'
            enterFrom='transform opacity-0 scale-95'
            enterTo='transform opacity-100 scale-100'
            leave='transition ease-in duration-75'
            leaveFrom='transform opacity-100 scale-100'
            leaveTo='transform opacity-0 scale-95'
          >
            <EditIcon
              className={`relative cursor-pointer transition-all ${
                forGetPage ? 'h-7 w-7' : 'h-6 w-6'
              } ${
                open
                  ? !mutation.isError //if open && not error
                    ? forGetPage
                      ? 'text-[#CCCCD7]' // get page, show gray
                      : 'text-primary/70' // history page, show blue
                    : 'text-rose-300' // error, show red
                  : mutation.isPending // if not open and pending
                    ? forGetPage
                      ? 'text-[#CCCCD7]' // get page, show gray
                      : 'text-primary/70' // history page, show blue
                    : !mutation.isError // if not open and not pending and not error
                      ? forGetPage
                        ? 'text-offWhite hover:text-[#CCCCD7]' // get page, show white
                        : 'text-primary hover:text-primary/70' // history page, show blue
                      : 'text-rose-500 hover:text-rose-300' // error, show red
              }`}
            />

            {mutation.isError && !open ? (
              <AlertPing
                variant='error'
                customX='-right-[5px]'
                customY='-top-[5px]'
              />
            ) : mutation.isPending ? (
              <SimpleSpinner
                customX='-right-[5px]'
                customY='-top-[5px]'
                customColor={
                  forGetPage
                    ? 'border-offWhite group-hover:opacity-80'
                    : undefined
                }
              />
            ) : (
              ''
            )}
          </Transition>
        </div>
        <PopoverStyled
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={() => handleClose()}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
        >
          <div className='relative flex w-full min-w-[280px] max-w-[504px] flex-col gap-3 rounded-2xl bg-cloudGray p-6 xs:min-w-[320px] sm:min-w-[380px] md:min-w-[400px] lg:min-w-[504px]'>
            <div
              onClick={() => handleClose(true)}
              className='absolute right-3 top-3 cursor-pointer fill-darkSteel hover:fill-gray-400'
            >
              <CloseIcon
                className='transition-all'
                style={{ fontSize: '24px', fill: 'inherit' }}
              />
            </div>
            <div className='inline-flex w-full items-center justify-start gap-2'>
              <EmptyCartIcon className='w-5 text-darkSteel' />
              <h3 className='font-primary text-xl font-semibold text-primary-dark'>
                {t('common.cart_name')}
              </h3>
            </div>

            <input
              type='text'
              placeholder={t('history.example_cart_name')}
              value={inputTitle}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();

                  handleUpdate();
                }
              }}
              autoFocus
              disabled={!cartCreated}
              onChange={(e) => {
                setInputTitle(e.target.value);
              }}
              className='h-12 w-full rounded border-none bg-offWhite px-3 py-2 font-secondary font-semibold leading-[24px] text-primary-dark outline-none transition-all selection:bg-primary selection:text-offWhite placeholder:font-normal placeholder:text-[#636E7A] hover:bg-gray-200 focus:ring-2 focus:ring-primary hover:focus:bg-offWhite disabled:cursor-text disabled:bg-gray-200'
            />

            {/* Tip displayed in UI if attempting to edit cart they're not the creator of. */}
            {!cartCreated && (
              <span className='text-100 -mb-1 -mt-3 text-rose-500'>
                {t('history.cannot_edit_title')}
              </span>
            )}

            {mutation.isError ? (
              <ToastContentBlock variant='error'>
                <Trans
                  i18nKey={t('popup.issue_updating')}
                  values={{ field: 'title' }}
                  components={[<strong />]}
                />
              </ToastContentBlock>
            ) : (
              ''
            )}

            <div className='inline-flex h-12 w-full flex-nowrap justify-end gap-2'>
              <div onClick={() => handleClose(true)}>
                <ButtonSecondary customWidth='w-[102px]'>
                  {t('common.cancel')}
                </ButtonSecondary>
              </div>
              <div onClick={handleUpdate}>
                <ButtonPrimary customWidth='w-[86px]'>
                  {t('common.save')}
                </ButtonPrimary>
              </div>
            </div>
          </div>
        </PopoverStyled>
      </div>
    </>
  );
};
