import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Provider as JotaiProvider } from 'jotai';
import { Suspense } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Route, Routes, useLocation } from 'react-router-dom';
import { Toaster } from 'sonner';

import { useTranslation } from 'react-i18next';
import {
  ImportCartsModal,
  Layout,
  LoadingCartFullPage,
  RequireAuth,
} from './components';
import { useConvertLocalCartsToCookieCarts, useI18nQueryParam } from './hooks';
import { AuthProvider } from './providers';
import { ScrollToAnchor } from './utils';
import * as Views from './Views';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export default function App() {
  const location = useLocation();
  const { t, i18n } = useTranslation();

  const lng = i18n.resolvedLanguage;

  useI18nQueryParam();
  useConvertLocalCartsToCookieCarts();

  return (
    <HelmetProvider>
      <Helmet prioritizeSeoTags htmlAttributes={{ lang: lng }}>
        <link
          rel='canonical'
          href={`https://share-a-cart.com${location.pathname}${lng && lng !== 'en' ? `?lng=${lng}` : ''}`}
        />
        <link
          rel='alternate'
          hrefLang='en'
          href={`https://share-a-cart.com${location.pathname}`}
        />
        <link
          rel='alternate'
          hrefLang='es'
          href={`https://share-a-cart.com${location.pathname}?lng=es`}
        />
        <link
          rel='alternate'
          hrefLang='de'
          href={`https://share-a-cart.com${location.pathname}?lng=de`}
        />
        <link
          rel='alternate'
          hrefLang='fr'
          href={`https://share-a-cart.com${location.pathname}?lng=fr`}
        />
        <link
          rel='alternate'
          hrefLang='pt'
          href={`https://share-a-cart.com${location.pathname}?lng=pt`}
        />
        <link
          rel='alternate'
          hrefLang='x-default'
          href={`https://share-a-cart.com${location.pathname}`}
        />
      </Helmet>
      <Suspense fallback={<LoadingCartFullPage />}>
        <QueryClientProvider client={queryClient}>
          <JotaiProvider>
            <AuthProvider>
              <main className='flex flex-col'>
                <Routes>
                  {/* we don't use navbar or footer here */}
                  <Route path='/embed/:cartId?' element={<Views.GetEmbed />} />
                  {/* 
              Login has no Footer and a different Header
              component than the other routes
            */}
                  <Route
                    path='/login'
                    element={
                      <>
                        <Helmet>
                          <title>
                            {t('common.amazingly_simple_list_sharing_sac')}
                          </title>
                        </Helmet>
                        <Views.Login />
                      </>
                    }
                  />
                  <Route path='/' element={<Layout />}>
                    <Route path='/get/:cartId?' element={<Views.Get />} />
                    <Route
                      path='/plus/cart-history'
                      element={
                        <>
                          <Helmet>
                            <title>Share-A-Cart+</title>
                          </Helmet>
                          <RequireAuth>
                            <Views.CartHistory />
                          </RequireAuth>
                        </>
                      }
                    />
                    <Route
                      path='/plus/account-details'
                      element={
                        <>
                          <Helmet>
                            <title>
                              {t('common.amazingly_simple_list_sharing_sac')}
                            </title>
                          </Helmet>
                          <RequireAuth>
                            <Views.AccountDetails />
                          </RequireAuth>
                        </>
                      }
                    />

                    {/* implemented but rendering Coming Soon for now */}
                    <Route
                      path='/plus/upgrade'
                      element={<Views.ComingSoon />}
                    />
                    {/* <Route path='/plus/upgrade' element={<ComingSoon />} /> */}
                    {/* <Route path='/plus/upgrade-success' element={<UpgradeSuccess />} /> */}
                    <Route
                      path='/plus/upgrade-success'
                      element={<Views.ComingSoon />}
                    />
                    {/* <Route path='/plus/subscribe' element={<Subscribe />} /> */}
                    <Route
                      path='/plus/subscribe'
                      element={<Views.ComingSoon />}
                    />

                    {/* Not yet implemented */}
                    <Route
                      path='/plus/profile'
                      element={<Views.ComingSoon />}
                    />
                    <Route
                      path='/plus/use-cases'
                      element={<Views.ComingSoon />}
                    />
                    <Route
                      path='/plus/manage-subscription'
                      element={<Views.ComingSoon />}
                    />
                    <Route
                      path='/plus/coming-soon'
                      element={<Views.ComingSoon />}
                    />

                    <Route
                      path='/changelog'
                      element={
                        <>
                          <Helmet>
                            <title>
                              {t('common.amazingly_simple_list_sharing_sac')}
                            </title>
                          </Helmet>
                          <Views.Changelog />
                        </>
                      }
                    />

                    {/* Redirect to /plus/cart-history */}
                    <Route path='/cart-history' element={<Views.Home />} />
                  </Route>
                </Routes>

                <ImportCartsModal />

                <ScrollToAnchor />

                <Toaster
                  position='top-right'
                  toastOptions={{
                    classNames: {
                      error: 'bg-errorRedLight text-errorRedDark',
                      success: 'bg-[#7CFDD7] text-[#207259]',
                      warning: 'bg-[#FFE9AF] text-[#78683D]',
                      info: 'bg-skyBlue text-[#2E4B93]',
                      toast: 'bg-skyBlue rounded-lg w-full',
                      title:
                        'text-[#2E4B93] font-primary text-sm font-semibold',
                      description: 'text-[#2E4B93] font-secondary text-xs',
                    },
                  }}
                />
              </main>
            </AuthProvider>
          </JotaiProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </Suspense>
    </HelmetProvider>
  );
}
