import { useMutation } from '@tanstack/react-query';
import type { TReviewFormState } from '../types';
import {
  HappyFeedbackForm,
  SadFeedbackForm,
} from '../components/get/getFeedbackBlocks';
import { useSearchParams } from 'react-router-dom';
import { useGetFeedback } from '.';

export const useFeedbackReviewMutation = (
  setReviewFormState: React.Dispatch<React.SetStateAction<TReviewFormState>>,
) => {
  const [searchParams] = useSearchParams();
  const { hideReviewCTA } = useGetFeedback();

  return useMutation({
    mutationFn: async (
      data: HappyFeedbackForm | SadFeedbackForm,
    ): Promise<boolean> => {
      const body = {
        ...data,
        browser: searchParams.get('browser'),
        extPresent: searchParams.get('extPresent'),
        vendor: searchParams.get('vendor'),
      };

      const res = await fetch(
        `${process.env.REACT_APP_LIVE_URL}/api/contact/get-feedback`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
          method: 'POST',
          body: JSON.stringify(body),
        },
      );

      if (!res.ok) {
        throw new Error('Error submitting review... res not ok...');
      }

      const { status } = await res;

      if (status !== 200) {
        throw new Error('Error updating cart... status...');
      }

      // return true to trigger the onSuccess below
      return true;
    },
    onSuccess: () => {
      hideReviewCTA('completed');
      setReviewFormState('thanks');
    },
  });
};
