type Props = {
  children: React.ReactNode;
  customHeight?: string;
  customWidth?: string;
  customStyles?: string;
  customPadding?: string;
  customFontSize?: string;
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
  onClick?: () => any;
};

export const ButtonSecondary = ({
  children,
  customHeight,
  customWidth,
  customStyles,
  customPadding,
  customFontSize,
  type,
  disabled = false,
  onClick,
}: Props) => {
  return (
    <button
      type={type ? type : 'button'}
      className={`${customStyles || ''} ${
        customHeight ? customHeight : 'h-full'
      } ${
        customWidth ? customWidth : 'w-full'
      } ${customFontSize ? customFontSize : 'text-base'} ${customPadding ? customPadding : 'px-6'} inline-flex cursor-pointer flex-nowrap items-center justify-center whitespace-nowrap rounded-full border border-primary-dark font-secondary font-bold transition-all hover:bg-gray-200 active:scale-95 disabled:transform-none disabled:opacity-20 disabled:hover:bg-transparent`}
      disabled={disabled}
      onClick={onClick || undefined}
    >
      {children}
    </button>
  );
};
