import { useRef, useState } from 'react';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { CustomDropdown } from '../reusableBlocks';
import { useAtom } from 'jotai';
import { historyFilterByAtom } from 'src/store';
import { useTranslation } from 'react-i18next';
import { FilterItems } from './filterAndSortBlocks';

export const FilterByDropdown = () => {
  const [filterBy] = useAtom(historyFilterByAtom);
  const [open, setOpen] = useState<boolean>(false);

  const anchorEl = useRef<HTMLDivElement>(null);

  const { t, i18n } = useTranslation();

  const handleClick = () => {
    setOpen((previousOpen) => !previousOpen);
  };

  const canBeOpen = open && Boolean(anchorEl);
  const id = canBeOpen ? 'transition-popper' : undefined;

  const i18nLang = i18n.resolvedLanguage
    ? i18n.resolvedLanguage.toLowerCase()
    : i18n.language.toLowerCase();

  return (
    <>
      <div
        className={`flex h-12 cursor-pointer items-center justify-between rounded bg-primary-off p-3 font-semibold transition-all hover:bg-primary-offHover ${
          open ? 'ring-2 ring-primary' : ''
        } ${
          i18nLang === 'en'
            ? 'w-[176px]'
            : i18nLang === 'es'
              ? 'w-[190px]'
              : i18nLang === 'ru'
                ? 'w-[242px]'
                : i18nLang === 'zh'
                  ? 'w-[160px]'
                  : 'w-[176px]'
        }`}
        aria-describedby={id}
        onClick={handleClick}
        ref={anchorEl}
      >
        <span className='truncate whitespace-nowrap text-cloudGray'>
          {t(`history.${filterBy}`)}
        </span>
        <KeyboardArrowUpIcon
          style={{
            fontSize: '28px',
            transform: open ? 'rotate(0deg)' : 'rotate(180deg)',
            transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1)',
          }}
          className='-mr-[7px] cursor-pointer text-blueGray'
        />
      </div>
      <CustomDropdown
        id={id}
        open={open}
        anchorEl={anchorEl.current}
        handleClick={handleClick}
        customStyle={{
          marginTop: '6px !important',
          marginRight: '-2px !important',
        }}
      >
        <div className='z-20 flex min-w-[200px] flex-col items-start justify-center rounded-lg bg-cloudGray font-secondary xs:min-w-[240px]'>
          <div className='flex flex-col items-start gap-2 py-4 pl-6 pr-4 font-semibold'>
            <FilterItems handleClick={handleClick} />
          </div>
        </div>
      </CustomDropdown>
    </>
  );
};
