type Args = {
  cartId: string;
  page: 'get' | 'embed';
};

export const sendCartToKroger = ({ cartId, page }: Args) => {
  const redirectHost = 'https://share-a-cart.com';
  // const redirectHost = 'http://localhost:3000';
  const redirectUrl = `${redirectHost}/api/kroger?cart=${cartId}`;

  const krogerClientId =
    'share-a-cart-a5b84a3d22e770507569795c9fc654656665518944428003157';
  const krogerUrl = 'https://api.kroger.com/v1/connect/oauth2/authorize';
  const krogerParams = `?scope=cart.basic:write&response_type=code&client_id=${krogerClientId}&redirect_uri=${encodeURIComponent(redirectUrl)}`;

  if (page === 'get') {
    window.location.href = `${krogerUrl}${krogerParams}`;
  } else {
    window.open(`${krogerUrl}${krogerParams}`, '_blank');
  }

  return;
};
