import { NavLink } from 'react-router-dom';
import { ReactComponent as ReceiveSvg } from '../../images/icons/receive.svg';
import { useTranslation } from 'react-i18next';

type Props = {
  forDropdown?: boolean;
  clickHandler?: () => void;
};

export const ReceiveACartBlock = ({ forDropdown, clickHandler }: Props) => {
  const { t } = useTranslation();

  return (
    <NavLink
      to='/get'
      onClick={clickHandler}
      className={`group inline-flex flex-row flex-nowrap items-center justify-start gap-2 whitespace-nowrap transition-all active:scale-[97%]
        ${
          forDropdown
            ? 'h-7 w-full rounded-md px-1 py-0.5 text-primary hover:bg-gray-300/60'
            : 'w-fit px-1 py-1 text-skyBlue hover:text-white'
        }
      `}
    >
      <ReceiveSvg
        width={forDropdown ? 18 : 20}
        title='Receive Cart Icon'
        className={`${
          forDropdown
            ? 'fill-primary pt-0.5'
            : 'fill-skyBlue group-hover:fill-white'
        } transition-all`}
      />
      {t('common.receive_a_cart')}
    </NavLink>
  );
};
