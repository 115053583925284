import { useTranslation } from 'react-i18next';

type Props = {
  show: boolean;
  value: string;
  changeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export const WelcomeModalOptionalTextInput = ({
  show,
  value,
  changeHandler,
}: Props) => {
  const { t } = useTranslation();

  if (!show) return null;

  return (
    <>
      <input
        type='text'
        value={value}
        onChange={changeHandler}
        placeholder={t('popup.please_specify')}
        className='h-12 w-full rounded border-none bg-offWhite px-3 py-2 font-secondary font-semibold leading-[16px] text-primary-dark outline-none transition-all selection:bg-primary selection:text-offWhite placeholder:font-normal placeholder:text-[#636E7A] hover:bg-gray-200 focus:ring-2 focus:ring-primary hover:focus:bg-offWhite disabled:cursor-text disabled:bg-gray-200'
        autoFocus
      />
    </>
  );
};
