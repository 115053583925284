import { useRef, useState } from 'react';

import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import { ReactComponent as HelpIcon } from '../../images/icons/help.svg';
import { ReactComponent as EditIcon } from '../../images/icons/edit-icon.svg';
import { ReactComponent as EmbedIcon } from '../../images/icons/embed.svg';
import { ReactComponent as LockIcon } from '../../images/icons/password.svg';
import { ReactComponent as ExportIcon } from '../../images/icons/export.svg';
import { CartGetItem } from '../../types';
import { exportCartToExcel } from '../../utils';
import { useTranslation } from 'react-i18next';
import { CustomDropdown } from './CustomDropdown';
import { useAtom } from 'jotai';
import { showEmbedModalAtom } from 'src/store';
import { useWindowSize } from 'src/hooks';

type Props = {
  cartId: string;
  isLoading: boolean;
  handleClose: (
    clear: boolean,
    ignoreSaveBeforeClose: boolean,
    callbackFn?: () => void,
  ) => void;
  cartCCY: string;
  cartCCYS: string;
  cartTitle: string | undefined;
  cartItems: CartGetItem[];
  cartTimestamp: number;
  cartStore: string | undefined;
};

export const FurtherActions = ({
  cartId,
  isLoading,
  handleClose,
  cartCCY,
  cartCCYS,
  cartTitle,
  cartItems,
  cartTimestamp,
  cartStore,
}: Props) => {
  const [open, setOpen] = useState<boolean>(false);
  const [, setShowEmbedModal] = useAtom<boolean>(showEmbedModalAtom);

  const { t } = useTranslation();

  const anchorEl = useRef<HTMLDivElement>(null);

  const screenWidth = useWindowSize().windowWidth;

  const handleClick = () => {
    setOpen((previousOpen) => !previousOpen);
  };

  const canBeOpen = open && Boolean(anchorEl);
  const id = canBeOpen ? 'transition-popper' : undefined;

  const actionItems = [
    {
      icon: HelpIcon,
      text: t('common.help'),
      callbackFn: () =>
        (window.location.href = `${process.env.REACT_APP_LIVE_URL}/guide/loading-a-list`),
      ignoreSaveBeforeClose: false,
      needToClose: true,
    },
    {
      icon: EditIcon,
      text: t('common.edit_items'),
      callbackFn: () =>
        (window.location.href = `${process.env.REACT_APP_LIVE_URL}/make/${cartId}`),
      ignoreSaveBeforeClose: false,
      needToClose: true,
    },
    {
      icon: ShareOutlinedIcon,
      text: t('common.share'),
      callbackFn: () =>
        (window.location.href = `${process.env.REACT_APP_LIVE_URL}/share/${cartId}`),
      ignoreSaveBeforeClose: false,
      needToClose: true,
    },
    {
      icon: EmbedIcon,
      text: t('common.embed'),
      callbackFn: () => setShowEmbedModal(true),
      ignoreSaveBeforeClose: false,
      needToClose: true,
    },
    {
      icon: LockIcon,
      text: t('common.password'),
      callbackFn: () =>
        (window.location.href = `${process.env.REACT_APP_LIVE_URL}/make/${cartId}#password`),
      ignoreSaveBeforeClose: false,
      needToClose: true,
    },
    {
      icon: ExportIcon,
      text: t('common.export'),
      callbackFn: () =>
        exportCartToExcel(
          cartId,
          cartCCY,
          cartCCYS,
          cartItems,
          cartTitle,
          cartTimestamp,
          cartStore,
        ),
      ignoreSaveBeforeClose: true,
      needToClose: false,
    },
  ];

  return (
    <>
      <div ref={anchorEl} className='w-full xs:w-fit'>
        <button
          disabled={isLoading}
          type='button'
          onClick={handleClick}
          className={`button-secondary flex h-12 w-full flex-row items-center justify-center gap-2 px-4 transition-all ${open ? 'bg-[#e5e7eb]' : ''}`}
        >
          <SettingsOutlinedIcon
            className='text-2xl text-primary'
            style={{
              fontSize: 24,
              transform: open ? 'rotate(45deg)' : 'rotate(0deg)',
              transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1)',
            }}
          />
          {t('common.further_actions')}
        </button>
      </div>

      <CustomDropdown
        id={id}
        open={open}
        anchorEl={anchorEl.current}
        handleClick={handleClick}
        customZIndex={1301}
        customPlacement={screenWidth >= 414 ? 'right-start' : 'top-end'}
        customTransformOrigin='bottom left'
        customStyle={{
          margin: '6px !important',
        }}
      >
        <ul className='flex w-fit list-none flex-col items-start justify-center gap-2 rounded-lg bg-cloudGray p-6'>
          {actionItems.map((item, index) => (
            <li
              key={index}
              onClick={() => {
                handleClick();

                if (item.needToClose) {
                  handleClose(
                    false,
                    item.ignoreSaveBeforeClose,
                    item.callbackFn,
                  );

                  return;
                }

                item.callbackFn();
              }}
              className='group flex w-full flex-row flex-nowrap items-center justify-start gap-2 hover:cursor-pointer'
            >
              <div className='fill-primary text-primary transition group-hover:fill-primary-dark group-hover:text-primary-dark'>
                <item.icon />
              </div>
              <span className='font-secondary text-base font-semibold leading-[25.6px] text-primary transition group-hover:text-primary-dark'>
                {item.text}
              </span>
            </li>
          ))}
        </ul>
      </CustomDropdown>
    </>
  );
};
