import { useQuery } from '@tanstack/react-query';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { getChangelog } from '../api';
import { SimpleSpinner } from '../components';
import { ChangelogList, ChangelogHeader } from '../components/changelog';
import { useTranslation } from 'react-i18next';
import { useScrollToTop } from 'src/hooks';

export const Changelog = () => {
  const { t } = useTranslation();

  useScrollToTop();

  const changelogsQuery = useQuery({
    queryKey: ['changelog'],
    queryFn: async () => getChangelog(),
    staleTime: 1000 * 60 * 60 * 24, // 1 day
    retry: 0,
  });

  return (
    <>
      <ChangelogHeader />
      {changelogsQuery.isLoading ? (
        <div className='mx-auto h-screen w-fit py-10'>
          <SimpleSpinner
            customWidth='w-16'
            customHeight='h-16'
            customPosition='block'
          />
        </div>
      ) : changelogsQuery.isError ? (
        <div className='mx-auto flex h-screen w-fit flex-col items-center justify-start gap-4 py-10'>
          <div className='fill-rose-500'>
            <HighlightOffIcon style={{ fontSize: '112px', fill: 'inherit' }} />
          </div>
          <h3 className='heading-200'>{t('common.something_went_wrong')}</h3>
        </div>
      ) : (
        <ChangelogList changelogs={changelogsQuery.data} />
      )}
    </>
  );
};
