import { useTranslation } from 'react-i18next';
import { ReactComponent as Icon } from 'src/images/icons/ext-upgrades-cart.svg';
import { Input } from 'src/components/reusableBlocks';
import { ChangelogFilterDropdown, ChangelogItemCreateOrEdit } from '.';
import { useEffect, useRef, useState } from 'react';
import { useAtom } from 'jotai';
import { changelogSearchByAtom } from 'src/store';
import { Link } from 'react-router-dom';
import { useUserIsAdmin, useWindowSize } from 'src/hooks';

export const ChangelogHeader = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [searchBy, setSearchBy] = useAtom(changelogSearchByAtom);

  const searchAndFilterContainerRef = useRef<HTMLDivElement>(null);
  const newRetailerButtonRef = useRef<HTMLAnchorElement>(null);

  const screenWidth = useWindowSize().windowWidth;

  const { t } = useTranslation();

  const userIsAdmin = useUserIsAdmin();

  // this is to make the 'Suggest New Retailer' button stick to the right side of the screen
  // while the search and filter container is always dead center of the screen.
  // It only does this if there is enough space, otherwise the Retailer button will be below
  // the search and filter container and sticky to the right side of the screen.
  useEffect(() => {
    if (searchAndFilterContainerRef.current && newRetailerButtonRef.current) {
      if (screenWidth >= 768) {
        const searchAndFilterContWidth =
          searchAndFilterContainerRef.current.clientWidth;
        const newRetailerBtn = newRetailerButtonRef.current;
        const screenPaddingRight = 24; // padding on the right side of the screen, same as p-6 on outermost div
        const gapBetweenElements = 48;

        if (
          (screenWidth - searchAndFilterContWidth) / 2 >
          newRetailerBtn.clientWidth + screenPaddingRight + gapBetweenElements
        ) {
          newRetailerBtn.style.position = 'absolute';
          newRetailerBtn.style.right = `${screenPaddingRight}px`;
        } else {
          newRetailerButtonRef.current.style.position = 'static';
          newRetailerButtonRef.current.style.marginLeft = 'auto';
        }
      }
    }
  }, [screenWidth]);

  return (
    <div className='flex w-full flex-col items-center justify-center gap-6 bg-primary-dark p-6 md:gap-10 lg:gap-12'>
      <div className='flex flex-col items-center justify-center gap-6'>
        <Icon className='h-[98px] w-[105px]' />
        <h1 className='heading-700 text-center text-offWhite'>
          {t('changelog.extension_updates')}
        </h1>
        <h2 className='text-300 w-fit max-w-3xl text-center text-blueGray'>
          {t('changelog.stay_up_to_date_on_the_latest_updates_to_sac_ext')}
        </h2>
      </div>
      <div className='flex w-full flex-col items-center justify-center gap-8'>
        <div
          ref={searchAndFilterContainerRef}
          className='relative flex w-full max-w-[320px] flex-col items-center justify-center gap-4 md:w-fit md:max-w-full md:flex-row md:gap-6'
        >
          <div className='group relative w-full md:w-[341px]'>
            <Input
              placeholder={t('changelog.search_updates')}
              textValue={searchBy}
              setTextValue={setSearchBy}
              showSearchIcon={true}
              showCancelIcon={true}
            />
          </div>
          <div className='w-full md:w-[280px]'>
            <ChangelogFilterDropdown />
          </div>
        </div>

        <Link
          to={`${process.env.REACT_APP_LIVE_URL}/surveys/suggest-a-retailer`}
          reloadDocument
          ref={newRetailerButtonRef}
          style={{
            marginLeft: 'auto',
          }}
          className='button-primary h-12'
        >
          {t('common.suggest_new_retailer')}
        </Link>
      </div>
      {userIsAdmin && (
        <>
          <button
            className='button-secondary h-10 bg-offWhite'
            onClick={() => setShowModal(true)}
          >
            Add New Log
          </button>
          <ChangelogItemCreateOrEdit open={showModal} setOpen={setShowModal} />
        </>
      )}
    </div>
  );
};
