import Popover, { PopoverProps, popoverClasses } from '@mui/material/Popover';
import { styled } from '@mui/material/styles';

export const CartTagsPopoverStyled = styled(
  ({ className, ...props }: PopoverProps) => (
    <Popover {...props} classes={{ root: className }} />
  ),
)(() => ({
  [`& .${popoverClasses.paper}`]: {
    borderRadius: 16,
    width: '90%',
    maxWidth: 480,
    minWidth: 294,
  },
}));
