type Props = {
  step: 1 | 2 | 3 | 4 | 5 | 6;
  children: React.ReactNode;
};

export const WelcomeModalTitle = ({ step, children }: Props) => {
  return (
    <h2 className={`-mt-4 text-center ${step === 1 ? 'heading-400' : 'heading-300'}`}>
      {children}
    </h2>
  );
};
