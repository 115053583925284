import {
  WelcomeModalStep1,
  WelcomeModalStep2,
  WelcomeModalStep3,
  WelcomeModalStep4,
  WelcomeModalStep5,
  WelcomeModalStep6,
} from '.';
import { WelcomeModalContinueButton } from '../WelcomeModalBlocks';

type Props = {
  step: 1 | 2 | 3 | 4 | 5 | 6;
  setStep: React.Dispatch<React.SetStateAction<1 | 2 | 3 | 4 | 5 | 6>>;
  disabledContinueBtn: boolean;
  handleClose: () => void;
};

export const WelcomeModalStepsDisplay = ({
  step,
  setStep,
  disabledContinueBtn,
  handleClose,
}: Props) => {
  return (
    <>
      {step === 1 ? (
        <WelcomeModalStep1 step={step} />
      ) : step === 2 ? (
        <WelcomeModalStep2 step={step} />
      ) : step === 3 ? (
        <WelcomeModalStep3 step={step} />
      ) : step === 4 ? (
        <WelcomeModalStep4 step={step} />
      ) : step === 5 ? (
        <WelcomeModalStep5 step={step} />
      ) : step === 6 ? (
        <WelcomeModalStep6 step={step} />
      ) : (
        <WelcomeModalStep1 step={step} />
      )}

      <WelcomeModalContinueButton
        step={step}
        setStep={setStep}
        disabled={disabledContinueBtn}
        handleClose={handleClose}
      />
    </>
  );
};
