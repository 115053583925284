import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { CustomDropdown } from '../reusableBlocks';
import { ChangelogFilterItems } from './ChangelogFilterItems';
import { useAtom } from 'jotai';
import { changelogFilterByAtom } from 'src/store';
import type { ChangelogFilterByValue } from 'src/types';

export const ChangelogFilterDropdown = () => {
  const [filterBy, setFilterBy] = useAtom(changelogFilterByAtom);
  const [dropdownText, setDropdownText] = useState<string>('All');
  const [open, setOpen] = useState<boolean>(false);

  const { t } = useTranslation();

  const anchorEl = useRef<HTMLDivElement>(null);

  const handleClick = () => {
    setOpen((previousOpen) => !previousOpen);
  };

  const canBeOpen = open && Boolean(anchorEl);
  const id = canBeOpen ? 'changelog-filter' : undefined;

  // if 'all' is selected, remove all other filters
  // if any other filter is selected, remove 'all' filter
  const handleUpdateFilterBy = (clickedFilter: ChangelogFilterByValue) => {
    if (clickedFilter === 'all') {
      handleClick();
      setFilterBy(['all']);
    } else {
      if (filterBy.includes('all')) {
        setFilterBy([clickedFilter]);
      } else {
        if (filterBy.includes(clickedFilter) && filterBy.length > 1) {
          setFilterBy(filterBy.filter((filter) => filter !== clickedFilter));
        } else if (filterBy.includes(clickedFilter) && filterBy.length === 1) {
          setFilterBy(['all']);
        } else {
          setFilterBy([...filterBy, clickedFilter]);
        }
      }
    }
  };

  useEffect(() => {
    const filtersTranslated = filterBy.map((filter) =>
      t(`changelog.tags.${filter}`),
    );

    setDropdownText(filtersTranslated.join(', '));
  }, [filterBy, t]);

  return (
    <div
      className={`flex h-12 cursor-pointer items-center justify-between rounded bg-primary-off p-3 font-semibold transition-all hover:bg-primary-offHover ${
        open ? 'ring-2 ring-primary' : ''
      }`}
      aria-describedby={id}
      onClick={handleClick}
      ref={anchorEl}
    >
      <span className='truncate whitespace-nowrap text-cloudGray'>
        {dropdownText}
      </span>
      <KeyboardArrowUpIcon
        style={{
          fontSize: '28px',
          transform: open ? 'rotate(0deg)' : 'rotate(180deg)',
          transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1)',
        }}
        className='-mr-[7px] cursor-pointer text-blueGray'
      />
      <CustomDropdown
        id={id}
        open={open}
        anchorEl={anchorEl.current}
        handleClick={handleClick}
        customStyle={{
          marginTop: '6px !important',
          marginRight: '-2px !important',
          width: `${anchorEl.current && anchorEl.current.clientWidth ? anchorEl.current.clientWidth + 4 : 0}px !important`,
        }}
      >
        <div className='z-20 flex w-full flex-col items-start justify-center rounded-lg bg-cloudGray font-secondary'>
          <div className='flex w-full flex-col items-start gap-2 py-4 pl-6 pr-4 font-semibold'>
            <ChangelogFilterItems
              handleUpdateFilterBy={handleUpdateFilterBy}
              filterBy={filterBy}
              showAllTag={true}
            />
          </div>
        </div>
      </CustomDropdown>
    </div>
  );
};
