import { forwardRef } from 'react';
import { CartGetItem } from '../../../types';
import { ListActions } from '../../reusableBlocks';
import { ContinueTotalBlock } from '../reuseables';
import { LottieRefCurrentProps } from 'lottie-react';
import { useAtom } from 'jotai';
import { getCartIdAtom } from '../../../store';

type Props = {
  handleContinue: () => void;
  ccy: string;
  ccyS: string;
  cartPrice: string;
  totalQty: number;
  cartTitle: string | undefined;
  cartItems: CartGetItem[];
  cartTimestamp: number;
  cartStore: string | undefined;
  isLoading: boolean;
  page: 'get' | 'embed';
};

export const GetReviewFooter = forwardRef<LottieRefCurrentProps, Props>(
  (
    {
      handleContinue,
      ccyS,
      ccy,
      cartPrice,
      totalQty,
      cartTitle,
      cartItems,
      cartTimestamp,
      cartStore,
      isLoading,
      page,
    },
    ref,
  ) => {
    const [cartId] = useAtom(getCartIdAtom);

    return (
      <div className='flex flex-row flex-wrap items-end justify-between gap-y-8 p-6 pb-0 pt-8 xs:gap-y-10 smMed:gap-x-4 sm:gap-y-8 md:px-10 medLg:flex-nowrap medLg:items-start lg:px-12'>
        {page === 'get' && (
          <ListActions
            cartId={cartId}
            cartCCY={ccy}
            cartCCYS={ccyS}
            cartTitle={cartTitle}
            cartItems={cartItems}
            cartTimestamp={cartTimestamp}
            cartStore={cartStore}
            isLoading={isLoading}
          />
        )}
        <ContinueTotalBlock
          ref={ref}
          handleContinue={handleContinue}
          totalPrice={cartPrice}
          totalQty={totalQty}
          forReviewFooter={true}
          isLoading={isLoading}
        />
      </div>
    );
  },
);
