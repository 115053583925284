import dayjs from 'dayjs';
import { useAtom } from 'jotai';
import { isMobile } from 'react-device-detect';
import { getCartIdAtom } from '../store';

type ReviewCTAState =
  | {
      completed: boolean;
      shownAt?: number;
    }
  | undefined;

export const useGetFeedback = () => {
  const [cartId] = useAtom(getCartIdAtom);
  /**
   *
   * @param status
   *  'completed'  : If the user completed the review form, set to 'completed' so we don't show it again.
   *  'interacted' : If the user interacted (clicked 'yes' or 'no' on the form) with the review form but didn't complete it, set to 'interacted' so we don't show it again for 30 days.
   * @returns
   */
  const hideReviewCTA = (status: 'completed' | 'interacted') => {
    const reviewState: ReviewCTAState = {
      completed: false,
    };

    if (status === 'completed') {
      reviewState.completed = true;
    } else if (status === 'interacted') {
      reviewState.shownAt = dayjs().add(30, 'day').valueOf();
    }

    localStorage.setItem('feedbackReview', JSON.stringify(reviewState));
  };

  const shouldReviewBeShown = (): boolean => {
    // https://github.com/duskload/react-device-detect/blob/HEAD/docs/selectors.md
    if (isMobile || cartId === 'AKGUX') {
      return false;
    }

    const reviewLocalStorageString = localStorage.getItem('feedbackReview');
    const reviewState: ReviewCTAState = reviewLocalStorageString
      ? JSON.parse(reviewLocalStorageString)
      : undefined;

    // User hasn't seen the form. Show it.
    if (!reviewState) {
      localStorage.setItem(
        'feedbackReview',
        JSON.stringify({ completed: false, shownAt: dayjs().valueOf() }),
      );

      return true;
    }

    // User has completed the review form. Don't show again
    if (reviewState.completed === true) {
      return false;
    }

    // By now, we know the user hasn't completed the form but they have seen it before.
    // We need to decide if we should show it now or not.
    // If the last time they saw it was MORE than 1 hour ago
    //      show and update the shownAt time to now
    // it was MORE than 0 minutes ago and LESS than 5 minutes ago
    //      show but don't update the shownAt time - we are in the 5 minute grace period
    if (reviewState.shownAt) {
      // more than 1 hour ago
      if (dayjs().diff(dayjs(reviewState.shownAt), 'hour', true) > 1) {
        localStorage.setItem(
          'feedbackReview',
          JSON.stringify({ completed: false, shownAt: dayjs().valueOf() }),
        );

        return true;
      } else if (
        // less than 5 seconds ago, more than 0 minutes ago
        dayjs().diff(dayjs(reviewState.shownAt), 'second', true) < 5 &&
        dayjs().diff(dayjs(reviewState.shownAt), 'minute', true) > 0
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      console.error('showReviewCTA() shownAt is missing from reviewState');

      localStorage.setItem(
        'feedbackReview',
        JSON.stringify({ completed: false, shownAt: dayjs().valueOf() }),
      );

      return true;
    }
  };

  return { shouldReviewBeShown, hideReviewCTA };
};
