/**
 * This page renders when we are at /get
 *
 * cartQuery:
 *    With 'enabled' set to false,
 *      the query will NOT run on page load.
 *      Query runs inside the 'handleGetCart()' function by calling 'refetch()'.
 *    With 'staleTime' set to 5 minute,
 *      the query will be marked 'stale' after 5 minutes, meaning the query data
 *      will live in the cache.
 *    With 'retry' set to 0,
 *      the query will not retry if it fails. It will just fail.
 *    With 'notifyOnChangeProps' set to an empty array,
 *      when 'refetch()' is called, it won't trigger a rerender of the this page.
 *      Essentially, fetching the data in the background.
 *      The data is never used on this page but we need to fetch it in this component
 *      so the Lottie animation can play.
 *
 * If this query is a success and the cart data is found, we redirect to the GetReview page.
 *    On the GetReview page, it will fetch the cart data again
 *    but it will just get the data from the cache, resulting in pre-loaded data
 *    that does not need to be retrieved from the server.
 * If this query is an error, we will show an error message,
 *    prompting the user to try again and not redirecting them.
 * If the user tries to visit a URL with a cart ID in the params,
 *    the query on the GetReview page will first look in the cache
 *    and if it's not there, it will fetch the data from the server.
 *    If the data is not found, it will redirect to the GetEmpty page,
 *    with some state attached to the redirect that we can destructure from
 *    the useLocation() hook.
 */

import { useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Lottie, { LottieRefCurrentProps } from 'lottie-react';
import { useAtom } from 'jotai';
import { getStepAtom } from '../../store';
import { useTranslation } from 'react-i18next';
import { useFocus, useGetCartAPI } from 'src/hooks';
import { useQuery } from '@tanstack/react-query';

const cartAnimation = require('../../images/lotties/cash-cart.json');

type Props = {
  page?: 'get' | 'embed';
};

export const GetEmpty = ({ page = 'get' }: Props) => {
  const [inputCartId, setInputCartId] = useState<string>('');
  const [animationFinished, setAnimationFinished] = useState<boolean>(false);
  const [fetchNow, setFetchNow] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [, setStep] = useAtom(getStepAtom);

  const nav = useNavigate();
  const loc = useLocation();
  const { t } = useTranslation();

  const getCartAPI = useGetCartAPI();
  const [inputRef, setFocus] = useFocus();

  const lottieRef = useRef<LottieRefCurrentProps | null>(null);

  const cartQuery = useQuery({
    queryKey: ['cart', inputCartId.toUpperCase()],
    queryFn: async () => getCartAPI(inputCartId),
    enabled: fetchNow,
    staleTime: 1000 * 60 * 5,
    retry: 0,
    notifyOnChangeProps: [],
  });

  const handleGetCart = () => {
    if (isError) {
      setIsError(false);
    }

    if (!inputCartId) {
      setIsError(true);
      return;
    }

    if (animationFinished) {
      setAnimationFinished(false);
    }

    // when we set 'fetchNow' to true, the query will run
    if (inputCartId.length <= 9) {
      setFetchNow(true);
    }
    lottieRef.current?.playSegments([56, 150]);

    setStep(2);
    // animation lasts ~2 seconds, so we set a timeout to set 'animationFinished' to true
    setTimeout(() => {
      if (inputCartId.length > 9) {
        window.history.replaceState(null, '', '/get');
        return (window.location.href = `https://share-a-cart.com/make/?vendor=amazon&keywords=${encodeURIComponent(inputCartId)}`);
      }
      setAnimationFinished(true);
      setFetchNow(false);
    }, 2000);
  };

  // Display error message AFTER animation finishes
  useEffect(() => {
    if (cartQuery.isError && animationFinished) {
      setAnimationFinished(false);
      setIsError(true);
      setStep(1);
      setFocus();
    }
  }, [animationFinished, cartQuery.isError, setFocus, setStep]);

  // We were redirected here from the GetReview page
  useEffect(() => {
    if (loc.state?.error) {
      setIsError(true);
      setInputCartId(loc.state?.cartId || '');
    }
  }, [loc.state]);

  // Redirect to GetReview page AFTER animation finishes
  useEffect(() => {
    if (animationFinished && !cartQuery.isError) {
      setAnimationFinished(false);
      nav(`/${page}/${inputCartId.trim().toUpperCase()}`);
    }
  }, [animationFinished, inputCartId, nav, cartQuery.isError, page]);

  return (
    <>
      {/* -mt-2 pushes lottie up so the animation looks like it's starting behind the blue bg */}
      <div className='-mt-2 flex w-full flex-col items-center justify-center gap-12 px-6 pb-24 text-center md:gap-16'>
        <div className='flex flex-col items-center justify-center gap-4'>
          <Lottie
            lottieRef={lottieRef}
            animationData={cartAnimation}
            loop={false}
            autoplay={true}
            initialSegment={[0, 56]}
            style={{ width: '128px', height: 'auto', paddingBlock: '8px' }}
          />
          <h1 className='heading-600 text-4xl md:text-[42px]'>
            {t('common.receive_a_cart')}
          </h1>
        </div>
        <div className='flex w-full flex-col items-center justify-center gap-8'>
          <div className='flex w-full max-w-xs flex-col items-center justify-center gap-x-6 gap-y-4 sm:max-w-full sm:flex-row'>
            <div className='relative h-12 w-full sm:w-auto'>
              <input
                ref={inputRef}
                disabled={fetchNow}
                autoFocus
                // on focus, remove the error message
                // onFocus={() => {
                //   if (isError) setIsError(false);
                // }}
                // on focus out, remove error message
                onBlur={() => {
                  if (isError) setIsError(false);
                }}
                onChange={(e) => {
                  setInputCartId(e.target.value.toUpperCase());
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    handleGetCart();
                  } else if (e.key === ' ') {
                    e.preventDefault();
                  }
                }}
                value={inputCartId}
                type='text'
                placeholder={t('get.enter_cart_id_here')}
                className={`flex h-12 w-full items-center rounded border-none bg-cloudGray p-2 pr-8 font-secondary text-base font-semibold uppercase text-primary-dark outline-none ring-2 transition-all selection:bg-primary selection:text-cloudGray placeholder:font-normal placeholder:normal-case placeholder:text-[#636E7A] hover:bg-gray-200 focus:bg-cloudGray hover:focus:bg-cloudGray sm:w-[320px] ${
                  isError
                    ? 'ring-rose-500 focus:ring-2 focus:ring-rose-500'
                    : 'ring-transparent focus:ring-2 focus:ring-primary'
                }`}
              />
              {isError ? (
                <span className='text-100 absolute bottom-[52px] left-0 right-0 whitespace-nowrap text-center text-rose-500 transition-all'>
                  {t('get.error_message')}
                </span>
              ) : (
                ''
              )}
            </div>
            <button
              onClick={handleGetCart}
              disabled={fetchNow}
              className='button-primary inline-block h-12 w-full sm:w-auto sm:min-w-[140px]'
            >
              {t('get.get_cart')}
            </button>
          </div>
          <div>
            <Link
              to={`${process.env.REACT_APP_LIVE_URL}/guide/loading-a-list`}
              reloadDocument
              className='font-secondary text-sm text-primary-dark underline transition-all hover:opacity-70'
            >
              {t('get.i_need_some_help')}
            </Link>
            &nbsp;&mdash;&nbsp;
            <span className='font-secondary text-sm text-primary-dark'>
              {t('get.see_example')}:&nbsp;
              <Link
                to='/get/AKGUX'
                className='underline transition-all hover:opacity-70'
              >
                AKGUX
              </Link>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};
