import ChromeLogo from '../../../../images/browsers/chrome.svg';
import FirefoxLogo from '../../../../images/browsers/firefox.svg';
import EdgeLogo from '../../../../images/browsers/edge.svg';
import SafariLogo from '../../../../images/browsers/safari.svg';

export type ExtensionsList = {
  name: 'Chrome' | 'Firefox' | 'Edge' | 'Safari';
  link: string;
  Icon: string;
  extId: string;
};

export const extensionsList: ExtensionsList[] = [
  {
    name: 'Chrome',
    link: 'https://chrome.google.com/webstore/detail/share-a-cart/hcjohblbkdgcoikaedjndgbcgcfoojmj',
    Icon: ChromeLogo,
    extId: 'hcjohblbkdgcoikaedjndgbcgcfoojmj', // chrome prod
  },
  {
    name: 'Edge',
    link: 'https://microsoftedge.microsoft.com/addons/detail/bgangcfogcfpakhljddkcjaaganbndeh',
    Icon: EdgeLogo,
    extId: 'bgangcfogcfpakhljddkcjaaganbndeh', // edge prod
  },
  {
    name: 'Firefox',
    link: 'https://addons.mozilla.org/en-US/firefox/addon/share-a-cart-for-everything/',
    Icon: FirefoxLogo,
    extId: '{c0257452-ac3b-4d2f-9b73-f556992f9bb7}',
  },
  {
    name: 'Safari',
    link: 'https://apps.apple.com/us/app/share-a-cart-for-everything/id1537641390',
    Icon: SafariLogo,
    extId: 'com.shareacart.Share-A-Cart-for-Everything.Extension (QZSD6Q9S74)',

  },
];
