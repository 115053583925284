import { CartGetItem } from '../../../types';

type Args = {
  cartItems: CartGetItem[];
};

export const sendCartToBestBuy = ({ cartItems }: Args) => {
  let cartUrl = '';
  let itemParams = '';

  for (let i = 0; i < cartItems.length; i++) {
    // TODO - functionality of selecting items on a per vendor basis is not supported at the moment
    // if ((handleSplitNewVendor || handleSplitOriginalVendor) && !cartItems[i].selected == true) {
    //   continue;
    // }

    for (let q = 1; q <= cartItems[i].quantity; q += 1) {
      itemParams += `${cartItems[i].asin}`;
      if (q !== cartItems[i].quantity) {
        itemParams += ',';
      }
    }

    if (i < cartItems.length - 1) {
      itemParams += ',';
    }
  }

  cartUrl = `https://api.bestbuy.com/click/-/${itemParams}/cart?IPID=1425298`;

  return `${encodeURIComponent(cartUrl)}`;
};
