import { useEffect, useState } from 'react';
import {
  ClickAwayListener,
  Grow,
  Popper,
  type PopperPlacementType,
} from '@mui/material';

/**
 * Paste below code in component you want to use in:
 // const [isOpen, setIsOpen] = useState(open);

  // const anchorEl = useRef<HTMLDivElement>(null);

  // const handleClick = () => {
  //   setIsOpen((previousOpen) => !previousOpen);
  // };

  // const canBeOpen = isOpen && Boolean(anchorEl);
  // const id = canBeOpen ? 'transition-popper' : undefined;
 */

type Props = {
  anchorEl: null | HTMLDivElement | Element;
  children: React.ReactNode;
  open: boolean;
  handleClick: () => void;
  id: string | undefined;
  customStyle?: object;
  customBorderRadius?: string;
  customTransformOrigin?: string;
  customZIndex?: number;
  customPlacement?: PopperPlacementType;
};

export const CustomDropdown = ({
  anchorEl,
  children,
  open,
  handleClick,
  id,
  customStyle,
  customBorderRadius,
  customTransformOrigin,
  customZIndex,
  customPlacement,
}: Props) => {
  const [canBeClosed, setCanBeClosed] = useState<boolean>(false);

  // without this, popper will close immediately after opening because of the ClickawayListener
  useEffect(() => {
    if (open) {
      setTimeout(() => {
        setCanBeClosed(true);
      }, 10);
      return;
    } else {
      setTimeout(() => {
        setCanBeClosed(false);
      }, 10);
    }
  }, [open]);

  return (
    <ClickAwayListener
      onClickAway={() => {
        if (open && canBeClosed) {
          return handleClick();
        }
      }}
    >
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement={customPlacement ? customPlacement : 'bottom-end'}
        role='menu'
        transition
        sx={{ zIndex: customZIndex ? customZIndex : 20, ...customStyle }}
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            timeout={350}
            style={{
              transformOrigin: customTransformOrigin
                ? customTransformOrigin
                : 'top right',
            }}
          >
            <div
              className={`custom-shadows ${
                customBorderRadius ? customBorderRadius : 'rounded-lg'
              }`}
            >
              {children}
            </div>
          </Grow>
        )}
      </Popper>
    </ClickAwayListener>
  );
};
