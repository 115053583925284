import { useAtom } from 'jotai';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getCartIdAtom, getStepAtom } from '../../store';
import { useTranslation } from 'react-i18next';

export const GetTopControls = () => {
  const [stepStatus] = useAtom(getStepAtom);
  const [cartId] = useAtom(getCartIdAtom);
  const [prevStep, setPrevStep] = useState<1 | 2 | 3 | 4>(1);

  const { t, i18n } = useTranslation();

  useEffect(() => {
    setPrevStep(stepStatus);
  }, [stepStatus]);

  if (stepStatus === 4) {
    return null;
  }

  return (
    <div className='relative flex w-full flex-col items-center justify-center gap-y-4 bg-primary-dark px-2 pb-12 pt-16 font-secondary text-sm font-normal'>
      <div className='flex w-full max-w-[380px] flex-row items-center justify-evenly gap-x-8'>
        <Link
          to='/get'
          className={`duration-[225ms] grow basis-0 cursor-pointer text-balance text-center transition-all ${
            stepStatus === 1 ? 'text-offWhite' : 'text-blueGray'
          } ${i18n.resolvedLanguage === 'de' ? 'whitespace-pre-wrap break-all' : ''}`}
        >
          {/* Enter<span className='block xs:hidden'>{'\n'}</span> Cart ID */}
          {t('get.step_one')}
        </Link>
        <Link
          to={`/get/${cartId.toUpperCase()}`}
          className={`duration-[225ms] grow basis-0 text-balance text-center transition-all ${
            stepStatus === 2 ? 'text-offWhite' : 'text-blueGray'
          } ${stepStatus >= 2 ? 'cursor-pointer' : 'cursor-events-none cursor-default'} ${i18n.resolvedLanguage === 'de' ? 'whitespace-pre-wrap break-all' : ''}`}
        >
          {/* Review<span className='block xs:hidden'>{'\n'}</span> Cart */}
          {t('get.step_two')}
        </Link>
        <span
          className={`duration-[225ms] grow basis-0 text-balance text-center transition-all ${
            stepStatus === 3
              ? 'cursor-pointer text-offWhite'
              : 'cursor-default text-blueGray'
          } ${i18n.resolvedLanguage === 'de' ? 'whitespace-pre-wrap break-all' : ''}`}
        >
          {/* Go & Buy<span className='block xs:hidden'>{'\n'}</span> Items */}
          {t('get.step_three')}
        </span>
      </div>

      {/* Dots & Lines */}
      <div className='inline-flex w-full items-center justify-center px-[11%] xs:max-w-[290px] xs:p-0'>
        <Link
          to='/get'
          id='step-1-circle'
          aria-label='Step 1, Input Cart Id'
          className='mx-auto inline-block h-4 w-4 min-w-[16px] cursor-pointer rounded-full bg-[#26CA99] text-center'
        ></Link>
        <div
          id='step-1-to-2-line'
          className={`custom-loader inline-block h-1 w-full transition-all duration-150 ${
            stepStatus >= 2 ? 'custom-loader-fill' : 'delay-75'
          }`}
        ></div>

        <Link
          to={`/get/${cartId.toUpperCase()}`}
          id='step-2-circle-green'
          aria-label='Step 2, Review Cart'
          className={`custom-loader mx-auto inline-block h-4 w-4 min-w-[16px] rounded-full transition-all duration-75 ${
            stepStatus >= 2
              ? 'custom-loader-fill delay-150'
              : 'pointer-events-none'
          }`}
        ></Link>
        <div
          id='step-2-to-3-line'
          aria-label='Step 3, Compare Cart Items'
          className={`custom-loader inline-block h-1 w-full transition-all duration-150 ${
            stepStatus === 3 ? 'custom-loader-fill' : ''
          } ${prevStep === 3 ? 'delay-75' : ''}`}
        ></div>

        <div
          id='step-3-circle'
          className={`custom-loader mx-auto inline-block h-4 w-4 min-w-[16px] rounded-full transition-all duration-75 ${
            stepStatus === 3 ? 'custom-loader-fill delay-150' : ''
          } ${prevStep === 2 ? '' : ''}`}
        ></div>
      </div>
    </div>
  );
};
