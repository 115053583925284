import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'sonner';
import { ToastStyled } from 'src/components';
import { Changelog } from 'src/types';

export const useDeleteChangelog = ({
  logIndex,
  setOpen,
}: {
  logIndex: number;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (logId: number): Promise<boolean> => {
      const res = await fetch(
        `${process.env.REACT_APP_LIVE_URL}/api/changelog/ext/issues/delete/${logIndex}`,
        {
          credentials: 'include',
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ id: logId }),
        },
      );

      if (!res.ok) {
        throw new Error('Error deleting changelog... res not ok...');
      }

      const { status } = await res;

      if (status !== 200) {
        throw new Error('Error deleting changelog... status...');
      }

      return true;
    },
    onSuccess: (mutationReturn, variables) => {
      queryClient.setQueryData(['changelog'], (oldData: Changelog[]) => {
        const newLogs = oldData.filter((log) => log.id !== variables);

        return newLogs;
      });

      setOpen(false);

      toast.custom((t) => (
        <ToastStyled variant='success' handleClose={() => toast.dismiss(t)}>
          Changelog deleted successfully!
        </ToastStyled>
      ));
    },
    onError: () => {
      toast.custom((t) => (
        <ToastStyled variant='error' handleClose={() => toast.dismiss(t)}>
          Something went wrong while deleting the changelog...
        </ToastStyled>
      ));
    },
  });
};
