import { CartGetItem } from 'src/types';

type Args = {
  cartItems: CartGetItem[];
  cartStore: string;
};

export const sendCartToNewegg = ({ cartItems, cartStore }: Args): string => {
  // https://secure.newegg.com/api/shop/add?Submit=ADD&ItemList=
  const addToCartParams = '/Shopping/AddToCart.aspx?Submit=ADD&ItemList=';
  // const addToCartParams = '/api/shop/add?Submit=ADD&ItemList=';

  let cartUrl = cartStore.replace('www', 'secure'); // store may be .com or .ca
  let itemParams: string[] = [];

  cartItems.forEach((item) => itemParams.push(`${item.asin}|${item.quantity}`));

  const link = `${cartUrl}${addToCartParams}${itemParams.join(',')}`;

  return encodeURIComponent(link);
};
