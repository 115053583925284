import CelebrationOutlinedIcon from '@mui/icons-material/CelebrationOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { Grow } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { useAtom } from 'jotai';
import { forwardRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useContinueWithItems } from 'src/hooks';
import { getCartIdAtom, getStepAtom } from 'src/store';
import { CartGetData } from 'src/types';
import { DialogStyled } from '../DialogStyled';

type Props = {
  cartQueryData: CartGetData;
  page: 'get' | 'embed';
};

export const ExtensionInstalledModal = ({ cartQueryData, page }: Props) => {
  const [show, setShow] = useState<boolean>(false);
  const [cartId] = useAtom(getCartIdAtom);
  const [step] = useAtom(getStepAtom);

  let [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const sendItems = useContinueWithItems();

  const { t } = useTranslation();

  const handleSendItems = () => {
    sendItems({
      id: cartId,
      cart: cartQueryData,
      handleSplitNewVendor: false,
      handleSplitOriginalVendor: false,
      page,
    });

    setShow(false);
  };

  useEffect(() => {
    if (searchParams.has('ext-installed')) {
      setShow(true);
      searchParams.delete('ext-installed');

      navigate({ search: searchParams.toString() }, { replace: true });
    }
  }, [navigate, searchParams, setSearchParams, setShow]);

  return (
    <DialogStyled
      open={show}
      onClose={() => setShow(false)}
      TransitionComponent={Transition}
      aria-describedby='Thanks for installing the Share-A-Cart Extension!'
    >
      <div
        onClick={() => setShow(false)}
        className='absolute right-3 top-2.5 cursor-pointer fill-darkSteel hover:fill-gray-400'
      >
        <CloseIcon
          className='transition-all'
          style={{ fontSize: '24px', fill: 'inherit' }}
        />
      </div>
      <div className='flex flex-col items-center justify-center gap-4 p-4 sm:py-6 md:gap-6 md:px-6 md:py-8'>
        <div className='flex flex-col items-center justify-center gap-2 md:gap-3'>
          <div className='flex h-16 w-16 items-center justify-center rounded-full bg-green-200'>
            <CelebrationOutlinedIcon
              style={{ fontSize: 40 }}
              className='text-green-600'
            />
          </div>

          <h3 className='heading-400 text-balance text-center'>
            {t('get.thanks_for_installing_the_sac_ext')}
          </h3>
        </div>

        <div className='flex w-full flex-col flex-nowrap items-center gap-4 sm:flex-row-reverse'>
          {/* if the user is on the compare page, only allow them to close the modal */}
          {step !== 3 && (
            <button
              onClick={handleSendItems}
              className='button-primary h-12 w-full sm:w-auto'
            >
              {t('get.send_items')}
            </button>
          )}
          <button
            onClick={() => setShow(false)}
            className='button-secondary h-12 w-full sm:w-auto'
          >
            {t('common.close')}
          </button>
        </div>
      </div>
    </DialogStyled>
  );
};

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Grow ref={ref} {...props} />;
});
