import { Fragment } from 'react';
import type { CartGetItem } from '../../../types';
import { GetCartItem } from './GetCartItem';

type Props = {
  cartItems: CartGetItem[];
  cartCCY: string;
  cartCCYS: string;
  cartVendor: string;
  cartLocale: string;
};

export const GetCartItemsList = ({
  cartItems,
  cartCCY,
  cartCCYS,
  cartVendor,
  cartLocale,
}: Props) => {
  return (
    <ul className='flex w-full flex-col items-center justify-center'>
      {cartItems.map((item, i) => (
        <Fragment key={`${item.asin},${i}` || i}>
          <GetCartItem
            item={item}
            ccy={cartCCY}
            ccys={cartCCYS}
            vendor={cartVendor}
            compare={false}
            locale={cartLocale}
          />
        </Fragment>
      ))}
    </ul>
  );
};
