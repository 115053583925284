import type { CartHistoryData } from '../../types';
import { SimpleImportCartsText, TooltipStyled } from '../reusableBlocks';
import {
  CartsDisplayEmpty,
  CartsDisplayGrid,
  CartsDisplayList,
} from './cartDisplayBlocks';
import { Link } from 'react-router-dom';
import { useAtom } from 'jotai';
import { authUserAtom } from '../../store';
import {
  FetchNextPageOptions,
  InfiniteQueryObserverResult,
  InfiniteData,
} from '@tanstack/query-core';
import { Trans, useTranslation } from 'react-i18next';
import { ReactComponent as SacPlusIcon } from '../../images/icons/sac-plus-fire-cart.svg';
import { ExtensionMissingModal } from '../reusableBlocks/modals';

type Props = {
  cartsList:
    | {
        carts: CartHistoryData[];
        pageParam: number;
      }[]
    | undefined;
  displayedCartsLength: number;
  isListView: boolean;
  queryError: boolean;
  loadMoreCarts: (options?: FetchNextPageOptions | undefined) => Promise<
    InfiniteQueryObserverResult<
      InfiniteData<
        {
          carts: CartHistoryData[];
          pageParam: number;
        },
        unknown
      >,
      Error
    >
  >;
  fetchingNextPage: boolean;
  hasNextPage: boolean;
};

export const CartsDisplay = ({
  cartsList,
  displayedCartsLength,
  isListView,
  queryError,
  loadMoreCarts,
  fetchingNextPage,
  hasNextPage,
}: Props) => {
  const [user] = useAtom(authUserAtom);

  const { t } = useTranslation();

  return (
    <div className='w-full bg-white font-primary text-primary-dark'>
      {cartsList && displayedCartsLength !== 0 && !queryError ? (
        <>
          {isListView ? (
            <CartsDisplayList
              cartsList={cartsList}
              fetchingNextPage={fetchingNextPage}
            />
          ) : (
            <CartsDisplayGrid
              cartsList={cartsList}
              fetchingNextPage={fetchingNextPage}
            />
          )}
          <div className='flex w-full flex-col items-center justify-center gap-2 gap-y-4 border-b border-blueGray p-6 sm:flex-row sm:justify-between md:flex-nowrap'>
            {!user ||
            !user.subscription ||
            user!.subscription?.level === 'free' ? (
              <>
                <p className='text-center font-secondary text-base font-normal'>
                  <Trans
                    i18nKey={t('history.showing_x_of_y_carts', {
                      count: user!.carts.length,
                      displayedCartsLength,
                    })}
                    components={[<strong />]}
                  />
                </p>
                <div className='flex flex-col flex-nowrap items-center justify-center gap-2 md:flex-row md:gap-4'>
                  <span className='text-center font-primary text-lg font-semibold xs:text-xl'>
                    {t('history.access_your_full_cart_history')}
                  </span>
                  <div className='inline-flex flex-nowrap items-center justify-center'>
                    <Link to='/plus/upgrade'>
                      <button className='button-primary h-12'>
                        <span className='font-secondary text-base font-bold'>
                          {t('history.upgrade_now')}
                        </span>
                      </button>
                    </Link>
                    <div className='flex h-12 w-[75px] items-center justify-center overflow-visible'>
                      <SacPlusIcon
                        title='Full cart with fire emoji'
                        className='h-auto w-[75px]'
                      />
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className='flex flex-col items-center justify-center gap-x-3 gap-y-2 sm:flex-row'>
                  <p className='text-200 text-balance text-center'>
                    <Trans
                      i18nKey={t('history.showing_x_of_y_carts', {
                        count: user!.carts.length,
                        displayedCartsLength,
                      })}
                      components={[<strong />]}
                    />
                  </p>
                  <TooltipStyled
                    title={t('history.no_more_carts_to_display')}
                    placement='top'
                    disableHoverListener={hasNextPage || fetchingNextPage}
                    disableTouchListener={hasNextPage || fetchingNextPage}
                  >
                    <span onClick={() => loadMoreCarts()}>
                      <button
                        className='button-secondary h-12 w-full max-w-fit'
                        disabled={
                          !hasNextPage ||
                          fetchingNextPage ||
                          displayedCartsLength === user!.carts?.length
                        }
                      >
                        {t('history.load_more_carts')}
                      </button>
                    </span>
                  </TooltipStyled>
                  <SimpleImportCartsText />
                </div>
                {/* <div className='flex flex-col items-center justify-center gap-x-4 gap-y-2 md:flex-row'>
                  <p className='text-center font-secondary text-base font-normal'>
                    Need more users?
                  </p>
                  <p className='text-center font-primary text-base font-semibold'>
                    Upgrade to&nbsp;
                    <Link
                      to='/plus/upgrade'
                      className='text-primary transition-all hover:text-primary-dark'
                    >
                      Organization+
                    </Link>
                  </p>
                </div> */}
              </>
            )}
          </div>
          <ExtensionMissingModal forImport={true} />
        </>
      ) : (
        <CartsDisplayEmpty
          displayedCartsLength={displayedCartsLength}
          queryError={queryError}
        />
      )}
    </div>
  );
};
