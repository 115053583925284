import { useState } from 'react';
import { predefinedTags, randomTagColorGenerator } from '../../../../utils';

type Props = {
  tag: string;
  handleOpen?: () => void;
  addGlobalTag?: (tag: string) => void;
};

export const BaseCartTag = ({ tag, handleOpen, addGlobalTag }: Props) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);

  let lightColor, darkColor;
  const lowercaseTag = tag.toLowerCase();

  if (
    predefinedTags.findIndex((predefinedTag) =>
      lowercaseTag.includes(predefinedTag.toLowerCase()),
    ) === -1
  ) {
    [lightColor, darkColor] = randomTagColorGenerator(lowercaseTag);
  }

  const handleClickGlobalTag = (e: React.MouseEvent) => {
    const tag = e.currentTarget.id;

    addGlobalTag && addGlobalTag(tag);
  };

  return (
    <li
      id={tag}
      onClick={
        handleOpen
          ? handleOpen
          : addGlobalTag
            ? handleClickGlobalTag
            : undefined
      }
      {...(lightColor
        ? {
            style: {
              background: lightColor,
              color: darkColor,
              fill: darkColor,
              ...(isHovered && { border: `1px solid ${darkColor}` }),
            },
            onMouseEnter: () => setIsHovered(true),
            onMouseLeave: () => setIsHovered(false),
          }
        : {})}
      className={`flex h-[32px] min-h-[30px] max-w-[160px] items-center justify-center truncate whitespace-nowrap rounded border border-transparent px-4 py-1 font-semibold transition-all hover:cursor-pointer xs:max-w-[240px] ${
        lowercaseTag.includes('pending')
          ? 'bg-skyBlue text-[#425FA9] hover:border-[#425FA9]'
          : lowercaseTag.includes('purchased')
            ? 'bg-[#7CFDD7] text-[#207259] hover:border-[#207259]'
            : lowercaseTag.includes('received')
              ? 'bg-[#9cf2fc] text-[#046f7b] hover:border-[#046f7b]'
              : lowercaseTag.includes('completed')
                ? 'bg-[#78edb7] text-[#0f7044] hover:border-[#0f7044]'
                : lowercaseTag.includes('not sent')
                  ? 'bg-accentDuskyPink text-[#7D5D57] hover:border-[#7D5D57]'
                  : lowercaseTag.includes('cancelled')
                    ? 'bg-[#fd9ba7] text-[#7d0212] hover:border-[#7d0212]'
                    : lowercaseTag.includes('placed')
                      ? 'bg-[#FFE9AF] text-[#78683D] hover:border-[#78683D]'
                      : lowercaseTag.includes('ordered')
                        ? 'bg-[#9baefd] text-[#021b7d] hover:border-[#021b7d]'
                        : lowercaseTag.includes('shipped')
                          ? 'bg-[#f4cdfe] text-[#65037d] hover:border-[#65037d]'
                          : ''
      }`}
    >
      {tag}
    </li>
  );
};
