import {
  CheckCircleOutline,
  ErrorOutline,
  Info,
  WarningAmber,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

type Props = {
  children: React.ReactNode;
  toastTitle?: string;
  variant?: 'success' | 'error' | 'warning' | 'info';
};

export const ToastContentBlock = ({
  children,
  toastTitle,
  variant = 'success',
}: Props) => {
  const { t } = useTranslation();

  return (
    <div
      className={`flex w-full flex-col items-start justify-center gap-3 rounded-lg p-3 ${
        variant === 'success'
          ? 'bg-[#7CFDD7] text-[#207259]'
          : variant === 'error'
            ? 'bg-errorRedLight text-errorRedDark'
            : variant === 'warning'
              ? 'bg-[#FFE9AF] text-[#78683D]'
              : 'bg-skyBlue text-[#2E4B93]'
      }`}
    >
      <div className='inline-flex flex-nowrap items-start justify-center gap-2'>
        {variant === 'success' ? (
          <CheckCircleOutline />
        ) : variant === 'error' ? (
          <WarningAmber />
        ) : variant === 'warning' ? (
          <ErrorOutline />
        ) : (
          <Info />
        )}
        <div className='flex flex-col items-start justify-center gap-1 pt-0.5'>
          <h4 className='font-primary text-sm font-semibold'>
            {toastTitle
              ? toastTitle
              : t('popup.toast_titles', { context: variant })}
          </h4>
          <p className='font-secondary text-xs'>{children}</p>
        </div>
      </div>
    </div>
  );
};
