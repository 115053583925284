import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useTranslation } from 'react-i18next';

type Props = {
  missingCount: number;
  cartItemsLength: number;
};

export const GetReviewMissingItems = ({
  missingCount,
  cartItemsLength,
}: Props) => {
  const { t } = useTranslation();

  if (missingCount < 2 || (missingCount / cartItemsLength) * 100 <= 30) {
    return null;
  }

  return (
    <div className='flex items-center justify-center'>
      <div className='m-2 flex max-w-[1240px] flex-col items-start justify-center gap-2 rounded-lg border-2 border-primary bg-[#F6F6FE] p-2 lg:flex-row lg:items-center lg:gap-10 lg:p-4 lgXl:gap-12'>
        <div className='inline-flex flex-nowrap items-center justify-center gap-2'>
          <InfoOutlinedIcon
            style={{ fill: '#3032DC' }}
            className='inline-block h-6 min-h-[24px] w-6 min-w-[24px] align-middle'
          />
          <h5 className='heading-100 whitespace-nowrap text-primary md:text-lg md:leading-[19.2px]'>
            {t('common.just_a_heads_up')}
          </h5>
        </div>
        <p className='text-200 text-sm leading-[22.4px] md:text-base md:leading-[25.6px]'>
          {t('common.missing_details')}
        </p>
      </div>
    </div>
  );
};
