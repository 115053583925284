import { SetStateAction, forwardRef, useRef, useState } from 'react';
import { ParseKeys } from 'i18next';
import SearchIcon from '@mui/icons-material/Search';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { cn } from 'src/utils';

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  placeholder: ParseKeys<string>;
  secondaryPlaceholder?: ParseKeys<string>;
  textValue: string;
  setTextValue: React.Dispatch<SetStateAction<string>>;
  showSearchIcon?: boolean;
  showCancelIcon?: boolean;
}

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className,
      placeholder,
      secondaryPlaceholder,
      textValue,
      setTextValue,
      showSearchIcon = true,
      showCancelIcon = false,
      ...props
    },
    ref,
  ) => {
    const [isFocused, setIsFocused] = useState<boolean>(false);
    const inputRef = useRef<HTMLInputElement>(null);

    return (
      <>
        <input
          ref={ref ?? inputRef}
          type='text'
          value={textValue}
          placeholder={placeholder}
          onChange={(e) => {
            setTextValue(e.target.value);
          }}
          onFocus={(e) => {
            setIsFocused(true);
            secondaryPlaceholder &&
              (e.target.placeholder = secondaryPlaceholder);
          }}
          onBlur={(e) => {
            setIsFocused(false);
            e.target.placeholder = placeholder;
          }}
          className={cn(
            'h-10 w-full rounded border-none bg-cloudGray py-2 pl-3 pr-8 font-secondary font-semibold leading-[24px] text-primary-dark outline-none transition-all selection:bg-primary selection:text-cloudGray placeholder:font-normal placeholder:text-[#636E7A] focus:ring-2 focus:ring-primary group-hover:bg-gray-300 group-hover:focus:bg-cloudGray xs:h-12',
          )}
          {...props}
        />
        {isFocused && showCancelIcon ? (
          <div
            // mousedown fires before the onBlur for the above input. So, it will clear in the input AND retain focus on the input.
            onMouseDown={(e) => {
              e.preventDefault();
              setTextValue('');
            }}
            className='absolute right-2 top-1.5 cursor-pointer fill-darkSteel hover:fill-gray-400 active:scale-[94%] xs:top-2.5'
          >
            <HighlightOffIcon
              className='transition-all'
              style={{ fontSize: '24px', fill: 'inherit' }}
            />
          </div>
        ) : (
          showSearchIcon && (
            <div
              onClick={() => inputRef.current?.focus()}
              className='absolute right-2 top-1.5 cursor-pointer fill-darkSteel hover:fill-gray-400 xs:top-2.5'
            >
              <SearchIcon
                className='transition-all'
                style={{ fontSize: '24px', fill: 'inherit' }}
              />
            </div>
          )
        )}
      </>
    );
  },
);
