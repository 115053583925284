import { useAtom } from 'jotai';
import { useParams, useSearchParams } from 'react-router-dom';
import { GetEmpty, GetReview } from 'src/components/get';
import { ExtensionLoadingModal } from 'src/components/reusableBlocks/modals';
import { useScrollToTop } from 'src/hooks';
import { useRedirectToUpperCase } from 'src/hooks';
import { getCartIdAtom } from 'src/store';

export const GetEmbed = () => {
  const [, setCartId] = useAtom(getCartIdAtom);
  const { cartId: cartIdParam } = useParams();
  let [searchParams] = useSearchParams();

  useScrollToTop();
  useRedirectToUpperCase({
    cartIdParam,
    setCartId,
    searchParams,
    pathname: 'embed',
  });

  return (
    <>
      {!cartIdParam ? (
        <GetEmpty page='embed' />
      ) : (
        <div className='bg-primary-dark pt-4'>
          <GetReview page='embed' />
        </div>
      )}

      <ExtensionLoadingModal />
    </>
  );
};
