import { CartGetItem } from '../../../types';

type Args = {
  cartItems: CartGetItem[];
  cartAp?: string;
};

export const sendCartToIkea = ({ cartItems, cartAp }: Args) => {
  let cartUrl = '';
  let itemParams = '';

  const ou = cartAp && cartAp === 'us/en' ? 'o' : 'ou';

  for (let i = 0; i < cartItems.length; i++) {
    // TODO - functionality of selecting items on a per vendor basis is not supported at the moment
    // if ((handleSplitNewVendor || handleSplitOriginalVendor) && !cartItems[i].selected == true) {
    //   continue;
    // }

    itemParams += `${cartItems[i].asin}:${cartItems[i].quantity}`;
    if (i < cartItems.length - 1) {
      itemParams += ',';
    }
  }

  cartUrl = `https://www.ikea.com/${cartAp}/fav${ou}rites/receive-share/${itemParams}`;

  return `${encodeURIComponent(cartUrl)}`;
};
