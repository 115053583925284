type Props = {
  totalPrice: string | number;
};

export const CartTotalPrice = ({ totalPrice }: Props) => {
  return (
    <span className='h-full w-full font-semibold text-primary-dark'>
      {totalPrice}
    </span>
  );
};
