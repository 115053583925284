import { useAtom } from 'jotai';
import { globalCartTagsAtom } from '../store';
import { predefinedTags } from '../utils';

export const useGlobalCartTags = () => {
  const [globalCartTags, setGlobalCartTags] = useAtom(globalCartTagsAtom);

  return (tagsArray: string[]) => {
    tagsArray.forEach((tag) => {
      const currGlobalTags = globalCartTags;

      // Check if tag exists in global cart tags (case insensitive, partial matches allowed)
      // Check if tag exists in predefined tags (case insensitive, partial matches allowed)
      if (
        currGlobalTags.findIndex((currTag) =>
          tag.toLowerCase().includes(currTag.toLowerCase()),
        ) === -1 &&
        predefinedTags.findIndex((preTag) =>
          tag.toLowerCase().includes(preTag.toLowerCase()),
        ) === -1
      ) {
        currGlobalTags.push(tag);
        currGlobalTags.sort();
      }

      setGlobalCartTags([...currGlobalTags]);
    });
  };
};
