import { NavbarSimple } from '../components';
import { Link, Navigate } from 'react-router-dom';
import { ReactComponent as SacPlusIcon } from '../images/icons/sac-plus-fire-cart.svg';
import { ReactComponent as GoogleLogo } from '../images/providers/google.svg';
import { ReactComponent as MicrosoftLogo } from '../images/providers/microsoft.svg';
// import { ReactComponent as AppleLogo } from '../images/providers/apple.svg';
// import { ReactComponent as FacebookLogo } from '../images/providers/facebook.svg';
import { ReactComponent as TwitterLogo } from '../images/providers/twitter.svg';
import { useAtom } from 'jotai';
import { authUserAtom } from '../store';
import { useScrollToTop } from '../hooks';
import { Trans, useTranslation } from 'react-i18next';

export function Login() {
  const [user] = useAtom(authUserAtom);

  const { t } = useTranslation();

  useScrollToTop();

  if (user) {
    return <Navigate to='/cart-history' replace />;
  }

  return (
    <div className='h-full min-h-screen overflow-hidden bg-primary-dark'>
      <NavbarSimple />
      <div className='flex w-full flex-col items-center justify-center pb-16 pt-4 xs:pt-6 sm:pt-8 md:pt-12'>
        <section className='flex w-fit min-w-80 max-w-[340px] flex-col items-center justify-center gap-12 font-secondary'>
          <div className='flex flex-col items-center justify-center gap-2'>
            <SacPlusIcon className='-ml-5 h-auto w-[160px]' />
            <h1 className='heading-500 text-offWhite'>
              Share-A-Cart<span className='text-primary'>+</span>
            </h1>
          </div>
          <div className='flex w-full flex-col items-center justify-center gap-6 px-6 text-base font-bold text-offWhite'>
            <a
              href={`${process.env.REACT_APP_LIVE_URL}/signin/google`}
              className='inline-flex h-10 w-[100%] flex-nowrap items-center justify-center gap-3 whitespace-nowrap rounded-full bg-[#EA4335] py-2 pl-4 pr-6 transition-all duration-150 hover:opacity-90 active:scale-[99%]'
            >
              <GoogleLogo
                title='Google Logo'
                className='h-6 min-h-6 w-6 min-w-6'
              />
              {t('login.continue_with_google')}
            </a>
            {/* <a
              href={`${process.env.REACT_APP_LIVE_URL}/signin/facebook`}
              className='inline-flex h-10 w-[100%] flex-nowrap items-center justify-center gap-3 whitespace-nowrap rounded-full bg-[#1877F2] py-2 pl-4 pr-6 transition-all duration-150 hover:opacity-90 active:scale-[99%]'
            >
              <FacebookLogo title='Facebook Logo' className='h-6 w-6 min-h-6 min-w-6' />
              Continue with Facebook
            </a> */}
            <a
              href={`${process.env.REACT_APP_LIVE_URL}/signin/twitter`}
              className='inline-flex h-10 w-[100%] flex-nowrap items-center justify-center gap-3 whitespace-nowrap rounded-full bg-[#1DA1F2] py-2 pl-4 pr-6 transition-all duration-150 hover:opacity-90 active:scale-[99%]'
            >
              <TwitterLogo
                title='Twitter Logo'
                className='h-6 min-h-6 w-6 min-w-6 fill-white'
              />
              {t('login.continue_with_twitter')}
            </a>
            <a
              href={`${process.env.REACT_APP_LIVE_URL}/signin/microsoft`}
              className='inline-flex h-10 w-[100%] flex-nowrap items-center justify-center gap-3 whitespace-nowrap rounded-full bg-[#2F2F2F] py-2 pl-4 pr-6 transition-all duration-150 hover:opacity-90 active:scale-[99%]'
            >
              <MicrosoftLogo
                title='Microsoft Logo'
                className='h-6 min-h-6 w-6 min-w-6'
              />
              {t('login.continue_with_microsoft')}
            </a>
            {/* <a
              href={`${process.env.REACT_APP_LIVE_URL}/signin/apple`}
              className='inline-flex h-10 w-[100%] flex-nowrap items-center justify-center gap-3 whitespace-nowrap rounded-full bg-black py-2 pl-4 pr-6 transition-all duration-150 hover:opacity-90 active:scale-[99%]'
            >
              <AppleLogo title='Apple Logo' className='h-6 w-6 min-h-6 min-w-6' />
              Continue with Apple
            </a> */}
          </div>
          <p className='px-2 text-center text-sm font-normal leading-6 text-blueGray'>
            <Trans
              i18nKey={t('login.by_logging_in_you_agree_to_the_following')}
              components={[
                <Link
                  to={`${process.env.REACT_APP_LIVE_URL}/terms-of-service`}
                  reloadDocument
                  className='font-semibold text-grassGreen underline hover:opacity-95'
                ></Link>,
                <Link
                  to={`${process.env.REACT_APP_LIVE_URL}/terms-of-service`}
                  reloadDocument
                  className='font-semibold text-grassGreen underline hover:opacity-95'
                ></Link>,
              ]}
            />
          </p>
        </section>
      </div>
    </div>
  );
}
