import { WelcomeModalTitle } from "../WelcomeModalBlocks";

type Props = {
  step: 1 | 2 | 3 | 4 | 5 | 6;
};

export const WelcomeModalStep6 = ({ step }: Props) => {
  return (
    <>
      <WelcomeModalTitle step={step}>
        You're All Set!
      </WelcomeModalTitle>
    </>
  )
}
