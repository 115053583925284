import type { CartHistoryData } from '../../../types';
import {
  CartCreatedOrReceived,
  CartId,
  CartTitle,
  CartTotalPrice,
  CartTotalQty,
  CartVendor,
} from './reusables';
import { SimpleSpinner } from '../../reusableBlocks';
import {
  CartDate,
  CartNotes,
  // CartPublic,
  CartTags,
} from '../../reusableBlocks/cartBlocks';

type Props = {
  cartsList:
    | {
        carts: CartHistoryData[];
        pageParam: number;
      }[]
    | undefined;
  fetchingNextPage: boolean;
};

/**
 * Broken down into smaller components to make for
 * easier view changing between list and grid view.
 * See ./reusables for the smaller components.
 */
export const CartsDisplayList = ({ cartsList, fetchingNextPage }: Props) => {
  return (
    <div className='overflow-y-hidden overflow-x-scroll md:overflow-x-hidden'>
      {cartsList!.map((group, i) => (
        <ul key={i}>
          {group.carts.map((cart) => (
            <li
              key={cart.cart.id}
              className='min-h-[82px] min-w-[768px] items-center justify-center border-b border-blueGray lgXl:min-h-[48px]'
            >
              <div className='grid-rows-auto mx-auto grid max-w-[1800px] grid-cols-12 items-center justify-center gap-x-2 px-6 py-2 lg:px-8'>
                <div className='col-span-7 row-start-1 inline-flex flex-nowrap items-center justify-start gap-3 lgXl:col-span-3'>
                  <div className='max-w-[26px]'>
                    <CartCreatedOrReceived created={cart.created} />
                  </div>
                  <div className='min-w-[200px] max-w-[240px] xs:max-w-[280px] lgXl:max-w-[100%]'>
                    <CartTitle
                      cartId={cart.cart.id}
                      cartTitle={cart.cart.title || ''}
                      cartCreated={cart.created}
                      forGetPage={false}
                      cartVendorDisplayName={cart.vendorDisplayName || ''}
                    />
                  </div>
                </div>
                <div className='col-span-5 row-start-1 flex flex-row flex-nowrap items-center justify-end gap-4 lgXl:col-span-2'>
                  <div className='min-w-[150px] text-right'>
                    <CartTotalPrice totalPrice={cart.formattedPrice || 0} />
                  </div>
                  <div className='min-w-[80px] text-right'>
                    <CartTotalQty
                      totalQty={cart.cart.cartTotalQty || 0}
                      cartId={cart.cart.id}
                    />
                  </div>
                </div>
                <div className='col-span-12 row-start-2 inline-flex flex-nowrap items-start justify-start gap-x-4 font-secondary text-sm lgXl:col-span-7 lgXl:col-start-4 lgXl:row-start-1 lgXl:items-center'>
                  <div className='inline-flex min-h-[36px] flex-nowrap items-center justify-start gap-x-4'>
                    <CartVendor
                      cartVendor={
                        cart.cart.vendor ? cart.cart.vendor : 'Unknown'
                      }
                      cartVendorDisplayName={
                        cart.vendorDisplayName
                          ? cart.vendorDisplayName
                          : cart.cart.vendor
                            ? cart.cart.vendor
                            : 'Unknown'
                      }
                    />
                    <CartId cartId={cart.cart.id} />
                    <CartDate
                      cartTimestamp={cart.cart.timestamp}
                      forGetPage={false}
                    />
                    {/* <CartPublic
                      cartId={cart.cart.id}
                      cartListed={cart.listed || false}
                      forGetPage={false}
                      cartVendorDisplayName={cart.vendorDisplayName || ''}
                    /> */}
                    <CartNotes
                      cartId={cart.cart.id}
                      cartNotes={cart.note || ''}
                      forGetPage={false}
                      cartVendorDisplayName={cart.vendorDisplayName || ''}
                    />
                  </div>
                  <CartTags
                    cartId={cart.cart.id}
                    cartTags={cart.tags || []}
                    forGetPage={false}
                    cartVendorDisplayName={cart.vendorDisplayName || ''}
                  />
                </div>
              </div>
            </li>
          ))}
        </ul>
      ))}

      {fetchingNextPage && (
        <div className='mx-auto w-fit py-6'>
          <SimpleSpinner
            customWidth='w-12'
            customHeight='h-12'
            customPosition='block'
          />
        </div>
      )}
    </div>
  );
};
