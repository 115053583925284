import { utils, writeFile } from 'xlsx';
import { CartGetItem } from '../types';

export type CartToExcelRow = {
  id: string;
  title: string;
  quantity: string | number;
  ccyS: string;
  price: string | number;
  ccy: string;
  sku: string | undefined;
  link: string;
};

export const exportCartToExcel = (
  cartId: string,
  cartCCY: string,
  cartCCYS: string,
  items: CartGetItem[],
  title: string | undefined,
  timestamp: number,
  store: string | undefined,
) => {
  const fileName = (title ? title + ' - ' + cartId : cartId) + '.xlsx';

  const targetLinks: string[] = [];

  const rows: CartToExcelRow[] = items.map((item) => {
    targetLinks.push(item.url);

    return {
      id: item.asin,
      title: item.title ? item.title : '--',
      quantity: item.quantity || 0,
      ccyS: item.ccyS ? item.ccyS : cartCCYS,
      price: item.price
        ? typeof item.price === 'number'
          ? item.price
          : parseFloat(item.price).toFixed(2)
        : '0.00',
      ccy: item.priceccy ? item.priceccy : cartCCY,
      sku: item.skuString ? item.skuString : item.sku,
      link: `https://share-a-cart.com${item.url}`,
    };
  });

  // @ts-expect-error - TS error but still works.
  const ws = utils.json_to_sheet(rows, { origin: 'A5' });
  const wb = utils.book_new();

  // add hyperlinks to Link column
  for (let i = 6, j = 0; j < rows.length; i++, j++) {
    ws[`H${i}`].l = {
      Target: ws[`H${i}`].v,
    };
  }

  // new workbook, worksheet from above, sheetname
  utils.book_append_sheet(wb, ws, 'Cart Items');

  // Header of sheet
  utils.sheet_add_aoa(ws, [['Share-A-Cart.com']], { origin: 'A1' });
  ws['A1'].l = {
    Target: 'https://share-a-cart.com',
  };

  // Cart Timestamp
  utils.sheet_add_aoa(
    ws,
    [[`Cart Created: ${new Date(timestamp).toLocaleString()}`]],
    {
      origin: 'C1',
    },
  );

  if (store) {
    // Vendor Link
    utils.sheet_add_aoa(ws, [[`Cart Vendor: ${store}`]], {
      origin: 'C2',
    });
    ws['C2'].l = {
      Target: `${store}`,
    };
  }

  // Cart Name or Cart ID if cart.title doesn't exist
  utils.sheet_add_aoa(
    ws,
    [[`${title ? 'Cart Name: ' + title : 'Cart ID: ' + cartId}`]],
    {
      origin: 'A2',
    },
  );
  utils.sheet_add_aoa(ws, [[`${title ? 'Cart ID: ' + cartId : ''}`]], {
    origin: 'A3',
  });

  // Add cart link in appropriate row
  const rowToAddCartLink = title ? 'A3' : 'A2';
  ws[`${rowToAddCartLink}`].l = {
    Target: `https://Share-A-Cart.com/get/${cartId}`,
  };

  utils.sheet_add_aoa(
    ws,
    [
      [
        'ID',
        'Title',
        'Quantity',
        'Price',
        'Price',
        'Currency',
        'SKU Data',
        'Link',
      ],
    ],
    {
      origin: 'A5',
    },
  );

  // Column Width
  const wscols = [
    {
      wch: 20,
    }, // ID
    {
      wch: 40,
    }, // Title
    {
      wch: 8,
    }, // Quantity
    {
      wch: 2,
    }, // ccyS - titled Price
    {
      wch: 8,
    }, // Price
    {
      wch: 8,
    }, // Currency
    {
      wch: 20,
    }, // SKU Data
    {
      wch: 40,
    }, // Link
  ];
  ws['!cols'] = wscols;

  // Cell Merging: s = start, e = end, r = row, c = column *0 based*
  const wsmerges = [
    {
      s: {
        r: 1,
        c: 2,
      },
      e: {
        r: 1,
        c: 6,
      },
    },
    {
      s: {
        r: 4,
        c: 3,
      },
      e: {
        r: 4,
        c: 4,
      },
    },
  ];
  ws['!merges'] = wsmerges;

  // Total Quantity
  utils.sheet_add_aoa(ws, [['Quantity Total']], {
    origin: 'C' + (rows.length + 2 + 5),
  });

  ws['C' + (rows.length + 2 + 5)].f = `SUM(C6:C${5 + rows.length})`;

  // Total Price
  utils.sheet_add_aoa(ws, [['Price Total']], {
    origin: 'E' + (rows.length + 2 + 5),
  });

  let formula: string[] | string = [];
  for (let c = 6; c < rows.length + 6; c++) {
    formula.push(`E${c} * C${c}`);
  }

  formula = `=sum(${formula.join(',')})`;

  ws['E' + (rows.length + 2 + 5)].f = formula;

  writeFile(wb, fileName);
};
