import styled from '@emotion/styled';
import { Dialog } from '@mui/material';

export const DialogStyled = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    // padding: 32,
    borderRadius: 16,
    backgroundColor: '#F1F1F1',
    position: 'relative',
    width: '90%',
    maxWidth: 600,
    minWidth: 294,
  },
}));

DialogStyled.defaultProps = {
  disableScrollLock: true,
};
