import { Link } from 'react-router-dom';
import { useAtom } from 'jotai';
import { authUserAtom } from '../../../store';
import travolta from '../../../images/other/travolta.webp';
import { useExtension } from '../../../hooks';
import { ExtensionMissingModal } from '../../reusableBlocks/modals';
import { useTranslation } from 'react-i18next';

type Props = {
  displayedCartsLength: number;
  queryError: boolean;
};

export const CartsDisplayEmpty = ({
  displayedCartsLength,
  queryError,
}: Props) => {
  const [user] = useAtom(authUserAtom);

  const { t } = useTranslation();
  const { importCartsFromExtension } = useExtension();

  return (
    <div className='flex flex-col items-center justify-center pb-20 pt-6 sm:pt-10 md:pt-14'>
      <div className='relative h-32 w-32'>
        <img
          src={travolta}
          width='100%'
          height='100%'
          alt='Travolta Sticker'
          className='absolute'
        />
      </div>
      {/* user can only see this page if they're logged in */}
      {/* if the user has carts attached to their profile but no carts returned from API, display no matching carts */}
      {/* if the user has carts attached to their profile and carts returned from API - we won't get here */}
      {/* if the user has no carts attached to their profile, display the create/import carts buttons */}
      {/* UI for a user that is logged in and has carts attached to their profile but the API didn't return anything due to filters */}
      {displayedCartsLength === 0 &&
      user!.carts?.length !== 0 &&
      !queryError ? (
        <>
          <h2 className='pb-2 pt-4 text-center text-xl font-bold xs:text-2xl'>
            {t('history.no_matching_carts')}
          </h2>
          <h4 className='max-w-[420px] px-6 text-center font-secondary xs:px-0'>
            {t('history.try_refining_search')}
          </h4>
        </>
      ) : queryError ? (
        <>
          <h2 className='pb-2 pt-4 text-center text-xl font-bold xs:text-2xl'>
            {t('history.something_went_wrong')}
          </h2>
          <h4 className='text-center font-secondary'>
            {t('history.please_try_again')}
          </h4>
        </>
      ) : (
        // UI for a user that is logged in but has no carts
        <>
          <h2 className='w-[220px] pb-4 pt-4 text-center text-2xl font-extrabold xs:w-full xs:whitespace-nowrap xs:pb-6 sm:text-3xl'>
            {t('history.you_dont_have_any_carts_yet')}
          </h2>
          <div className='flex flex-col items-center justify-center gap-4 sm:flex-row'>
            <button
              className='button-secondary h-10 xs:h-12'
              onClick={importCartsFromExtension}
            >
              {t('history.import_carts')}
            </button>
            <Link to={`${process.env.REACT_APP_LIVE_URL}/make`} reloadDocument>
              <button className='button-primary h-10 xs:h-12'>
                {t('common.create_a_cart')}
              </button>
            </Link>
          </div>

          <ExtensionMissingModal forImport={true} />
        </>
      )}
    </div>
  );
};
