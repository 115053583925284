import { ReactComponent as ReceiveIcon } from '../../../../images/icons/receive-cart-icon.svg';
import { ReactComponent as CreateIcon } from '../../../../images/icons/create-cart-icon.svg';
import { TooltipStyled } from '../../..';

type Props = {
  created: boolean;
};

export const CartCreatedOrReceived = ({ created }: Props) => {
  return (
    <TooltipStyled title={created ? 'Created cart' : 'Received cart'}>
      {created ? (
        <CreateIcon className='max-h-[26px] min-h-[26px] min-w-[26px] max-w-[26px]' />
      ) : (
        <ReceiveIcon className='max-h-[26px] min-h-[26px] min-w-[26px] max-w-[26px]' />
      )}
    </TooltipStyled>
  );
};
