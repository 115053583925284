export const getCookie = (name: string) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) {
    return parts.pop()!.split(';').shift();
  }
};

export const setCookie = (name: string, value: string) => {
  document.cookie = `${name}=${value}; path=/; max-age=31536000; domain=.share-a-cart.com; samesite=none; secure`;
};

export const removeCookie = (name: string) => {
  document.cookie = `${name}=; path=/; max-age=0; domain=.share-a-cart.com; samesite=none; secure`;
};
