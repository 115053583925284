import {
  CartGetData,
  CartHistoryCacheUpdate,
  HandleUpdateCartDetails,
} from '../types';

export const updateCartDetailsMutation = async ({
  cartId,
  tags,
  listed,
  title,
  note,
}: HandleUpdateCartDetails): Promise<boolean> => {
  const body = {
    cartid: cartId,
    tags: JSON.stringify(tags),
    listed,
    title,
    note,
  };

  const res = await fetch(
    `${process.env.REACT_APP_LIVE_URL}/user/cart/update`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify(body),
    },
  );

  if (!res.ok) {
    throw new Error('Error updating cart... res not ok...');
  }

  // status will be an empty string if successful
  const { status } = await res.json();

  // since status is an empty string if successful, we can check
  // to see if status exists, if it does, it means there was an error
  if (status) {
    throw new Error('Error updating cart... status...');
  }

  // return true to trigger the onSuccess below
  return true;
};

export const updateCartDetailsMutationSuccess = (
  cartsCache: CartHistoryCacheUpdate,
  mutationVariables: HandleUpdateCartDetails,
) => {
  if (!cartsCache) {
    return undefined;
  }

  // if the cart is not in the cache, return undefined to trigger a refetch/invalidation
  if (
    !cartsCache.pages.some((cartPage) =>
      cartPage.carts.some(
        (iteratedCart) => iteratedCart.cart.id === mutationVariables.cartId,
      ),
    )
  ) {
    return undefined;
  }

  const updatedCartsCache: CartHistoryCacheUpdate = {
    pages: [],
    pageParams: [],
  };

  cartsCache.pages.forEach((cartPage, i) => {
    const page = cartPage.carts.map((iteratedCart) => {
      if (iteratedCart.cart.id === mutationVariables.cartId) {
        const updatedCart = { ...iteratedCart };
        updatedCart.cart.title = mutationVariables.title;
        updatedCart.tags = mutationVariables.tags;
        updatedCart.listed = mutationVariables.listed;
        updatedCart.note = mutationVariables.note;

        return { ...updatedCart };
      }

      return iteratedCart;
    });

    updatedCartsCache.pages.push({
      carts: page,
    });

    updatedCartsCache.pageParams.push(i);
  });

  return updatedCartsCache;
};

export const updateCartGetDetailsMutationSuccess = (
  oldData: CartGetData,
  mutationVariables: HandleUpdateCartDetails,
) => {
  if (!oldData || !oldData.ownershipData) {
    return undefined;
  }

  const newCart = { ...oldData, title: mutationVariables.title };

  if (newCart.ownershipData) {
    newCart.ownershipData = {
      ...newCart.ownershipData,
      listed: mutationVariables.listed,
      note: mutationVariables.note,
      tags: mutationVariables.tags,
    };
  }

  return { ...newCart };
};
