import { useQuery } from '@tanstack/react-query';
import { useAtom } from 'jotai';
import { getCartIdAtom } from '../store';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetCartAPI } from '.';

export const useGetCartQuery = () => {
  const [cartId] = useAtom(getCartIdAtom);
  const nav = useNavigate();

  const getCartAPI = useGetCartAPI();

  const cartQuery = useQuery({
    queryKey: ['cart', cartId],
    queryFn: () => getCartAPI(cartId),
    staleTime: 1000 * 60 * 60, // 1 hour
    retry: 0,
  });

  useEffect(() => {
    if (
      cartQuery.isError ||
      cartQuery.data === null ||
      (cartQuery.data === undefined && !cartQuery.isLoading)
    ) {
      nav('/get', { state: { error: true, cartId } });
    }
  }, [cartQuery.isError, cartId, nav, cartQuery.data, cartQuery.isLoading]);

  return cartQuery;
};
