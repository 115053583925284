import React, { useEffect, useState } from 'react';
import type { TypesOfGoodsOpts } from '../../../../../types';
import {
  WelcomeModalTitle,
  WelcomeModalOptionalTextInput,
} from '../WelcomeModalBlocks';
import { useAtom } from 'jotai';
import {
  typesOfGoodsSelectedAtom,
  typesOfGoodsTextAtom,
} from '../../../../../store';

type Props = {
  step: 1 | 2 | 3 | 4 | 5 | 6;
};

const typesOfGoodsOpts = [
  'Groceries',
  'Home',
  'Office',
  'School',
  'Sports',
  'Electronics',
  'Pharmacy',
  'Clothing/Fashion',
  'Other',
];

export const WelcomeModalStep5 = ({ step }: Props) => {
  const [typesOfGoodsSelected, setTypesOfGoodsSelected] = useAtom(
    typesOfGoodsSelectedAtom,
  );
  const [typesOfGoodsText, setTypesOfGoodsText] = useAtom(typesOfGoodsTextAtom);
  const [showOtherTextInput, setShowOtherTextInput] = useState<boolean>(false);

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, value: name } = e.target;

    if (checked) {
      setTypesOfGoodsSelected([
        ...typesOfGoodsSelected,
        name as TypesOfGoodsOpts,
      ]);
    } else {
      setTypesOfGoodsSelected(
        typesOfGoodsSelected.filter((option) => option !== name),
      );
    }
  };

  const handleContainerClick = (e: React.MouseEvent<HTMLLIElement>) => {
    const { id } = e.currentTarget;

    if (typesOfGoodsSelected.includes(id as TypesOfGoodsOpts)) {
      setTypesOfGoodsSelected(
        typesOfGoodsSelected.filter((option) => option !== id),
      );
    } else {
      setTypesOfGoodsSelected([
        ...typesOfGoodsSelected,
        id as TypesOfGoodsOpts,
      ]);
    }
  };

  useEffect(() => {
    if (typesOfGoodsSelected.includes('Other')) {
      return setShowOtherTextInput(true);
    }

    setShowOtherTextInput(false);
    setTypesOfGoodsText('');
  }, [typesOfGoodsSelected, setTypesOfGoodsText]);
  return (
    <>
      <WelcomeModalTitle step={step}>
        What types of goods do you share?
      </WelcomeModalTitle>

      <ul className='grid auto-cols-max gap-x-8 gap-y-4 xs:grid-cols-[min-content_min-content] smMed:grid-cols-[min-content_max-content]'>
        {typesOfGoodsOpts.map((option, i) => (
          <li
            key={i}
            id={option}
            onClick={(e) => handleContainerClick(e)}
            className='text-300 flex w-fit cursor-pointer flex-row items-center justify-start gap-4'
          >
            <input
              type='checkbox'
              value={option}
              checked={typesOfGoodsSelected.includes(
                option as TypesOfGoodsOpts,
              )}
              onChange={handleCheckboxChange}
              className='green-checkbox bg-cloudGray'
            />
            {option}
          </li>
        ))}
        <li className=''>
          <WelcomeModalOptionalTextInput
            show={showOtherTextInput}
            value={typesOfGoodsText}
            changeHandler={(e) => setTypesOfGoodsText(e.target.value)}
          />
        </li>
      </ul>
    </>
  );
};
