import { useTranslation } from 'react-i18next';
import { useAtom } from 'jotai';
import { HistoryFilterByEnum } from 'src/types';
import { historyFilterByAtom } from 'src/store';

type Props = {
  handleClick: () => void;
};

export const FilterItems = ({ handleClick }: Props) => {
  const [filterBy, setFilterBy] = useAtom(historyFilterByAtom);

  const { t } = useTranslation();

  return (
    <>
      {Object.keys(HistoryFilterByEnum).map((filter, i) => (
        <button
          key={`filter-${i}`}
          className={`w-full whitespace-nowrap py-0.5 text-left transition-all ${
            filterBy === filter
              ? 'text-primary-dark hover:text-primary'
              : 'text-primary hover:text-primary-dark'
          }`}
          name={filter}
          onClick={(e) => {
            setFilterBy(
              e.currentTarget.name as keyof typeof HistoryFilterByEnum,
            );
            handleClick();
          }}
        >
          {t(`history.${filter}`)}
        </button>
      ))}
    </>
  );
};
