type Props = {
  variant: 'success' | 'error' | 'warning' | 'info';
  displayLeft?: boolean;
  customY?: string;
  customX?: string;
};

export const AlertPing = ({
  variant,
  displayLeft,
  customX,
  customY,
}: Props) => {
  return (
    <div
      className={`absolute h-2 w-2 animate-ping rounded-full transition-all ${
        customY ? customY : '-top-0.5'
      } ${customX ? customX : displayLeft ? '-left-0.5' : '-right-0.5'} ${
        variant === 'error'
          ? 'bg-rose-500 group-hover:bg-rose-300'
          : variant === 'warning'
          ? 'bg-yellow-500 group-hover:bg-yellow-300'
          : variant === 'info'
          ? 'bg-skyBlue group-hover:bg-[#2E4B93]'
          : 'bg-[#7CFDD7] group-hover:bg-[#207259]'
      }`}
    ></div>
  );
};
