import FullCartIcon from '../../images/icons/full-cart-icon.png';
import EmptyCartIcon from '../../images/icons/empty-cart-icon.png';
import SearchIcon from '@mui/icons-material/Search';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import AddchartIcon from '@mui/icons-material/Addchart';
import { CartHistoryData } from '../../types';
import { InfiniteData } from '@tanstack/query-core';
import { FilterByDropdown } from './FilterByDropdown';
import { MobileDropdown } from './MobileDropdown';
import { SortByDropdown } from './SortByDropdown';
import { exportCartHistoryToExcel } from '../../utils';
import { useRef, useState } from 'react';
import { useAtom } from 'jotai';
import { authUserAtom, historySearchByAtom } from '../../store';
import type { UseInfiniteQueryResult } from '@tanstack/react-query';
import { TooltipStyled } from '../reusableBlocks';
import { useTranslation } from 'react-i18next';
// import { FormatListBulleted as ListViewIcon } from '@mui/icons-material';
// import GridViewIcon from '@mui/icons-material/GridView';

type Props = {
  cartsQuery: UseInfiniteQueryResult<
    InfiniteData<
      {
        carts: CartHistoryData[];
        pageParam: number;
      },
      unknown
    >,
    Error
  >;
  displayedCartsLength: number;
  isListView: boolean;
  setIsListView: React.Dispatch<React.SetStateAction<boolean>>;
  setDebounceDelay: React.Dispatch<React.SetStateAction<number>>;
};

export const CartHistoryHeader = ({
  cartsQuery,
  displayedCartsLength,
  isListView,
  setIsListView,
  setDebounceDelay,
}: Props) => {
  const [user] = useAtom(authUserAtom);
  const [searchBy, setSearchBy] = useAtom(historySearchByAtom);
  const [isSearchFocused, setIsSearchFocused] = useState<boolean>(false);

  const searchInputRef = useRef<HTMLInputElement>(null);

  const { t, i18n } = useTranslation();

  const userCartsLength = user!.carts.length;

  const exportCartData = () => {
    if (!cartsQuery.data || !cartsQuery.data.pages) return;

    exportCartHistoryToExcel(cartsQuery.data.pages);
  };

  return (
    <div className='grid grid-cols-12 gap-y-4 bg-primary-dark p-6 pt-0 font-secondary xs:pt-2 sm:pt-6 md:px-6 lg:px-8 xl:px-12'>
      <div className='order-1 col-span-8 inline-flex flex-nowrap items-center justify-start gap-2 whitespace-nowrap xs:gap-4'>
        {cartsQuery.isLoading && !cartsQuery.isFetchingNextPage ? (
          <div className='h-[58px] w-[58px] animate-pulse rounded-full bg-slate-600'></div>
        ) : user!.carts && userCartsLength !== 0 ? (
          <img
            src={FullCartIcon}
            alt={t('history.full_cart')}
            className='mb-2 w-[50px]'
          />
        ) : (
          <img
            src={EmptyCartIcon}
            alt={t('history.empty_cart')}
            className='mb-2 w-[50px]'
          />
        )}
        <h1
          className={`font-primary text-2xl font-extrabold text-offWhite xs:text-4xl ${i18n.resolvedLanguage === 'de' || i18n.resolvedLanguage === 'pt' ? 'whitespace-pre-wrap break-all' : ''}`}
        >
          {cartsQuery.isLoading ? (
            <div className='h-6 w-28 animate-pulse rounded bg-slate-600 xs:w-36 sm:w-48'></div>
          ) : (
            t('history.header_carts', { count: userCartsLength })
          )}
        </h1>
      </div>
      <div className='order-2 col-span-4 inline-flex items-center justify-end gap-1 md:hidden'>
        <MobileDropdown isListView={isListView} setIsListView={setIsListView} />
        <TooltipStyled title={t('history.export_history_to_excel')}>
          <div
            className={`flex items-center justify-center fill-skyBlue active:scale-90 ${
              displayedCartsLength !== 0
                ? 'cursor-pointer hover:fill-white'
                : 'opacity-20'
            }`}
            onClick={exportCartData}
          >
            <AddchartIcon
              className='cursor-pointer p-0.5 transition-all'
              style={{ fontSize: '29px', fill: 'inherit' }}
            />
          </div>
        </TooltipStyled>
      </div>
      <div className='order-3 col-span-12 inline-flex w-full items-center gap-4 lg:order-2 lg:col-span-9'>
        <div className='group relative w-full max-w-[392px]'>
          <input
            ref={searchInputRef}
            type='text'
            placeholder={t('history.search_carts')}
            onFocus={(e) => {
              setIsSearchFocused(true);
              e.target.placeholder = t('history.type_to_filter_carts');
            }}
            onBlur={(e) => {
              setIsSearchFocused(false);
              e.target.placeholder = t('history.search_carts');
            }}
            onChange={(searchBy) => {
              setSearchBy(searchBy.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();

                setDebounceDelay(0);

                setTimeout(() => {
                  setDebounceDelay(1000);
                }, 20);
              }
            }}
            value={searchBy}
            className='h-10 w-full rounded border-none bg-cloudGray py-2 pl-3 pr-8 font-secondary font-semibold leading-[24px] text-primary-dark outline-none transition-all selection:bg-primary selection:text-cloudGray placeholder:font-normal placeholder:text-[#636E7A] focus:ring-2 focus:ring-primary group-hover:bg-gray-300 group-hover:focus:bg-cloudGray xs:h-12'
          />
          {isSearchFocused ? (
            <div
              // mousedown fires before the onBlur for the above input. So, it will clear in the input AND retain focus on the input.
              onMouseDown={(e) => {
                e.preventDefault();
                setSearchBy('');
              }}
              className='absolute right-2 top-1.5 cursor-pointer fill-darkSteel hover:fill-gray-400 active:scale-[94%] xs:top-2.5'
            >
              <HighlightOffIcon
                className='transition-all'
                style={{ fontSize: '24px', fill: 'inherit' }}
              />
            </div>
          ) : (
            <div
              onClick={() => searchInputRef.current?.focus()}
              className='absolute right-2 top-1.5 cursor-pointer fill-darkSteel hover:fill-gray-400 xs:top-2.5'
            >
              <SearchIcon
                className='transition-all'
                style={{ fontSize: '24px', fill: 'inherit' }}
              />
            </div>
          )}
        </div>
        <div className='hidden gap-4 md:inline-flex'>
          <FilterByDropdown />
          <SortByDropdown />
        </div>
      </div>
      <div className='order-2 col-span-4 hidden items-center justify-end gap-4 md:inline-flex lg:order-3 lg:col-span-3'>
        <TooltipStyled title={t('history.export_history_to_excel')}>
          <span>
            <button
              className={`inline-flex flex-nowrap items-center justify-center gap-1.5 rounded-full bg-primary-off py-3 pl-[18px] pr-5 font-semibold transition-all active:scale-95 ${
                displayedCartsLength !== 0
                  ? 'hover:bg-primary-offHover'
                  : 'opacity-20'
              }`}
              disabled={displayedCartsLength === 0}
              onClick={exportCartData}
            >
              <AddchartIcon
                className='p-0.5 text-2xl text-skyBlue'
                style={{ fontSize: '24px' }}
              />
              <span className='text-cloudGray'>{t('common.export')}</span>
            </button>
          </span>
        </TooltipStyled>
        {/* <ListViewIcon
            className={`cursor-pointer p-0.5 transition-all active:scale-95 ${
              isListView
                ? 'text-white hover:text-skyBlue'
                : 'text-skyBlue hover:text-white'
            }`}
            style={{ fontSize: '30px' }}
            onClick={() => setIsListView(true)}
          /> */}
        {/* <GridViewIcon
            className={`cursor-pointer p-0.5 transition-all active:scale-95 ${
              !isListView
                ? 'text-white hover:text-skyBlue'
                : 'text-skyBlue hover:text-white'
            }`}
            style={{ fontSize: '30px' }}
            onClick={() => setIsListView(false)}
          /> */}
      </div>
    </div>
  );
};
