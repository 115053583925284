import { useTranslation } from 'react-i18next';

export const GetFeedbackThanks = () => {
  const { t } = useTranslation();

  return (
    <div className='flex h-[255px] w-full max-w-[600px] flex-col items-center justify-center gap-4 rounded-lg bg-customYellow px-1 py-6 sm:px-6'>
      <div className='flex h-[175px] w-full max-w-[300px] flex-col items-center justify-between rounded-2xl bg-primary-dark p-4'>
        <div className='flex w-full flex-row flex-nowrap items-start justify-between'>
          <span className='font-primary text-[32px] font-extrabold leading-8 text-white'>
            <span className='text-customYellow '>$</span>5
          </span>

          <span className='text-nowrap font-primary text-[22px] font-extrabold leading-[22px] text-customYellow'>
            {t('get.gift_card')}
          </span>
        </div>

        <div className='flex w-full flex-col items-start justify-center gap-2 p-2'>
          <span className='heading-100 text-offWhite'>
            {t('get.im_on_my_way')}
          </span>
          <span className='font-secondary text-sm leading-[22.4px] text-customYellow'>
            {t('get.well_email_you_your_gift_card_soon')}
          </span>
        </div>
      </div>
    </div>
  );
};
