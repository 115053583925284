import { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { ReactComponent as ImgUnavailable } from '../../../images/other/unavailable.svg';
import type { CartGetItem } from '../../../types';
import styled from '@emotion/styled';
import { TooltipProps, Tooltip, tooltipClasses } from '@mui/material';
import { VendorLogoBlock } from '../reuseables';
import { useTranslation } from 'react-i18next';
import { decode } from 'html-entities';
import { formatPrice } from 'src/utils/formatPrice';

type Props = {
  item: CartGetItem;
  ccy: string;
  ccys: string;
  vendor: string;
  compare: boolean;
  locale: string;
};

export const GetCartItem = ({
  item,
  ccy,
  ccys,
  vendor,
  compare,
  locale,
}: Props) => {
  const [showItemImage, setShowItemImage] = useState<boolean>(false);
  const [itemLink, setItemLink] = useState(item.url);
  let [searchParams] = useSearchParams();
  const [showPrice, setShowPrice] = useState<boolean>(true);
  const [formattedPrice, setFormattedPrice] = useState<string>('');

  const { t } = useTranslation();

  /**
   * if item price doesn't exist
   * OR if item price does exist, is a string, and includes -1
   * OR if item price does exist, is a number, and equals -1
   * then don't show the price
   */
  useEffect(() => {
    if (
      !item.price ||
      (typeof item.price === 'string' && item.price.includes('-1')) ||
      item.price === -1
    ) {
      setShowPrice(false);
    } else {
      const priceInt =
        typeof item.price === 'number' ? item.price : parseFloat(item.price);
      setFormattedPrice(formatPrice(priceInt, locale, ccy, ccys, false));
    }
  }, [ccy, ccys, item.price, locale]);

  useEffect(() => {
    const tag = searchParams.get('tag');
    if (tag && vendor === 'amazon') {
      setItemLink(`${itemLink}?AssociateTag=${tag}&tag=${tag}`);
    }
  }, [searchParams, item, vendor, itemLink]);

  useEffect(() => {
    if (item.image) {
      setShowItemImage(true);
    }
  }, [item]);

  return (
    <li className='flex w-full items-center justify-center border-b border-blueGray'>
      <div className='flex w-full max-w-[1440px] flex-col items-start justify-center gap-2 p-4 xs:flex-row xs:items-center sm:items-center sm:gap-4 sm:p-6 md:gap-6 lg:px-[112px]'>
        <div className='flex h-[110px] max-h-[110px] min-h-[110px] w-[110px] min-w-[110px] max-w-[110px] grow-0 items-center justify-center'>
          {showItemImage ? (
            <img
              className='h-auto max-h-[110px] w-auto max-w-[110px]'
              src={item.image}
              alt={item.title ? decode(item.title) : 'item'}
              loading='lazy'
              onError={() => setShowItemImage(false)}
            />
          ) : (
            <ImgUnavailable className='h-[110px] max-h-[110px] min-h-[110px] w-[110px] min-w-[110px] max-w-[110px]' />
          )}
        </div>
        <div className='flex h-full min-h-[110px] w-full grow flex-col items-start justify-between gap-2 sm:flex-row sm:items-center sm:gap-4 md:gap-6'>
          <div className='flex grow flex-col items-start justify-center sm:w-min'>
            <h5 className='heading-200'>
              <Link
                to={`https://share-a-cart.com${item.url}`}
                target='_blank'
                rel='noopener noreferrer'
                className='hover:underline'
              >
                {item.title ? decode(item.title) : item.asin}
              </Link>
            </h5>
            {(item.skuString || item.sku) && (
              <p className='text-100' style={{ overflowWrap: 'anywhere' }}>
                <b className='font-bold'>SKU:</b>&nbsp;
                {item.skuString || item.sku}
              </p>
            )}
            {!item.title && (
              <MissingDetailsTooltip
                title={
                  <>
                    <p className='font-secondary'>
                      {decode(t('common.missing_details'))}
                    </p>
                  </>
                }
                placement='top'
                arrow
              >
                <div className='group inline-flex items-center justify-center gap-1 fill-primary pt-2 hover:fill-primary/70'>
                  <InfoOutlinedIcon style={{ fill: 'inherit', fontSize: 24 }} />
                  <span className='heading-100 text-primary group-hover:text-primary/70'>
                    {t('common.couldnt_fetch_details')}
                  </span>
                </div>
              </MissingDetailsTooltip>
            )}
          </div>
          <div
            className={`ml-auto flex flex-row flex-nowrap items-center justify-between xs:gap-x-6 sm:gap-4 md:gap-6 ${showPrice && 'w-full xs:w-fit xs:min-w-[240px]'}`}
          >
            <div className='inline-flex items-center justify-center gap-3'>
              <span className='text-200 whitespace-nowrap'>
                {t('common.qty')}
              </span>
              <div className='flex h-10 w-[43px] items-center justify-center rounded-lg'>
                <span className='font-secondary text-base font-semibold leading-[16px] text-primary-dark'>
                  {item.quantity}
                </span>
              </div>
            </div>
            {/* show on the compare page regardless of status of price */}
            {(showPrice || compare) && (
              <div className='flex flex-col items-end justify-center gap-y-2 lg:flex-row lg:items-center lg:gap-x-2'>
                {compare && item.savingsBool === true
                  ? (() => {
                      const savingsInt = item.savings
                        ? typeof item.savings === 'number'
                          ? item.savings
                          : parseFloat(item.savings)
                        : 0;
                      const formattedSavings = formatPrice(
                        savingsInt,
                        locale,
                        ccy,
                        ccys,
                        false,
                      );

                      return (
                        <div className='flex min-w-fit max-w-fit flex-row flex-nowrap items-center justify-center whitespace-nowrap rounded-md bg-customYellow px-2 py-0'>
                          <span className='text-200'>
                            {t('get.save')}&nbsp;
                          </span>
                          <span className='heading-200'>
                            {formattedSavings}
                          </span>
                        </div>
                      );
                    })()
                  : null}
                <div className='flex flex-row flex-nowrap items-center justify-center gap-1'>
                  {compare && <VendorLogoBlock vendor={vendor} />}
                  <span className='heading-200 text-end'>{formattedPrice}</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </li>
  );
};

const MissingDetailsTooltip = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ),
)(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#F1F1F1',
    color: '#04033C',
    fontSize: 14,
    fontWeight: 500,
    borderRadius: 8,
    borderWidth: 2,
    borderColor: '#3032DC',
    padding: 16,
    fontFamily: 'Open Sans',
    marginInline: 4,
    marginTop: '6px !important',
    marginBottom: '0px !important',
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25)',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: '#F1F1F1',
    fontSize: 20,
    '&:before': {
      border: '2px solid #3032DC',
    },
  },
  [`& .${tooltipClasses.popper}`]: {
    color: '#F1F1F1',
  },
  [`& .${tooltipClasses.popperArrow}`]: {
    color: '#F1F1F1',
  },
}));
