import { Grow } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { forwardRef, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import {
  useShowWelcomeModal,
  useSubmitSurvey,
  useWelcomeModalContinueButtonState,
} from '../../../hooks';
import { WelcomeModalStepsDisplay } from './WelcomeModal/WelcomeModalSteps';
import { WelcomeModalStyled } from './WelcomeModal/WelcomeModalStyled';
import { WelcomeModalIcon } from './WelcomeModal/WelcomeModalBlocks';

export const WelcomeModal = () => {
  const [step, setStep] = useState<1 | 2 | 3 | 4 | 5 | 6>(1);
  const [disableContinueBtn, setDisableContinueBtn] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);

  const submitSurvey = useSubmitSurvey();

  useShowWelcomeModal({ setOpen });

  useWelcomeModalContinueButtonState({
    step,
    setDisableContinueBtn,
  });

  const handleClose = () => {
    submitSurvey.mutate();

    setOpen(false);
  };

  return (
    <WelcomeModalStyled
      open={open}
      TransitionComponent={Transition}
      aria-describedby='Welcome to SAC Plus!'
    >
      <div className='flex flex-col items-center justify-center gap-6 overflow-hidden p-4 py-6 sm:p-6 md:p-8'>
        <div
          onClick={handleClose}
          className='absolute right-3 top-2.5 z-[1] cursor-pointer fill-darkSteel hover:fill-gray-400'
        >
          <CloseIcon
            className='transition-all'
            style={{ fontSize: '24px', fill: 'inherit' }}
          />
        </div>

        <WelcomeModalIcon step={step} />

        <WelcomeModalStepsDisplay
          step={step}
          setStep={setStep}
          disabledContinueBtn={disableContinueBtn}
          handleClose={handleClose}
        />
      </div>
    </WelcomeModalStyled>
  );
};

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Grow ref={ref} {...props} />;
});
