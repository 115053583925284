// fun words to describe the carts
export const adjectives = [
  'Amazing',
  'Astonishing',
  'Awesome',
  'Brilliant',
  'Cool',
  'Excellent',
  'Exceptional',
  'Exquisite',
  'Extraordinary',
  'Fabulous',
  'Fantastic',
  'Fine',
  'Grand',
  'Great',
  'Impressive',
  'Inspiring',
  'Magnificent',
  'Miraculous',
  'Rad',
  'Remarkable',
  'Spectacular',
  'Splendid',
  'Stunning',
  'Striking',
  'Sublime',
  'Super',
  'Superb',
  'Terrific',
  'Wonderful',
];
