import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useTranslation } from 'react-i18next';

export const GetReviewHeaderAllCartsBtn = () => {
  const [showAllCartsButton, setShowAllCartsButton] = useState<boolean>(false);

  const location = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    if (
      location.state &&
      location.state.from &&
      location.state.from === 'history'
    ) {
      return setShowAllCartsButton(true);
    }

    return setShowAllCartsButton(false);
  }, [location.state]);

  if (!showAllCartsButton) {
    return null;
  }

  return (
    <Link
      to='/plus/cart-history'
      className='heading-100 inline-flex items-center justify-center text-center text-skyBlue transition-all hover:text-white'
    >
      <ChevronLeftIcon className='-ml-1.5 -mr-0.5 -mt-0.5' />
      <h4>{t('get.all_carts')} </h4>
    </Link>
  );
};
