import { useTranslation } from 'react-i18next';

type Props = {
  step: 1 | 2 | 3 | 4 | 5 | 6;
  setStep: React.Dispatch<React.SetStateAction<1 | 2 | 3 | 4 | 5 | 6>>;
  disabled: boolean;
  handleClose: () => void;
};

export const WelcomeModalContinueButton = ({
  step,
  setStep,
  disabled,
  handleClose,
}: Props) => {
  const { t } = useTranslation();

  const handleNextStep = () => {
    if (step < 6) {
      return setStep((step + 1) as 1 | 2 | 3 | 4 | 5 | 6);
    }

    handleClose();
  };

  return (
    <button
      onClick={handleNextStep}
      className='button-secondary h-12'
      disabled={disabled}
    >
      {step === 1
        ? t('popup.continue_to_setup')
        : step === 6
          ? t('popup.go_to_dashboard')
          : t('popup.next')}
    </button>
  );
};
