import { forwardRef, useEffect, useState } from 'react';
import { TransitionProps } from '@mui/material/transitions';
import { Dialog, Grow, styled } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { toast } from 'sonner';
import { ToastStyled } from '../ToastStyled';
import { useTranslation } from 'react-i18next';

type Props = {
  cartId: string;
  show: boolean;
  setShow: (value: boolean) => void;
};

export const EmbedModal = ({ cartId, show, setShow }: Props) => {
  const [affiliateTagInput, setAffiliateTagInput] = useState<string>('');
  const [enableAutoHeight, setEnableAutoHeight] = useState<boolean>(false);
  const [embedSnippet, setEmbedSnippet] = useState<string>('');

  const { t } = useTranslation();

  useEffect(() => {
    const baseSnippet = `<script id="sac-widget" src="https://share-a-cart.com/widget/${cartId}/`;
    let extraSnippet = '';
    const finalSnippet = '"></script>';

    if (affiliateTagInput !== '' || enableAutoHeight) {
      extraSnippet += '?';
    }
    if (affiliateTagInput !== '') {
      extraSnippet += `tag=${affiliateTagInput}`;
    }
    if (enableAutoHeight && affiliateTagInput !== '') {
      extraSnippet += '&';
    }
    if (enableAutoHeight) {
      extraSnippet += 'opts=autoheight';
    }

    setEmbedSnippet(baseSnippet + extraSnippet + finalSnippet);
  }, [affiliateTagInput, enableAutoHeight, cartId]);

  const handleClickCopy = () => {
    navigator.clipboard.writeText(embedSnippet);
    toast.custom(
      (toastBlock) => (
        <ToastStyled
          variant='info'
          toastTitle={t('popup.copied_to_clipboard')}
          handleClose={() => toast.dismiss(toastBlock)}
        >
          {t('popup.copied_embed_snippet_to_clipboard')}
        </ToastStyled>
      ),
      {
        duration: 2000,
      },
    );
  };

  return (
    <EmbedDialogStyled
      open={show}
      onClose={() => setShow(false)}
      TransitionComponent={Transition}
      aria-describedby='Embed this widget into your website.'
    >
      <div
        onClick={() => setShow(false)}
        className='absolute right-3 top-2.5 cursor-pointer fill-darkSteel hover:fill-gray-400'
      >
        <CloseIcon
          className='transition-all'
          style={{ fontSize: '24px', fill: 'inherit' }}
        />
      </div>
      <div className='flex flex-col items-center justify-center gap-4 p-4 sm:py-6 md:gap-6 md:px-6 md:py-8'>
        <div className='flex flex-col items-center justify-center gap-2 md:gap-3'>
          <div className='flex h-12 w-12 items-center justify-center rounded-full bg-indigo-200'>
            {/* Heroicon name: outline/code */}
            <svg
              xmlns='http://www.w3.org/2000/svg'
              className='h-8 w-8 text-indigo-600'
              fill='none'
              viewBox='0 0 24 24'
              stroke='currentColor'
              strokeWidth='2'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4'
              />
            </svg>
          </div>

          <h3 className='heading-300 text-center'>
            {t('popup.embed_this_list')}
          </h3>
          <p className='text-200'>
            {t(
              'popup.to_insert_this_widget_into_your_website_please_copy_and_paste_code_below',
            )}
          </p>
        </div>

        <div className='flex w-full flex-col items-start justify-center gap-1 md:gap-2'>
          <div className='flex min-h-[48px] w-full flex-col items-start justify-center gap-x-4 gap-y-1 sm:flex-row sm:items-center md:gap-y-1.5'>
            <label className='text-200 w-[140px] min-w-[140px] whitespace-nowrap text-left sm:text-right'>
              {t('popup.affiliate_tag')}
            </label>
            <input
              value={affiliateTagInput}
              onChange={(e) => setAffiliateTagInput(e.target.value)}
              className='h-12 w-full rounded border-none bg-offWhite px-3 py-2 font-secondary font-semibold leading-[24px] text-primary-dark outline-none transition-all selection:bg-primary selection:text-offWhite placeholder:font-normal placeholder:text-[#636E7A] hover:bg-gray-200 focus:ring-2 focus:ring-primary hover:focus:bg-offWhite'
            />
          </div>

          <div className='flex h-12 w-full flex-row items-center justify-start gap-x-4 gap-y-1 md:gap-y-1.5'>
            <label className='text-200 w-fit min-w-[140px] whitespace-nowrap text-right'>
              {t('popup.enable_auto_height')}
            </label>
            <input
              type='checkbox'
              checked={enableAutoHeight}
              onChange={() => setEnableAutoHeight(!enableAutoHeight)}
              className=''
            />
          </div>
        </div>

        <div
          onClick={handleClickCopy}
          className='flex min-h-24 w-full cursor-pointer flex-row items-center justify-center gap-1 rounded-lg bg-offWhite p-2 transition-all hover:bg-gray-200'
        >
          <p
            style={{ overflowWrap: 'anywhere' }}
            className='text-200'
          >{`<script id="sac-widget" src="https://share-a-cart.com/widget/${cartId}/${affiliateTagInput !== '' || enableAutoHeight ? '?' : ''}${affiliateTagInput !== '' ? `tag=${affiliateTagInput}` : ''}${enableAutoHeight && affiliateTagInput !== '' ? '&' : ''}${enableAutoHeight ? 'opts=autoheight' : ''}"></script>`}</p>
          <div className='flex items-center justify-center fill-primary hover:fill-primary/70'>
            <ContentCopyIcon
              className='transition-all'
              style={{ fontSize: 20, fill: 'inherit' }}
            />
          </div>
        </div>

        <div className='flex w-full flex-col flex-nowrap items-center gap-4 sm:flex-row-reverse'>
          <button
            onClick={handleClickCopy}
            className='button-primary h-12 w-full sm:w-auto'
          >
            {t('popup.copy_to_clipboard')}
          </button>
          <button
            onClick={() => setShow(false)}
            className='button-secondary h-12 w-full sm:w-auto'
          >
            {t('common.close')}
          </button>
        </div>
      </div>
    </EmbedDialogStyled>
  );
};

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Grow ref={ref} {...props} />;
});

export const EmbedDialogStyled = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    // padding: 32,
    borderRadius: 16,
    backgroundColor: '#F1F1F1',
    position: 'relative',
    width: '90%',
    maxWidth: 532,
    minWidth: 294,
  },
}));
