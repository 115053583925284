import { cva } from 'class-variance-authority';

export const changelogTagVariants = cva(
  'font-openSans w-fit whitespace-nowrap rounded px-4 py-2 text-center text-sm font-semibold leading-[14px]',
  {
    variants: {
      variant: {
        all: 'text-300 text-lg',
        improvement: 'bg-[#7CFDD7] text-[#207259]',
        safari: 'bg-[#D3E0FF] text-[#425FA9]',
        firefox: 'bg-[#FFD3D3] text-[#A93F3F]',
        bug_fix: 'bg-[#F7DED9] text-[#7D5D57]',
        retailer_added: 'bg-[#FFE9AF] text-[#78683D]',
      },
    },
  },
);
