import { NavLink } from 'react-router-dom';
import { ReactComponent as SacPlusFire } from '../../images/icons/sac_plus_fire.svg';
import { useTranslation } from 'react-i18next';
import { useAtom } from 'jotai';
import { authUserAtom } from '../../store';

type Props = {
  forDropdown?: boolean;
  clickHandler?: () => void;
};

export const SacPlusBlock = ({ forDropdown, clickHandler }: Props) => {
  const { t } = useTranslation();
  const [user] = useAtom(authUserAtom);

  return (
    <>
      {/* If user doesn't exist OR if user exists and they're NOT subscribed to SAC+, display SAC+ link. */}
      {/* {!user || user.subscription?.level === 'free' ? ( */}
      <NavLink
        to={`${process.env.REACT_APP_LIVE_URL}/plus${user ? '/cart-history' : ''}`}
        reloadDocument
        onClick={clickHandler}
        className={`inline-flex flex-nowrap items-center justify-start gap-2 whitespace-nowrap transition-all active:scale-95 medLg:gap-1 lg:gap-2 ${
          forDropdown
            ? 'w-full rounded-md px-1 py-0.5 text-primary hover:bg-gray-300/60'
            : 'px-1 py-1 text-skyBlue hover:text-white'
        }`}
      >
        <SacPlusFire width={forDropdown ? 18 : 25} title='Fire Icon' />
        <p>
          {t('common.share_a_cart')}
          <span className={!forDropdown ? 'text-customYellow' : ''}>+</span>
        </p>
      </NavLink>
      {/* ) : (
        ''
      )} */}
    </>
  );
};
