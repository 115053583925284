import { NavLink } from 'react-router-dom';
import { ReactComponent as CreateSvg } from '../../images/icons/create.svg';
import { useTranslation } from 'react-i18next';

type Props = {
  forDropdown?: boolean;
  clickHandler?: () => void;
};

export const CreateACartBlock = ({ forDropdown, clickHandler }: Props) => {
  const { t } = useTranslation();

  return (
    <NavLink
      to={`${process.env.REACT_APP_LIVE_URL}/make`}
      reloadDocument
      onClick={clickHandler}
      className={`group inline-flex flex-row flex-nowrap items-center justify-start gap-2 whitespace-nowrap transition-all active:scale-[97%]
        ${
          forDropdown
            ? 'h-7 w-full rounded-md px-1 py-0.5 text-primary hover:bg-gray-300/60'
            : 'w-fit px-1 py-1 text-skyBlue hover:text-white'
        }
      `}
    >
      <CreateSvg
        width={forDropdown ? 18 : 20}
        title='Create Cart Icon'
        className={`${
          forDropdown
            ? 'fill-primary pt-0.5'
            : 'fill-skyBlue group-hover:fill-white'
        } transition-all`}
      />
      {t('common.create_a_cart')}
    </NavLink>
  );
};
