import dayjs from 'dayjs';
import { TooltipStyled } from '..';
import { useTranslation } from 'react-i18next';

type Props = {
  cartTimestamp: number;
  forGetPage: boolean;
};

export const CartDate = ({ cartTimestamp, forGetPage }: Props) => {
  const { t } = useTranslation();

  return (
    <TooltipStyled title={t('history.cart_creation_date')}>
      <span
        className={`text-100 whitespace-nowrap text-right ${forGetPage ? '' : 'min-w-[86px]'}`}
      >
        {dayjs(cartTimestamp).format("D MMM 'YY")}
      </span>
    </TooltipStyled>
  );
};
