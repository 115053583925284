import { ToastContentBlock } from './ToastContentBlock';
import CloseIcon from '@mui/icons-material/Close';

type Props = {
  children?: React.ReactNode;
  variant: 'success' | 'error' | 'warning' | 'info';
  toastTitle?: string;
  handleClick?: () => void;
  handleClose?: () => void;
};

export const ToastStyled = ({
  variant,
  children,
  toastTitle,
  handleClick,
  handleClose,
}: Props) => {
  return (
    <div
      onClick={() => {
        if (handleClick) {
          handleClick();
        }
        if (handleClose) {
          handleClose();
        }
      }}
      className={`relative flex cursor-pointer flex-col items-center justify-center rounded-lg ${
        variant === 'success'
          ? 'bg-[#7CFDD7] text-[#207259]'
          : variant === 'error'
            ? 'bg-errorRedLight text-errorRedDark'
            : variant === 'warning'
              ? 'bg-[#FFE9AF] text-[#78683D]'
              : 'bg-skyBlue text-[#2E4B93]'
      }`}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
          if (handleClose) {
            handleClose();
          }
        }}
        className={`absolute right-2 top-2 cursor-pointer ${
          variant === 'success'
            ? 'fill-[#207259] hover:fill-[#4EB798]'
            : variant === 'error'
              ? 'fill-errorRedDark hover:fill-[#A3746A]'
              : variant === 'warning'
                ? 'fill-[#78683D] hover:fill-[#BBA876]'
                : 'fill-[#2E4B93] hover:fill-[#7890C9]'
        }`}
      >
        <CloseIcon
          className='transition-all'
          style={{ fontSize: '24px', fill: 'inherit' }}
        />
      </div>
      <ToastContentBlock variant={variant} toastTitle={toastTitle}>
        {children}
      </ToastContentBlock>
    </div>
  );
};
