import { useEffect, useState } from 'react'

export const useActiveBrowser = () => {
  const [activeBrowser, setActiveBrowser] = useState<'Chrome' | 'Firefox' | 'Edge' | 'Safari'>('Chrome');

  useEffect(() => {
    if (navigator.userAgent.indexOf('Edg') !== -1) {
      return setActiveBrowser('Edge');
    }

    if (/Firefox|FxiOS/.test(navigator.userAgent)) {
      return setActiveBrowser('Firefox');
    }

    if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
      return setActiveBrowser('Safari');
    }

    return setActiveBrowser('Chrome');
  }, [])
  
  return (
    activeBrowser
  )
}
