import { Link } from 'react-router-dom';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { GetFeedbackReviewCTA } from './getFeedbackBlocks';
import { useAtom } from 'jotai';
import { getCartIdAtom } from '../../store';
import { useState } from 'react';
import {
  GetFeedbackHappy,
  GetFeedbackSad,
  GetFeedbackThanks,
} from './getFeedbackBlocks';
import { useTranslation } from 'react-i18next';

import type { TReviewFormState } from '../../types';

export const GetFeedback = () => {
  const [getCartId] = useAtom(getCartIdAtom);
  const [reviewFormState, setReviewFormState] =
    useState<TReviewFormState>(undefined);

  const { t } = useTranslation();

  return (
    <div className='w-full bg-primary-dark'>
      <div className='ml-4 pt-10'>
        <Link
          to={`/get/${getCartId}`}
          className='heading-100 inline-flex items-center justify-center text-center text-skyBlue transition-all hover:text-white'
        >
          <ChevronLeftIcon className='-ml-1.5 -mr-0.5 -mt-0.5' />
          <h4>{t('get.back_to_cart')}</h4>
        </Link>
      </div>
      <div
        className={`flex flex-col items-center justify-center gap-10 overflow-x-hidden bg-primary-dark pb-40 pt-4 ${reviewFormState === 'thanks' ? 'px-1' : 'px-8'}`}
      >
        {!reviewFormState && (
          <GetFeedbackReviewCTA setReviewFormState={setReviewFormState} />
        )}
        {reviewFormState === 'happy' && (
          <GetFeedbackHappy setReviewFormState={setReviewFormState} />
        )}
        {reviewFormState === 'sad' && (
          <GetFeedbackSad setReviewFormState={setReviewFormState} />
        )}
        {reviewFormState === 'thanks' && <GetFeedbackThanks />}
      </div>
    </div>
  );
};
