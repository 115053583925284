import { useTranslation } from 'react-i18next';
import { useScrollToTop } from '../hooks';

export const ComingSoon = () => {
  const { t } = useTranslation();

  useScrollToTop();

  return (
    <div className='flex flex-col items-center justify-center gap-8 bg-primary-dark px-10 pb-60 pt-10'>
      <h2 className='text-center font-primary text-5xl font-bold text-offWhite'>
        {t('common.coming_soon')}
      </h2>
      <p className='text-center font-secondary text-lg text-skyBlue'>
        {t(
          'other.we_are_working_hard_to_bring_you_this_feature_please_check_back_later',
        )}
      </p>
    </div>
  );
};
