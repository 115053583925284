import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAtom } from 'jotai';
import { useEffect } from 'react';
import { authUserAtom } from 'src/store';
import { CookieCart, UserProfile } from 'src/types';
import { getCookie, removeCookie } from 'src/utils';

export const useAssociateCartCookie = () => {
  const [user, setUser] = useAtom(authUserAtom);

  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: async (carts: CookieCart[]) => {
      const res = await fetch(
        `${process.env.REACT_APP_LIVE_URL}/user/cart/associate`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
          body: JSON.stringify(carts),
        },
      );

      if (!res.ok) {
        throw new Error('Error associating localStorage carts...');
      }

      return true;
    },
    onSuccess: (mutationReturn, variables) => {
      removeCookie('cookieCarts');

      const cartIds = variables.map((c) => c.cartid);

      // Update user atom to contain new cart
      setUser((oldUser) => {
        if (!oldUser) {
          return oldUser;
        }

        return {
          ...oldUser,
          carts: [...oldUser.carts, ...cartIds],
        };
      });
      // Update user in cache to contain new cart
      queryClient.setQueryData(['currentUser'], (oldData: UserProfile) => {
        return {
          ...oldData,
          carts: [...oldData.carts, ...cartIds],
        };
      });
      // There should be only 1 cart list query at this point.
      // Reasoning: This runs when the user logs in and they have carts in localStorage.
      queryClient.refetchQueries({
        queryKey: ['cart', 'list'],
        exact: false,
      });
    },
    onError: (err) => {
      console.error(err);
    },
  });

  return useEffect(() => {
    if (mutation.isPending) {
      return;
    }

    const cookieCarts = getCookie('cookieCarts');

    if (cookieCarts) {
      const carts: CookieCart[] = JSON.parse(decodeURIComponent(cookieCarts));

      const cartsToAssociate = carts.reduce((acc, cart) => {
        if (!user!.carts.some((c) => c === cart.cartid)) {
          acc.push(cart);
        }

        return acc;
      }, [] as CookieCart[]);

      if (cartsToAssociate.length > 0) {
        mutation.mutate(cartsToAssociate);
      } else {
        removeCookie('cookieCarts');
      }
    }
  }, [mutation, user]);
};
